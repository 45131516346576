import React, { useState } from 'react';
import { Route, Link } from "react-router-dom";
import '../mlresource/style.scss';
import Dropdown from 'react-bootstrap/Dropdown';

const MlDropdown = () => (
  <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
          <i class="fas fa-user"></i>
      </Dropdown.Toggle>

      <Dropdown.Menu>
          <div className="d-flex">
              <Dropdown.Item href="#" className="dk-active">
                  <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/5a701dc2182ac.png" className="img-fluid img-circle" alt="" />
              </Dropdown.Item>
              <Dropdown.Item href="#" className="dk-active">
                  <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/5c041fb96042e.png" className="img-fluid img-circle" alt="" />
              </Dropdown.Item>
              <Dropdown.Item href="#" className="dk-active">
                  <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="img-fluid img-circle" alt="" />
              </Dropdown.Item>
              <Dropdown.Item href="#" className="dk-active">
                  <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/5ad5bd2c15416.png" className="img-fluid img-circle" alt="" />
              </Dropdown.Item>
              <Dropdown.Item href="#" className="dk-active">
                  <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/5ad49d2ca540d.png" className="img-fluid img-circle" alt="" />
              </Dropdown.Item>
              <Dropdown.Item href="#" className="dk-active">
                  <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/5ad5c2940e458.png" className="img-fluid img-circle" alt="" />
              </Dropdown.Item>
          </div>
      </Dropdown.Menu>
  </Dropdown>
)
  
export default MlDropdown;
