import React, { useEffect } from "react";
import AdminHeader from "../elements/header/adminHeader";
import AdminNavbar from "../elements/header/navbar/mainMenu/admin/AdminNavbar";

const Layout = ({ children }) => {

    return (
        <React.Fragment>
            <AdminHeader />
            <AdminNavbar />
            {children}
        </React.Fragment>
    )
}
export default Layout;