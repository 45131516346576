import React, { useState, useEffect } from "react";
import { Route, Link } from "react-router-dom";
import EditJobProfileModal1 from "./EditJobProfileModal1";
import AddJobProfileModal1 from "./AddJobProfileModal";
import AddJobProfileModal2 from "./AddJobProfileModal2";
import AddJobProfileModal3 from "./AddJobProfileModal3";
import { useDispatch, useSelector } from "react-redux";
import Authentication from "../../helpers/authenticate";
import Tooltip from "../../ui/tooltip";
import { IMG_CLOUD_BASE_URL } from "../../../constants";
import { showError, showSuccess, clearMessage } from "../../../slice/utils/message/messageSlice";
import { listTable, clearState, addHeadings, updateHeadings } from "../../../slice/jobProfile/jobProfileSlice";
import DisplayResponsbility from "./DisplayResponsbility";

const JobProfile = () => {
    const dispatch = useDispatch();
    const { usersList } = useSelector(state => state.users);
    const { dataList, isInsert, isUpdate } = useSelector(state => state.jobProfile);
    const [userId, setUserId] = useState(0);

    const [show1, setShow1] = useState(false)
    const [show2, setShow2] = useState(false)
    const [show3, setShow3] = useState(false)

    const [showEdit1, setShowEdit1] = useState(false)
    const [showEdit2, setShowEdit2] = useState(false)
    const [showEdit3, setShowEdit3] = useState(false)

    const [showEditResponsibilities, setShowEditResponsibilities] = useState(false)
    const [responbility, setResponbility] = useState('')
    const [responbilityId, setResponbilityId] = useState(0)

    useEffect(() => {
        (usersList && usersList.length > 0) ?
            setUserId(usersList[0]._id)
            :
            setUserId('0')
    }, [usersList])

    useEffect(() => {
        async function fetchRules() {
            try {
                await dispatch(listTable({ userId: userId }))
            } catch (err) {
                dispatch(showError({ msg: err }))
            }
        }
        fetchRules()
    }, [userId])

    const handleShow1 = () => {
        setShow1(true)
    }

    const handleClose1 = () => {
        setShow1(false)
    }

    const handleShow2 = () => {
        setShow2(true)
    }

    const handleClose2 = () => {
        setShow2(false)
    }

    const handleShow3 = () => {
        setShow3(true)
    }

    const handleClose3 = () => {
        setShow3(false)
    }

    const handleShowEdit1 = () => {
        setShowEdit1(true)
    }

    const handleCloseEdit1 = () => {
        setShowEdit1(false)
    }

    const getProfile = (id) => {
        setUserId(id)
        setResponbility('')
        setShowEditResponsibilities(false)
    }

    const handleEditResponsibilities = () => {
        setShowEditResponsibilities(true)
        let list = dataList.filter(items => items.boxId == 4)
        if (list.length > 0) {
            setResponbilityId(list[0]._id)
            list = list[0].name;
            setResponbility(list)
        }
        // list.split('\n').map((str =>
        //     responbility.push(str)
        // ))
        // console.log(responbility)
        // dataList.filter(items => items.boxId == 4).map(filteredItems => (
        //     responbility.push(filteredItems.name)
        // ))
    }

    const handleSaveResponsibilities = async () => {
        if (responbilityId === 0) {
            await dispatch(addHeadings({ name: responbility, userId, bgColor: '#fff', txtColor: '#333', boxId: '4', parentId: '0' }))
        } else {
            await dispatch(updateHeadings({ id: responbilityId, name: responbility, userId, bgColor: '#fff', txtColor: '#333', boxId: '4', parentId: '0' }))
        }
        setShowEditResponsibilities(false)
    }

    useEffect(() => {
        if (isInsert) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: 'Job Responsibilities added suucessfully' }))
            setShowEditResponsibilities(false)
        }
        if (isUpdate) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: 'Job Responsibilities updated suucessfully' }))
            setShowEditResponsibilities(false)
        }
    }, [isInsert, isUpdate])

    return (
        <React.Fragment>
            <Authentication />
            <div className="dk-jobProfileMain">
                <div className="dk-jobProfile-users">
                    <div className="d-flex justify-content-between">
                        {
                            (usersList) ?
                                usersList.map((item, index) =>
                                    <Link to="#" className={(item._id === userId) ? 'dk-jobProfile-users-box active' : 'dk-jobProfile-users-box'} onClick={() => getProfile(item._id)}>
                                        <Tooltip type="img" title={item.firstName + " " + item.lastName} value={IMG_CLOUD_BASE_URL + item.userImg} className="img-fluid img-circle" />
                                    </Link>
                                )
                                :
                                null
                        }
                    </div>
                </div>
                {
                    (show1) ? <AddJobProfileModal1 handleClose={handleClose1} profileUserId={userId} /> : null
                }
                {
                    (show2) ? <AddJobProfileModal2 handleClose={handleClose2} profileUserId={userId} /> : null
                }
                {
                    (show3) ? <AddJobProfileModal3 handleClose={handleClose3} profileUserId={userId} /> : null
                }
                {
                    (showEdit1) ? <EditJobProfileModal1 handleClose={handleCloseEdit1} profileUserId={userId} /> : null
                }
                <div className="dk-jobProfile-container">
                    <div className="d-md-flex">
                        <div className="dk-jobProfile-cols cols_2">
                            <div className="dk-head">
                                <div className="d-flex align-items-center justify-content-between">
                                    <i class="far fa-edit" onClick={handleShowEdit1}></i>
                                    <div className="title">Your Departments</div>
                                    <i className="fas fa-plus" onClick={handleShow1}></i>
                                </div>
                            </div>
                            <div className="dk-jobProfileSec">
                                <ul>
                                    {dataList.filter(items => items.boxId == 1).map(filteredItems => (
                                        <li style={{ background: filteredItems.bgColor, color: filteredItems.txtColor }}>{filteredItems.name}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="dk-jobProfile-cols cols_2">
                            <div className="dk-head">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="title">Your Role in Departments</div>
                                    <i className="fas fa-plus" onClick={handleShow2}></i>
                                </div>
                            </div>
                            <div className="dk-jobProfileSec">
                                <ul>
                                    {dataList.filter(items => items.boxId == 2).map(filteredItems => (
                                        <li style={{ background: filteredItems.bgColor, color: filteredItems.txtColor }}>{filteredItems.name}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="dk-jobProfile-cols cols_2">
                            <div className="dk-head">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="title">Overall Tasks Summary</div>
                                    <i className="fas fa-plus" onClick={handleShow3}></i>
                                </div>
                            </div>
                            <div className="dk-jobProfileSec">
                                <ul>
                                    {dataList.filter(items => items.boxId == 3).map(filteredItems => (
                                        <li style={{ background: filteredItems.bgColor, color: filteredItems.txtColor }}>{filteredItems.name}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="dk-jobProfile-cols cols_4">
                            <div className="dk-head">
                                <div className="d-flex align-items-center justify-content-between">
                                    {
                                        (!showEditResponsibilities) ?
                                            <i class="far fa-edit" onClick={handleEditResponsibilities}></i>
                                            :
                                            <i class="far fa-save" onClick={handleSaveResponsibilities}></i>
                                    }
                                    <div className="title">Overall Job Responsibilities</div>
                                    <span></span>
                                </div>
                            </div>
                            <div className="dk-jobProfileSec">
                                {
                                    (!showEditResponsibilities) ?
                                        <ul className="responbilityBullets">
                                            {dataList.filter(items => items.boxId == 4).map(filteredItems => (
                                                <DisplayResponsbility list={filteredItems.name} />
                                            ))}
                                        </ul>
                                        :
                                        <ul className="responbilityBullets">
                                            <textarea value={responbility} onChange={(e) => setResponbility(e.target.value)} />
                                        </ul>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}

export default JobProfile;
