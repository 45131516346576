import React, { useState } from 'react';
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from "react-redux";
import { rulesItems, clearState } from '../../../../slice/rules/rulesSlice';
import List from "./list";
const RulesChecklistModal = ({ heading, userId, ruleFor }) => {
    const dispatch = useDispatch();
    const { rules, isError, errorMessage } = useSelector(state => state.rules)
    let checkList = ''
    if (rules && rules != null) {
        switch (heading) {
            case 'Morning':
                checkList = rules.morningData
                break;

            case 'Afternoon':
                checkList = rules.noonData
                break;

            case 'Closing Day':
                checkList = rules.eveningData
                break;
        }
    }
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const addList = async (userId, ruleFor) => {
        await dispatch(rulesItems({ userId, ruleFor }))
        dispatch(clearState())
    }

    return (
        <React.Fragment>
            <div className="editbtn" onClick={handleShow}><i class="far fa-edit"></i></div>
            <Modal className="dk-levelModal dk-checklistModal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{heading} Checklist</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-checklistModalBody">
                    <div className="dk-rulesChecklist-addbtn">
                        <button onClick={() => addList(userId, ruleFor)}>Add New Task</button>
                    </div>

                    <div className="dk-checkListCols">
                        {
                            checkList && checkList.map((item, index) =>
                                <List number={index + 1} itemValue={item.list} id={rules._id} type={ruleFor} dataId={item._id} />
                            )
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default RulesChecklistModal;