import React from "react";
import { Route, Link } from "react-router-dom";
import "../../../assets/css/error.css";

const Error404 = () => {
    return (
        <React.Fragment>
            <div id="notfound">
                <div className="notfound">
                    <div className="notfound-404">
                        <h1>Oops!</h1>
                        <h2>404 - The Page can't be found</h2>
                    </div>
                    <Route>
                        <Link to="/">Go To Dashbaord</Link>
                    </Route>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Error404;