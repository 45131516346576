import React from 'react';
import {  } from "./summary.scss";

const SummaytextCols = (props) => {
    return (
        <React.Fragment>
            <div className="dk-reSummThreeCols">
                <div className="dk-reSummTwoHeader dk-reSHeaderCont78">
                    <div className="dk-filterIcon">
                        <i className="fa fa-search" aria-hidden="true"></i>
                    </div>
                    <div className="dk-reSHeaderTitle">{props.headerTitle}</div>
                    <span>345</span>
                </div>  
                <div className="dk-reSummTwoContent">
                    <li>
                        <div>{props.text1}</div>
                        <span>156</span>
                    </li>
                    <li>
                        <div>{props.text2}</div>
                        <span>58</span>
                    </li>
                    <li>
                        <div>{props.text3}</div>
                        <span>86</span>
                    </li>
                    <li>
                        <div>{props.text4}</div>
                        <span>156</span>
                    </li>
                    <li>
                        <div>{props.text5}</div>
                        <span>58</span>
                    </li>
                    <li>
                        <div>{props.text6}</div>
                        <span>86</span>
                    </li>
                    <li>
                        <div>{props.text7}</div>
                        <span>156</span>
                    </li>
                    <li>
                        <div>{props.text8}</div>
                        <span>58</span>
                    </li>
                    <li>
                        <div>{props.text9}</div>
                        <span>86</span>
                    </li>
                    <li>
                        <div>{props.text10}</div>
                        <span>86</span>
                    </li>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SummaytextCols;
