import React from "react";
import { Route, Link } from "react-router-dom";
import Submenu from "../../subMenu/report/menu/Samplemenu";
import { useSelector } from "react-redux";
import './style.scss';

const ReportmenuNavbar = () => {
    const { allBadge } = useSelector(state => state.tasks);
    const pathname = window.location.pathname;
    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-reportNavbar">
                <Route>
                    <ul>
                        <li><Link to="#">Dashboard</Link></li>
                        <li><Link to="#">Sample</Link></li>
                        <li><Link to="#">Sample 2</Link></li>
                        <li><Link to="#">Sample 3</Link></li>
                        <li><Link to="#">Sample 4</Link></li>
                        <li><Link to="#">Sample 5</Link></li>
                        <li><Link to="#">Sample 6</Link></li>
                        <li><Link to="#">Sample 7</Link></li>
                        <li><Link to="#">Sample 8</Link></li>
                        <li><Link to="#">Sample 9</Link></li>
                        <li><Link to="#">Sample 10</Link></li>
                    </ul>
                </Route>
            </div>
            <Submenu path={pathname} />
        </React.Fragment>
    );
}

export default ReportmenuNavbar;
