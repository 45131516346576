import React, { Suspense, useState, useMemo, useEffect } from "react";
import TableLoader from "../../utils/tableLoader/taskTable";
import NoData from "../../utils/tableNoData";
import { Route, Link } from "react-router-dom";
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import './style.scss';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import CommentPopup from "../../ui/comment/index";
import Authentication from "../../helpers/authenticate";
import { useDispatch, useSelector } from "react-redux";
import { IMG_CLOUD_BASE_URL } from "../../../constants";
import { showSuccess, showError } from "../../../slice/utils/message/messageSlice";
import { myTasklist, taskComments, newTaskComment, deleteComment, clearMessage, taskCommentUpdate } from "../../../slice/tasks/taskSlice";
import Tooltip from "../../ui/tooltip";
import CustomDateFormat from "../../utils/dateFormats/CustomDateFormat";
import CountComment from "../../utils/countComment";
import DeleteModal from "../../ui/delete";
import ExtraDiv from "../../pages/tasks/ExtraDiv"
import $ from "jquery";

function Table({ loading, columns, data }) {
    const tableInstance = useTable({
        columns: columns,
        data: data
    }, useGlobalFilter, useSortBy)

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = tableInstance
    const { globalFilter } = state

    return (
        <React.Fragment>
            <table className="table table-bordered" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                {(!loading && tableInstance.flatRows.length <= 0) ? <NoData colspan="12" txt="No Task list found..." /> : null}
                {
                    (loading) ? <TableLoader colspan="12" /> :
                        <tbody {...getTableBodyProps()}>
                            {rows.map(
                                (row, i) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return (
                                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                )
                                            })}
                                        </tr>
                                    )
                                }
                            )}
                        </tbody>
                }
            </table>
        </React.Fragment>
    )
}

const MyTask = () => {
    const dispatch = useDispatch();

    const { userInfo } = useSelector(state => state.auth)
    const { myTasks, isLoading, isError, isDelete, errorMessage, isUpdate, isCommentLoading, comments, isSuccess, msg } = useSelector(state => state.tasks);
    const userId = userInfo.id;
    const { _id } = useSelector(state => state.auth.userInfo.companyId)
    const companyId = _id;

    const [data, setData] = useState([]);
    const [rowId, setRowId] = useState('');
    const [show, setShow] = useState(false);

    /** Show Comments */
    const [showComment, setShowComment] = useState(false);
    const [commentList, setCommentList] = useState([]);
    const [btnTxt, setBtnTxt] = useState('Submit');

    const openComment = async (id) => {
        setRowId(id)
        setShowComment(true)
        await dispatch(taskComments({ taskId: id }))
    }

    const handleCloseComment = () => setShowComment(false);

    useEffect(() => {
        if (!isCommentLoading) {
            setCommentList(comments)
        }
    }, [isCommentLoading])
    /** End Show Comments */

    /** Insert Comment */
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [parentId, setParentId] = useState('0');
    const [comment, setComment] = useState('');

    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {
            case "comment":
                setComment(value)
                break;

            default:
                break;
        }
    }

    const handleButtonClick = async () => {
        if (!comment) {
            dispatch(showError({ msg: 'Please enter comment.' }))
        } else {
            if (!loading) {
                setSuccess(false);
                setLoading(true);
                if (commentId == '0') {
                    await dispatch(newTaskComment({ type: 'my', taskId: rowId, parentId: parentId, userId: userId, comment: comment }))
                } else {
                    await dispatch(taskCommentUpdate({ taskId: rowId, commentId: commentId, comment: comment }))
                }
            }
        }
    };

    useEffect(() => {
        if (isError) {
            dispatch(showError({ msg: errorMessage }))
            setSuccess(true);
            setLoading(false);
            dispatch(clearMessage());
        }
        if (isSuccess) {
            dispatch(showSuccess({ msg: msg }))
            setSuccess(true);
            setLoading(false);
            setComment('')
            setData(myTasks)
            dispatch(clearMessage());
        }
    }, [isSuccess, isError])
    /** End Insert Comment */

    /*Delete Comment */
    const [showDelete, setShowDelete] = useState(false)
    const [commentId, setCommentId] = useState('0')

    // const deleteData = async (id) => {
    //     setCommentId(id)
    //     setShowDelete(true)
    // }
    const handleCloseDelete = () => {
        setShowDelete(false)
    }
    // const deleteItem = async () => {
    //     setSuccess(false);
    //     setLoading(true);
    //     dispatch(deleteComment({ type: 'my', id: rowId, commentId: commentId }))
    // }

    useEffect(() => {
        if (isDelete) {
            dispatch(showSuccess({ msg: msg }))
            setShowDelete(false)
            setData(myTasks)
            setCommentList(comments)
            setSuccess(true);
            setLoading(false);
            setCommentId('0');
            dispatch(clearMessage())
        }
    }, [isDelete])
    /*End Delete Comment*/
    /*Delete Comment */
    //const [showDelete, setShowDelete] = useState(false)
    //const [commentId, setCommentId] = useState('0')

    const deleteData = async (id) => {
        setCommentId(id)
        setShowDelete(true)
    }
    //  const handleCloseDelete = () => {
    //      setShowDelete(false)
    //  }
    const deleteItem = async () => {
        setSuccess(false);
        setLoading(true);
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount == 0) {
            dispatch(deleteComment({ type: 'my', id: rowId, commentId: commentId }))
        } else {
            var canIds = [];
            $.each($(".chkCandidate:checked"), function () {
                canIds.push($(this).val());
            });

            const { token } = JSON.parse(localStorage.getItem("authentication"));
            fetch('/task/delete', {
                method: 'POST',
                body: JSON.stringify({ 'ids': canIds }),
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token,
                }
            }).then(response => {
                if (response.status === 200) {
                    dispatch(showSuccess({ msg: 'Delete successfully' }))
                    setShowDelete(false)
                    setIsActive(false)
                    dispatch(myTasklist({ userId: userId, companyId: companyId }))
                    setLoading(false)
                }
            })
        }
    }

    useEffect(() => {
        if (isDelete) {
            dispatch(showSuccess({ msg: msg }))
            setShowDelete(false)
            setData(myTasks)
            setCommentList(comments)
            setSuccess(true);
            setLoading(false);
            setCommentId('0');
            dispatch(clearMessage())
        }
    }, [isDelete])
    /*End Delete Comment*/


    /** Edit Comment */
    const hendleEditClick = async (id, value) => {
        setCommentId(id)
        setComment(value)
        setBtnTxt('Update')
    }

    useEffect(() => {
        if (isUpdate) {
            dispatch(showSuccess({ msg: 'Comment update successfully' }))
            setBtnTxt('Submit')
            setSuccess(true);
            setLoading(false);
            setComment('')
            setCommentId('0')
            setCommentList(comments)
            dispatch(clearMessage());
        }
    }, [isUpdate])
    /** End Edit Comment */

    /** Reply Comment */
    const hendleReplyClick = async (id) => {
        setCommentId('0')
        setParentId(id)
    }
    /** End Reply Comment */

    const handleClose = () => setShow(false);

    useEffect(() => {
        async function fetchData() {
            try {
                dispatch(myTasklist({ userId: userId, companyId: companyId }))
            } catch (err) {
                console.log(err)
            }
        }
        fetchData()
    }, [dispatch])

    useEffect(() => {
        if (!isLoading && myTasks && myTasks.length > -1) {
            setData(myTasks)
        }
    }, [isLoading])

    const columns = useMemo(() => [
        {
            Header: 'SNO.',
            title: 'SNO.',
            Cell: (row) => Number(row.row.id) + 1
        },
        {
            Header: 'Task By',
            accessor: 'taskBy',
            Cell: ({ cell }) => (
                <div className="dk-taskbyImg">
                    <Tooltip type="img" title={cell.row.original.taskBy.firstName + " " + cell.row.original.taskBy.lastName} value={IMG_CLOUD_BASE_URL + cell.row.original.taskBy.userImg} className="img-fluid img-circle" />
                </div>
            )
        },
        {
            id: 'taskTo',
            Header: 'Task For',
            accessor: 'taskTo.userId.firstName',
            Cell: ({ cell }) => {
                return (
                    <div className="dk-taskbyImg" >
                        {
                            cell.row.original.taskTo.map((item, index) =>
                                <Tooltip type="img" title={item.userId.firstName + " " + item.userId.lastName} value={IMG_CLOUD_BASE_URL + item.userId.userImg} className="img-fluid img-circle" />
                            )
                        }
                    </div >
                )
            }
        },
        {
            Header: 'Comment',
            Cell: ({ cell }) => {
                return (
                    <div class="dk-taskComment" onClick={() => openComment(cell.row.original._id)}><CountComment comment={cell.row.original.comments} /></div>
                )
            }
        },
        {
            Header: 'Categories',
            accessor: 'category',
            Cell: ({ cell }) => (
                <div className="dk-taskCategories dk-skyBlue">{cell.row.original.category}</div>
            )
        },
        {
            Header: 'Title',
            accessor: 'title'
        },
        {
            id: 'date',
            Header: 'Due Date',
            accessor: 'taskDateTime',
            Cell: ({ cell }) => (
                <CustomDateFormat date={cell.row.original.taskDateTime.substr(0, cell.row.original.taskDateTime.indexOf(' '))} format='Do MMM YY' />
            )
        },
        {
            id: 'time',
            Header: 'Due Time',
            accessor: 'taskDateTime',
            Cell: ({ cell }) => (
                cell.row.original.taskDateTime.substring(cell.row.original.taskDateTime.indexOf(' ') + 1)
            )
        },
        {
            Header: 'Amount €',
            accessor: 'amount'
        },
        {
            Header: 'Progress',
            accessor: 'taskProgress',
            Cell: ({ cell }) => {
                switch (cell.row.original.taskProgress) {
                    case 'New Task':
                        return (
                            <div className="dk-taskProgress newTask"><i class="far fa-envelope"></i> New Task</div>
                        )
                        break;

                    case 'Accept':
                        return (
                            <div className="dk-taskProgress acceptTask"><i class="far fa-envelope"></i> Accept</div>
                        )
                        break;

                    case 'In Progress':
                        return (
                            <div className="dk-taskProgress inProgressTask"><i class="far fa-envelope"></i> In Progress</div>
                        )
                        break;

                    case 'Completed':
                        return (
                            <div className="dk-taskProgress completedTask"><i class="far fa-envelope"></i> Completed</div>
                        )
                        break;

                    case 'Query':
                        return (
                            <div className="dk-taskProgress queryTask"><i class="far fa-envelope"></i> Query</div>
                        )
                        break;
                }
            }
        },
        {
            Header: 'Priority',
            accessor: 'prioriy',
            Cell: ({ cell }) => {
                return (
                    (cell.row.original.prioriy == 'Normal') ? <span className="normal-prioriy">{cell.row.original.prioriy}</span> : <span className="urgent-prioriy">{cell.row.original.prioriy}</span>
                )
            }
        },
        {
            Header: 'Edit/Del',
            Cell: ({ cell }) => {
                return (
                    (cell.row.original.taskBy._id == userId) ? <div className="dk-taskAction" >
                        <input type="checkbox" class="chkCandidate" name="chkCandidate[]" value={cell.row.original._id} onChange={(e) => toggleCheckbox(e, cell.row.original._id)} />
                    </div> : null
                )
            }
        }
    ], [])

    /** Extra Div */
    const [isActive, setIsActive] = useState(false);
    const [countCheck, setCountCheck] = useState(0)
    const [checkBtnTxt, setCheckBtnTxt] = useState('Check All')

    const handleToggle = () => {
        $('.chkCandidate').prop('checked', false);
        setIsActive(false);
    };

    const toggleCheckAll = () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;

        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            setCheckBtnTxt('Uncheck All')
            setCountCheck(totalCheckboxes)
            $('.chkCandidate').prop('checked', true);
            // setCheckedBoxes(checkedBoxes.filter((v, i, a) => a.indexOf(v) === i))
        }
        if (checkedBoxCount == totalCheckboxes) {
            setCheckBtnTxt('Check All')
            setCountCheck(0)
            $('.chkCandidate').prop('checked', false);
            setIsActive(false)
        }
    }
    /** Delete Selected Checkbox  */
    const deleteSelected = () => {
        setShowDelete(true)
    }
    /* End Delete Selected Checkbox*/

    /** Single Checkbox Select */
    const toggleCheckbox = (e, id) => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;

        if (checkedBoxCount > 0) {
            setCountCheck(checkedBoxCount)
            setIsActive(true)
        } else {
            setCountCheck(0)
            setIsActive(false)
        }
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            setCheckBtnTxt('Check All')
        }
        if (checkedBoxCount == totalCheckboxes) {
            setCheckBtnTxt('Uncheck All')
        }
    }
    /** End Single Checkbox Select */
    /** End Extra Div */

    return (
        <React.Fragment>
            <Authentication />
            <div className="dk-tasksMain">
                <div className="dk-tasksTable">
                    <div className="table-responsive">
                        <Table
                            data={data}
                            columns={columns}
                            loading={isLoading}
                        />
                    </div>
                </div>
            </div>
            {
                (showDelete) ? <DeleteModal loading={loading} success={success} handleClose={handleCloseDelete} deleteItem={deleteItem} /> : null
            }
            {
                (showComment) ? <CommentPopup loading={loading} success={success} id={rowId} handleClose={handleCloseComment} isCommentLoading={isCommentLoading} comments={commentList} handleSubmit={handleButtonClick} handleChange={handleChange} value={comment} handleClickDelete={deleteData} hendleEditClick={hendleEditClick} hendleReplyClick={hendleReplyClick} btnTxt={btnTxt} /> : null
            }
            {/* modal */}
            <Modal className="dk-taskModalMain" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>DK Global</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-taskModalBody">
                    <div className="dk-taskModalTop">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="dk-taskTopCols">
                                <div className="title">Created By</div>
                                <div className="dk-taskTopImg">
                                    <img src="https://www.admin.dkmasterbox.com/assets/uploads/user_images/5a701dc2182ac.png" className="img-fluid img-circle" alt="" />
                                </div>
                            </div>
                            <div className="dk-taskTopCols">
                                <div className="title">Created By</div>
                                <div className="dk-taskTopImg">
                                    <img src="https://www.admin.dkmasterbox.com/assets/uploads/user_images/5a701dc2182ac.png" className="img-fluid img-circle" alt="" />
                                </div>
                            </div>
                            <div className="dk-taskTopCols">
                                <div className="title">Due Date : 14th Jan 21</div>
                            </div>
                            <div className="dk-taskTopCols">
                                <div className="title">Due Time : 16:44</div>
                            </div>
                        </div>
                    </div>
                    <div className="dk-taskSubject">
                        <div className="d-flex">
                            <span>Subject:</span>
                            <input type="text" value="Myy Task Testt" className="form-control" name="" id="" readOnly />
                            <div className="dk-taskCountBtn">3</div>
                        </div>
                        <textarea name="" id="" className="form-control" value="Myy sadaff" readOnly></textarea>
                    </div>
                    <div className="dk-taskModalFooter">
                        <div className="d-flex">
                            <div className="dk-taskModalFooter-cols">
                                <div className="title">Attachments</div>
                            </div>
                            <div className="dk-taskModalFooter-cols">
                                <div className="title">Task Priority</div>
                                <div className="dk-taskSwitch">
                                    <li class="tg-list-item">
                                        <input class="tgl tgl-skewed" id="cb3" type="checkbox" />
                                        <label class="tgl-btn" data-tg-off="Normal" data-tg-on="Urgent" for="cb3"></label>
                                    </li>
                                </div>
                            </div>
                            <div className="dk-taskModalFooter-cols">
                                <div className="title">Snooze Task</div>
                                <div className="dk-taskSwitch">
                                    <button className="snooz"><i class="far fa-clock"></i> Snooz</button>
                                </div>
                            </div>
                            <div className="dk-taskModalFooter-cols">
                                <div className="title">Task Progress</div>
                                <div className="dk-taskSwitch">
                                    <Dropdown>
                                        <Dropdown.Toggle className="inprogress" variant="success" id="dropdown-basic">
                                            In Progress
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1" className="accept"><i class="fas fa-check"></i> Accept</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2" className="inprogress"><i class="fas fa-chart-line"></i> In Progress</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3" className="completed"><i class="fas fa-check-circle"></i> Completed</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3" className="query"><i class="fas fa-question"></i> Query</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dk-taskSnoozCols" style={{ display: 'none' }}>
                        <div className="d-flex">
                            <div className="form-group">
                                <label htmlFor="">Snooze Date</label>
                                <label htmlFor="">
                                    <input type="date" className="form-control" name="" id="" />
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Snooze Time</label>
                                <label htmlFor="">
                                    <input type="time" className="form-control" name="" id="" />
                                </label>
                            </div>
                            <div className="form-group">
                                <div className="d-flex">
                                    <button>Save</button>
                                    <button className="cancelBtn">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* end */}
            <ExtraDiv isActive={isActive} countCheck={countCheck} toggleCheckAll={toggleCheckAll} checkBtnTxt={checkBtnTxt} deleteSelected={deleteSelected} handleToggle={handleToggle} />
        </React.Fragment>
    );
}

export default MyTask;
