import React from 'react'

const CostLiving = () => {
    return (
        <React.Fragment>
          <div className="container-fluid w-100 text-center" style={{marginTop: "155px"}}>
              <h5>Cost of Living</h5>
          </div>
        </React.Fragment>
    )
}

export default CostLiving;
