import React, { useEffect, useState } from 'react';
import { Route, Link } from "react-router-dom";
import './style.scss';
import { Dropdown } from 'semantic-ui-react';

const options = [
    {
        key: 'Half Day',
        text: 'Half Day',
        value: 'Half Day',
    },
    {
        key: 'Full Day',
        text: 'Full Day',
        value: 'Full Day',
    },
    {
        key: 'Short Day',
        text: 'Short Day',
        value: 'Short Day',
    },
    {
        key: 'Long leave',
        text: 'Long leave',
        value: 'Long leave',
    },
]

const SelectTypeField = () => {
    return (
        <React.Fragment>
            <Dropdown clearable options={options} selection placeholder='Select' />
        </React.Fragment>
    );
}

export default SelectTypeField;
