import React, { useState } from 'react';
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

const RulesInfoModal = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <React.Fragment>
            <span  onClick={handleShow}><i class="fas fa-info-circle"></i></span>
            <Modal className="dk-levelModal dk-rulesModalMain" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Daily Working Plan Information</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-reorderModal">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet dicta nam consequatur voluptatum, maiores sequi, quasi tenetur magnam, deleniti totam possimus facilis. Repudiandae explicabo consectetur blanditiis maxime aliquid. Facere, at? Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit repudiandae possimus asperiores sapiente commodi dicta cumque illum eum natus atque, laudantium sequi perspiciatis pariatur praesentium fugit quam! Delectus, error earum.</p>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatum sed quibusdam recusandae explicabo id rem saepe illo aut neque. Nemo suscipit perferendis corporis tempora quae obcaecati aliquid. Odio, nemo dicta.</p>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default RulesInfoModal;