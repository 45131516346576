import React, { useEffect, useState } from 'react';
import { Route, Link } from "react-router-dom";
import '../../pages/resources/mlresource/style.scss';
import Modal from 'react-bootstrap/Modal';
import Submit from "../../ui/submitButton";

const DeleteModal = ({ id, handleClose, deleteItem, loading, success }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true)
    }, [id])

    return (
        <React.Fragment>
            <Modal className="dk-mlAddNewModal dk-deleteModal" show={show} onHide={handleClose}>
                <Modal.Body className="dk-mlAddModalBody">
                    <p>Are you sure wants to Delete these records ?</p>
                    <div className="d-flex justify-content-end">
                        <button onClick={handleClose}>No</button>
                        <Submit txt="Yes" loading={loading} success={success} onClick={deleteItem} position="justify-content-center" className="dk-yesBtn" />
                        {/* <button className="dk-yesBtn" onClick={deleteItem}>Yes</button> */}
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default DeleteModal;
