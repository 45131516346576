import React, { useEffect, useState } from 'react';
import { Route, Link } from "react-router-dom";
import RulesInfoModal from "./models/rulesInfoModal";
import RulesChecklistModal from "./models/rulesChecklistModal";
import Authentication from "../../helpers/authenticate";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { IMG_CLOUD_BASE_URL } from '../../../constants';
import { rulesDetails } from "../../../slice/rules/rulesSlice";
import Tooltip from "../../ui/tooltip";

const Rules = () => {
    const dispatch = useDispatch();
    const { usersList } = useSelector(state => state.users)
    const { rules } = useSelector(state => state.rules)
    const [userId, setUserId] = useState(0);
    useEffect(() => {
        (usersList && usersList.length > 0) ?
            setUserId(usersList[0]._id)
            :
            setUserId('0')
    }, [usersList])

    useEffect(() => {
        async function fetchRules() {
            try {
                await dispatch(rulesDetails({ userId: userId }))
            } catch (err) {
                console.log(err)
                // dispatch(showError({ msg: err }))
            }
        }
        fetchRules()
    }, [userId])

    const getRules = (id) => {
        setUserId(id)
    }

    return (
        <React.Fragment>
            <Authentication />
            <div className="dk-rulesMain">
                <div className="dk-rulesHead">
                    <div className="d-flex justify-content-end align-items-center">
                        <h3 className="text-center title">DAILY WORKING PLAN <RulesInfoModal /></h3>
                        <div className="dk-rulesSearch">
                            {
                                moment().format("DD-MMMM-YYYY")
                            }
                            <span><i class="far fa-calendar-alt"></i></span>
                        </div>
                    </div>
                </div>
                <div className="dk-rulesContainer">
                    <Route>
                        <ul>
                            {
                                (usersList) ?
                                    usersList.map((item, index) =>
                                        <li>
                                            <Link to="#" className={(item._id === userId) ? 'active' : null} onClick={() => getRules(item._id)}>
                                                <span>
                                                    <Tooltip type="img" title={item.firstName + " " + item.lastName} value={IMG_CLOUD_BASE_URL + item.userImg} className="img-fluid img-circle" />
                                                </span>
                                            </Link>
                                        </li>
                                    )
                                    :
                                    null
                            }
                        </ul>
                    </Route>
                    <div className="dk-rulesCols">
                        <div className="row">
                            <div className="col-lg-4 col-md-12">
                                <div className="dk-rulesBox">
                                    <div className="dk-rulesBox-head">
                                        <div className="title">Morning Work Plan</div>
                                        <RulesChecklistModal heading="Morning" userId={userId} ruleFor="morningData" />
                                    </div>
                                    <div className="dk-rulesBox-cols">
                                        <ul>
                                            {
                                                (rules && rules != null && rules.morningData) ? rules.morningData.map((item, index) =>
                                                    <li key={index}>{item.list}</li>
                                                ) : null
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="dk-rulesBox">
                                    <div className="dk-rulesBox-head">
                                        <div className="title">Afternoon Work Plan</div>
                                        <RulesChecklistModal heading="Afternoon" userId={userId} ruleFor="noonData" />
                                    </div>
                                    <div className="dk-rulesBox-cols">
                                        <ul>
                                            {
                                                (rules && rules != null && rules.noonData) ? rules.noonData.map((item, index) =>
                                                    <li key={index}>{item.list}</li>
                                                ) : null
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="dk-rulesBox">
                                    <div className="dk-rulesBox-head">
                                        <div className="title">Closing Day Checklist</div>
                                        <RulesChecklistModal heading="Closing Day" userId={userId} ruleFor="eveningData" />
                                    </div>
                                    <div className="dk-rulesBox-cols">
                                        <ul>
                                            {
                                                (rules && rules != null && rules.eveningData) ? rules.eveningData.map((item, index) =>
                                                    <li key={index}>{item.list}</li>
                                                ) : null
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default Rules;