import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import ProgressBarReducer from '../slice/utils/loader/linearSlice';
import alertMessagesReducer from '../slice/utils/message/messageSlice';
import authReducer from '../slice/auth/authSlice';
import moduleReducer from '../slice/modules/moduleSlice';
import tokenReducer from '../slice/auth/tokenSlice';
import teamReducer from '../slice/dkgTeam/teamSlice';
import userListReducer from '../slice/userList/userSlice';
import faqReducer from '../slice/faqs/faqSlice';
import rulesReducer from '../slice/rules/rulesSlice';
import smsTemplateReducer from '../slice/sms/templateSlice';
import mailshotsReducer from '../slice/mailshots/mailshotsSlice';
import smsReducer from '../slice/sms/smsSlice';
import tasksReducer from '../slice/tasks/taskSlice';
import jobProfileReducer from '../slice/jobProfile/jobProfileSlice';

import backendValuesReducer from '../slice/admin/backendValues/backendValuesSlice';


const reducers = combineReducers({
  progressBar: ProgressBarReducer,
  alertMessage: alertMessagesReducer,
  auth: authReducer,
  module: moduleReducer,
  teamData: teamReducer,
  token: tokenReducer,
  faq: faqReducer,
  users: userListReducer,
  rules: rulesReducer,
  smsTemplate: smsTemplateReducer,
  sms: smsReducer,
  mailshots: mailshotsReducer,
  tasks: tasksReducer,
  jobProfile: jobProfileReducer,

  backendValues: backendValuesReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'module', 'users']
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});