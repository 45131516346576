import React, { useState, useEffect } from 'react';
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { showError, showSuccess, clearMessage } from "../../../slice/utils/message/messageSlice";
import { useDispatch, useSelector } from "react-redux";
import { addHeadings, clearState } from "../../../slice/jobProfile/jobProfileSlice";
import $ from "jquery";
import SubmitButton from "../../ui/submitButton";
import Dropdown from "./Dropdown";

const AddJobProfileModal = ({ handleClose, profileUserId }) => {
    const dispatch = useDispatch();
    const { dataList, isInsert } = useSelector(state => state.jobProfile);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [box1List, setBox1List] = useState([])
    const [box2List, setBox2List] = useState([])

    const [name, setName] = useState('');
    const [userId] = useState(profileUserId);
    const [bgColor, setBgColor] = useState('');
    const [txtColor] = useState('#FFFFFF');
    const [boxId] = useState('3');
    const [parentId, setParentId] = useState('0');

    const [ddl1Name, setDdl1Name] = useState('Select')
    const [ddl2Name, setDdl2Name] = useState('Select')

    useEffect(() => {
        setBox1List(dataList.filter(items => items.boxId == 1))
    }, [])

    const handleButtonClick = async () => {
        if (parentId == '0') {
            dispatch(showError({ msg: 'Please select departments first.' }))
        } else {
            if (ddl2Name == 'Select') {
                dispatch(showError({ msg: 'Please select role first.' }))
            } else {
                if (!name) {
                    dispatch(showError({ msg: 'Please fill tasks Summary.' }))
                } else {
                    setSuccess(false);
                    setLoading(true);
                    dispatch(addHeadings({ name, userId, bgColor, txtColor, boxId, parentId }))
                }
            }
        }
    }

    const onSelectDepartment = (e) => {
        setParentId(e.target.dataset.id)
        setBgColor(e.target.dataset.bgcolor)
        setDdl1Name(e.target.dataset.name)
        setDdl2Name('Select')
        setBox2List(dataList.filter(items => items.parentId == e.target.dataset.id))
    }

    const onSelectRole = (e) => {
        setDdl2Name(e.target.dataset.name)
    }

    useEffect(() => {
        if (isInsert) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: 'Tasks summary added suucessfully' }))
            setSuccess(true);
            setLoading(false);
            handleClose()
        }
    }, [isInsert])

    return (
        <React.Fragment>
            <Modal className="dk-editJobProfile" show={true} onHide={handleClose}>
                <Modal.Body>
                    <div className="dk-editJobProfile-container">
                        <div className="dk-editJobProfile-head" style={{ background: '#5e5e5e' }}>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="title">Overall Tasks Summary</div>
                                <Modal.Header closeButton></Modal.Header>
                            </div>
                        </div>
                        <div className="dk-editJobProfile-editSec">
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>Departments</td>
                                            <td>
                                                <div className="dk-colorDropdown">
                                                    <Dropdown list={box1List} onSelectDepartment={onSelectDepartment} name={ddl1Name} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Roles</td>
                                            <td>
                                                <div className="dk-colorDropdown">
                                                    <Dropdown list={box2List} onSelectDepartment={onSelectRole} name={ddl2Name} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <div className="dk-editSec-input" style={{ width: '80%', margin: 'auto' }}>
                                                    <input type="text" className="form-control" placeholder="Enter Heading..." onChange={(e) => setName(e.target.value)} />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <SubmitButton txt="SAVE" loading={loading} success={success} onClick={handleButtonClick} className="btnAddResponsbility mt-3" position="justify-content-center" />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default AddJobProfileModal;