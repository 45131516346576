import React from 'react';
import Authentication from "../../helpers/authenticate";
import ModuleHeader from "../ModuleHeader";
import Navbar from "../../elements/header/navbar/mainMenu/modulesNavbar"
const BlankPage = () => {
    return (
        <React.Fragment>
            <ModuleHeader />
            <Authentication />
            <Navbar />
        </React.Fragment>
    )
}
export default BlankPage;