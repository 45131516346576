import React, { Suspense, useState, useMemo, useEffect } from "react";
import { Route, Link } from "react-router-dom";
import './style.scss';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../slice/utils/message/messageSlice";
import { mailshotAdd } from "../../../slice/mailshots/mailshotsSlice";
import UserList from "../../ui/userList";
import Submit from "../../ui/submitButton";
import { loadingStart, loadingEnd, clearLoading } from "../../../slice/utils/loader/linearSlice";
import { mailshotlist, clearState } from "../../../slice/mailshots/mailshotsSlice";

const AddNewMailshots = () => {
    const dispatch = useDispatch();
    const { mailList, isLoading, isError, isDelete, msg, isInsert } = useSelector(state => state.mailshots)
    const [show, setShow] = useState(false);
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [candidate, setCandidate] = useState('');
    const [mailshotVia, setMailshotVia] = useState('Mailchimp');
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('ML');
    const [company, setCompany] = useState('');
    const [project, setProject] = useState('');
    const [language, setLanguage] = useState('');
    const [roletype, setRoletype] = useState('');

    const handleClose = () => setShow('');
    const handleShow = () => setShow(true);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [userId, setUserId] = useState('0')
    const [userImg, setUserImg] = useState('')
    const [userImgName, setUserImgName] = useState('')
    const { _id } = useSelector(state => state.auth.userInfo.companyId)
    const companyId = _id;

    const selectUser = (e) => {
        setUserImgName(e.target.alt)
        setUserImg(e.target.src)
        setUserId(e.target.dataset.id)
    }
    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {
            case "date":
                setDate(value)
                break;
            case "time":
                setTime(value)
                break;
            case "candidate":
                setCandidate(value)
                break;
            case "mailshotVia":
                setMailshotVia(value)
                break;
            case "title":
                setTitle(value)
                break;
            case "category":
                setCategory(value)
                break;
            case "company":
                setCompany(value)
                break;
            case "project":
                setProject(value)
                break;
            case "language":
                setLanguage(value)
                break;
            case "roletype":
                setRoletype(value)
                break;
            default:
                break;
        }
    }

    const handleButtonClick = async () => {

        if (!date || !time || !candidate || !mailshotVia || !title || !category || !project || !roletype || !company) {
            dispatch(showError({ msg: 'Please fill all fields.' }))
        } else {
            await dispatch(mailshotAdd({ userId: userId, joinDate: date, joinTime: time, totalCandidate: candidate, mailshotVia: mailshotVia, projectTitle: title, company: company, category: category, project: project, language: language, roleType: roletype, companyId: companyId }))

        }
    };

    useEffect(() => {
        if (isError) {
            dispatch(clearState())
            dispatch(clearLoading())
            dispatch(showError({ msg: msg }))
        }
        if (isInsert) {
            setShow(false);
            dispatch(showSuccess({ msg: "Details added successfully" }))
            dispatch(clearState())
            dispatch(clearLoading())
        }
    }, [isError, isInsert])

    return (
        <React.Fragment>
            <button onClick={handleShow}><i class="fas fa-plus"></i> Add New</button>
            <Modal className="dk-mailshotsAddNewModal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Mailshots</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Date</label>
                                    <label htmlFor="">
                                        <input type="date" name="date" id="" className="form-control" value={date} onChange={handleChange} />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Time</label>
                                    <label htmlFor="">
                                        <input type="time" name="time" id="" value={time} onChange={handleChange} className="form-control" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">No. of Candidates</label>
                                    <label htmlFor="">
                                        <input type="text" name="candidate" id="" value={candidate} onChange={handleChange} className="form-control" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Send Mailshot Via</label>
                                    <label htmlFor="">
                                        <select name="mailshotVia" id="" className="form-control" value={mailshotVia} onChange={handleChange}>
                                            <option value="Mailchimp">Mailchimp</option>
                                            <option value="Outlook">Outlook</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="">Project Title</label>
                                    <label htmlFor="">
                                        <input type="text" name="title" id="" className="form-control" value={title} onChange={handleChange} />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Category</label>
                                    <label htmlFor="">
                                        <select name="category" id="" className="form-control" value={category} onChange={handleChange} >
                                            <option value="ML">ML</option>
                                            <option value="IT">IT</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Company</label>
                                    <label htmlFor="">
                                        <input type="text" name="company" id="" className="form-control" value={company} onChange={handleChange} />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Project</label>
                                    <label htmlFor="">
                                        <input type="text" name="project" id="" className="form-control" value={project} onChange={handleChange} />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Language</label>
                                    <label htmlFor="">
                                        <input type="text" name="language" id="" className="form-control" value={language} onChange={handleChange} />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Role Type</label>
                                    <label htmlFor="">
                                        <input type="text" name="roletype" id="" className="form-control" value={roletype} onChange={handleChange} />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor=""></label>
                                    <label htmlFor="" className="mb-0 mt-2">
                                        <input type="hidden" id="userId" value="0" />
                                        <Dropdown className="dk-globalUserDropdown">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                {
                                                    (userId !== '0') ? <img src={userImg} className="select-user-img" /> : <i className="fas fa-user"></i>
                                                }
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <div className="d-flex">
                                                    <UserList onClick={selectUser} />
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12 text-center">
                                <Submit txt="Save" onClick={handleButtonClick} className="dk-submitBtn" />
                                {/* <button className="dk-submitBtn" onClick={handleButtonClick} >Save</button> */}
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default AddNewMailshots;
