import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const DatabaseSubmenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[2];
    const segment2 = pathname.split("/")[3];
    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/recruitment/database/summary" className={(segment1 == 'database' && segment2 == 'summary') ? 'active' : ''}>Summary</Link></li>
                        <li><Link to="/recruitment/database/search-cvs" className={`${(segment2 == 'search-cvs') ? 'active' : ''}`}>Search CV's</Link></li>
                        <li><Link to="/recruitment/database/projects" className={`${(segment2 == 'projects') ? 'active' : ''}`}>Projects</Link></li>
                        <li><Link to="/recruitment/database/mailshots" className={`${(segment2 == 'mailshots') ? 'active' : ''}`}>Mailshots</Link></li>
                        <li><Link to="/recruitment/database/archive" className={`${(segment2 == 'archive') ? 'active' : ''}`}>Archive</Link></li>
                        <li><Link to="/recruitment/database/personal" className={`${(segment2 == 'personal') ? 'active' : ''}`}>Personal</Link></li>
                        <li><Link to="/recruitment/database/database" className={`${(segment2 == 'database') ? 'active' : ''}`}>Database</Link></li>
                        <li><Link to="/recruitment/database/duplicate" className={`${(segment2 == 'duplicate') ? 'active' : ''}`}>Duplicate</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default DatabaseSubmenu;
