import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import './projects.scss';

const Projects = () => {
    return (
        <React.Fragment>
            <div className="dk-projectsMain">
                <div className="dk-projectHead">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="dk-projectLeft">
                            <div className="dk-searchCols">
                                <input type="text" placeholder="Keywords" name="" id="" className="form-control" />
                            </div>
                            <div className="dk-userCols">
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <i className="fa fa-user" aria-hidden="true"></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="d-flex">
                                            <Dropdown.Item href="#/action-1">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="img-fluid img-circle" alt="" />
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad49d2ca540d.png" className="img-fluid img-circle" alt="" />
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5b4dce328e872.png" className="img-fluid img-circle" alt="" />
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ca355dac8269.png" className="img-fluid img-circle" alt="" />
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5e54fdac9c.png" className="img-fluid img-circle" alt="" />
                                            </Dropdown.Item>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="dk-searchProject">
                                <button><i class="fas fa-search"></i></button>
                            </div>
                        </div>
                        <h3 className="title">SAVED PROJECTS</h3>
                        <div className="dk-projectRight">
                            <button><i class="fas fa-plus"></i> Create New</button>
                        </div>
                    </div>
                </div>

                <div className="dk-projectTable">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Recruiter</th>
                                    <th>Project Title</th>
                                    <th>Candidates</th>
                                    <th>Category</th>
                                    <th>Company</th>
                                    <th>Project</th>
                                    <th>Role Type</th>
                                    <th>Language</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="dk-recrImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                    </td>
                                    <td className="text-left">
                                        <b>Dutch Role for Wipro Bucharest</b>
                                    </td>
                                    <td>
                                        <div className="dk-candidate">
                                            <Route>
                                                <Link to="#"><i class="fas fa-users"></i> 63 Candidates</Link>
                                            </Route>
                                        </div>
                                    </td>
                                    <td>ML</td>
                                    <td>Wipro Bucharest</td>
                                    <td>Confidential</td>
                                    <td>QA</td>
                                    <td>Dutch B2</td>
                                    <td>
                                        <div className="dk-action">
                                            <Route>
                                                <Link to="#">
                                                    <i className="fas fa-file"></i>
                                                </Link>
                                                <Link to="#">
                                                    <i className="fas fa-edit"></i>
                                                </Link>
                                                <Link to="#">
                                                    <i className="fas fa-trash-alt"></i>
                                                </Link>
                                            </Route>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Projects;

