import React, { useState, useEffect } from 'react';
import { Route, Link } from "react-router-dom";
import './style.scss';
import Modal from 'react-bootstrap/Modal';
import Submit from "../../ui/submitButton";
import CommentLoader from "../../utils/commentLoader";
import Tooltip from "../../ui/tooltip";
import { IMG_CLOUD_BASE_URL } from "../../../constants";
import moment from "moment-timezone";

const CommentPopup = ({ id, loading, success, handleClose, handleChange, handleSubmit, isCommentLoading, comments, value, handleClickDelete, hendleEditClick, hendleReplyClick, btnTxt }) => {
    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(true)
    }, [id])

    return (
        <React.Fragment>
            <Modal className="dk-commentModalMain right" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>TASK COMMENTS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="comments-container">
                        <div className="scrollBox">
                            <ul className="comments-list">
                                {
                                    (isCommentLoading) ? <CommentLoader />
                                        :
                                        <React.Fragment>
                                            {
                                                comments.map((item, index) =>
                                                    <li>
                                                        <div className="comment-main-level">
                                                            <div className="comment-avatar">
                                                                <Tooltip type="img" title={item.userId.firstName + " " + item.userId.lastName} value={IMG_CLOUD_BASE_URL + item.userId.userImg} className="img-fluid" />
                                                            </div>
                                                            <div className="comment-box">
                                                                <div className="comment-head d-flex align-items-center justify-content-between">
                                                                    <h6 className="comment-name d-none">
                                                                        <Link to="Maria Ciocas"></Link>
                                                                    </h6>
                                                                    <span className="d-flex">
                                                                        <div className="date"><i class="far fa-calendar-alt"></i>
                                                                            {
                                                                                moment(item.updatedOn).tz("Europe/Dublin").format('DD MMM YY')
                                                                            }
                                                                        </div>
                                                                        <div className="date"><i class="far fa-clock"></i>
                                                                            {
                                                                                moment(item.updatedOn).tz("Europe/Dublin").format('h:mm')
                                                                            }
                                                                        </div>
                                                                    </span>
                                                                    <div className="d-flex headIcons">
                                                                        <i class="fas fa-reply" title="Reply" onClick={() => hendleReplyClick(item._id)}></i>
                                                                        <i class="far fa-edit" title="Edit" onClick={() => hendleEditClick(item._id, item.comment)}></i>
                                                                        <i class="far fa-trash-alt" title="Delete" onClick={() => handleClickDelete(item._id)}></i>
                                                                    </div>
                                                                </div>
                                                                <div className="comment-content">
                                                                    {item.comment}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            (item.children.length !== 0) ?
                                                                <React.Fragment>
                                                                    <ul className="comments-list-reply">
                                                                        {
                                                                            item.children.map((item, index) =>
                                                                                <li>
                                                                                    <div className="comment-main-level">
                                                                                        <div className="comment-avatar">
                                                                                            <Tooltip type="img" title={item.userId.firstName + " " + item.userId.lastName} value={IMG_CLOUD_BASE_URL + item.userId.userImg} className="img-fluid" />
                                                                                        </div>
                                                                                        <div className="comment-box">
                                                                                            <div className="comment-head d-flex align-items-center justify-content-between">
                                                                                                <h6 className="comment-name d-none">
                                                                                                    <Link to="Maria Ciocas"></Link>
                                                                                                </h6>
                                                                                                <span className="d-flex">
                                                                                                    <div className="date"><i class="far fa-calendar-alt"></i>
                                                                                                        {
                                                                                                            moment(item.updatedOn).tz("Europe/Dublin").format('DD MMM YY')
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="date"><i class="far fa-clock"></i>
                                                                                                        {
                                                                                                            moment(item.updatedOn).tz("Europe/Dublin").format('h:mm')
                                                                                                        }
                                                                                                    </div>
                                                                                                </span>
                                                                                                <div className="d-flex headIcons">
                                                                                                    <i class="far fa-edit" title="Edit" onClick={() => hendleEditClick(item._id, item.comment)}></i>
                                                                                                    <i class="far fa-trash-alt" title="Delete" onClick={() => handleClickDelete(item._id)}></i>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="comment-content">
                                                                                                {item.comment}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </React.Fragment>
                                                                :
                                                                null
                                                        }
                                                    </li>
                                                )
                                            }
                                        </React.Fragment>
                                }

                            </ul>
                        </div>
                        <div className="dk-commentReplyBox">
                            <textarea name="comment" id="" className="form-control" value={value} onChange={handleChange} autoFocus ></textarea>
                            <div className="dk-commentSubmitBtn">
                                <Submit txt={btnTxt} loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" className="dk-sendBtn" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default CommentPopup;
