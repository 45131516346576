import React from 'react'
const DisplayResponsbility = ({ list }) => {
    return (
        <React.Fragment>
            {
                list.split('\n').map(str => <li>{str}</li>)
            }
        </React.Fragment>
    )
}
export default DisplayResponsbility;