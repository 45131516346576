import React from 'react';
import { Route, Link } from "react-router-dom";
import './style.scss';

const SmsLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <div className="col-md-2">
                <div className="dk-smsLeftPanel">
                    <Route>
                        <ul>
                            <li><Link to="/sms/compose" className={`${pathname.match('/sms/compose') ? 'active' : ''}`}>Compose New</Link></li>
                            <li><Link to="/sms/inbox" className={`${pathname.match('/sms/inbox') ? 'active' : ''}`}>Inbox</Link></li>
                            <li><Link to="/sms/sent" className={`${pathname.match('/sms/sent') ? 'active' : ''}`}>Sent Messsages</Link></li>
                            <li><Link to="/sms/templates" className={`${pathname.match('/sms/templates') ? 'active' : ''}`}>Templates</Link></li>
                        </ul>
                    </Route>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SmsLeftPanel;
