import React, { useState, useEffect } from 'react';
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";
import { loadingStart, loadingEnd, clearLoading } from "../../../../../slice/utils/loader/linearSlice";
import Submit from "../../../../ui/submitButton";
import { updateTemplate, clearState } from "../../../../../slice/sms/templateSlice";
const EditTeamModal = ({ id, handleClose }) => {
    const dispatch = useDispatch();
    const { isDelete, isError, errorMessage, isUpdate, msg } = useSelector(state => state.smsTemplate)
    const { templateDetails } = useSelector(state => state.smsTemplate)
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true)
    }, [id])

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [templateId, setTemplateId] = useState('0')
    const [name, setName] = useState('')
    const [body, setBody] = useState('')

    useEffect(() => {
        setTemplateId(templateDetails._id)
        setName(templateDetails.name)
        setBody(templateDetails.body)
    }, [templateDetails])

    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {
            case "name":
                setName(value)
                break;

            case "body":
                setBody(value)
                break;

            default:
                break;
        }
    }

    const saveData = async () => {
        let userId = templateDetails.userId._id;
        if (!name || !body || userId == '0') {
            dispatch(showError({ msg: 'All fields required' }))
        } else {
            if (!loading) {
                setSuccess(false);
                setLoading(true);
                await dispatch(updateTemplate({ name, body, userId, templateId }))
            }
        }
    }

    useEffect(() => {
        if (isError) {
            setSuccess(true);
            setLoading(false);
            dispatch(clearState())
            dispatch(clearLoading())
            dispatch(showError({ msg: msg }))
        }
        if (isUpdate) {
            setSuccess(true);
            setLoading(false);
            setShow(false);
            dispatch(showSuccess({ msg: msg }))
            dispatch(clearState())
        }
    }, [isError, isUpdate])

    return (
        <React.Fragment>
            <Modal className="dk-smsMessageModal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update SMS Template</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-mlAddModalBody dk-smstemplatesModalBody">
                    <form action="">
                        <div className="form-group">
                            <label htmlFor="">Template Name:</label>
                            <label htmlFor="">
                                <input type="text" name="name" className="form-control" value={name} onChange={handleChange} />
                            </label>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Template Body:</label>
                            <label htmlFor="">
                                <textarea name="body" className="form-control" value={body} onChange={handleChange}></textarea>
                            </label>
                        </div>
                        <div className="form-group text-center mb-0">
                            <label htmlFor="">
                                <Submit txt="Update" loading={loading} success={success} onClick={saveData} position="justify-content-center" />
                            </label>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default EditTeamModal;