import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
export const rulesDetails = createAsyncThunk(
    'rules/rules_details',
    async ({ userId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/rules/' + userId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const rulesItems = createAsyncThunk(
    'rules/rules_items',
    async ({ userId, ruleFor }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/rules/' + userId + '/' + ruleFor,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const rulesUpdate = createAsyncThunk(
    'rules/rules_update',
    async ({ id, type, listId, list }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch(`/api/rules/${id}/${type}/${listId}`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        list,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const rulesDelete = createAsyncThunk(
    'rules/rules_delete',
    async ({ id, type, listId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch(`/api/rules/${id}/${type}/${listId}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 202) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

const rulesSlice = createSlice({
    name: 'rules',
    initialState: {
        rules: {},
        isSuccess: false,
        isError: false,
        isLoading: false,
        isDelete: false,
        errorMessage: '',
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isLoading = false;
            state.isDelete = false;
            state.errorMessage = '';
            return state;
        }
    },
    extraReducers: {
        [rulesDetails.pending]: (state) => {
            state.isLoading = true;
        },
        [rulesDetails.fulfilled]: (state, { payload }) => {
            state.rules = payload.rules;
            state.isSuccess = true;
            state.isLoading = false;
        },
        [rulesDetails.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
            state.isLoading = false;
        },
        [rulesItems.pending]: (state) => {
        },
        [rulesItems.fulfilled]: (state, { payload }) => {
            state.rules = payload.resultData;
        },
        [rulesItems.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [rulesUpdate.pending]: (state) => {
        },
        [rulesUpdate.fulfilled]: (state, { payload }) => {
            let mainArray;
            let finalResult;
            switch (payload.data.type) {
                case 'morningData':
                    mainArray = state.rules.morningData
                    finalResult = mainArray.map((r) => {
                        if (r._id === payload.data.id) {
                            return ({ ...r, list: payload.data.value })
                        } else {
                            return r
                        }
                    })
                    state.rules.morningData = finalResult
                    break;

                case 'noonData':
                    mainArray = state.rules.noonData
                    finalResult = mainArray.map((r) => {
                        if (r._id === payload.data.id) {
                            return ({ ...r, list: payload.data.value })
                        } else {
                            return r
                        }
                    })
                    state.rules.noonData = finalResult
                    break;

                case 'eveningData':
                    mainArray = state.rules.eveningData
                    finalResult = mainArray.map((r) => {
                        if (r._id === payload.data.id) {
                            return ({ ...r, list: payload.data.value })
                        } else {
                            return r
                        }
                    })
                    state.rules.eveningData = finalResult
                    break;
            }
        },
        [rulesUpdate.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [rulesDelete.pending]: (state) => {
        },
        [rulesDelete.fulfilled]: (state, { payload }) => {
            let mainArray;
            let finalResult;
            switch (payload.data.type) {
                case 'morningData':
                    mainArray = state.rules.morningData
                    finalResult = mainArray.filter((item) => item._id !== payload.data.id)
                    state.rules.morningData = finalResult
                    break;

                case 'noonData':
                    mainArray = state.rules.noonData
                    finalResult = mainArray.filter((item) => item._id !== payload.data.id)
                    state.rules.noonData = finalResult
                    break;

                case 'eveningData':
                    mainArray = state.rules.eveningData
                    finalResult = mainArray.filter((item) => item._id !== payload.data.id)
                    state.rules.eveningData = finalResult
                    break;
            }
            state.isDelete = true;
        },
        [rulesDelete.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
    }
});

export const { clearState } = rulesSlice.actions;
export default rulesSlice.reducer;