import React,{useState,useEffect} from 'react';
import  "./style.scss";
import SearchdropDown from "../../../ui/searchdropdown/SearchdropDown";

const Summary = () => {
    const [statusFilter, setStatusFilter] = useState(false);
    const [paymentFilter, setPaymentFilter] = useState(false);
    const [sourceTypeFilter, setSourceTypeFilter] = useState(false);
    const [sourcecategoryFilter, setSourceCategoryFilter] = useState(false);
    const [mlSourceFilter, setMlSourceFilter] = useState(false);
    const [itSourceFilter, setItSourceFilter] = useState(false);
    const [locationFilter, setLocationFilter] = useState(false);
    useEffect(() => {
        document.addEventListener("mousedown", () => {
            setStatusFilter(false);
            setPaymentFilter(false);
            setSourceTypeFilter(false);
            setSourceCategoryFilter(false);
            setMlSourceFilter(false);
            setItSourceFilter(false);
            setLocationFilter(false);
        });
    });
    return (
        <React.Fragment>
            <div className="container-fluid dk-resSummaryCon">
                <div className="col-md-12 col-sm-12">
                    <div className="dk-reSCenterheaderCont">
                        <h2 className="dk-reSPageTitle">Resource Summary </h2>
                    </div>
                </div>
                <div className="dk-reSMMainContent">
                    <div className="row">
                        <div className="col-lg-5 col-md-5 col-sm-12 pr-0">
                            <div className="dk-reSummTwoBox d-flex">
                                <div className="dk-reSummTwoCols">
                                    <div className="dk-reSummTwoHeader dk-reSHeaderCont78">
                                       <div className="dk-filterIcon" onClick={() => { setStatusFilter(!statusFilter) }}><i className="fa fa-search" aria-hidden="true"></i></div>
                                       <div className="dk-reSHeaderTitle">Status</div>
                                       <span>345</span>
                                        {
                                            statusFilter ? <SearchdropDown placeholder="Search Status"  text1="Active" text2="Inactive" text3="Qualifying"/> : null
                                        }
                                    </div>
                                    <div className="dk-reSummTwoContent">
                                        <li>
                                            <div>Active</div>
                                            <span>156</span>
                                        </li>
                                        <li>
                                            <div>Inactive</div>
                                            <span>58</span>
                                        </li>
                                        <li>
                                            <div>Qualifying</div>
                                            <span>86</span>
                                        </li>
                                    </div>
                                </div>
                                <div className="dk-reSummTwoCols">
                                    <div className="dk-reSummTwoHeader dk-reSHeaderCont78">
                                       <div className="dk-filterIcon" onClick={() => { setPaymentFilter(!paymentFilter) }}><i className="fa fa-search" aria-hidden="true"></i></div>
                                       <div className="dk-reSHeaderTitle">Payment Type</div>
                                       <span>345</span>
                                        {
                                           paymentFilter ? <SearchdropDown placeholder="Search Payment Type"  text1="Paid" text2="Unpaid" text3="Free Trail" /> : null
                                        }
                                    </div>
                                    <div className="dk-reSummTwoContent">
                                        <li>
                                            <div>Paid</div>
                                            <span>156</span>
                                        </li>
                                        <li>
                                            <div>Unpaid</div>
                                            <span>58</span>
                                        </li>
                                        <li>
                                            <div>Free Trail</div>
                                            <span>86</span>
                                        </li>
                                    </div>
                                </div>
                            </div>
                            <div className="dk-reSummTwoBox d-flex mt-4">
                                <div className="dk-reSummTwoCols">
                                    <div className="dk-reSummTwoHeader dk-reSHeaderCont78">
                                       <div className="dk-filterIcon" onClick={() => {setSourceTypeFilter(!sourceTypeFilter) }}><i className="fa fa-search" aria-hidden="true"></i></div>
                                        <div className="dk-reSHeaderTitle">Source Type</div>
                                        <span>345</span>
                                        {
                                           sourceTypeFilter ? <SearchdropDown placeholder="Search Source Type"  text1="Source Type 1" text2="Source Type 2" text3="Source Type  3"  /> : null
                                        }
                                    </div>
                                    <div className="dk-reSummTwoContent dk-restwoSecCols">
                                        <li>
                                            <div>Source Type 1</div>
                                            <span>156</span>
                                        </li>
                                        <li>
                                            <div>Source Type 2</div>
                                            <span>58</span>
                                        </li>
                                        <li>
                                            <div>Source Type 3</div>
                                            <span>86</span>
                                        </li>
                                        <li>
                                            <div>Source Type 4</div>
                                            <span>156</span>
                                        </li>
                                        <li>
                                            <div>Source Type 5</div>
                                            <span>58</span>
                                        </li>
                                        <li>
                                            <div>Source Type 6</div>
                                            <span>86</span>
                                        </li>
                                        <li>
                                            <div>Source Type 7</div>
                                            <span>156</span>
                                        </li>
                                        <li>
                                            <div>Source Type 8</div>
                                            <span>58</span>
                                        </li>
                                        <li>
                                            <div>Source Type 9</div>
                                            <span>86</span>
                                        </li>
                                        <li>
                                            <div>Source Type 10</div>
                                            <span>86</span>
                                        </li>
                                    </div>
                                </div>
                            <div className="dk-reSummTwoCols">
                                <div className="dk-reSummTwoHeader dk-reSHeaderCont78">
                                    <div className="dk-filterIcon" onClick={() => {setSourceCategoryFilter(!sourcecategoryFilter) }}><i className="fa fa-search" aria-hidden="true"></i></div>
                                    <div className="dk-reSHeaderTitle">Source Category</div>
                                    <span>345</span>
                                    {
                                        sourcecategoryFilter ? <SearchdropDown placeholder="Search Source Category"  text1="Source Category 1" text2="Source Category 2" text3="Source Category  3" /> : null
                                    }
                                    </div>
                                    <div className="dk-reSummTwoContent dk-restwoSecCols">
                                        <li>
                                            <div>Source Category 1</div>
                                            <span>156</span>
                                        </li>
                                        <li>
                                            <div>Source Category 2</div>
                                            <span>58</span>
                                        </li>
                                        <li>
                                            <div>Source Category 3</div>
                                            <span>86</span>
                                        </li>
                                        <li>
                                            <div>Source Category 4</div>
                                            <span>156</span>
                                        </li>
                                        <li>
                                            <div>Source Category 5</div>
                                            <span>58</span>
                                        </li>
                                        <li>
                                            <div>Source Category 6</div>
                                            <span>86</span>
                                        </li>
                                        <li>
                                            <div>Source Category 7</div>
                                            <span>156</span>
                                        </li>
                                        <li>
                                            <div>Source Category 8</div>
                                            <span>58</span>
                                        </li>
                                        <li>
                                            <div>Source Category 9</div>
                                            <span>58</span>
                                        </li>
                                        <li>
                                            <div>Source Category 10</div>
                                            <span>58</span>
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-12 pl-0">
                            <div className="dk-reSummThreBox d-flex">
                            <div className="dk-reSummThreCols">
                                    <div className="dk-reSummThreHeader dk-reSHeaderCont78">
                                        <div className="dk-filterIcon" onClick={() => {setMlSourceFilter(!mlSourceFilter)}}><i className="fa fa-search" aria-hidden="true"></i></div>
                                        <div className="dk-reSHeaderTitle">ML Source</div>
                                        <span>345</span>
                                        {
                                            mlSourceFilter ? <SearchdropDown placeholder="Search ML Source"  text1="ML Source 1" text2="ML Source 2" text3="ML Source 3" /> : null
                                        }
                                    </div>
                                    <div className="dk-reSummTreContent">
                                        <li>
                                            <div>MLSource 1</div>
                                            <span>156</span>
                                        </li>
                                        <li>
                                            <div>MLSource 2</div>
                                            <span>58</span>
                                        </li>
                                        <li>
                                            <div>MLSource 3</div>
                                            <span>86</span>
                                        </li>
                                        <li>
                                            <div>MLSource 4</div>
                                            <span>156</span>
                                        </li>
                                        <li>
                                            <div>MLSource 5</div>
                                            <span>58</span>
                                        </li>
                                        <li>
                                            <div>MLSource 6</div>
                                            <span>86</span>
                                        </li>
                                        <li>
                                            <div>MLSource 7</div>
                                            <span>156</span>
                                        </li>
                                        <li>
                                            <div>MLSource 8</div>
                                            <span>58</span>
                                        </li>
                                        <li>
                                            <div>MLSource 9</div>
                                            <span>86</span>
                                        </li>
                                        <li>
                                            <div>MLSource 10</div>
                                            <span>156</span>
                                        </li>
                                        <li>
                                            <div>MLSource 11</div>
                                            <span>58</span>
                                        </li>
                                        <li>
                                            <div>MLSource 12</div>
                                            <span>86</span>
                                        </li>
                                            <li><div>MLSource 13</div>
                                            <span>156</span>
                                        </li>
                                        <li>
                                            <div>MLSource 14</div>
                                            <span>58</span>
                                        </li>
                                        <li>
                                            <div>MLSource 15</div>
                                            <span>86</span>
                                        </li>
                                        <li>
                                            <div>MLSource 16</div>
                                            <span>156</span>
                                        </li>
                                    </div>
                                </div>
                                <div className="dk-reSummThreCols">
                                    <div className="dk-reSummThreHeader dk-reSHeaderCont78">
                                        <div className="dk-filterIcon"  onClick={() => {setItSourceFilter(!itSourceFilter)}}><i className="fa fa-search" aria-hidden="true"></i></div>
                                        <div className="dk-reSHeaderTitle">IT Source</div>
                                        <span>345</span>
                                        {
                                            itSourceFilter ? <SearchdropDown placeholder="Search IT Source"  text1="IT Source 1" text2="IT Source 2" text3="IT Source 3" /> : null
                                        }
                                    </div>
                                    <div className="dk-reSummTreContent">
                                        <li>
                                            <div>ITSource 1</div>
                                            <span>156</span>
                                        </li>
                                        <li>
                                            <div>ITSource 2</div>
                                            <span>58</span>
                                        </li>
                                        <li>
                                            <div>ITSource 3</div>
                                            <span>86</span>
                                        </li>
                                        <li>
                                            <div>ITSource 4</div>
                                            <span>156</span>
                                        </li>
                                        <li>
                                            <div>ITSource 5</div>
                                            <span>58</span>
                                        </li>
                                        <li>
                                            <div>ITSource 6</div>
                                            <span>86</span>
                                        </li>
                                        <li>
                                            <div>ITSource 7</div>
                                            <span>156</span>
                                        </li>
                                        <li>
                                            <div>ITSource 8</div>
                                            <span>58</span>
                                        </li>
                                        <li>
                                            <div>ITSource 9</div>
                                            <span>86</span>
                                        </li>
                                        <li>
                                            <div>ITSource 10</div>
                                            <span>156</span>
                                        </li>
                                        <li>
                                            <div>ITSource 11</div>
                                            <span>58</span>
                                        </li>
                                        <li>
                                            <div>ITSource 12</div>
                                            <span>86</span>
                                        </li>
                                            <li><div>ITSource 13</div>
                                            <span>156</span>
                                        </li>
                                        <li>
                                            <div>ITSource 14</div>
                                            <span>58</span>
                                        </li>
                                        <li>
                                            <div>ITSource 15</div>
                                            <span>86</span>
                                        </li>
                                        <li>
                                            <div>ITSource 16</div>
                                            <span>156</span>
                                        </li>
                                    </div>
                                </div>
                                <div className="dk-reSummThreCols">
                                    <div className="dk-reSummThreHeader dk-reSHeaderCont78">
                                        <div className="dk-filterIcon" onClick={() => {setLocationFilter(!locationFilter)}}><i className="fa fa-search" aria-hidden="true"></i></div>
                                        <div className="dk-reSHeaderTitle">Location</div>
                                        <span>345</span>
                                        {
                                            locationFilter ? <SearchdropDown placeholder="Search Location"  text1="Dublin" text2="New Delhi" text3="Noida" /> : null
                                        }
                                    </div>
                                    <div className="dk-reSummTreContent">
                                        <li>
                                            <div>Dublin</div>
                                            <span>156</span>
                                        </li>
                                        <li>
                                            <div>New Delhi</div>
                                            <span>58</span>
                                        </li>
                                        <li>
                                            <div>Noida</div>
                                            <span>86</span>
                                        </li>
                                        <li>
                                            <div>Cork</div>
                                            <span>156</span>
                                        </li>
                                        <li>
                                            <div>Galway</div>
                                            <span>58</span>
                                        </li>
                                        <li>
                                            <div>Louth</div>
                                            <span>86</span>
                                        </li>
                                        <li>
                                            <div>Kilkenny</div>
                                            <span>156</span>
                                        </li>
                                        <li>
                                            <div>Wexford</div>
                                            <span>58</span>
                                        </li>
                                        <li>
                                            <div>Sligo</div>
                                            <span>86</span>
                                        </li>
                                        <li>
                                            <div>Tipperary</div>
                                            <span>156</span>
                                        </li>
                                        <li>
                                            <div>Dundalk</div>
                                            <span>58</span>
                                        </li>
                                        <li>
                                            <div>Noida</div>
                                            <span>86</span>
                                        </li>
                                        <li>
                                            <div>Dublin</div>
                                            <span>156</span>
                                        </li>
                                        <li>
                                            <div>New Delhi</div>
                                            <span>58</span>
                                        </li>
                                        <li>
                                            <div>Noida</div>
                                            <span>86</span>
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Summary;
