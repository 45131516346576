import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
export const listTable = createAsyncThunk(
    'team/list',
    async ({ companyId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/team/' + companyId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);
export const addTeam = createAsyncThunk(
    'team/add',
    async ({ userId, name, jobTitle, location, joinDate, level, nationality, status, companyId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/team',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        userId, name, jobTitle, location, joinDate, level, nationality, status, companyId
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)
export const fethById = createAsyncThunk(
    'team/singleRecord',
    async ({ teamId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/team/details/' + teamId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)
export const updateTeamDetails = createAsyncThunk(
    'team/update',
    async ({ id, userId, name, jobTitle, location, joinDate, level, nationality, status }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/team/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        userId, name, jobTitle, location, joinDate, level, nationality, status
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)
export const teamDelete = createAsyncThunk(
    'team/delete',
    async ({ rowId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/team/' + rowId,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

const teamSlice = createSlice({
    name: 'team',
    initialState: {
        dataList: {},
        teamDetails: {},
        isSuccess: false,
        isInsert: false,
        isUpdate: false,
        isDelete: false,
        isLoading: false,
        isError: false,
        msg: ''
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isDelete = false;
            state.isError = false;
            state.msg = '';
        }
    },
    extraReducers: {
        [listTable.pending]: (state) => {
            state.isLoading = true;
        },
        [listTable.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.dataList = payload.data;
        },
        [listTable.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        [addTeam.pending]: (state) => {
            state.isLoading = true;
        },
        [addTeam.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = true;
            state.dataList.push(payload.data)
            state.msg = payload.message.msgBody
        },
        [addTeam.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.message.msgBody
        },
        [fethById.pending]: (state) => {
            state.isLoading = true;
        },
        [fethById.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.teamDetails = payload.data;
        },
        [fethById.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
        },
        [updateTeamDetails.pending]: (state) => {
            state.isLoading = true;
        },
        [updateTeamDetails.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            let mainArray = state.dataList;
            let finalResult = mainArray.map((r) => {
                if (r._id === payload.data._id) {
                    return (payload.data)
                } else {
                    return r
                }
            })
            state.dataList = finalResult
            state.msg = payload.message.msgBody
        },
        [updateTeamDetails.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.msgBody
        },
        [teamDelete.pending]: (state) => {

        },
        [teamDelete.fulfilled]: (state, action) => {
            state.isDelete = true;
            state.dataList = state.dataList.filter((item) => item._id !== action.meta.arg.rowId)
            state.msg = 'Delete Successfully';
        },
        [teamDelete.rejected]: (state, { payload }) => {
            state.isDelete = false;
            state.isError = true;
            state.msg = payload.msgBody
        },
    }
})
export const { clearState } = teamSlice.actions
export default teamSlice.reducer;
