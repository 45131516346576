import React from 'react';
import  "./summary.scss";
import SummarylongCols from './SummarylongCols';
import SummaytextCols from './SummaytextCols';
import SearchinputFilter from "../../../ui/selectpicker/searchinput/SearchinputFilter.js";

const Summary = () => {
    return (
        <React.Fragment>
        <div className="container-fluid dk-resSummaryCon">
            <div className="row">
                <div className="col-md-12 col-sm-12">
                    <div className="dk-reSCenterheaderCont">
                        <h2 className="dk-reSPageTitle">CLIENTS OF SUMMARY</h2>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-lg-6 col-md-6 col-sm-12 pr-0">
                    <div className="dk-reSummThreeBox d-flex">
                        <div className="dk-reSummThreeCols">
                            <div className="dk-reSummTwoHeader dk-reSHeaderCont78">
                                <div className="dk-filterIcon">
                                   <i className="fa fa-search" aria-hidden="true"></i>
                                </div>
                                <div className="dk-reSHeaderTitle">Status</div>
                                <span>345</span>
                            </div>
                            <div className="dk-reSummTwoContent">
                                <li>
                                    <div>Active</div>
                                    <span>156</span>
                                </li>
                                <li>
                                    <div>Inactive</div>
                                    <span>58</span>
                                </li>
                                <li>
                                    <div>Qualifying</div>
                                    <span>86</span>
                                </li>
                            </div>
                            {/* <div className="dkSearchLiveInput">
                                <SearchinputFilter />
                            </div> */}
                        </div>
                        <div className="dk-reSummThreeCols">
                            <div className="dk-reSummTwoHeader dk-reSHeaderCont78">
                                <div className="dk-filterIcon">
                                   <i className="fa fa-search" aria-hidden="true"></i>
                                </div>
                                <div className="dk-reSHeaderTitle">Category</div>
                                <span>345</span>
                            </div>
                            <div className="dk-reSummTwoContent">
                                <li>
                                    <div>ML</div>
                                    <span>156</span>
                                </li>
                                <li>
                                    <div>IT</div>
                                    <span>58</span>
                                </li>
                                <li>
                                    <div>Others</div>
                                    <span>86</span>
                                </li>
                            </div>
                        </div>
                        <div className="dk-reSummThreeCols">
                            <div className="dk-reSummTwoHeader dk-reSHeaderCont78">
                                <div className="dk-filterIcon">
                                   <i className="fa fa-search" aria-hidden="true"></i>
                                </div>
                                <div className="dk-reSHeaderTitle">Business</div>
                                <span>345</span>
                            </div>
                            <div className="dk-reSummTwoContent">
                                <li>
                                    <div>BPO</div>
                                    <span>156</span>
                                </li>
                                <li>
                                    <div>Direct</div>
                                    <span>58</span>
                                </li>
                                <li>
                                    <div>Others</div>
                                    <span>86</span>
                                </li>
                            </div>
                        </div>
                    </div>
                    <div className="dk-reSummThreeBox d-flex mt-4">
                        <div className="dk-reSummThreeCols">
                            <div className="dk-reSummTwoHeader dk-reSHeaderCont78">
                                <div className="dk-filterIcon">
                                   <i className="fa fa-search" aria-hidden="true"></i>
                                </div>
                                <div className="dk-reSHeaderTitle">A/C Manager</div>
                                <span>345</span>
                            </div>  
                            <div className="dk-reSummTwoContent">
                                <li>
                                    <div className="dk-clisuMMLeft d-flex">
                                        <div className="dk-CliensuMMImgCri">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="clientSummImg"/>
                                        </div>
                                        Account Manager
                                    </div>
                                    <span>156</span>
                                </li>
                                <li>
                                    <div className="dk-clisuMMLeft d-flex">
                                        <div className="dk-CliensuMMImgCri">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="clientSummImg"/>
                                        </div>
                                        Account Manager
                                    </div>
                                    <span>156</span>
                                </li>
                                <li>
                                    <div className="dk-clisuMMLeft d-flex">
                                        <div className="dk-CliensuMMImgCri">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="clientSummImg"/>
                                        </div>
                                        Account Manager
                                    </div>
                                    <span>156</span>
                                </li>
                                <li>
                                    <div className="dk-clisuMMLeft d-flex">
                                        <div className="dk-CliensuMMImgCri">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="clientSummImg"/>
                                        </div>
                                        Account Manager
                                    </div>
                                    <span>156</span>
                                </li>
                                <li>
                                    <div className="dk-clisuMMLeft d-flex">
                                        <div className="dk-CliensuMMImgCri">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="clientSummImg"/>
                                        </div>
                                        Account Manager
                                    </div>
                                    <span>156</span>
                                </li>
                                <li>
                                    <div className="dk-clisuMMLeft d-flex">
                                        <div className="dk-CliensuMMImgCri">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="clientSummImg"/>
                                        </div>
                                        Account Manager
                                    </div>
                                    <span>156</span>
                                </li>
                                <li>
                                    <div className="dk-clisuMMLeft d-flex">
                                        <div className="dk-CliensuMMImgCri">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="clientSummImg"/>
                                        </div>
                                        Account Manager
                                    </div>
                                    <span>156</span>
                                </li>
                                <li>
                                    <div className="dk-clisuMMLeft d-flex">
                                        <div className="dk-CliensuMMImgCri">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="clientSummImg"/>
                                        </div>
                                        Account Manager
                                    </div>
                                    <span>156</span>
                                </li>
                                <li>
                                    <div className="dk-clisuMMLeft d-flex">
                                        <div className="dk-CliensuMMImgCri">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="clientSummImg"/>
                                        </div>
                                        Account Manager
                                    </div>
                                    <span>156</span>
                                </li>
                                <li>
                                    <div className="dk-clisuMMLeft d-flex">
                                        <div className="dk-CliensuMMImgCri">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="clientSummImg"/>
                                        </div>
                                        Account Manager
                                    </div>
                                    <span>156</span>
                                </li>
                            </div> 
                        </div>
                        <SummaytextCols headerTitle="Companies" text1="Companies 1" text2="Companies 3" text3="Companies 3" text4="Companies 4"
                            text5="Companies 5" text6="Companies 6" text7="Companies 7" text8="Companies 8" text9="Companies 9" text10="Companies 10"/>                       <SummaytextCols  headerTitle="Industry" text1="Industry 1" text2="Industry 3" text3="Industry 3" text4="Industry 4"
                            text5="Industry 5" text6="Industry 6" text7="Industry 7" text8="Industry 8" text9="Industry 9" text10="Industry 10"/>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 pl-0">
                    <div className="dk-reSummThreBox d-flex">
                        <SummarylongCols boxheaderTitle="Locations" text1="Dublin" text2="New Delhi" text3="Noida" text4="Cork"
                            text5="Galway" text6="Louth" text7="Kilkenny" text8="Wexford" text9="Sligo" text10="Tipperary" text11="Galway" text12="Louth" text13="Kilkenny" text14="Wexford" text15="Sligo" text16="Tipperary" />
                        <SummarylongCols boxheaderTitle="Projects" text1="Project 1" text2="Project 3" text3="Project 3" text4="Project 4"
                            text5="Project 5" text6="Project 6" text7="Project 7" text8="Project 8" text9="Project 9" text10="Project 10" text11="Project 11" text12="Project 12" text13="Project 13" text14="Project 14" text15="Project 15" text16="Project 16"/>
                        <SummarylongCols boxheaderTitle="Role Type" text1="Role Type 1" text2="Role Type 3" text3="Role Type 3" text4="Role Type 4"
                            text5="Role Type 5" text6="Role Type 6" text7="Role Type 7" text8="Role Type 8" text9="Role Type 9" text10="Role Type 10" text11="Role Type 11" text12="Role Type 12" text13="Role Type 13" text14="Role Type 14" text15="Role Type 15" text16="Role Type 16"/>
                    </div>
                </div>
            </div> 
        </div>
    </React.Fragment>
    )
}

export default Summary;
