import React from 'react';
import { Link, Route } from "react-router-dom";
import Logo from "../../../assets/images/logo.png";
import Dropdown from 'react-bootstrap/Dropdown'
import { logoutUser } from "../../../slice/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { IMG_CLOUD_BASE_URL } from "../../../constants";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import UserNav from "./userNav"
const AdminHeader = () => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.auth);
    const { myModule } = useSelector(state => state.module);
    const logout = async () => {
        dispatch(logoutUser({}))
        window.location.assign(process.env.REACT_APP_REDIRECT_LOGOUT_URL);
    }
    return (
        <React.Fragment>
            <div className="dk-mdHeaderMain">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="dk-moduleLogo">
                        <Route>
                            <Link to="#"><img src={Logo} className="img-fluid" alt="" /></Link>
                        </Route>
                    </div>
                    <h3 className="titleClient text-center" style={{ color: '#5C737D', fontWeight: 600 }}>ACCOUNT ADMIN</h3>
                    <div className="dk-mdHeader-rightPanel">
                        <div className="dk-btnsClient">
                            <Route>
                                <Link to="#"><i class="fas fa-envelope"></i></Link>
                                <Link to="#"><i class="far fa-calendar-alt"></i></Link>
                                <Link to="#"><i class="fas fa-bullhorn"></i></Link>
                                <Link to="#"><i class="far fa-comments"></i></Link>
                            </Route>
                        </div>
                        <UserNav />
                        <ArrowDropDownIcon />
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}

export default AdminHeader;
