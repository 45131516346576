import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from "react-redux";
import BlankLayout from "../layout/BlankLayout";
const BlankPrivateRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticate } = useSelector(state => state.auth)

    return (
        <Route {...rest} render={props => {
            if (isAuthenticate)
                return <BlankLayout><Component {...props} /></BlankLayout>
            return <Redirect
                to={{ pathname: '/', state: { from: props.location } }}
            />
        }} />
    )
};
export default BlankPrivateRoute;