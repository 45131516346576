import React, { useState } from 'react';
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';

const EditJobProfileModal = ({ handleClose, profileUserId }) => {
    const [show, setShow] = useState(false);
    return (
        <React.Fragment>
            <Modal className="dk-editJobProfile" show={true} onHide={handleClose}>
                <Modal.Body>
                    <div className="dk-editJobProfile-container">
                        <div className="dk-editJobProfile-head">
                            <div className="d-flex align-items-center justify-content-between">
                                <Link to="#" className="active">Edit</Link>
                                <Link to="#">Delete</Link>
                                <div className="title">Departments</div>
                                <Link to="#">Reorder</Link>
                                <Modal.Header closeButton></Modal.Header>
                            </div>
                        </div>
                        <div className="dk-editJobProfile-editSec">
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>
                                                <div className="dk-editSec-input">
                                                    <input type="text" value="All Departments" name="" id="" className="form-control" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="dk-editSec-colorBox">
                                                    <span></span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="dk-editJobProfile-deleteSec dk-editJobProfile-editSec" style={{ display: 'none' }}>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td><input type="checkbox" id="" name="" /></td>
                                            <td>
                                                <div className="dk-editSec-input text-center">
                                                    All Departments
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="dk-deleteSec-saveBtn mt-3">
                                    <button>Delete</button>
                                </div>
                            </div>
                        </div>
                        <div className="dk-editJobProfile-reOrder" style={{ display: 'none' }}>
                            <ul>
                                <li>
                                    <span><i class="fas fa-arrows-alt-v"></i></span>
                                    All Departments
                                </li>
                            </ul>
                            <div className="dk-deleteSec-saveBtn">
                                <button>Delete</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default EditJobProfileModal;