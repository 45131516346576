import React, { useState, useEffect } from "react";
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { SketchPicker } from 'react-color';
import Submit from "../../../../ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";
import { updateColor, clearState } from "../../../../../slice/admin/backendValues/backendValuesSlice";

const ColorPickerModal = ({ id, backgroundColor, textColor, value }) => {
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);

    const [background, setBackground] = useState(backgroundColor);
    const [txtColor, setTxtColor] = useState(textColor);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { isUpdateColor, isError, msg } = useSelector(state => state.backendValues)

    const handleChangeBG = (color, event) => {
        setBackground(color.hex)
    }

    const handleChangeText = (color, event) => {
        setTxtColor(color.hex)
    }

    const updateValueColor = async () => {
        await dispatch(updateColor({ id, bgColor: background, textColor: txtColor }))
        setShow(false)
    }

    useEffect(() => {
        if (isUpdateColor) {
            dispatch(clearState())
            setLoading(false)
            setSuccess(false)
            setShow(false)
        }
        if (isError) {
            dispatch(clearState())
            setLoading(false)
            setSuccess(false)
            setShow(false)
        }
    }, [isError])

    return (
        <React.Fragment>
            <span className="dk-toolsValueColor" onClick={handleShow} style={{ backgroundColor: "#fff", border: "1px solid #ccc", color: "#333 !important", height: "35px", width: "50px", textAlign: "center", lineHeight: "35px", cursor: "pointer" }}><i class="fas fa-tint"></i></span>

            <Modal className="dk-colorPickerModal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Value Colors</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="dk-colorPickerCols">
                                <div className="sbTitle">Preview</div>
                                <input type="text" value={value} className="form-control" style={{ backgroundColor: background, color: txtColor }} />
                            </div>
                            <div className="title">BG color</div>
                            <SketchPicker color={background} onChange={handleChangeBG} />
                        </div>
                        <div className="col-md-6">
                            <div className="dk-colorPickerCols">
                                <div className="d-flex">
                                    <div className="mr-2">
                                        <div className="sbTitle">BG Colors</div>
                                        <input type="text" value={background} name="" id="" className="form-control" style={{ backgroundColor: background, color: txtColor }} />
                                    </div>
                                    <div className="ml-2">
                                        <div className="sbTitle">Text Color</div>
                                        <input type="text" value={txtColor} name="" id="" className="form-control" style={{ backgroundColor: background, color: txtColor }} />
                                    </div>
                                </div>
                            </div>
                            <div className="title">BG color</div>
                            <SketchPicker color={txtColor} onChange={handleChangeText} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button variant="" onClick={handleClose}>
                        Close
                    </button>
                    <Submit txt="Ok" loading={loading} success={success} onClick={updateValueColor} position="justify-content-center" className="ok-class" />
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}

export default ColorPickerModal;
