import React, { useEffect } from "react";
import Header from "../elements/header";
import ProjectsNavbar from "../elements/header/navbar/mainMenu/projects/ProjectsNavbar";

const Layout = ({ children }) => {

    return (
        <React.Fragment>
            <Header />
            {/* <RecruitmentNavbar /> */}
            <ProjectsNavbar />
            {children}
        </React.Fragment>
    )
}
export default Layout;