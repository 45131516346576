import React, { useEffect, useState } from 'react';
import { Route, Link } from "react-router-dom";
import './style.scss';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import TypesModal from './TypesModal';
import ReasonModal from './ReasonModal';
import SelectTypeField from './SelectTypeField';
import SelectReasonField from './SelectReasonField';

const AddNewLeave = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <React.Fragment>
            <button onClick={handleShow}><i class="fas fa-plus"></i> Add New</button>
            <Modal className="dk-addNewLeaveMain" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <h4 className="modal-title">Add New Leave</h4>
                </Modal.Header>
                <Modal.Body className="dk-addNewleaveBody">
                    <form action="">
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <div className="dk-projectrecruiter">
                                    <span>User</span>
                                    <Dropdown className="dk-globalUserDropdown">
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            <i class="fas fa-user"></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <div className="d-flex">
                                                <Dropdown.Item href="#/action-1">
                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/1523633534-luiza.jpg" className="img-fluid" alt="" />
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#/action-1">
                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad49d2ca540d.png" className="img-fluid" alt="" />
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#/action-1">
                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/5b4dce328e872.png" className="img-fluid" alt="" />
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#/action-1">
                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5bd2c15416.png" className="img-fluid" alt="" />
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#/action-1">
                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#/action-1">
                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ca355dac8269.png" className="img-fluid" alt="" />
                                                </Dropdown.Item>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="col-md-5 pl-0">
                                <div className="form-group">
                                    <label htmlFor="" className="text-center">Date From</label>
                                    <label htmlFor="">
                                        <input type="date" name="date" className="form-control" id="" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-5 pl-0">
                                <div className="form-group">
                                    <label htmlFor="" className="text-center">Date Till</label>
                                    <label htmlFor="">
                                        <input type="date" name="date" className="form-control" id="" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="form-group">
                                    <label htmlFor="">Select Type</label>
                                    <label htmlFor="">
                                        <SelectTypeField />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="form-group">
                                    <label htmlFor="">Select Type</label>
                                    <label htmlFor="">
                                        <SelectReasonField />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="">
                                        <textarea name="" id="" className="form-control" placeholder="Write additional comments"></textarea>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group mt-0 text-center mb-1">
                                    <label htmlFor="">
                                        <button className="dk-saveBtn">Save</button>
                                    </label>
                                </div>
                            </div>

                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default AddNewLeave;
