import React from 'react';
import { Route, Link } from "react-router-dom";
import '../mlresource/style.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import AddNew from "./AddNew";
import ReorderModal from "./ReorderModal";
import DeleteModal from "./DeleteModal";
import EditModal from "./EditModal";
import ViewModal from "./ViewModal";

const MlResource = () => {
    return(
        <React.Fragment>
            <div className="dk-mlResourceMain">
                <div className="dk-head">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="dk-headLeftPanel">
                            <div className="dk-mlSearchBox">
                                <input type="text" className="form-control" placeholder="Search by Text" id="" name="" />
                            </div>
                            <div className="d-flex">
                                <div className="dk-mlFilterBtns">
                                    <Route>
                                        <Link to="#"><i class="fas fa-user"></i></Link>
                                        <Link to="#"><i class="fas fa-file"></i></Link>
                                        <Link to="#"><i class="fas fa-filter"></i></Link>
                                    </Route>
                                </div>
                            </div>
                        </div>
                        <h3 className="title">MULTILINGUAL SOURCES</h3>
                        <div className="dk-headRightPanel">
                            <AddNew />
                            <ReorderModal />
                        </div>
                    </div>
                </div>
                <div className="dk-mlResourceTable">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Sno.</th>
                                    <th>Manager</th>
                                    <th>Status</th>
                                    <th>Name of Source</th>
                                    <th>Category</th>
                                    <th>Source Type</th>
                                    <th>Paid/Unpaid</th>
                                    <th>Coverage</th>
                                    <th>Location</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>
                                        <div className="dk-userImg">
                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="img-fluid" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-mlDropdownStatus">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    Active
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#" className="dk-active">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#"  className="dk-inactive">Inactive</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>Bakeago</td>
                                    <td>Job Board</td>
                                    <td>Ad Posting</td>
                                    <td>Paid</td>
                                    <td>Local Only</td>
                                    <td>Poland</td>
                                    <td>
                                        <div className="dk-mlAction">
                                            <Route>
                                                <ViewModal />
                                                <EditModal />
                                                <DeleteModal />
                                            </Route>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>
                                        <div className="dk-userImg">
                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="img-fluid" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-mlDropdownStatus">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    Active
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#" className="dk-active">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#"  className="dk-inactive">Inactive</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>Bakeago</td>
                                    <td>Job Board</td>
                                    <td>Ad Posting</td>
                                    <td>Paid</td>
                                    <td>Local Only</td>
                                    <td>Poland</td>
                                    <td>
                                        <div className="dk-mlAction">
                                            <Route>
                                                <ViewModal />
                                                <EditModal />
                                                <DeleteModal />
                                            </Route>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>
                                        <div className="dk-userImg">
                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="img-fluid" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-mlDropdownStatus">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    Active
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#" className="dk-active">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#"  className="dk-inactive">Inactive</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>Bakeago</td>
                                    <td>Job Board</td>
                                    <td>Ad Posting</td>
                                    <td>Paid</td>
                                    <td>Local Only</td>
                                    <td>Poland</td>
                                    <td>
                                        <div className="dk-mlAction">
                                            <Route>
                                                <ViewModal />
                                                <EditModal />
                                                <DeleteModal />
                                            </Route>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>
                                        <div className="dk-userImg">
                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="img-fluid" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-mlDropdownStatus">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    Active
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#" className="dk-active">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#"  className="dk-inactive">Inactive</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>Bakeago</td>
                                    <td>Job Board</td>
                                    <td>Ad Posting</td>
                                    <td>Paid</td>
                                    <td>Local Only</td>
                                    <td>Poland</td>
                                    <td>
                                        <div className="dk-mlAction">
                                            <Route>
                                                <ViewModal />
                                                <EditModal />
                                                <DeleteModal />
                                            </Route>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>
                                        <div className="dk-userImg">
                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="img-fluid" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-mlDropdownStatus">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    Active
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#" className="dk-active">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#"  className="dk-inactive">Inactive</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>Bakeago</td>
                                    <td>Job Board</td>
                                    <td>Ad Posting</td>
                                    <td>Paid</td>
                                    <td>Local Only</td>
                                    <td>Poland</td>
                                    <td>
                                        <div className="dk-mlAction">
                                            <Route>
                                                <ViewModal />
                                                <EditModal />
                                                <DeleteModal />
                                            </Route>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>
                                        <div className="dk-userImg">
                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="img-fluid" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-mlDropdownStatus">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    Active
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#" className="dk-active">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#"  className="dk-inactive">Inactive</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>Bakeago</td>
                                    <td>Job Board</td>
                                    <td>Ad Posting</td>
                                    <td>Paid</td>
                                    <td>Local Only</td>
                                    <td>Poland</td>
                                    <td>
                                        <div className="dk-mlAction">
                                            <Route>
                                                <ViewModal />
                                                <EditModal />
                                                <DeleteModal />
                                            </Route>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>
                                        <div className="dk-userImg">
                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="img-fluid" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-mlDropdownStatus">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    Active
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#" className="dk-active">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#"  className="dk-inactive">Inactive</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>Bakeago</td>
                                    <td>Job Board</td>
                                    <td>Ad Posting</td>
                                    <td>Paid</td>
                                    <td>Local Only</td>
                                    <td>Poland</td>
                                    <td>
                                        <div className="dk-mlAction">
                                            <Route>
                                                <ViewModal />
                                                <EditModal />
                                                <DeleteModal />
                                            </Route>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>
                                        <div className="dk-userImg">
                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="img-fluid" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-mlDropdownStatus">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    Active
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#" className="dk-active">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#"  className="dk-inactive">Inactive</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>Bakeago</td>
                                    <td>Job Board</td>
                                    <td>Ad Posting</td>
                                    <td>Paid</td>
                                    <td>Local Only</td>
                                    <td>Poland</td>
                                    <td>
                                        <div className="dk-mlAction">
                                            <Route>
                                                <ViewModal />
                                                <EditModal />
                                                <DeleteModal />
                                            </Route>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>
                                        <div className="dk-userImg">
                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="img-fluid" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-mlDropdownStatus">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    Active
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#" className="dk-active">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#"  className="dk-inactive">Inactive</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>Bakeago</td>
                                    <td>Job Board</td>
                                    <td>Ad Posting</td>
                                    <td>Paid</td>
                                    <td>Local Only</td>
                                    <td>Poland</td>
                                    <td>
                                        <div className="dk-mlAction">
                                            <Route>
                                                <ViewModal />
                                                <EditModal />
                                                <DeleteModal />
                                            </Route>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>
                                        <div className="dk-userImg">
                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="img-fluid" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-mlDropdownStatus">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    Active
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#" className="dk-active">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#"  className="dk-inactive">Inactive</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>Bakeago</td>
                                    <td>Job Board</td>
                                    <td>Ad Posting</td>
                                    <td>Paid</td>
                                    <td>Local Only</td>
                                    <td>Poland</td>
                                    <td>
                                        <div className="dk-mlAction">
                                            <Route>
                                                <ViewModal />
                                                <EditModal />
                                                <DeleteModal />
                                            </Route>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td>
                                        <div className="dk-userImg">
                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="img-fluid" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-mlDropdownStatus">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    Active
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#" className="dk-active">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#"  className="dk-inactive">Inactive</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>Bakeago</td>
                                    <td>Job Board</td>
                                    <td>Ad Posting</td>
                                    <td>Paid</td>
                                    <td>Local Only</td>
                                    <td>Poland</td>
                                    <td>
                                        <div className="dk-mlAction">
                                            <Route>
                                                <ViewModal />
                                                <EditModal />
                                                <DeleteModal />
                                            </Route>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>12</td>
                                    <td>
                                        <div className="dk-userImg">
                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="img-fluid" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-mlDropdownStatus">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    Active
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#" className="dk-active">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#"  className="dk-inactive">Inactive</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>Bakeago</td>
                                    <td>Job Board</td>
                                    <td>Ad Posting</td>
                                    <td>Paid</td>
                                    <td>Local Only</td>
                                    <td>Poland</td>
                                    <td>
                                        <div className="dk-mlAction">
                                            <Route>
                                                <ViewModal />
                                                <EditModal />
                                                <DeleteModal />
                                            </Route>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>13</td>
                                    <td>
                                        <div className="dk-userImg">
                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="img-fluid" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-mlDropdownStatus">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    Active
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#" className="dk-active">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#"  className="dk-inactive">Inactive</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>Bakeago</td>
                                    <td>Job Board</td>
                                    <td>Ad Posting</td>
                                    <td>Paid</td>
                                    <td>Local Only</td>
                                    <td>Poland</td>
                                    <td>
                                        <div className="dk-mlAction">
                                            <Route>
                                                <ViewModal />
                                                <EditModal />
                                                <DeleteModal />
                                            </Route>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default MlResource;
