import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LineLoader from "../../../utils/skeletonLoad/singleLine";
import { smsCredits } from "../../../../slice/sms/smsSlice"

const SmsCredits = () => {
    const dispatch = useDispatch();
    const { credits, isLoading } = useSelector(state => state.sms)

    useEffect(() => {
        async function fetchTemplates() {
            try {
                await dispatch(smsCredits({}))
            } catch (err) {
                console.log(err)
            }
        }
        fetchTemplates()
    }, [dispatch])

    return (
        <React.Fragment>
            <div className="dk-smsRightPanel">
                <div className="title">Account (EX0215699)</div>
                <div className="dk-smsRightPanel-body">
                    {
                        (isLoading) ? <LineLoader /> : <React.Fragment><b>{credits}</b> message credits available</React.Fragment>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
export default SmsCredits;