import React, { useEffect } from "react";
import Header from "../elements/header";
import ProgressBar from "../utils/loader/linear";
import { useSelector } from "react-redux";
import TrainingmenuNavbar from "../elements/header/navbar/mainMenu/training/TrainingmenuNavbar";

const Layout = ({ children }) => {

    return (
        <React.Fragment>
            <Header />
            {/* <RecruitmentNavbar /> */}
            <TrainingmenuNavbar />
            {children}
        </React.Fragment>
    )
}
export default Layout;