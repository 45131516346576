import React from "react";
import { Route, Link } from "react-router-dom";
import Submenu from "../../subMenu/recruitment";
import { useSelector } from "react-redux";
import './style.scss';

const RecruitmentNavbar = () => {
    const { allBadge } = useSelector(state => state.tasks);
    const pathname = window.location.pathname;
    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-recruitmentNavbar">
                <Route>
                    <ul>
                        <li><Link to="/recruitment/dashboard" className={`${pathname.match('/recruitment/dashboard') ? 'active' : ''}`}>Dashboard</Link></li>
                        {/* <li>
                            <Link to="/recruitment/tasks/all-task" className={`${pathname.match('/tasks') ? 'active' : ''}`}>
                                {
                                    (allBadge !== 0) ? <span className="dk-badge">{allBadge}</span> : null
                                }
                                Task
                            </Link>
                        </li> */}
                        <li><Link to="/recruitment/companies/summary" className={`${pathname.match('/companies') ? 'active' : ''}`}>Companies</Link></li>
                        <li><Link to="#">Jobs</Link></li>
                        <li><Link to="#">Job Specs</Link></li>
                        <li><Link to="#">Int Specs</Link></li>
                        <li><Link to="#">Candidates</Link></li>
                        <li><Link to="#">KPI's</Link></li>
                        <li><Link to="/recruitment/resources/summary" className={`${pathname.match('/resources') ? 'active' : ''}`}>Resources</Link></li>
                        <li><Link to="/recruitment/projections/salaries" className={`${pathname.match('/projections') ? 'active' : ''}`}>Projections</Link></li>
                        <li><Link to="/recruitment/database/summary" className={`${pathname.match('/database') ? 'active' : ''}`}>Database</Link></li>
                        <li><Link to="/recruitment/settings" className={`${pathname.match('/settings') ? 'active' : ''}`}>Settings</Link>
                        </li>
                    </ul>
                </Route>
            </div>
            <Submenu path={pathname} />
        </React.Fragment>
    );
}

export default RecruitmentNavbar;
