import React, { Suspense, useMemo, useState, useEffect } from 'react';
import TableLoader from "../../../utils/tableLoader";
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import { GlobalFilter } from "../filters/GlobalFilter";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from '../../../ui/pagination';
import { makeStyles } from '@material-ui/core/styles';
import AddTemplatesModal from "./modals/AddTemplatesModal";
import { IMG_CLOUD_BASE_URL } from "../../../../constants";
import DeleteModal from "../../../ui/delete";
import { showSuccess, showError, clearMessage } from "../../../../slice/utils/message/messageSlice";
import { fethTemplateById, templeteDelete, clearState, templeteList } from "../../../../slice/sms/templateSlice";
import EditTeamModal from "./modals/EditTemplateModal";
import $ from "jquery";
import { loadingEnd, loadingStart } from "../../../../slice/utils/loader/linearSlice";
import Tooltip from "../../../ui/tooltip";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(0),
        },
    },
}));

export const BasicTable = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isShowEdit, setIsShowEdit] = useState(false)
    const [rowId, setRowId] = useState(null)
    const [showDelete, setShowDelete] = useState(false)
    const { isDelete, isError, errorMessage } = useSelector(state => state.smsTemplate)
    const { templates } = useSelector(state => state.smsTemplate)
    const { _id } = useSelector(state => state.auth.userInfo.companyId)
    const companyId = _id;

    const editPopupShow = async (templateId) => {
        setRowId(templateId)
        await dispatch(fethTemplateById({ templateId }))
        setIsShowEdit(true)
    }

    const columns = useMemo(() => [
        {
            Header: 'SNo.',
            title: 'SNo.',
            Cell: (row, { cell }) => (
                <React.Fragment>
                    <div className="dk-smsTableCheckbox">
                        <input type="checkbox" className="chkCandidate" name="chkCandidate[]" value={row.row.original._id} onChange={(e) => toggleCheckbox(e, row.row.original._id)} />
                        {Number(row.row.id) + 1}
                    </div>
                </React.Fragment >
            )
        },
        {
            id: 'userId',
            Header: 'Created By',
            accessor: 'userId.firstName',
            Cell: ({ cell }) => (
                <Tooltip type="img" title={cell.row.original.userId.firstName + " " + cell.row.original.userId.lastName} value={IMG_CLOUD_BASE_URL + cell.row.original.userId.userImg} className="person-imageFlex" />
            )
        },
        {
            id: 'name',
            Header: 'Name',
            accessor: 'name',
            Cell: ({ cell }) => (
                <div className="text-left">
                    {cell.row.values.name}
                </div>
            )
        },
        {
            id: 'body',
            Header: 'Message',
            accessor: 'body',
            Cell: ({ cell }) => (
                <div className="text-left text-pre">
                    {cell.row.values.body}
                </div>
            )
        },
        {
            Header: 'Action',
            accessor: '_id',
            Cell: ({ cell }) => (
                <div className="dk-smsTemplatesBtn">
                    <button className="dk-editBtn" onClick={() => editPopupShow(cell.row.values._id)}><i class="far fa-edit"></i></button>
                    <button className="dk-deleteBtn" onClick={() => deletePop(cell.row.values._id)}><i class="far fa-trash-alt"></i></button>
                </div>
            )
        }
    ], [])
    const data = templates

    const handlePageChange = (event, value) => {
        gotoPage(Number(value) - 1);
    };

    const tableInstance = useTable({
        columns: columns,
        data: data,
        initialState: { pageIndex: 0, pageSize: 100 },
    }, useGlobalFilter, useFilters, useSortBy, usePagination)

    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, state, setGlobalFilter, setFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize } } = tableInstance
    const { globalFilter } = state

    const deletePop = (id) => {
        setRowId(id)
        setShowDelete(true)
    }

    const handleClose = () => {
        setIsShowEdit(false)
        setShowDelete(false)
        setRowId('0')
    }

    // const deleteItem = async () => {
    //     await dispatch(templeteDelete({ id: rowId }))
    // }

    const deleteItem = async () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount == 0) {
            dispatch(templeteDelete({ rowId }))
        } else {
            var canIds = [];
            $.each($(".chkCandidate:checked"), function () {
                canIds.push($(this).val());
            });
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            fetch('/api/sms/template/delete', {
                method: 'POST',
                body: JSON.stringify({ 'ids': canIds }),
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token,
                }
            }).then(response => {
                if (response.status === 200) {
                    dispatch(showSuccess({ msg: 'Delete successfully' }))
                    setShowDelete(false)
                    setIsActive(false)
                    dispatch(templeteList({ companyId: companyId }))
                    dispatch(loadingEnd())
                }
            })
        }
    }

    useEffect(() => {
        dispatch(clearMessage())
    }, [])

    useEffect(() => {
        if (isDelete) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: 'Template deleted successfully.' }))
            setShowDelete(false)
            setRowId('0')
        }
        if (isError) {
            dispatch(showError({ msg: errorMessage }))
            dispatch(clearState())
        }
    }, [isDelete, isError])

    /** Extra Div */
    const [isActive, setIsActive] = useState(false);
    const [countCheck, setCountCheck] = useState(0)
    const [checkBtnTxt, setCheckBtnTxt] = useState('Check All')

    const handleToggle = () => {
        $('.chkCandidate').prop('checked', false);
        setIsActive(false);
    };

    const toggleCheckAll = () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            setCheckBtnTxt('Uncheck All')
            setCountCheck(totalCheckboxes)
            $('.chkCandidate').prop('checked', true);
            // setCheckedBoxes(checkedBoxes.filter((v, i, a) => a.indexOf(v) === i))
        }
        if (checkedBoxCount == totalCheckboxes) {
            setCheckBtnTxt('Check All')
            setCountCheck(0)
            $('.chkCandidate').prop('checked', false);
            setIsActive(false)
        }
    }
    /** Delete Selected Checkbox  */
    const deleteSelected = () => {
        setShowDelete(true)
    }
    /* End Delete Selected Checkbox*/

    /** Single Checkbox Select */
    const toggleCheckbox = (e, id) => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount > 0) {
            setCountCheck(checkedBoxCount)
            setIsActive(true)
        } else {
            setCountCheck(0)
            setIsActive(false)
        }
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            setCheckBtnTxt('Check All')
        }
        if (checkedBoxCount == totalCheckboxes) {
            setCheckBtnTxt('Uncheck All')
        }
    }
    /** End Single Checkbox Select */

    /** End Extra Div */

    return (
        <React.Fragment>
            {
                (isShowEdit) ? <EditTeamModal id={rowId} handleClose={handleClose} /> : null
            }

            {
                (showDelete) ? <DeleteModal id={rowId} handleClose={handleClose} deleteItem={deleteItem} /> : null
            }
            <div className="dk-smsTableHead">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="dk-smsTableHead-right">
                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                        <button className="dk-circleBtn"><i class="fas fa-sync-alt"></i></button>
                    </div>
                    <div className="dk-smsTableHead-right">
                        <AddTemplatesModal />
                    </div>
                </div>
            </div>
            <div className="table-reposive">
                <table className="table table-bordered" {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    {
                        <Suspense fallback={<TableLoader />}>
                            <tbody {...getTableBodyProps()}>
                                {page.map((row, i) => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Suspense>
                    }
                </table>
                <div className="dk-smsTable-pagination">
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="dk-showData">Showing {' '}{pageIndex + 1} to {pageOptions.length} (of {tableInstance.rows.length} entries)</span>
                        <div className={classes.root}>
                            <TablePagination count={pageCount} onChange={handlePageChange} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`dk-smsMessage-selectMsg ${isActive ? "activePopup" : ""}`}>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="dk-selectNo">{countCheck}</div>
                    <div className="dk-rowSelected">
                        <span>Row Selected</span>
                    </div>
                    <div className="dk-checkAll" onClick={toggleCheckAll}>
                        <span><i class="fas fa-check"></i></span>
                        {checkBtnTxt}
                    </div>
                    <div className="dk-checkAll" onClick={deleteSelected}>
                        <span><i class="far fa-trash-alt"></i></span>
                        Delete
                    </div>
                    <div className="dk-checkAll d-flex align-items-center justify-content-center" onClick={handleToggle}>
                        <span><i class="fas fa-times"></i></span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}