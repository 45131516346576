import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import './summary.scss';
import StatusDropdown from './StatusDropdown';


const Summary = () => {
    return (
        <React.Fragment>
            <div className="dk-summaryMain">
                <div className="dk-canSummPageHeader d-flex justify-content-between">
                    <div className="dk-cansUMMLeft d-flex">
                        <div className="dk-userCols">
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <i className="fa fa-user" aria-hidden="true"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <div className="d-flex">
                                        <Dropdown.Item href="#/action-1">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="img-fluid img-circle" alt="" />
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad49d2ca540d.png" className="img-fluid img-circle" alt="" />
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5b4dce328e872.png" className="img-fluid img-circle" alt="" />
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ca355dac8269.png" className="img-fluid img-circle" alt="" />
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5e54fdac9c.png" className="img-fluid img-circle" alt="" />
                                        </Dropdown.Item>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="dk-userCols dk-flagCols">
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <i className="fas fa-flag"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <div className="d-flex">
                                        <Dropdown.Item href="#/action-1">
                                            <i className="far fa-flag"></i>
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">
                                            <i className="fas fa-flag text-red"></i>
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">
                                            <i className="fas fa-flag text-green"></i>
                                        </Dropdown.Item>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="dk-userCols dk-flagCols">
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <i className="fas fa-tv"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <div className="d-flex">
                                        <Dropdown.Item href="#/action-1">
                                            IT
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">
                                            ML
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">
                                            None
                                        </Dropdown.Item>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <h3 className="title">DATABASE SUMMARY</h3>
                    <div className="dk-rightCols">
                        <StatusDropdown />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="d-flex">
                            <div className="dk-summaryCols">
                                <div className="dk-toptitle">
                                    <div className="dk-filterIcon">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                <i className="fas fa-search"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1">ML <span>33330</span></Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">IT <span>134</span></Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">None <span>448</span></Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="dk-colstitle"></div>
                                    <span className="dk-totalCvCount">
                                        33912
                                    </span>
                                </div>
                                <li>
                                    <div><i className="fas mr-2 fa-language" aria-hidden="true"></i>ML</div>
                                    <span className="mlCount">33330</span>
                                </li>
                                <li>
                                    <div><i className="fas mr-2 fa-tv" aria-hidden="true"></i>IT</div>
                                    <span className="mlCount">33330</span>
                                </li>
                                <li>
                                    <div><i className="far mr-2 fa-circle" aria-hidden="true"></i>None</div>
                                    <span className="mlCount">448</span>
                                </li>
                            </div>
                            <div className="dk-summaryCols">
                                <div className="dk-toptitle">
                                    <div className="dk-filterIcon">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                <i className="fas fa-search"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1">ML <span>33330</span></Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">IT <span>134</span></Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">None <span>448</span></Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="dk-colstitle"></div>
                                    <span className="dk-totalCvCount">
                                        33912
                                    </span>
                                </div>
                                <li>
                                    <div><i className="far mr-2 fa-flag"></i>Blank</div>
                                    <span className="mlCount">33330</span>
                                </li>
                                <li>
                                    <div><i className="fas mr-2 fa-flag text-red"></i>Red</div>
                                    <span className="mlCount">485</span>
                                </li>
                                <li>
                                    <div><i className="fas fa-flag mr-2 text-green"></i>Green</div>
                                    <span className="mlCount">448</span>
                                </li>
                            </div>
                            <div className="dk-summaryCols">
                                <div className="dk-toptitle">
                                    <div className="dk-filterIcon">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                <i className="fas fa-search"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1">ML <span>33330</span></Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">IT <span>134</span></Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">None <span>448</span></Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="dk-colstitle"></div>
                                    <span className="dk-totalCvCount">
                                        33912
                                    </span>
                                </div>
                                <li>
                                    <div><i className="fas mr-2 fa-male"></i>Male</div>
                                    <span className="mlCount">2057</span>
                                </li>
                                <li>
                                    <div><i className="fas mr-2 fa-female"></i>Female</div>
                                    <span className="mlCount">1709</span>
                                </li>
                                <li>
                                    <div><i className="fas mr-2 fa-transgender-alt"></i>Not Confirmed</div>
                                    <span className="mlCount">30150</span>
                                </li>
                            </div>
                            <div className="dk-summaryCols">
                                <div className="dk-toptitle">
                                    <div className="dk-filterIcon">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                <i className="fas fa-search"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1">ML <span>33330</span></Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">IT <span>134</span></Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">None <span>448</span></Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="dk-colstitle"></div>
                                    <span className="dk-totalCvCount">
                                        33912
                                    </span>
                                </div>
                                <li>
                                    <div><i className="far mr-2 fa-file-alt" aria-hidden="true"></i>Job Specs</div>
                                    <span className="mlCount">10</span>
                                </li>
                                <li>
                                    <div><i className="far mr-2 fa-file-alt" aria-hidden="true"></i>Job Sendouts</div>
                                    <span className="mlCount">12</span>
                                </li>
                                <li>
                                    <div><i className="far mr-2 fa-file-alt" aria-hidden="true"></i>Placements</div>
                                    <span className="mlCount">11</span>
                                </li>
                            </div>

                            <div className="dk-summaryCols">
                                <div className="dk-toptitle">
                                    <div className="dk-filterIcon">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                <i className="fas fa-search"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1">ML <span>33330</span></Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">IT <span>134</span></Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">None <span>448</span></Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="dk-colstitle"></div>
                                    <span className="dk-totalCvCount">
                                        33912
                                    </span>
                                </div>
                                <li>
                                    <div><i className="fas mr-2 fa-user" aria-hidden="true"></i>EU</div>
                                    <span className="mlCount">33330</span>
                                </li>
                                <li>
                                    <div><i className="fas mr-2 fa-user" aria-hidden="true"></i>Non-EU</div>
                                    <span className="mlCount">33330</span>
                                </li>
                                <li>
                                    <div><i className="far mr-2 fa-window-close" aria-hidden="true"></i>Not Confirmed</div>
                                    <span className="mlCount">448</span>
                                </li>
                            </div>


                        </div>
                    </div>


                    <div className="col-md-12">
                        <div className="d-flex">

                            <div className="dk-summaryCols xl">
                                <div className="dk-toptitle">
                                    <div className="dk-filterIcon">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                <i className="fas fa-search"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1">ML <span>33330</span></Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">IT <span>134</span></Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">None <span>448</span></Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="dk-colstitle">RECRUITERS</div>
                                    <span className="dk-totalCvCount">
                                        33912
                                    </span>
                                </div>
                                <div className="dk-summaryList">
                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="summary-rec-img" /> Mariana Cont</div>
                                        <span>8542</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad49d2ca540d.png" className="summary-rec-img" /> Maria Ciocas</div>
                                        <span>4513</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/5b4dce328e872.png" className="summary-rec-img" /> Elena lanole</div>
                                        <span>4349</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/5ca355dac8269.png" className="summary-rec-img" /> Adelina Coada</div>
                                        <span>3566</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5e54fdac9c.png" className="summary-rec-img" /> Filip Gichev</div>
                                        <span>3014</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/1564477864-IMG_20190730_120417.jpg" className="summary-rec-img" /> Zeynep Karpuz</div>
                                        <span>1891</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/5cc993057d6b0.png" className="summary-rec-img" /> Angel Lupu</div>
                                        <span>1350</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/5bb1f15c48665.png" className="summary-rec-img" /> Gabriela Ailenii</div>
                                        <span>1113</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/5bb1ef74b41b8.png" className="summary-rec-img" /> Cristina Branciaroli</div>
                                        <span>1022</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/5c7d086a427a5.png" className="summary-rec-img" /> Laura Dima</div>
                                        <span>949</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" className="summary-rec-img" /> Onur Metin</div>
                                        <span>592</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="summary-rec-img" /> Deepak Kumar</div>
                                        <span>525</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5bd2c15416.png" className="summary-rec-img" /> Bianca Gherasim</div>
                                        <span>457</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/5bb20b20dfaa6.png" className="summary-rec-img" /> Diana Bordeanu</div>
                                        <span>381</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/default_profile_empty.png" className="summary-rec-img" /> Camelia</div>
                                        <span>360</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/default_profile_empty.png" className="summary-rec-img" /></div>
                                        <span>347</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/5ca334624706b.png" className="summary-rec-img" /> Elena Luchis</div>
                                        <span>290</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/1523633534-luiza.jpg" className="summary-rec-img" /> Luiza Nechifor</div>
                                        <span>165</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/1623316236.jpg" className="summary-rec-img" /> Pooja Mangla</div>
                                        <span>151</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad0b61b38fa3.png" className="summary-rec-img" /> Mariyan Zhelev</div>
                                        <span>94</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/1570005216-20190919_143448.jpg" className="summary-rec-img" /> Gabriela</div>
                                        <span>91</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/olga1623316236.jpg" className="summary-rec-img" /> Olga Cristov</div>
                                        <span>51</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/1609918515.png" className="summary-rec-img" /> Madalina Pop</div>
                                        <span>43</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/1537182852-DK Blue.png" className="summary-rec-img" /> DKG</div>
                                        <span>36</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c3ad279ec.png" className="summary-rec-img" /> Miruna Alexandra</div>
                                        <span>20</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/default_profile_empty.png" className="summary-rec-img" /> Daniela</div>
                                        <span>2</span>
                                    </li>

                                    <li>
                                        <div><img src="https://www.dkmasterbox.com/assets/upload/user_image/5a701dc2182ac.png" className="summary-rec-img" /> Mukul Jauhari</div>
                                        <span>2</span>
                                    </li>
                                </div>

                            </div>
                            <div className="dk-summaryCols xl dk-listing">
                                <div className="dk-toptitle">
                                    <div className="dk-filterIcon">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                <i className="fas fa-search"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1">ML <span>33330</span></Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">IT <span>134</span></Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">None <span>448</span></Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="dk-colstitle">COMPANIES</div>
                                    <span className="dk-totalCvCount">
                                        33912
                                    </span>
                                </div>
                                <div className="dk-summaryList">
                                    <li>TP Greece<span>17997</span></li>

                                    <li><span>5247</span></li>

                                    <li>Webhelp Lisbon<span>3117</span></li>

                                    <li>Webhelp Athens<span>1656</span></li>

                                    <li>Pragmatic Play<span>1467</span></li>

                                    <li>Wipro Bucharest<span>699</span></li>

                                    <li>Wipro Porto<span>641</span></li>

                                    <li>Genpact Lisbon<span>594</span></li>

                                    <li>TP Thessaloniki<span>425</span></li>

                                    <li>Wipro Timisoara<span>352</span></li>

                                    <li>Genpact Bucharest<span>295</span></li>

                                    <li>Tech Mahindra Lisbon<span>230</span></li>

                                    <li>Genpact Krakow<span>170</span></li>

                                    <li>Wipro Dublin<span>157</span></li>

                                    <li>GEP Cluj<span>151</span></li>

                                    <li>Sitel<span>52</span></li>

                                    <li>Tech M Timisoara<span>52</span></li>

                                    <li>Genpact Prague<span>50</span></li>

                                    <li>Ttech Athens<span>43</span></li>

                                    <li>Webhelp Oeiras<span>42</span></li>

                                    <li>SuperPlay<span>38</span></li>

                                    <li>Wipro UK<span>37</span></li>

                                    <li>Genpact Dublin<span>37</span></li>

                                    <li>GEP Prague <span>36</span></li>

                                    <li>SBTech <span>35</span></li>

                                    <li>Carousel<span>33</span></li>

                                    <li>Genpact Cluj<span>33</span></li>

                                    <li>June Homes<span>30</span></li>

                                    <li>HCL Bucharest<span>22</span></li>

                                    <li>HCL Iasi<span>20</span></li>

                                    <li>WNS<span>19</span></li>

                                    <li>Mphasis Germany<span>19</span></li>

                                    <li>National Pen Dundalk<span>19</span></li>

                                    <li>Tech Mahindra Italy<span>13</span></li>

                                    <li>HCL Krakow<span>12</span></li>

                                    <li>Salescode<span>12</span></li>

                                    <li>HCL Lithuania<span>12</span></li>

                                    <li>Genpact Katowice <span>9</span></li>

                                    <li>Tech M Spain<span>8</span></li>

                                    <li>Carrolls Dublin<span>8</span></li>

                                    <li>HCL Sofia<span>8</span></li>

                                    <li>HCL Netherlands<span>7</span></li>

                                    <li>HCL Ireland<span>7</span></li>

                                    <li>HCL France<span>7</span></li>
                                </div>

                            </div>
                            <div className="dk-summaryCols xl dk-listing">
                                <div className="dk-toptitle">
                                    <div className="dk-filterIcon">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                <i className="fas fa-search"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1">ML <span>33330</span></Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">IT <span>134</span></Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">None <span>448</span></Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="dk-colstitle">CV SOURCES</div>
                                    <span className="dk-totalCvCount">
                                        33912
                                    </span>
                                </div>
                                <div className="dk-summaryList">
                                    <li>Indeed<span>11074</span></li>

                                    <li>ELJ<span>9284</span></li>

                                    <li>MLV<span>2576</span></li>

                                    <li>TL<span>2352</span></li>

                                    <li>Ejobs<span>1208</span></li>

                                    <li>DK Website<span>1090</span></li>

                                    <li>Facebook<span>935</span></li>

                                    <li><span>781</span></li>

                                    <li>Direct Email<span>760</span></li>

                                    <li>Hipo<span>693</span></li>

                                    <li>CV Search - TL<span>332</span></li>

                                    <li>Bestjobs<span>329</span></li>

                                    <li>Jobfind<span>317</span></li>

                                    <li>Bakeca.it<span>296</span></li>

                                    <li>Jooble<span>223</span></li>

                                    <li>LinkedIn<span>188</span></li>

                                    <li>Vivivallestero<span>148</span></li>

                                    <li>Not Confirmed<span>132</span></li>

                                    <li>Referral from another user<span>125</span></li>

                                    <li>CV Search - Hipo<span>116</span></li>

                                    <li>Strike jobs<span>108</span></li>

                                    <li>PROFESIa.SK<span>102</span></li>

                                    <li>CV Search - MLV<span>97</span></li>

                                    <li>Softest (Partner)<span>88</span></li>

                                    <li>Learn4Good<span>84</span></li>

                                    <li>Eurojobs<span>80</span></li>

                                    <li>CV Search - Ejobs<span>73</span></li>

                                    <li>Jobs.IE<span>41</span></li>

                                    <li>Careers4a<span>36</span></li>

                                    <li>CV Search Indeed<span>32</span></li>

                                    <li>Scambieuropei<span>24</span></li>

                                    <li>Referral (Candidate)<span>18</span></li>

                                    <li>Email<span>16</span></li>

                                    <li>Loadjobs<span>15</span></li>

                                    <li>CV Library UK<span>13</span></li>

                                    <li>DKG Website<span>11</span></li>

                                    <li>Linkedin search<span>9</span></li>

                                    <li>Eurocultura<span>9</span></li>

                                    <li>Accalia (Partner)<span>8</span></li>

                                    <li>Workwide.de<span>8</span></li>

                                    <li>CV Search - CV Library<span>6</span></li>

                                    <li>Eures Slovak<span>6</span></li>

                                    <li>Jobsite<span>6</span></li>

                                    <li>Pracuj.pl<span>6</span></li>

                                    <li>Bakeago<span>5</span></li>

                                    <li>Kijiji<span>5</span></li>

                                    <li>3MinutesJob<span>5</span></li>

                                    <li>Kariera<span>5</span></li>

                                    <li>Agency-Urban Recruits<span>4</span></li>

                                    <li>Europe Language Jobs<span>4</span></li>

                                    <li>EURES ITALY<span>4</span></li>

                                    <li>Jobatus<span>4</span></li>

                                    <li>Net Emprego<span>4</span></li>

                                    <li>HRS CV sourcing<span>4</span></li>

                                    <li>JobTiger<span>3</span></li>

                                    <li>arbejdeiudlandet.dk<span>3</span></li>

                                    <li>CV Search - Strike jobs<span>3</span></li>

                                    <li>Jobinireland<span>2</span></li>

                                    <li>TipTopJobs<span>1</span></li>

                                    <li>FW: Candidate subscription to your job offer: Quality Analyst with Polish or Russian<span>1</span></li>

                                    <li>Verslitas Agency<span>1</span></li>

                                    <li>jobsdonedeal<span>1</span></li>

                                    <li>FW: Candidate subscription to your job offer: Content Reviewer English in Poland<span>1</span></li>

                                    <li>CV- Library<span>1</span></li>
                                </div>

                            </div>
                            <div className="dk-summaryCols xl dk-listing">
                                <div className="dk-toptitle">
                                    <div className="dk-filterIcon">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                <i className="fas fa-search"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1">ML <span>33330</span></Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">IT <span>134</span></Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">None <span>448</span></Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="dk-colstitle">NATIONALITY</div>
                                    <span className="dk-totalCvCount">
                                        33912
                                    </span>
                                </div>
                                <div className="dk-summaryList">
                                    <li>Italian<span>7655</span></li>

                                    <li>Romanian&nbsp;<span>2720</span></li>

                                    <li>Not Confirmed<span>2585</span></li>

                                    <li>Greek<span>2533</span></li>

                                    <li>Polish<span>2125</span></li>

                                    <li>French&nbsp;<span>1857</span></li>

                                    <li>Portuguese<span>1683</span></li>

                                    <li>British<span>1283</span></li>

                                    <li>Spanish<span>1153</span></li>

                                    <li>Hungarian<span>1038</span></li>

                                    <li>German<span>757</span></li>

                                    <li><span>693</span></li>

                                    <li>Slovakian<span>534</span></li>

                                    <li>Dutch&nbsp;<span>533</span></li>

                                    <li>Czech<span>490</span></li>

                                    <li>Romanian<span>462</span></li>

                                    <li>Swedish<span>442</span></li>

                                    <li>English<span>386</span></li>

                                    <li>Turkish<span>283</span></li>

                                    <li>Lithuanian<span>276</span></li>

                                    <li>French<span>269</span></li>

                                    <li>Latvian&nbsp;<span>252</span></li>

                                    <li>Czech&nbsp;<span>251</span></li>

                                    <li>Finnish&nbsp;<span>233</span></li>

                                    <li>Bulgarian<span>230</span></li>

                                    <li>Croatian<span>208</span></li>

                                    <li>Indian<span>206</span></li>

                                    <li>Israeli<span>192</span></li>

                                    <li>Danish&nbsp;<span>175</span></li>

                                    <li>Norwegian&nbsp;<span>173</span></li>

                                    <li>Belgian&nbsp;<span>152</span></li>

                                    <li>Russian<span>114</span></li>

                                    <li>Brazilian<span>114</span></li>

                                    <li>Irish<span>112</span></li>

                                    <li>Dutch<span>111</span></li>

                                    <li>Serbian<span>100</span></li>

                                    <li>Slovenian&nbsp;<span>87</span></li>

                                    <li>South African<span>77</span></li>

                                    <li>Tunisian<span>71</span></li>

                                    <li>Albanian<span>66</span></li>

                                    <li>Ukrainian<span>62</span></li>

                                    <li>Estonian<span>55</span></li>

                                    <li>Austrian<span>47</span></li>

                                    <li>Japanese<span>43</span></li>

                                    <li>finnish<span>42</span></li>

                                    <li>Filipino<span>42</span></li>

                                    <li>Moroccan<span>41</span></li>

                                    <li>Irish&nbsp;<span>41</span></li>

                                    <li>Swiss - German<span>39</span></li>

                                    <li>Danish<span>38</span></li>

                                    <li>Belgian<span>37</span></li>

                                    <li>Egyptian<span>34</span></li>

                                    <li>Italiana<span>28</span></li>

                                    <li>Slovenian<span>27</span></li>

                                    <li>Indonesian<span>26</span></li>

                                    <li>slovak<span>25</span></li>

                                    <li>Bosnian<span>22</span></li>

                                    <li>Macedonian<span>20</span></li>

                                    <li>Cypriot<span>18</span></li>

                                    <li>Italy<span>17</span></li>

                                    <li>Azerbaijani<span>17</span></li>

                                    <li>UNKNOWN<span>16</span></li>

                                    <li>Canadian<span>15</span></li>

                                    <li>Norwegian<span>13</span></li>

                                    <li>Poland<span>12</span></li>

                                    <li>Netherlands<span>11</span></li>

                                    <li>US<span>10</span></li>

                                    <li>Lebanon<span>9</span></li>

                                    <li>Romania<span>9</span></li>

                                    <li>Latvian<span>9</span></li>

                                    <li>Scottish&nbsp;<span>8</span></li>

                                    <li>Maltese<span>7</span></li>

                                    <li>Portugal<span>7</span></li>

                                    <li>Nigerian<span>5</span></li>

                                    <li>Egyptian&nbsp;<span>5</span></li>

                                    <li>USA<span>4</span></li>

                                    <li>Mexican<span>4</span></li>

                                    <li>Pakistani<span>4</span></li>

                                    <li>Zimbabwein<span>4</span></li>

                                    <li>Czech, British<span>4</span></li>

                                    <li>Ethiopian<span>3</span></li>

                                    <li>deutsch<span>3</span></li>

                                    <li>Bulgaria<span>3</span></li>

                                    <li>française<span>3</span></li>

                                    <li>UK<span>3</span></li>

                                    <li>Spain<span>3</span></li>

                                    <li>Macedonian&nbsp;<span>3</span></li>

                                    <li>Cameroonian<span>3</span></li>

                                    <li>nigerian, italian<span>2</span></li>

                                    <li>Hungarian, Russian<span>2</span></li>

                                    <li>Portuguesa<span>2</span></li>

                                    <li>Ireland<span>2</span></li>

                                    <li>Italian, German<span>2</span></li>

                                    <li>Vietnamese<span>2</span></li>

                                    <li>Greek, Australian<span>2</span></li>

                                    <li>Nederlandse<span>2</span></li>

                                    <li>Moldova<span>2</span></li>

                                    <li>Greek, Lebanese<span>2</span></li>

                                    <li>Croat<span>2</span></li>

                                    <li>Brazilian, Italian<span>2</span></li>

                                    <li>griechisch<span>2</span></li>

                                    <li>Greece<span>2</span></li>

                                    <li>Swiss<span>2</span></li>

                                    <li>Italian, Portuguese<span>2</span></li>

                                    <li>Argentina<span>1</span></li>

                                    <li>Greek, Canadian<span>1</span></li>

                                    <li>Philippine<span>1</span></li>

                                    <li>Belgium<span>1</span></li>

                                    <li>Polish, Argentinean<span>1</span></li>

                                    <li>Polish, Russian<span>1</span></li>

                                    <li>Chinese<span>1</span></li>

                                    <li>Mexican, Greek<span>1</span></li>

                                    <li>Kenya<span>1</span></li>

                                    <li>Chilean, German<span>1</span></li>

                                    <li>British, Czech<span>1</span></li>

                                    <li>Polish, American<span>1</span></li>

                                    <li>Portuguese, Venezuelan<span>1</span></li>

                                    <li>German, Algerian<span>1</span></li>

                                    <li>Kazakistan<span>1</span></li>

                                    <li>Italian, Tunisian<span>1</span></li>

                                    <li>Austrian, Argentinian<span>1</span></li>

                                    <li>Israeli, Spanish<span>1</span></li>

                                    <li>Israeli, Polish<span>1</span></li>

                                    <li>Belgian, Lebanese<span>1</span></li>

                                    <li>Slovakia<span>1</span></li>

                                    <li>Canadian, Greek<span>1</span></li>

                                    <li>Argentinian, Italian<span>1</span></li>

                                    <li>Rwanda<span>1</span></li>

                                    <li>Syrian<span>1</span></li>

                                    <li>Emirates<span>1</span></li>

                                    <li>Italian, Swedish<span>1</span></li>

                                    <li>Angolan<span>1</span></li>

                                    <li>Greek, Libyan<span>1</span></li>

                                    <li>Egypt<span>1</span></li>

                                    <li>Irish, Russian<span>1</span></li>

                                    <li>Finnish, Brazilian<span>1</span></li>

                                    <li>Italian, Hungarian<span>1</span></li>

                                    <li>Austria<span>1</span></li>

                                    <li>German, Venezuelan<span>1</span></li>

                                    <li>Bulgarian, Turkish<span>1</span></li>

                                    <li>Croatia<span>1</span></li>

                                    <li>German, Iranian<span>1</span></li>

                                    <li>Chilean<span>1</span></li>

                                    <li>Greek, American<span>1</span></li>

                                    <li>Algerian<span>1</span></li>

                                    <li>Greek, South African<span>1</span></li>

                                    <li>Spanish, Portuguese<span>1</span></li>

                                    <li>Española<span>1</span></li>

                                    <li>Iranian<span>1</span></li>

                                    <li>Ugandan<span>1</span></li>

                                    <li>British, British<span>1</span></li>

                                    <li>Turkey<span>1</span></li>

                                    <li>Greek, Romanian<span>1</span></li>

                                    <li>Norway<span>1</span></li>

                                    <li>Greek, English<span>1</span></li>

                                    <li>Dutch, Turkish<span>1</span></li>

                                    <li>Romanian, Greek<span>1</span></li>

                                    <li>Greek, Czech<span>1</span></li>

                                    <li>Polish, Norwegian<span>1</span></li>

                                    <li>Tanzania<span>1</span></li>

                                    <li>Bulgarian, Ukrainian<span>1</span></li>

                                    <li>Belarus<span>1</span></li>

                                    <li>Romanian, Italian<span>1</span></li>

                                    <li>USA, Czech<span>1</span></li>

                                    <li>German, South African<span>1</span></li>

                                    <li>Cuban<span>1</span></li>

                                    <li>British, Italian<span>1</span></li>

                                    <li>British, Croatian<span>1</span></li>

                                    <li>American<span>1</span></li>

                                    <li>Scottish<span>1</span></li>

                                    <li>Czech, Greek<span>1</span></li>

                                    <li>Romanian, Norwegian<span>1</span></li>

                                    <li>Colombian<span>1</span></li>

                                    <li>Dutch, Netherlands<span>1</span></li>

                                    <li>Swiss, Brazilian<span>1</span></li>

                                    <li>Nederlands<span>1</span></li>

                                    <li>British, Serbian<span>1</span></li>

                                    <li>Egyptian ?????? :<span>1</span></li>

                                    <li>Hungarian, Serbian<span>1</span></li>

                                    <li>Australia<span>1</span></li>

                                    <li>Hungarian, Romanian<span>1</span></li>
                                </div>

                            </div>

                            <div className="dk-summaryCols xl dk-listing">
                                <div className="dk-toptitle">
                                    <div className="dk-filterIcon">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                <i className="fas fa-search"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1">ML <span>33330</span></Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">IT <span>134</span></Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">None <span>448</span></Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="dk-colstitle">LANGUAGE</div>
                                    <span className="dk-totalCvCount">
                                        33912
                                    </span>
                                </div>
                                <div class="dk-summaryList">
                                    <li>Italian<span>7352</span></li>
                                    <li>English<span>5468</span></li>
                                    <li><span>5251</span></li>
                                    <li>French<span>4062</span></li>
                                    <li>German<span>2900</span></li>
                                    <li>Polish<span>1913</span></li>
                                    <li>Spanish<span>934</span></li>
                                    <li>Hungarian<span>923</span></li>
                                    <li>Dutch<span>789</span></li>
                                    <li>Portuguese<span>767</span></li>
                                    <li>Czech&nbsp;<span>658</span></li>


                                    <li>Swedish&nbsp;<span>417</span></li>


                                    <li>Slovakian<span>365</span></li>


                                    <li>Latvian<span>263</span></li>


                                    <li>Lithuanian<span>223</span></li>


                                    <li>Hebrew<span>209</span></li>


                                    <li>Finnish<span>208</span></li>


                                    <li>Danish<span>193</span></li>


                                    <li>Russian<span>174</span></li>


                                    <li>Norwegian<span>147</span></li>


                                    <li>Croatian<span>115</span></li>


                                    <li>Slovenian<span>81</span></li>


                                    <li>Arabic<span>72</span></li>


                                    <li>Brazilian<span>49</span></li>


                                    <li>Serbian<span>48</span></li>


                                    <li>Romanian<span>48</span></li>


                                    <li>Japanese<span>45</span></li>


                                    <li>Turkish<span>32</span></li>


                                    <li>Bosnian+Croatian<span>25</span></li>


                                    <li>Fyrom<span>25</span></li>


                                    <li>Estonian<span>25</span></li>


                                    <li>Bulgarian<span>18</span></li>


                                    <li>Spanish + Portuguese<span>18</span></li>


                                    <li>Greek<span>13</span></li>


                                    <li>French+ Spanish<span>12</span></li>


                                    <li>Hindi<span>12</span></li>


                                    <li>Bosnian<span>11</span></li>


                                    <li>French+Italian(B1)<span>9</span></li>


                                    <li>Arabic + French<span>8</span></li>


                                    <li>FR/SP/GR<span>8</span></li>


                                    <li>Catalan<span>8</span></li>


                                    <li>French+Italian<span>5</span></li>


                                    <li>Hungarian+ German<span>4</span></li>


                                    <li>Czech/Slovak<span>4</span></li>


                                    <li>Spanish+Italian<span>3</span></li>


                                    <li>African<span>2</span></li>


                                    <li>Flemish<span>1</span></li>


                                    <li>French + German B2 <span>1</span></li>


                                </div>
                            </div>


                        </div>
                    </div>


                </div>

            </div>
        </React.Fragment>
    );
}

export default Summary;
