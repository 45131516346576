import React from 'react';
import { Switch, Route } from "react-router-dom";
import Login from './components/pages/login';
import LoginRoute from "./components/hocs/LoginRoute";
import BlankPrivateRoute from "./components/hocs/BlankPrivateRoute";
import PrivateRoute from "./components/hocs/PrivateRoute";
import AdminRoute from "./components/hocs/AdminRoute";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import "./App.css";

import CheckLogin from "./components/hocs/checkLogin";

// Main Pages
import AllModules from './components/allModules/';
import BlankPage from './components/allModules/pages/BlankPage';
// End Main Pages Pages //

// Recruitement Pages //
import Dashboard from "./components/pages/dashboard";
import DkgFaq from './components/pages/faq/dkgFaq';
import Onboarding from './components/pages/dashboard/Onboarding';
import DkgTeam from "./components/pages/dkgTeam"
import Rules from './components/pages/rules';
import MlResource from './components/pages/resources/mlresource';
import Error404 from './components/pages/error/error404';
import ComposeNew from './components/pages/sms/compose/ComposeNew';
import SmsInbox from './components/pages/sms/inbox/SmsInbox';
import SentSms from './components/pages/sms/sent/SentSms';
import SmsTemplates from './components/pages/sms/template';
import AllTask from './components/pages/tasks/AllTask';
import MyTask from './components/pages/tasks/MyTask';
import OthersTask from './components/pages/tasks/OthersTask';
import GroupTask from './components/pages/tasks/GroupTask';
import PaymentTask from './components/pages/tasks/PaymentTask';
import Mailshots from './components/pages/database/mailshots/index';
import JobProfile from './components/pages/jobProfiles';

import CompanySummary from './components/pages/companies/summary/Summary.js';
import CompanyListView from "./components/pages/companies/listview/ListView.js";
import CompanyDetailview from "./components/pages/companies/detailview/DetailView.js";
import CompanyAddnew from "./components/pages/companies/addnew/AddNew.js";
import CompanyPolicies from "./components/pages/companies/policies/Policies.js";
import Duplicate from './components/pages/recruitment/database/duplicate/Duplicate';
import Pipeline from './components/pages/recruitment/candidate/pipeline/Pipeline';
import Summary from './components/pages/recruitment/database/summary/Summary';
import SearchCv from './components/pages/recruitment/database/search/SearchCv';
import Projects from './components/pages/recruitment/database/projects/Projects';
// End Recruitement Pages //

// Purchase Pages //

// End Purchase Pages //

// Communication Pages //

// End Communication Pages //

// Advertisment Pages //

import AdvertismentDashboard from "./components/pages/advertisment/dashboard";

// End Advertisment Pages //

// InformationCenter Pages //

import InformationCentreDashboard from "./components/pages/informationcentre/dashboard";

// End InformationCenter Pages //

// Support Pages //

// End Support Pages //

// Marketing Pages //

// End Marketing Pages //

// Business-development Pages //

// End Business-development Pages //

// Accounting Pages //

// End Accounting Pages //

// Finance Pages //

// End Finance Pages //

// HR Pages //
import Holidays from './components/pages/hr/holidays';
// End HR Pages //

// Training Pages //

// End Training Pages //

// Resources Pages //
import ResourcesSummary from "./components/pages/resources/summary/Summary.js";
import ResourcesMlResource from "./components/pages/resources/mlresource/MlResource.js";
import ResourcesITResource from "./components/pages/resources/itresource/ITResource.js";
import ResourcesLogins from "./components/pages/resources/logins/Logins.js";
import ResourcesCostbenefit from "./components/pages/resources/costbenefit/CostBenefit.js";
import ResourcesIncometax from "./components/pages/resources/incometax/IncomeTax.js";
// End Resources Pages //

//Projections Pages
import ProjectionsSalaries from "./components/pages/projections/salaries/Salaries.js";
import ProjectionsIncometax from "./components/pages/projections/incometax/IncomeTax";
import ProjectionsCostliving from "./components/pages/projections/costofliving/CostLiving";
import ProjectionsJobprofile from "./components/pages/projections/jobprofiles/JobProfiles.js";
//End Projections Pages

// Report Pages //
import ReportDashboard from "./components/pages/report/index";
// End Report Pages //

// Sales Pages //

// End Sales Pages //

// Tasks Pages //

// End Tasks Pages //

// Projects Pages //

// End Projects Pages //

// My File Pages //

// End My File Pages //


// Admin Pages //

import BackendValues from "./components/pages/admin/tools/bkvalues/BkValues";

// Admin Pages End

// Blank Layout //
import Profile from './components/pages/profile';


// End Blank Layout //

function App() {
  return (
    <React.Fragment>
      <div className="App">
        <Switch>
          <Route path="/check-auth/:slug" component={CheckLogin} />
          <LoginRoute exact path="/" component={Login} />

          {/* Main Pages */}
          <BlankPrivateRoute path="/dashboard" component={AllModules} />
          <BlankPrivateRoute path="/get-started" component={BlankPage} />
          <BlankPrivateRoute path="/what-is" component={BlankPage} />
          <BlankPrivateRoute path="/standard" component={BlankPage} />
          <BlankPrivateRoute path="/customized" component={BlankPage} />
          <BlankPrivateRoute path="/knowledge" component={BlankPage} />
          <BlankPrivateRoute path="/announcements" component={BlankPage} />
          <BlankPrivateRoute path="/dkg-support" component={BlankPage} />
          {/* End Main Pages */}

          {/* Recruitement Pages */}
          <PrivateRoute path="/recruitment/dashboard/onboarding" component={Onboarding} />
          <PrivateRoute path="/recruitment/dashboard/dkgteam" component={DkgTeam} />
          <PrivateRoute path="/recruitment/dashboard/faqs" component={DkgFaq} />
          <PrivateRoute path="/recruitment/dashboard/rules" component={Rules} />
          <PrivateRoute path="/recruitment/dashboard/job-profile" component={JobProfile} />
          <PrivateRoute path="/recruitment/database/duplicate" component={Duplicate} />
          <PrivateRoute path="/recruitment/database/summary" component={Summary} />
          <PrivateRoute path="/recruitment/database/search-cvs" component={SearchCv} />
          <PrivateRoute path="/recruitment/database/projects" component={Projects} />
          <PrivateRoute path="/recruitment/dashboard" component={Dashboard} />

          <PrivateRoute path="/ml-resource" component={MlResource} />

          <PrivateRoute path="/sms/compose" component={ComposeNew} />
          <PrivateRoute path="/sms/inbox" component={SmsInbox} />
          <PrivateRoute path="/sms/sent" component={SentSms} />
          <PrivateRoute path="/sms/templates" component={SmsTemplates} />

          <PrivateRoute path="/recruitment/database/mailshots" component={Mailshots} />

          <PrivateRoute path="/recruitment/companies/summary" component={CompanySummary} />
          <PrivateRoute path="/recruitment/companies/list-view" component={CompanyListView} />
          <PrivateRoute path="/recruitment/companies/detail-view" component={CompanyDetailview} />
          <PrivateRoute path="/recruitment/companies/add-new" component={CompanyAddnew} />
          <PrivateRoute path="/recruitment/companies/policies" component={CompanyPolicies} />
          <PrivateRoute path="/recruitment/candidate/pipeline" component={Pipeline} />
          {/* End Recruitement Pages */}

          {/* Purchase Pages */}
          <PrivateRoute path="/purchase/dashboard" component={Dashboard} />
          {/* End Purchase Pages */}

          {/* Communication Pages */}
          <PrivateRoute path="/communication/dashboard" component={Dashboard} />
          {/* End Communication Pages */}

          {/* Support Pages */}
          <PrivateRoute path="/support/dashboard" component={Dashboard} />
          {/* End Support Pages */}

          {/* Marketing Pages */}
          <PrivateRoute path="/marketing/dashboard" component={Dashboard} />
          {/* End Marketing Pages */}

          {/* Business Development Pages */}
          <PrivateRoute path="/business-development/dashboard" component={Dashboard} />
          {/* End Business Development Pages */}

          {/* Accounting Pages */}
          <PrivateRoute path="/accounting/dashboard" component={Dashboard} />
          <PrivateRoute path="/accounting/clients" component={Dashboard} />
          <PrivateRoute path="/accounting/suppliers" component={Dashboard} />
          <PrivateRoute path="/accounting/invoices" component={Dashboard} />
          <PrivateRoute path="/accounting/income" component={Dashboard} />
          <PrivateRoute path="/accounting/expense" component={Dashboard} />
          <PrivateRoute path="/accounting/p-and-l" component={Dashboard} />
          <PrivateRoute path="/accounting/cashflow" component={Dashboard} />
          <PrivateRoute path="/accounting/overview" component={Dashboard} />
          <PrivateRoute path="/accounting/returns" component={Dashboard} />
          <PrivateRoute path="/accounting/settings" component={Dashboard} />
          {/* End Accounting Pages */}

          {/* Finance Pages */}
          <PrivateRoute path="/finance/dashboard" component={Dashboard} />
          {/* End Finance Pages */}

          {/* HR Pages */}
          <PrivateRoute path="/hr/dashboard" component={Dashboard} />
          <PrivateRoute path="/hr/leaves" component={Holidays} />
          {/* End HR Pages */}

          {/* Training Pages */}
          <PrivateRoute path="/training/dashboard" component={Dashboard} />
          {/* End Training Pages */}
          {/* Resources Pages */}
          <PrivateRoute path="/resources/dashboard" component={Dashboard} />
          <PrivateRoute path="/recruitment/resources/summary" component={ResourcesSummary} />
          <PrivateRoute path="/recruitment/resources/ml-resource" component={ResourcesMlResource} />
          <PrivateRoute path="/recruitment/resources/it-resource" component={ResourcesITResource} />
          <PrivateRoute path="/recruitment/resources/logins" component={ResourcesLogins} />
          <PrivateRoute path="/recruitment/resources/cost-and-benefit" component={ResourcesCostbenefit} />
          <PrivateRoute path="/recruitment/resources/income-tax" component={ResourcesIncometax} />
          {/* End Resources Pages */}
          {/*Projections  Page*/}
          <PrivateRoute path="/recruitment/projections/salaries" component={ProjectionsSalaries} />
          <PrivateRoute path="/recruitment/projections/income-tax" component={ProjectionsIncometax} />
          <PrivateRoute path="/recruitment/projections/cost-of-living" component={ProjectionsCostliving} />
          <PrivateRoute path="/recruitment/projections/job-profiles" component={ProjectionsJobprofile} />
          {/*End Projections Page*/}
          {/* Report Pages */}
          <PrivateRoute path="/report/dashboard" component={ReportDashboard} />
          {/* End Report Pages */}

          {/* Sales Pages */}
          <PrivateRoute path="/sales/dashboard" component={Dashboard} />
          {/* End Sales Pages */}

          {/* Advertisment Pages */}
          <PrivateRoute path="/advertisment/dashboard" component={AdvertismentDashboard} />
          <PrivateRoute path="/advertisment/settings/logins" component={AdvertismentDashboard} />
          {/* End Advertisment Pages */}

          {/* Information Centre Pages */}
          <PrivateRoute path="/informationcentre/dashboard" component={InformationCentreDashboard} />
          {/* End Information Centre Pages */}

          {/* Admin Pages */}
          <AdminRoute path="/admin/dashboard" component={Dashboard} />
          <AdminRoute path="/admin/tools/backend-values" component={BackendValues} />
          {/* End Admin Pages */}

          {/* Blank Layout Pages */}
          <BlankPrivateRoute path="/profile" component={Profile} />
          {/* End Blank Layout Pages */}

          {/* Tasks Pages */}
          <PrivateRoute path="/tasks/dashboard" component={Dashboard} />
          <PrivateRoute path="/tasks/all-task" component={AllTask} />
          <PrivateRoute path="/tasks/my-task" component={MyTask} />
          <PrivateRoute path="/tasks/others-task" component={OthersTask} />
          <PrivateRoute path="/tasks/group-task" component={GroupTask} />
          <PrivateRoute path="/tasks/payments-task" component={PaymentTask} />
          {/* End Tasks Pages */}

          {/* Projects Pages */}
          <PrivateRoute path="/projects/dashboard" component={Dashboard} />
          {/* End Projects Pages */}

          {/* My Files Pages */}
          <PrivateRoute path="/my-files/dashboard" component={Dashboard} />
          {/* End My Files Pages */}

          <PrivateRoute component={Error404} />
        </Switch>
      </div>
    </React.Fragment>
  );
}

export default App;
