import React, { useEffect, useState } from 'react';
import { Route, Link } from "react-router-dom";
import '../style.scss';
import SmsLeftPanel from "../SmsLeftPanel";
import Dropdown from 'react-bootstrap/Dropdown'
import Tooltip from '@material-ui/core/Tooltip';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles
} from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError } from "../../../../slice/utils/message/messageSlice";
import { loadingEnd, loadingStart } from "../../../../slice/utils/loader/linearSlice";
import { templeteList, clearState } from "../../../../slice/sms/templateSlice";
import { composeSms } from "../../../../slice/sms/smsSlice";
import SmsCredits from "../smsCredits";
import Authentication from "../../../helpers/authenticate";
import Submit from "../../../ui/submitButton";

const defaultTheme = createMuiTheme();
const theme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "13px",
                fontWeight: "normal",
                color: "#fff",
                backgroundColor: "black"
            }
        }
    }
});

const ComposeNew = () => {
    const dispatch = useDispatch();
    const { templates, isLoading } = useSelector(state => state.smsTemplate)
    const { isInsert, isError, errorMessage } = useSelector(state => state.sms)
    const { _id } = useSelector(state => state.auth.userInfo.companyId)
    const companyId = _id;

    const { userInfo } = useSelector(state => state.auth)

    const userId = userInfo.id;
    const [number, setNumber] = useState('')
    const [body, setBody] = useState('')

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        async function fetchTemplates() {
            try {
                await dispatch(templeteList({ companyId: companyId }))
                dispatch(loadingEnd())
            } catch (err) {
                console.log(err)
            }
        }
        fetchTemplates()
    }, [dispatch])

    useEffect(() => {
        if (isLoading) {
            // dispatch(loadingStart())
        }
    }, [dispatch, isLoading])

    const setTemplateBody = (templateBody) => {
        setBody(templateBody)
    }

    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {
            case "number":
                setNumber(value)
                break;

            case "smsBody":
                setBody(value)
                break;

            default:
                break;
        }
    }

    const handleButtonClick = async () => {
        if (!number || !body) {
            dispatch(showError({ msg: 'Please fill all fields.' }))
        } else {
            if (!loading) {
                setSuccess(false);
                setLoading(true);
                await dispatch(composeSms({ userId: userId, number: number, smsBody: body }))
            }
        }
    };

    useEffect(() => {
        if (isInsert) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: 'SMS sent successfully.' }))
            setNumber('')
            setBody('')
            setSuccess(true);
            setLoading(false);
        }
        if (isError) {
            dispatch(showError({ msg: errorMessage }))
            dispatch(clearState())
            setSuccess(true);
            setLoading(false);
        }
    }, [isInsert, isError])

    return (
        <React.Fragment>
            <Authentication />
            <div className="dk-smsMain">
                <h3 className="title">COMPOSE NEW SME</h3>

                <div className="row">
                    <SmsLeftPanel />
                    <div className="col-md-7">
                        <div className="dk-composeCols">
                            <div className="d-flex">
                                <div className="dk-composeEmail">
                                    <input type="text" placeholder="Enter Phone Number" name="number" className="form-control" value={number} onChange={handleChange} />
                                </div>
                                <div className="dk-composeBtn">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            Select Template
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {
                                                (!isLoading && templates && templates.length > -1) ? templates.map((item, index) =>
                                                    <MuiThemeProvider theme={theme}>
                                                        <Tooltip title={item.body} placement="left-start">
                                                            <Dropdown.Item href="#" onClick={() => setTemplateBody(item.body)}>
                                                                {item.name}
                                                            </Dropdown.Item>
                                                        </Tooltip>
                                                    </MuiThemeProvider>
                                                ) : null
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="dk-composeMessage-box">
                                <textarea name="smsBody" placeholder="Write Message..." className="form-control" value={body} onChange={handleChange} ></textarea>
                                <Submit txt="Send" loading={loading} success={success} onClick={handleButtonClick} position="justify-content-center" className="dk-sendBtn" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <SmsCredits />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ComposeNew;
