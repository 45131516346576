import React, { useEffect, useState } from 'react';
import { Route, Link } from "react-router-dom";
import './style.scss';
import { Dropdown } from 'semantic-ui-react';

const options = [
    {
        key: 'Person',
        text: 'Person',
        value: 'Person',
    },
    {
        key: 'Medical',
        text: 'Medical',
        value: 'Medical',
    },
    {
        key: 'Public',
        text: 'Public',
        value: 'Public',
    },
    {
        key: 'Festival',
        text: 'Festival',
        value: 'Festival',
    },
    {
        key: 'Summer',
        text: 'Summer',
        value: 'Summer',
    },
    {
        key: 'Christmas',
        text: 'Christmas',
        value: 'Christmas',
    },
    {
        key: 'Other',
        text: 'Other',
        value: 'Other',
    },
]

const SelectReasonField = () => {
    return (
        <React.Fragment>
            <Dropdown className="dk-reasonTypeDropdown" clearable options={options} selection placeholder='Select' />
        </React.Fragment>
    );
}

export default SelectReasonField;
