import React from 'react';
import FileUpload from '../../../ui/fileupload/FileUpload';
import "./style.scss";
import Browsetext from "../../../../assets/images/browsetext.png";

const AddNew = () => {
    return (
        <React.Fragment>
            <div className="container-fluid dk-cliAdNewPageCont pl-3 pr-3">
                <div className="row">
                    <div className="col-12 col-md-12 text-center">
                        <div className="dk-clntsPageTitle">
                            <h2>CREATE NEW CLIENT</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-8 col-md-8 col-sm-12 pl-4 pr-4">
                        <div className="dk-addNClientFormCont">
                            <div className="row">
                                <div className="col-md-7 col-sm-12">
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label className="dk-longllabel">Name of Business</label>
                                        <input type="text" className="form-control" placeholder="" />
                                        <span className="dk-clViewAddonIcon">
                                            <i className="fa fa-building" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-5 col-sm-12">
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label className="dk-smalllabel">Client ID</label>
                                        <input type="text" className="form-control" placeholder="" />
                                        <span className="dk-clViewAddonIcon">
                                            <i className="fa fa-building" aria-hidden="true"></i>
                                        </span>
                                    </div>          
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="form-group dk-Candinate-fromGroup d-flex w-100">
                                        <label className="dk-longllabel">City/Town</label>
                                        <input type="text" className="form-control" placeholder="" />
                                        <span className="dk-clViewAddonIcon">
                                            <i className="fa fa-map-marker" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label className="dk-smalllabel">Country</label>
                                        <input type="text" className="form-control" placeholder="" />
                                        <span className="dk-clViewAddonIcon">
                                            <i className="fa fa-map-marker" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label className="dk-longllabel">Industry</label>
                                        <select className="form-control addNewSelect" id="exampleFormControlSelect1">
                                            <option>Software</option>
                                            <option>Transport</option>
                                            <option>Computer</option>
                                            <option>Education</option>
                                            <option>Pharmaceutical</option>
                                        </select>
                                        <span className="dk-clViewAddonIcon">
                                            <i className="fa fa-building" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label className="dk-longllabel">Business</label>
                                        <select className="form-control addNewSelect" id="exampleFormControlSelect1">
                                            <option>Business 1</option>
                                            <option>Business 2</option>
                                            <option>Business 3</option>
                                            <option>Business 4</option>
                                            <option>Business 5</option>
                                        </select>
                                        <span className="dk-clViewAddonIcon">
                                            <i className="fa fa-building" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label className="dk-longllabel">Address</label>
                                        <input type="text" className="form-control" placeholder="" />
                                        <span className="dk-clViewAddonIcon">
                                           <i className="fas fa-pencil-alt"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group dk-Candinate-fromGroup d-flex">
                                                <label className="dk-longllabel">Phone 1</label>
                                                <input type="text" className="form-control" placeholder="" />
                                                <span className="dk-clViewAddonIcon">
                                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group dk-Candinate-fromGroup d-flex">
                                                <label className="dk-smalllabel">Phone 2</label>
                                                <input type="text" className="form-control" placeholder="" />
                                                <span className="dk-clViewAddonIcon">
                                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label className="dk-longllabel">Email @</label>
                                        <input type="text" className="form-control" placeholder="" />
                                        <span className="dk-clViewAddonIcon">
                                            <i className="fa fa-envelope" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label className="dk-longllabel">Website 1</label>
                                        <input type="text" className="form-control" placeholder="" />
                                        <span className="dk-clViewAddonIcon">
                                            <i className="fa fa-globe" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label className="dk-longllabel">Website 2</label>
                                        <input type="text" className="form-control" placeholder="" />
                                        <span className="dk-clViewAddonIcon">
                                            <i className="fa fa-globe" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                    <div className="form-group dk-Candinate-fromGroup d-flex mb-0">
                                        <label className="dk-longllabel">CV Upload Link</label>
                                        <input type="text" className="form-control" placeholder=""/>
                                        <span className="dk-clViewAddonIcon">
                                            <i className="fa fa-globe" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-12 pl-4 pr-4">
                        <div className="dkaddNewRightCon">
                            <div className="addNewBox">
                                <FileUpload />
                            </div>
                            {/* <div className="dk-ClientimgUpload">
                                <div className="dk-recuploadImg">
                                    <input type="file" name="" id="" />
                                    <span>
                                        <img src={Browsetext} className="img-fluid" alt="" />
                                    </span>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddNew
