import React from "react";
import { Route, Link } from "react-router-dom";
import '../../mainMenu/recruitment/style.scss';
import DashboardSubmenu from "./menu/DashboardSubmenu";
import TasksSubmenu from "./menu/TasksSubmenu";
import CompanySubmenu from "./menu/CompanySubmenu";
import ProjectionSubmenu from "./menu/ProjectionSubmenu";
import DatabaseSubmenu from "./menu/DatabaseSubmenu";
import ResourceSubmenu from "./menu/ResourceSubmenu";

const Submenu = ({ path }) => {

    const renderSubmenu = () => {
        switch (path.split("/")[2]) {
            case 'dashboard':
                return <DashboardSubmenu />
                break;

            case 'tasks':
                return <TasksSubmenu />
                break;

            case 'companies':
                return <CompanySubmenu />
                break;

            case 'projections':
                return <ProjectionSubmenu />
                break;

            case 'resources':
                return <ResourceSubmenu />
                break;

            case 'database':
                return <DatabaseSubmenu />
                break;
        }
    }

    return (
        <React.Fragment>
            {renderSubmenu()}
        </React.Fragment>
    )
}
export default Submenu;