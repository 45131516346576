import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import './bkvalues.scss';
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";
import { mainCategoryBox1, subCategoryBox2, subCategoryBox3, subCategoryBox4, deleteCategory, clearState } from "../../../../../slice/admin/backendValues/backendValuesSlice";
import DeleteModal from "../../../../ui/delete";
import { List } from "./List";
import { ReorderCategory } from "./ReorderCategory";

const EditBkValuesModal = ({ boxNo, parentId1, parentId2, parentId3, parentId4, headingText }) => {
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const { box1List, box2List, box3List, box4List, isReoder, isError, isDelete, msg } = useSelector(state => state.backendValues)

    const [data, setData] = useState([])

    const [rowId, setRowId] = useState('0')
    const [showDelete, setShowDelete] = useState(false)

    useEffect(() => {
        switch (boxNo) {
            case '1':
                setData(box1List)
                break;

            case '2':
                setData(box2List)
                break;

            case '3':
                setData(box3List)
                break;

            case '4':
                setData(box4List)
                break;
        }
    }, [boxNo, box1List, box2List, box3List, box4List])

    const deleteItem = (id) => {
        setShowDelete(true)
        setRowId(id)
    }

    const handleCloseDelete = () => {
        setShowDelete(false)
        setRowId('0')
    }

    const deleteValue = async () => {
        setSuccess(false);
        setLoading(true);
        await dispatch(deleteCategory({ boxNo, id: rowId }))
    }

    useEffect(() => {
        if (isDelete) {
            dispatch(clearState())
            dispatch(showSuccess({ msg }))
            setShowDelete(false)
            setRowId('0')
            setSuccess(false);
            setLoading(false);
        }
        if (isReoder) {
            dispatch(showSuccess({ msg }))
            dispatch(clearState())
            setLoading(false)
            setSuccess(false)
            switch (boxNo) {
                case '1':
                    dispatch(mainCategoryBox1({}))
                    break;

                case '2':
                    dispatch(subCategoryBox2({ mainCategory: parentId1 }))
                    break;

                case '3':
                    dispatch(subCategoryBox3({ parentId: parentId1, parentId2: parentId2 }))
                    break;

                case '4':
                    dispatch(subCategoryBox4({ parentId: parentId1, parentId2: parentId2, parentId3: parentId3 }))
                    break;
            }
        }
        if (isError) {
            dispatch(showError({ msg }))
            dispatch(clearState())
            setLoading(false)
            setSuccess(false)
        }
    }, [isDelete, isReoder, isError])

    return (
        <>
            {
                (showDelete) ? <DeleteModal id={rowId} loading={loading} success={success} handleClose={handleCloseDelete} deleteItem={deleteValue} /> : null
            }
            <Link to="#" onClick={handleShow}><i class="far fa-edit"></i></Link>

            <Modal className="dk-toolsBkVlueseditModal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{headingText}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-toolsModalbody">
                        <Tabs defaultActiveKey="edit" id="uncontrolled-tab-example" className="mb-3">
                            <Tab eventKey="edit" title="Edit">
                                <div className="dk-toolsValuesPanel">
                                    {
                                        data.map((item, index) => (
                                            <List boxNo={boxNo} key={index + 1} number={index + 1} itemValue={item.name} id={item._id} />
                                        ))
                                    }
                                </div>
                            </Tab>
                            <Tab eventKey="reorder" title="Reorder">
                                <div className="dk-toolsValuesPanel dk-toolsReorderPanel">
                                    {
                                        <ReorderCategory dataList={data} />
                                    }
                                </div>
                            </Tab>
                            <Tab eventKey="delete" title="Delete">
                                <div className="dk-toolsValuesPanel dk-toolsReorderPanel">
                                    {
                                        data.map((item, index) => (
                                            <div className="d-flex align-items-center" key={index + 1}>
                                                <input type="text" value={item.name} className="form-control" />
                                                <div className="dk-toolsPanel-updateIcon">
                                                    <i class="far fa-trash-alt" onClick={() => deleteItem(item._id)}></i>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EditBkValuesModal;
