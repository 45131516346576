import React, { useState, useEffect } from "react"
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from "react-redux";
import { IMG_CLOUD_BASE_URL } from '../../../constants'
const UserList = ({ list, onClick }) => {
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        console.log('userFilter')
        const result = [];
        const map = new Map();
        for (const item of list) {
            if (!map.has(item.original.userId._id)) {
                map.set(item.original.userId._id, true);
                result.push({
                    id: item.original.userId._id,
                    name: item.original.userId.firstName,
                    userImg: item.original.userId.userImg
                });
            }
        }
        setUserList(result)
    }, [list])

    return (
        <React.Fragment>
            {userList.map((list, index) => (
                <Dropdown.Item href="#">
                    <img src={IMG_CLOUD_BASE_URL + list.userImg} className="img-fluid img-circle" alt={list.userImg} title={list.name} id={list.id} onClick={onClick} />
                </Dropdown.Item>
            ))}
        </React.Fragment>
    )
}
export default UserList;