import React, { useState } from 'react';
import { Route, Link } from "react-router-dom";
import '../mlresource/style.scss';
import Modal from 'react-bootstrap/Modal';

const ReorderModal = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return(
        <React.Fragment>
            <button onClick={handleShow}><i class="fas fa-arrows-alt"></i> Reorder</button>
            <Modal className="dk-mlAddNewModal dk-reorderMOdal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>REORDER</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-mlAddModalBody">
                    <ui className="dk-sortable">
                        <li>
                            <div className="d-flex">
                                <div className="cols">
                                    <div><i class="fas fa-arrows-alt"></i></div>
                                    <div>Level 1</div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="d-flex">
                                <div className="cols">
                                    <div><i class="fas fa-arrows-alt"></i></div>
                                    <div>Level 1</div>
                                </div>
                            </div>
                        </li>
                    </ui>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default ReorderModal;
