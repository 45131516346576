import React, { useEffect, useState } from "react";
import { Route, Link } from "react-router-dom";
import '../style.scss';
import Authentication from "../../../helpers/authenticate";
import SmsLeftPanel from "../SmsLeftPanel";
import MessageModal from "../MessageModal";
import { useDispatch, useSelector } from "react-redux";
import { templeteList, clearState } from "../../../../slice/sms/templateSlice";
import { BasicTable } from "./BasicTable";
import { loadingEnd, loadingStart } from "../../../../slice/utils/loader/linearSlice";
import TableLoader from "../../../utils/tableLoader";
const SmsTemplates = () => {
    const dispatch = useDispatch();
    const { templates, isLoading } = useSelector(state => state.smsTemplate)
    const [isActive, setActive] = useState("false");
    const { _id } = useSelector(state => state.auth.userInfo.companyId)
    const companyId = _id;
    useEffect(() => {
        async function fetchTemplates() {
            try {
                await dispatch(templeteList({ companyId }))
                dispatch(loadingEnd())
            } catch (err) {
                console.log(err)
            }
        }
        fetchTemplates()
    }, [dispatch])

    useEffect(() => {
        if (isLoading) {
            dispatch(loadingStart())
        }
    }, [dispatch, isLoading])

    return (
        <React.Fragment>
            <Authentication />
            <div className="dk-smsMain">
                <h3 className="title">SMS Templates</h3>
                <div className="row">
                    <SmsLeftPanel />
                    <div className="col-md-10">
                        <div className="dk-smsInboxTable">
                            {
                                (!isLoading && templates && templates.length > -1) ? <BasicTable /> : null
                            }
                        </div>
                    </div>

                </div>
            </div>
        </React.Fragment>
    );
}

export default SmsTemplates;
