import React, { useEffect, useState } from "react";
import { SortableContainer, SortableElement, sortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from 'array-move';
import Submit from "../../../../ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import { reorderCategory, clearState } from "../../../../../slice/admin/backendValues/backendValuesSlice";
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";

const DragHandle = sortableHandle(() => <span className="dk-handle" style={{ backgroundColor: "#363f4c", height: "35px !important", width: "50px", textAlign: "center", lineHeight: "35px", color: "white", cursor: "all-scroll" }}><i class="fas fa-bars"></i></span>);

const SortableItem = SortableElement(({ value }) => {
    const deleteItem = (id) => {
        console.log(id)
    }
    return (
        <React.Fragment>
            <div className="Mukul">
                <div className="dk-valueFields d-flex" key={value._id} id={value._id}>
                    <DragHandle />
                    <input type="text" style={{ background: value.bgColor, color: value.textColor, height: "35px", borderRadius: "0", border: "1px solid #ddd", boxShadow: "none !important" }} value={value.name} name="" id="" className="form-control" />
                </div>
            </div>
        </React.Fragment>
    )
})

const SortableList = SortableContainer(({ items }) => {
    return (
        <ul>
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} />
            ))}
        </ul>
    );
});

export const ReorderCategory = ({ dataList }) => {
    const dispatch = useDispatch();

    const { isReoder, isError, msg } = useSelector(state => state.backendValues)

    const [list, setList] = useState([])

    useEffect(() => {
        setList(dataList)
    }, [dataList])

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setList(arrayMoveImmutable(list, oldIndex, newIndex))
    };

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const reorderCategoryValues = async () => {
        setLoading(true)
        setSuccess(false)
        let reorderListArray = []
        list.map((data) => {
            reorderListArray.push(data._id)
        })
        await dispatch(reorderCategory({ ids: reorderListArray }))
    }

    useEffect(() => {
        if (isReoder) {
            setLoading(false)
            setSuccess(false)
        }
        if (isError) {
            setLoading(false)
            setSuccess(false)
        }
    }, [isReoder, isError])

    return (
        <React.Fragment>
            <SortableList lockAxis="y" items={list} onSortEnd={onSortEnd} useDragHandle />
            <div className="d-flex justify-content-center">
                <Submit txt="Save Order" loading={loading} success={success} onClick={reorderCategoryValues} position="justify-content-center" className="dk-toolsPanelSaveBtn" />
            </div>
        </React.Fragment>
    )
}