import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const listAll = createAsyncThunk(
    'tasks/list_all',
    async ({ userId, companyId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/task/all/' + userId + '/' + companyId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const myTasklist = createAsyncThunk(
    'tasks/list_my_taks',
    async ({ userId, companyId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/task/mytask/' + userId + '/' + companyId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const othersTasklist = createAsyncThunk(
    'tasks/list_others_taks',
    async ({ userId, companyId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/task/otherstask/' + userId + '/' + companyId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const groupTasklist = createAsyncThunk(
    'tasks/list_group_task',
    async ({ userId, companyId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/task/grouptask/' + userId + '/' + companyId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const paymentTasklist = createAsyncThunk(
    'tasks/list_payment_task',
    async ({ userId, companyId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/task/paymenttask/' + userId + '/' + companyId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const taskComments = createAsyncThunk(
    'tasks/comments',
    async ({ taskId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/task/comment/' + taskId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const newTaskComment = createAsyncThunk(
    'tasks/new/comment',
    async ({ type, taskId, parentId, userId, comment }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/task/comment/' + type + '/' + taskId,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        parentId,
                        userId,
                        comment
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const deleteComment = createAsyncThunk(
    'tasks/delete/comment',
    async ({ type, id, commentId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/task/comment/' + type + '/' + id + '/' + commentId,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 202) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const taskCommentUpdate = createAsyncThunk(
    'tasks/update/comment',
    async ({ taskId, commentId, comment }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/task/comment/' + taskId + '/' + commentId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        comment
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

const taskSlice = createSlice({
    name: 'tasks',
    initialState: {
        allTasks: {},
        allBadge: 0,
        myBadge: 0,
        otherBadge: 0,
        paymentBadge: 0,
        groupBadge: 0,
        myTasks: {},
        othersTasks: {},
        groupTasks: {},
        paymentTasks: {},
        isLoading: false,
        isDetailsLoading: false,
        isCommentLoading: false,
        isInsert: false,
        isError: false,
        isUpdate: false,
        isDelete: false,
        taskDetails: {},
        comments: [],
        errorMessage: '',
        isSuccess: false,
        orinalComment: [],
        msg: ''
    },
    reducers: {
        countBadges: (state, { payload }) => {
            state.allBadge = payload.allCount;
            state.myBadge = payload.myCount;
            state.otherBadge = payload.otherCount;
            state.groupBadge = payload.groupCount;
            state.paymentBadge = payload.paymentCount;
        },
        clearState: (state) => {
            state.isLoading = false;
            state.isDetailsLoading = false;
            state.isCommentLoading = false;
            state.isError = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isDelete = false;
            state.errorMessage = '';
            state.taskDetails = {};
            state.isSuccess = false;
            state.msg = '';
            return state;
        },
        clearMessage: (state) => {
            state.isSuccess = false;
            state.isError = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isDelete = false;
        }
    },
    extraReducers: {
        [listAll.pending]: (state) => {
            state.isLoading = true;
        },
        [listAll.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.allTasks = payload.tasks;
        },
        [listAll.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [myTasklist.pending]: (state) => {
            state.isLoading = true;
        },
        [myTasklist.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.myTasks = payload.tasks;
        },
        [myTasklist.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [othersTasklist.pending]: (state) => {
            state.isLoading = true;
        },
        [othersTasklist.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.othersTasks = payload.tasks;
        },
        [othersTasklist.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [groupTasklist.pending]: (state) => {
            state.isLoading = true;
        },
        [groupTasklist.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.groupTasks = payload.tasks;
        },
        [groupTasklist.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [paymentTasklist.pending]: (state) => {
            state.isLoading = true;
        },
        [paymentTasklist.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.paymentTasks = payload.tasks;
        },
        [paymentTasklist.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [taskComments.pending]: (state) => {
            state.isCommentLoading = true;
        },
        [taskComments.fulfilled]: (state, { payload }) => {
            state.isCommentLoading = false;
            state.orinalComment = payload.comments;
            state.comments = payload.comments;
            state.comments = getSubItems(state.comments)
        },
        [taskComments.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [newTaskComment.pending]: (state) => {
            state.isCommentLoading = true;
        },
        [newTaskComment.fulfilled]: (state, { payload }) => {
            state.isCommentLoading = false;
            state.comments = payload.comments;
            state.orinalComment = payload.comments;
            state.comments = getSubItems(state.comments)
            let mainArray;
            let finalResult;
            switch (payload.type) {
                case 'all':
                    mainArray = state.allTasks;
                    finalResult = mainArray.map((r) => {
                        if (r._id === payload.id) {
                            return ({ ...r, comments: payload.comments })
                        } else {
                            return r
                        }
                    })
                    state.allTasks = finalResult
                    break;
                case 'my':
                    mainArray = state.myTasks;
                    finalResult = mainArray.map((r) => {
                        if (r._id === payload.id) {
                            return ({ ...r, comments: payload.comments })
                        } else {
                            return r
                        }
                    })
                    state.myTasks = finalResult
                    break;
                case 'others':
                    mainArray = state.othersTasks;
                    finalResult = mainArray.map((r) => {
                        if (r._id === payload.id) {
                            return ({ ...r, comments: payload.comments })
                        } else {
                            return r
                        }
                    })
                    state.othersTasks = finalResult
                    break;
                case 'payment':
                    mainArray = state.paymentTasks;
                    finalResult = mainArray.map((r) => {
                        if (r._id === payload.id) {
                            return ({ ...r, comments: payload.comments })
                        } else {
                            return r
                        }
                    })
                    state.paymentTasks = finalResult
                    break;
                case 'group':
                    mainArray = state.groupTasks;
                    finalResult = mainArray.map((r) => {
                        if (r._id === payload.id) {
                            return ({ ...r, comments: payload.comments })
                        } else {
                            return r
                        }
                    })
                    state.groupTasks = finalResult
                    break;
            }
            state.isSuccess = true;
            state.msg = payload.message.msgBody;
        },
        [newTaskComment.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [deleteComment.pending]: (state) => {
            state.isDelete = false;
        },
        [deleteComment.fulfilled]: (state, { payload }) => {
            state.isDelete = true;
            state.msg = payload.message.msgBody;
            state.comments = payload.comments;
            state.comments = state.comments.filter((item) => item._id !== payload.commentId)
            state.orinalComment = state.comments;
            state.comments = getSubItems(state.comments)
            let mainArray;
            let finalResult;
            switch (payload.type) {
                case 'all':
                    mainArray = state.allTasks;
                    finalResult = mainArray.map((r) => {
                        if (r._id === payload.id) {
                            return ({ ...r, comments: payload.comments })
                        } else {
                            return r
                        }
                    })
                    state.allTasks = finalResult
                    break;
                case 'my':
                    mainArray = state.myTasks;
                    finalResult = mainArray.map((r) => {
                        if (r._id === payload.id) {
                            return ({ ...r, comments: payload.comments })
                        } else {
                            return r
                        }
                    })
                    state.myTasks = finalResult
                    break;
                case 'others':
                    mainArray = state.othersTasks;
                    finalResult = mainArray.map((r) => {
                        if (r._id === payload.id) {
                            return ({ ...r, comments: payload.comments })
                        } else {
                            return r
                        }
                    })
                    state.othersTasks = finalResult
                    break;
                case 'payment':
                    mainArray = state.paymentTasks;
                    finalResult = mainArray.map((r) => {
                        if (r._id === payload.id) {
                            return ({ ...r, comments: payload.comments })
                        } else {
                            return r
                        }
                    })
                    state.paymentTasks = finalResult
                    break;
                case 'group':
                    mainArray = state.groupTasks;
                    finalResult = mainArray.map((r) => {
                        if (r._id === payload.id) {
                            return ({ ...r, comments: payload.comments })
                        } else {
                            return r
                        }
                    })
                    state.groupTasks = finalResult
                    break;
            }
        },
        [deleteComment.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [taskCommentUpdate.pending]: (state) => {
        },
        [taskCommentUpdate.fulfilled]: (state, action) => {
            state.isUpdate = true;
            let mainArray = state.orinalComment;
            let finalResult = mainArray.map((r) => {
                if (r._id === action.meta.arg.commentId) {
                    return ({ ...r, comment: action.meta.arg.comment })
                } else {
                    return r
                }
            })
            state.orinalComment = finalResult
            state.comments = getSubItems(state.orinalComment)
        },
        [taskCommentUpdate.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
    }
});


function getSubItems(categories, parentId = '0') {
    const itemsList = []
    let item;
    if (parentId == '0') {
        item = categories.filter(cat => cat.parentId == '0')
    } else {
        item = categories.filter(cat => cat.parentId == parentId)
    }
    for (let cate of item) {
        itemsList.push({
            _id: cate._id,
            parentId: cate.parentId,
            comment: cate.comment,
            createdOn: cate.createdOn,
            updatedOn: cate.updatedOn,
            userId: cate.userId,
            children: getSubItems(categories, cate._id)
        })
    }
    return itemsList;
}
export const { countBadges, clearState, clearMessage } = taskSlice.actions;
export default taskSlice.reducer;
