import React from "react";
import { Route, Link } from "react-router-dom";
import './style.scss';

const ModulesNavbar = () => {
    const pathname = window.location.pathname;
    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-modulesNavbar">
                <Route>
                    <ul>
                        <li><Link to="/dashboard" className={`${pathname.match('/dashboard') ? 'active' : ''}`}>Dashboard</Link></li>
                        <li><Link to="/get-started" className={`${pathname.match('/get-started') ? 'active' : ''}`}>Get Started</Link></li>
                        <li><Link to="/what-is" className={`${pathname.match('/what-is') ? 'active' : ''}`}>What is ?</Link></li>
                        <li><Link to="/standard" className={`${pathname.match('/standard') ? 'active' : ''}`}>Standard</Link></li>
                        <li><Link to="/customized" className={`${pathname.match('/customized') ? 'active' : ''}`}>Customized</Link></li>
                        <li><Link to="/knowledge" className={`${pathname.match('/knowledge') ? 'active' : ''}`}>Knowledge</Link></li>
                        <li><Link to="/announcements" className={`${pathname.match('/announcements') ? 'active' : ''}`}>Announcements</Link></li>
                        <li><Link to="/dkg-support" className={`${pathname.match('/dkg-suppor') ? 'active' : ''}`}>DKG Support</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default ModulesNavbar;
