import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const ResourceSubmenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[2];
    const segment2 = pathname.split("/")[3];
    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/recruitment/resources/summary" className={(segment1 == 'resources' && segment2 == 'summary') ? 'active' : ''}>Summary</Link></li>
                        <li><Link to="/recruitment/resources/ml-resource" className={`${(segment2 == 'ml-resource') ? 'active' : ''}`}>ML Resource</Link></li>
                        <li><Link to="/recruitment/resources/it-resource" className={`${(segment2 == 'it-resource') ? 'active' : ''}`}>IT Resource</Link></li>
                        <li><Link to="/recruitment/resources/logins" className={`${(segment2 == 'logins') ? 'active' : ''}`}>Logins</Link></li>
                        <li><Link to="/recruitment/resources/cost-and-benefit" className={`${(segment2 == 'cost-and-benefit') ? 'active' : ''}`}>Cost/Benefit</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default ResourceSubmenu;
