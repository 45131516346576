import React, { useState } from 'react';
import  "./listview.scss";
import DetialViewPoup from "./modal/DetialViewPoup.js";
import FlagImg from "../../../../assets/images/clientflag.jpg";
const ListView = () => {
    const [isProfile,setIsProfile] = useState(false);
    const showProfile = () =>{
        setIsProfile(true)
    }
    const hideProfile = () =>{
        setIsProfile(false)
    }
    return (
        <React.Fragment>
            {
                (isProfile) ? <DetialViewPoup onHide={hideProfile} /> : null
            }
            <div className="container-fluid dk-cliListViewPageCont">
                <div className="row">
                    <div className="col-12 col-md-12 text-center">
                        <div className="dk-clntsPageTitle">
                           <h2>Clients List View</h2>
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <div className="listViewTableCon table-responsive">
                            <div className="table dk-clListViewTable">
                                <thead>
                                    <tr>
                                        <th class="pl-0">
                                            <div class="dk-cliLeftheaderCont d-flex">
                                                <input id="srchterm" type="text" class="form-control searchTxtBox" name="search" placeholder="Search by Text" />
                                                <button class="btn dk-mbclientsrchInput legitRipple" type="button">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </div>
                                        </th>
                                        <th>Status</th>
                                        <th>Category</th>
                                        <th>Name of Client</th>
                                        <th> City/Town </th>
                                        <th>Country</th>
                                        <th>Industry</th>
                                        <th>Business</th>
                                        <th>Created On</th>
                                        <th>Logo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="d-flex"> 
                                                01
                                                <span class="dk-clupdeProfile mr-2 ml-2" onClick={showProfile}>
                                                   <i class="fa fa-chevron-right" ></i>
                                                </span>
                                                35076
                                            </div> 
                                        </td>
                                        <td>
                                            <a class=" dk-cllistStatusbtnt dk-clientsActBtn" href="">Active</a>
                                        </td>
                                        <td>
                                            ML
                                        </td>
                                        <td>
                                            Lydia Stavropoulou
                                        </td>
                                        <td>
                                        Dublin
                                        </td>
                                        <td>
                                        Ireland
                                        </td>
                                        <td>Direct advertising</td>
                                        <td>Business 1</td>
                                        <td>14 Jun 2021</td>
                                        <td>
                                            <div class="dk-clistViewTableIcn">
                                                <img class="dk-cllistViewTabImg" src={FlagImg} alt="Flag" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex"> 
                                                02
                                                <span class="dk-clupdeProfile mr-2 ml-2" onClick={showProfile}>
                                                   <i class="fa fa-chevron-right" data-toggle="modal" data-target="#dk-clientList-Pop"></i>
                                                </span>
                                                35076
                                            </div> 
                                        </td>
                                        <td>
                                            <a class=" dk-cllistStatusbtnt dk-clientsActBtn" href="">Active</a>
                                        </td>
                                        <td>
                                            ML
                                        </td>
                                        <td>
                                            Lydia Stavropoulou
                                        </td>
                                        <td>
                                        Dublin
                                        </td>
                                        <td>
                                        Ireland
                                        </td>
                                        <td>Direct advertising</td>
                                        <td>Business 1</td>
                                        <td>14 Jun 2021</td>
                                        <td>
                                            <div class="dk-clistViewTableIcn">
                                                <img class="dk-cllistViewTabImg" src={FlagImg} alt="Flag" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex"> 
                                                03
                                                <span class="dk-clupdeProfile mr-2 ml-2" onClick={showProfile}>
                                                   <i class="fa fa-chevron-right" data-toggle="modal" data-target="#dk-clientList-Pop"></i>
                                                </span>
                                                35076
                                            </div> 
                                        </td>
                                        <td>
                                            <a class=" dk-cllistStatusbtnt dk-clientsActBtn" href="">Active</a>
                                        </td>
                                        <td>
                                            ML
                                        </td>
                                        <td>
                                            Lydia Stavropoulou
                                        </td>
                                        <td>
                                        Dublin
                                        </td>
                                        <td>
                                        Ireland
                                        </td>
                                        <td>Direct advertising</td>
                                        <td>Business 1</td>
                                        <td>14 Jun 2021</td>
                                        <td>
                                            <div class="dk-clistViewTableIcn">
                                                <img class="dk-cllistViewTabImg" src={FlagImg} alt="Flag" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex"> 
                                                04
                                                <span class="dk-clupdeProfile mr-2 ml-2" onClick={showProfile}>
                                                   <i class="fa fa-chevron-right" data-toggle="modal" data-target="#dk-clientList-Pop"></i>
                                                </span>
                                                35076
                                            </div> 
                                        </td>
                                        <td>
                                            <a class=" dk-cllistStatusbtnt dk-clientsActBtn" href="">Active</a>
                                        </td>
                                        <td>
                                            ML
                                        </td>
                                        <td>
                                            Lydia Stavropoulou
                                        </td>
                                        <td>
                                        Dublin
                                        </td>
                                        <td>
                                        Ireland
                                        </td>
                                        <td>Direct advertising</td>
                                        <td>Business 1</td>
                                        <td>14 Jun 2021</td>
                                        <td>
                                            <div class="dk-clistViewTableIcn">
                                                <img class="dk-cllistViewTabImg" src={FlagImg} alt="Flag" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex"> 
                                                05
                                                <span class="dk-clupdeProfile mr-2 ml-2" onClick={showProfile}>
                                                   <i class="fa fa-chevron-right" data-toggle="modal" data-target="#dk-clientList-Pop"></i>
                                                </span>
                                                35076
                                            </div> 
                                        </td>
                                        <td>
                                            <a class=" dk-cllistStatusbtnt dk-clientsActBtn" href="">Active</a>
                                        </td>
                                        <td>
                                            ML
                                        </td>
                                        <td>
                                            Lydia Stavropoulou
                                        </td>
                                        <td>
                                        Dublin
                                        </td>
                                        <td>
                                        Ireland
                                        </td>
                                        <td>Direct advertising</td>
                                        <td>Business 5</td>
                                        <td>14 Jun 2021</td>
                                        <td>
                                            <div class="dk-clistViewTableIcn">
                                                <img class="dk-cllistViewTabImg" src={FlagImg} alt="Flag" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ListView;
