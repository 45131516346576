import React from "react";
import { Route, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import AddNewTask from "../../../../../../pages/tasks/AddNewTask";

const TasksSubmenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[2];
    const segment2 = pathname.split("/")[3];
    // const segment1 = pathname.split("/")[1];
    //const segment2 = pathname.split("/")[2];

    const { allBadge, myBadge, otherBadge, groupBadge, paymentBadge } = useSelector(state => state.tasks);

    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li>
                            <Link to="/recruitment/tasks/all-task" className={(segment1 == 'tasks' && segment2 == 'all-task') ? 'active' : ''}>
                                {
                                    (allBadge != 0) ? <span className="dk-badge">{allBadge}</span> : null
                                }All Tasks
                            </Link>
                        </li>
                        <li>
                            <Link to="/recruitment/tasks/my-task" className={(segment1 == 'tasks' && segment2 == 'my-task') ? 'active' : ''}>
                                {
                                    (myBadge != 0) ? <span className="dk-badge">{myBadge}</span> : null
                                }My Task
                            </Link>
                        </li>
                        <li>
                            <Link to="/recruitment/tasks/others-task" className={(segment1 == 'tasks' && segment2 == 'others-task') ? 'active' : ''}>
                                {
                                    (otherBadge != 0) ? <span className="dk-badge">{otherBadge}</span> : null
                                }Others
                            </Link>
                        </li>
                        <li>
                            <Link to="/recruitment/tasks/group-task" className={(segment1 == 'tasks' && segment2 == 'group-task') ? 'active' : ''}>
                                {
                                    (groupBadge != 0) ? <span className="dk-badge">{groupBadge}</span> : null
                                }Group
                            </Link>
                        </li>
                        <li>
                            <Link to="/recruitment/tasks/payments-task" className={(segment1 == 'tasks' && segment2 == 'payments-task') ? 'active' : ''}>
                                {
                                    (paymentBadge != 0) ? <span className="dk-badge">{paymentBadge}</span> : null
                                }Payments
                            </Link>
                        </li>
                        <li><Link to="/recruitment/tasks/admin-access" className={(segment1 == 'tasks' && segment2 == 'admin-access') ? 'active' : ''}>Admin Access</Link></li>
                        <li><AddNewTask /></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default TasksSubmenu;
