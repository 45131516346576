import React, { useEffect } from "react";
import Header from "../elements/header";
import CommunicationNavbar from "../elements/header/navbar/mainMenu/communication/communicationNavbar";

const Layout = ({ children }) => {

    return (
        <React.Fragment>
            <Header />
            <CommunicationNavbar />
            {children}
        </React.Fragment>
    )
}
export default Layout;