import React from 'react';
import {Link} from "react-router-dom";
import SelectItem from '../../../../ui/selectpicker/SelectItem';
import {DropdownButton,Dropdown,Tab,Nav} from 'react-bootstrap';


const WorkFlow = () => {
    const department = [
        { key: 'department_1', value: 'department_1', text: 'Department 1' },
        { key: 'department_2', value: 'department_2', text: 'Department 2' },
        { key: 'department_3', value: 'department_3', text: 'Department 3' },
        { key: 'department_4', value: 'department_4', text: 'Department 4' },
        { key: 'department_5', value: 'department_5', text: 'Department 5' }
    ]
    return (
        <React.Fragment>
            <div className="row pr-3">
                <div className="col-md-4">
                    <div className="dkclietopLeft d-flex">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Search by Text"/>
                            <div className="input-group-append">
                            <button className="btn btn-secondary legitRipple" type="button">
                                <i className="fa fa-search"></i>
                            </button>
                            </div>
                        </div>
                        <Link id="" to="#" className=" dk-topTbleIcon ml-2">
                            <i className="fa fa-plus" aria-hidden="true"></i>
                        </Link>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="dk-cliDetTabPaneBlkCon">
                        <div className="dk-cliDetTitle">
                            <h2 className="mb-0 mt-2">JOBS WORKFLOW</h2>
                        </div>
                    </div>    
                </div>
                <div className="col-md-4 dk-CliContactRight">
                    <div className="dkclietopRight">
                        <Link href="#" className=" dk-topTbleIcon mr-2">
                           <i className="fas fa-arrows-alt"></i>
                        </Link>
                    </div>
                    <div className="dk-CliDetSlectFilter pr-0">
                        <SelectItem options={department} placeholder="Department"/>
                    </div>
                </div>
            </div>
            <div className="row pr-3 mt-1">
                <div className="col-md-12 col-sm-12">
                    <div className="dk-cliDetailTableCon">
                        <table className="table table-bordered dk-cliDetialTable">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Status</th>
                                    <th>Opened</th>
                                    <th>Job ID</th>
                                    <th>Job Title</th>
                                    <th>No of Jobs</th>
                                    <th>Joined</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>01</td>
                                    <td>
                                        <div className="dk-cliDorpDwon">
                                            <DropdownButton id="dropdown-basic-button" title="Live">
                                                <Dropdown.Item href="#" className="dkActiveItem">Live</Dropdown.Item>
                                                <Dropdown.Item href="#" className="dkInactiveItem">Expired</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </td>
                                    <td>18 July 2021</td>
                                    <td>35076</td>
                                    <td>Senior Recruitment Consultant</td>
                                    <td>15</td>
                                    <td>
                                        <div className="dk-inputHlder">
                                        <input type="text" value="4367" placeholder="4367"/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <a className="dk-edit-btn mr-2" href="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </a>
                                            <a className="dk-delete-btn" href="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>02</td>
                                    <td>
                                        <div className="dk-cliDorpDwon">
                                            <DropdownButton id="dropdown-basic-button" title="Live">
                                                <Dropdown.Item href="#" className="dkActiveItem">Live</Dropdown.Item>
                                                <Dropdown.Item href="#" className="dkInactiveItem">Expired</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </td>
                                    <td>18 July 2021</td>
                                    <td>35076</td>
                                    <td>Senior Recruitment Consultant</td>
                                    <td>15</td>
                                    <td>
                                        <div className="dk-inputHlder">
                                        <input type="text" value="4367" placeholder="4367" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <a className="dk-edit-btn mr-2" href="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </a>
                                            <a className="dk-delete-btn" href="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>03</td>
                                    <td>
                                        <div className="dk-cliDorpDwon">
                                            <DropdownButton id="dropdown-basic-button" title="Live">
                                                <Dropdown.Item href="#" className="dkActiveItem">Live</Dropdown.Item>
                                                <Dropdown.Item href="#" className="dkInactiveItem">Expired</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </td>
                                    <td>18 July 2021</td>
                                    <td>35076</td>
                                    <td>Senior Recruitment Consultant</td>
                                    <td>15</td>
                                    <td>
                                        <div className="dk-inputHlder">
                                        <input type="text" value="4367" placeholder="4367" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <a className="dk-edit-btn mr-2" href="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </a>
                                            <a className="dk-delete-btn" href="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>04</td>
                                    <td>
                                        <div className="dk-cliDorpDwon">
                                            <DropdownButton id="dropdown-basic-button" title="Live">
                                                <Dropdown.Item href="#" className="dkActiveItem">Live</Dropdown.Item>
                                                <Dropdown.Item href="#" className="dkInactiveItem">Expired</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </td>
                                    <td>18 July 2021</td>
                                    <td>35076</td>
                                    <td>Senior Recruitment Consultant</td>
                                    <td>15</td>
                                    <td>
                                        <div className="dk-inputHlder">
                                        <input type="text" value="4367" placeholder="4367" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <a className="dk-edit-btn mr-2" href="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </a>
                                            <a className="dk-delete-btn" href="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>05</td>
                                    <td>
                                        <div className="dk-cliDorpDwon">
                                            <DropdownButton id="dropdown-basic-button" title="Live">
                                                <Dropdown.Item href="#" className="dkActiveItem">Live</Dropdown.Item>
                                                <Dropdown.Item href="#" className="dkInactiveItem">Expired</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </td>
                                    <td>18 July 2021</td>
                                    <td>35076</td>
                                    <td>Senior Recruitment Consultant</td>
                                    <td>15</td>
                                    <td>
                                        <div className="dk-inputHlder">
                                        <input type="text" value="4367" placeholder="4367" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <a className="dk-edit-btn mr-2" href="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </a>
                                            <a className="dk-delete-btn" href="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default WorkFlow;
