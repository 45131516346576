import React, { useEffect, useState } from "react";
import { SortableContainer, SortableElement, sortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from 'array-move';
import ColorPickerModal from "./ColorPickerModal";
import { useDispatch, useSelector } from "react-redux";
import { reorderValues, updateValue, deleteBkValue, clearState } from "../../../../../slice/admin/backendValues/backendValuesSlice";
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";
import DeleteModal from "../../../../ui/delete";

const DragHandle = sortableHandle(() => <span className="dk-handle" style={{ backgroundColor: "#363f4c", height: "35px !important", width: "50px", textAlign: "center", lineHeight: "35px", color: "white", cursor: "all-scroll" }}><i class="fas fa-bars"></i></span>);

const SortableItem = SortableElement(({ value }) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [rowId, setRowId] = useState('0')
    const [showDelete, setShowDelete] = useState(false)

    const [bkValue, setBkValue] = useState(value.name)

    const deleteItem = (id) => {
        setShowDelete(true)
        setRowId(id)
    }

    const handleCloseDelete = () => {
        setShowDelete(false)
        setRowId('0')
    }

    const deleteValue = async () => {
        setSuccess(false);
        setLoading(true);
        await dispatch(deleteBkValue({ id: rowId }))
    }

    const { isDelete, isError, msg } = useSelector(state => state.backendValues)

    useEffect(() => {
        if (isDelete) {
            dispatch(clearState())
            dispatch(showSuccess({ msg }))
            setShowDelete(false)
            setRowId('0')
            setSuccess(false);
            setLoading(false);
        }
        if (isError) {
            dispatch(showError({ msg }))
            dispatch(clearState())
            setLoading(false)
            setSuccess(false)
        }
    }, [isDelete, isError])

    const updateTypeValue = async (e) => {
        setBkValue(e.target.value)
        dispatch(updateValue({ id: e.target.id, name: e.target.value }))
    }

    return (
        <React.Fragment>
            {
                (showDelete) ? <DeleteModal id={rowId} loading={loading} success={success} handleClose={handleCloseDelete} deleteItem={deleteValue} /> : null
            }
            <div className="Mukul">
                <div className="dk-valueFields d-flex" key={value._id} id={value._id}>
                    <DragHandle />
                    <input type="text" id={value._id} style={{ background: value.bgColor, color: value.textColor, height: "35px", borderRadius: "0", border: "1px solid #ddd", boxShadow: "none !important" }} value={bkValue} onChange={updateTypeValue} className="form-control" />
                    <span className="dk-toolsValueDelete" onClick={() => deleteItem(value._id)} style={{ backgroundColor: "#dc3545", height: "35px", width: "50px", textAlign: "center", lineHeight: "35px", color: "white", cursor: "pointer" }}><i class="fas fa-times"></i></span>
                    <ColorPickerModal id={value._id} backgroundColor={value.bgColor} textColor={value.textColor} value={value.name} />
                </div>
            </div>
        </React.Fragment>
    )
})

const SortableList = SortableContainer(({ items }) => {
    return (
        <ul>
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} />
            ))}
        </ul>
    );
});

const ValueList = ({ dataList }) => {
    const dispatch = useDispatch();

    const [list, setList] = useState([])

    useEffect(() => {
        setList(dataList)
    }, [dataList])

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setList(arrayMoveImmutable(list, oldIndex, newIndex))
        let reorderListArray = []
        list.map((data) => {
            reorderListArray.push(data._id)
        })
        dispatch(reorderValues({ ids: reorderListArray }))
    };

    return (
        <SortableList lockAxis="y" items={list} onSortEnd={onSortEnd} useDragHandle />
    )
}
export default ValueList;