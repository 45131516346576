import React from 'react';
import  "./searchdropdown.scss";

const SearchdropDown = (props) => {
    return (
        <React.Fragment>
            <div className="dk-reSSearchIoFilter">
                <div className="SeachIoCon">
                    <div className="form-group ">
                        <input type="text" className="form-control" placeholder={props.placeholder} />
                    </div>
                </div>
                <ul className="dk-FilterItems">
                    <li>
                        <div className="leftFilterText">{props.text1}</div>
                        <span>86</span>
                    </li>
                    <li>
                        <div className="leftFilterText">{props.text2}</div>
                        <span>28</span>
                    </li>
                    <li>
                        <div className="leftFilterText">{props.text3}</div>
                        <span>56</span>
                    </li>
                </ul>
            </div>
        </React.Fragment>
    )
}

export default SearchdropDown;
