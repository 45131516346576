import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";
import { updateCategoryValue, clearState } from "../../../../../slice/admin/backendValues/backendValuesSlice";

export const List = ({ boxNo, number, itemValue, id }) => {
    const dispatch = useDispatch();

    const [value, setValue] = useState(itemValue)

    const { isUpdate, isError, msg } = useSelector(state => state.backendValues)

    const updateValue = async (id) => {
        dispatch(updateCategoryValue({ id, boxNo, name: value }))
    }

    useEffect(() => {
        if (isUpdate) {
            dispatch(clearState())
            dispatch(showSuccess({ msg }))
        }
        if (isError) {
            dispatch(clearState())
            dispatch(showError({ msg }))
        }
    }, [isUpdate, isError])

    return (
        <React.Fragment>
            <div className="d-flex align-items-center">
                <span className="dk-sno">{number}</span>
                <input type="text" value={value} name="list" className="form-control" onChange={(e) => setValue(e.target.value)} />
                <div className="dk-toolsPanel-updateIcon" onClick={() => updateValue(id)}>
                    <i class="far fa-save"></i>
                </div>
            </div>
        </React.Fragment>
    )
}
