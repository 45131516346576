import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import './pipeline.scss';

const Pipeline = () => {
    return (
        <React.Fragment>

        </React.Fragment>
    );
}

export default Pipeline;
