import React, { useState, useEffect } from 'react';
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import DatePickerCols from "../../../ui/datepicker";
import UserList from "../../../ui/userList";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { updateTeamDetails, listTable, clearState } from "../../../../slice/dkgTeam/teamSlice"
import { showSuccess, showError, clearMessage } from "../../../../slice/utils/message/messageSlice";
import { loadingStart, loadingEnd, clearLoading } from "../../../../slice/utils/loader/linearSlice";
import { IMG_CLOUD_BASE_URL } from '../../../../constants'
const EditTeamModal = ({ id, handleClose }) => {
    const dispatch = useDispatch();
    const { isSuccess, isUpdate, isDelete, isLoading, isError, msg } = useSelector(state => state.teamData)
    const { teamDetails } = useSelector(state => state.teamData)
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true)
    }, [id])

    const [teamid, setTeamid] = useState('0')
    const [userId, setUserId] = useState('0')
    const [userImg, setUserImg] = useState('')
    const [userImgName, setUserImgName] = useState('')
    const [joinOn, setJoinOn] = useState('')
    const [formJoinOn, setFormJoinOn] = useState('')
    const [memberName, setMemberName] = useState('')
    const [jobTitle, setJobTitle] = useState('')
    const [location, setLocation] = useState('')
    const [status, setStatus] = useState('')
    const [level, setLevel] = useState('')
    const [nationality, setNationality] = useState('')

    useEffect(() => {
        setTeamid(teamDetails._id)
        setUserId(teamDetails.userId._id)
        setUserImg(teamDetails.userId.userImg)
        setUserImgName(teamDetails.userId.firstName)
        setJoinOn(new Date())
        setFormJoinOn(teamDetails.joinDate)
        setMemberName(teamDetails.name)
        setJobTitle(teamDetails.jobTitle)
        setLocation(teamDetails.location)
        setStatus(teamDetails.status)
        setLevel(teamDetails.level)
        setNationality(teamDetails.nationality)
    }, [teamDetails])
    const selectDate = (date) => {
        setJoinOn(date)
        setFormJoinOn(moment(new Date(date)).format("DD MMM YYYY"));
    }
    const saveTeam = async () => {
        let name = memberName;
        let joinDate = formJoinOn;
        let id = teamid;
        if (!name || !jobTitle || userId == '0' || !location || !joinDate || !level || !nationality) {
            dispatch(showError({ msg: 'All fields required' }))
        } else {
            dispatch(loadingStart())
            await dispatch(updateTeamDetails({ id, userId, name, jobTitle, location, joinDate, level, nationality, status }))

        }
    }

    useEffect(() => {
        if (isError) {
            dispatch(clearState())
            dispatch(clearLoading())
            dispatch(showError({ msg: msg }))
        }
        if (isUpdate) {
            setShow(false);
            dispatch(showSuccess({ msg: msg }))
            dispatch(clearState())
            dispatch(clearLoading())
        }
    }, [isError, isUpdate])
    return (
        <React.Fragment>
            <Modal className="dk-levelModal dk-addNewModal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update New Member</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-AddnewModal">
                    <div className="row">
                        <input type="hidden" id="insertId" value="0" />
                        <div className="col-md-2">
                            <div className="form-group dk-projectrecruiter text-center">
                                <label for="">Member</label>
                                <input type="hidden" id="userId" value={teamDetails.userId._id} />
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        {
                                            (userId !== '0') ? <img src={IMG_CLOUD_BASE_URL + userImg} className="select-user-img" title={userImgName} /> : <i className="fas fa-user"></i>
                                        }
                                    </Dropdown.Toggle>

                                    {/* <Dropdown.Menu>
                                        <div className="d-flex">
                                            <UserList onClick={selectUser} />
                                        </div>
                                    </Dropdown.Menu> */}
                                </Dropdown>
                            </div>
                        </div>
                        <div className="col-md-4 pl-0">
                            <div className="form-group">
                                <label for="">Joined on</label>
                                <label for="" className="dateIcon">
                                    {/* <input type="text" id="memberDatePickerFrom" className="form-control joinedDate" /> */}
                                    {/* <DatePickerCols value={joinOn} onChange={selectDate}/> */}
                                    <DatePickerCols txtvalue={formJoinOn} onChange={selectDate} />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label for="">Name of Member</label>
                                <label for="">
                                    <input type="text" className="form-control membername" onChange={(e) => setMemberName(e.target.value)} value={memberName} />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label for="">Job Title</label>
                                <label for="">
                                    <input type="text" className="form-control jobTitle" onChange={(e) => setJobTitle(e.target.value)} value={jobTitle} />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group mb-2">
                                <label for="">Location</label>
                                <label for="">
                                    <input type="text" className="form-control location" onChange={(e) => setLocation(e.target.value)} value={location} />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group dk-dkgStatus">
                                <label for="">Status</label>
                                <label for="">
                                    <select value={status} className="form-control status" onChange={(e) => setStatus(e.target.value)}>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label for="">Level</label>
                                <label for="">
                                    <select value={level} className="form-control level" onChange={(e) => setLevel(e.target.value)}>
                                        <option value="-1">Select</option>
                                        <option value="Level 1">Level 1</option>
                                        <option value="Level 2">Level 2</option>
                                        <option value="Level 3">Level 3</option>
                                        <option value="Level 4">Level 4</option>
                                        <option value="Level 5">Level 5</option>
                                        <option value="Level 6">Level 6</option>
                                        <option value="Level 7">Level 7</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label for="">Nationality</label>
                                <label for="">
                                    <input type="text" className="form-control nationality" onChange={(e) => setNationality(e.target.value)} value={nationality} />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12 text-center">
                            <button className="dk-submitBtn" onClick={saveTeam}>SAVE</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default EditTeamModal;