import React from "react";
import { Route, Link } from "react-router-dom";
import Submenu from "../../subMenu/accounting";
import { useSelector } from "react-redux";
import './style.scss';

const AccountingmenuNavbar = () => {
    const { allBadge } = useSelector(state => state.tasks);
    const pathname = window.location.pathname;
    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar">
                <Route>
                    <ul>
                        <li><Link to="/accounting/dashboard" className={`${pathname.match('/dashboard') ? 'active' : ''}`}>Dashboard</Link></li>
                        <li><Link to="/accounting/clients" className={`${pathname.match('/clients') ? 'active' : ''}`}>Clients</Link></li>
                        <li><Link to="/accounting/suppliers" className={`${pathname.match('/suppliers') ? 'active' : ''}`}>Suppliers</Link></li>
                        <li><Link to="/accounting/invoices" className={`${pathname.match('/invoices') ? 'active' : ''}`}>Invoices</Link></li>
                        <li><Link to="/accounting/income" className={`${pathname.match('/income') ? 'active' : ''}`}>Income</Link></li>
                        <li><Link to="/accounting/expense" className={`${pathname.match('/expense') ? 'active' : ''}`}>Expense</Link></li>
                        <li><Link to="/accounting/p-and-l" className={`${pathname.match('/p-and-l') ? 'active' : ''}`}>P & L</Link></li>
                        <li><Link to="/accounting/cashflow" className={`${pathname.match('/cashflow') ? 'active' : ''}`}>Cashflow</Link></li>
                        <li><Link to="/accounting/overview" className={`${pathname.match('/overview') ? 'active' : ''}`}>Overview</Link></li>
                        <li><Link to="/accounting/returns" className={`${pathname.match('/returns') ? 'active' : ''}`}>Returns</Link></li>
                        <li><Link to="/accounting/settings" className={`${pathname.match('/settings') ? 'active' : ''}`}>Settings</Link></li>
                    </ul>
                </Route>
            </div>
            <Submenu path={pathname} />
        </React.Fragment>
    );
}

export default AccountingmenuNavbar;
