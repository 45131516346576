import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showError } from "../../slice/utils/message/messageSlice";
import { checkToken, genrateNewToken, clearState } from "../../slice/auth/tokenSlice"
import { logoutUser } from "../../slice/auth/authSlice";
import Metadata from "./meta";
const Authentcation = () => {

    const dispatch = useDispatch();
    const { isVerified, isRefreshed } = useSelector(state => state.token);

    useEffect(() => {
        const { token } = JSON.parse(localStorage.getItem("authentication"));
        async function tokenCheck() {
            try {
                await dispatch(checkToken({ token: token }))
            } catch (err) {
                console.log(err)
            }
        }
        tokenCheck()
    }, [])

    useEffect(() => {
        if (!isVerified) {
            const { refreshToken } = JSON.parse(localStorage.getItem("authentication"));
            async function fetchNewToken() {
                try {
                    await dispatch(genrateNewToken({ refreshToken: refreshToken }))
                } catch (err) {
                    dispatch(showError({ msg: err }))
                }
            }
            fetchNewToken()
        }
    }, [isVerified])

    useEffect(() => {
        if (!isRefreshed) {
            dispatch(clearState())
            dispatch(logoutUser({}))
        }
    }, [isRefreshed])

    const MINUTE_MS = 60000;
    useEffect(() => {
        const interval = setInterval(() => {
            console.log('Logs every minute');
        }, MINUTE_MS);
        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])

    return (
        <React.Fragment>
            <Metadata />
        </React.Fragment>
    )
}
export default Authentcation;