import React, { useMemo, useState, useEffect } from "react";
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import { useDispatch, useSelector } from "react-redux";
import { GlobalFilter } from "./GlobalFilter";
import { Link } from "react-router-dom";
import DateCalculation from '../../helpers/dateDifference';
import { IMG_CLOUD_BASE_URL } from '../../../constants';

import AddNewModal from "./modals/AddNewModal";
import EditTeamModal from "./modals/EditTeamModal";
import LevelModal from "./modals/LevelModal";
import ReorderModal from "./modals/ReorderModal";
import { fethById, teamDelete, clearState } from '../../../slice/dkgTeam/teamSlice';
import DeleteModal from "../../ui/delete";
import { showSuccess, showError, clearMessage } from "../../../slice/utils/message/messageSlice";

export const BasicTable = () => {
    const [showDelete, setShowDelete] = useState(false)
    const [isShowEdit, setIsShowEdit] = useState(false)
    const [rowId, setRowId] = useState('0')
    const dispatch = useDispatch();
    let { dataList, isDelete, isError, msg } = useSelector(state => state.teamData)
    const data = dataList

    const editPopupShow = async (teamId) => {
        await dispatch(fethById({ teamId }))
        setRowId(teamId)
        setIsShowEdit(true)
    }
    const handleClose = () => {
        setRowId('0')
        setIsShowEdit(false)
        setShowDelete(false)
    }
    const deleteList = async (dataId) => {
        setRowId(dataId)
        setShowDelete(true)
    }
    const deleteItem = async () => {
        dispatch(teamDelete({ rowId }))
    }
    useEffect(() => {
        if (isDelete) {
            dispatch(clearState())
            dispatch(clearMessage())
            dispatch(showSuccess({ msg: msg }))
            setShowDelete(false)
            setRowId('0')
        }
        if (isError) {
            // dispatch(showError({ msg: msg }))
            //dispatch(clearState())
            // dispatch(clearMessage())

        }
    }, [isDelete, isError])
    const columns = useMemo(() => [
        {
            Header: 'Sno',
            Cell: (row) => Number(row.row.id) + 1
        },
        {
            Header: 'Status',
            Cell: (row) => (
                <div className={row.row.original.status === 'Active' ? 'dk-status' : 'dk-status-inactive'}>
                    <button>{row.row.original.status}</button>
                </div>
            )
        },
        {
            Header: 'Joined On',
            accessor: 'joinDate'
        },
        {
            Header: 'Level',
            accessor: 'level'
        },
        {
            Header: 'Employee',
            Cell: (row) => (<img src={IMG_CLOUD_BASE_URL + row.row.original.userId.userImg} alt={row.row.original.name} className="img-fluid dk-userImg" />)
        },
        {
            Header: 'Name of Employee',
            accessor: 'name'
        },
        {
            Header: 'Job Title',
            accessor: 'jobTitle'
        },
        {
            Header: 'Location',
            accessor: 'location'
        },
        {
            Header: 'Nationality',
            accessor: 'nationality'
        },
        {
            Header: 'Employment Duration',
            Cell: (row) => (<DateCalculation date={row.row.original.joinDate} />)
        },
        {
            Header: 'Action',
            Cell: (row) => (
                <div className="dk-action d-flex justify-content-center">
                    {/* <EditTeamModal onClick={editPopupShow(row.row.original._id)} />      */}
                    <Link to="#" onClick={() => editPopupShow(row.row.original._id)} className="dk-edit dk-editBtn"><i className="fas fa-pencil-alt"></i></Link>
                    <Link to="#" onClick={() => deleteList(row.row.original._id)} className="dk-trash dk-deleteBtn"><i className="far fa-trash-alt"></i></Link>
                </div>
            )
        }
    ], [])

    const tableInstance = useTable({
        columns: columns,
        data: data
    }, useGlobalFilter, useSortBy)

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = tableInstance
    const { globalFilter } = state

    return (
        <React.Fragment>

            {
                (isShowEdit) ? <EditTeamModal id={rowId} handleClose={handleClose} /> : null
            }
            {
                (showDelete) ? <DeleteModal id={rowId} handleClose={handleClose} deleteItem={deleteItem} /> : null
            }

            <div className="dk-dkgTeamMain-head">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="dk-dkgHead-right">
                        <div className="dk-dkgHead-seach">
                            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                        </div>
                        <div className="dk-leveBtn">
                            <LevelModal />
                        </div>
                    </div>
                    <h3 className="title">DK GLOBAL RECRUITMENT TEAM</h3>

                    <div className="dk-dkgHead-left">
                        <AddNewModal />
                        <ReorderModal />
                    </div>

                </div>
            </div>
            <div className="dk-dkgTeamMain-table">
                <div className="table-responsive">
                    <table className="table table-bordered" {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}