import React from "react";
import '../../mainMenu/recruitment/style.scss';
import AllSubmenu from "./menu";
import ToolsSubmenu from "./menu/ToolsSubmenu";

const Submenu = ({ path }) => {

    const renderSubmenu = () => {
        switch (path.split("/")[2]) {
            case 'dashboard':
                return <AllSubmenu />
                break;

            case 'tools':
                return <ToolsSubmenu />
                break;
        }
    }

    return (
        <React.Fragment>
            {renderSubmenu()}
        </React.Fragment>
    )
}
export default Submenu;