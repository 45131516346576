import React from 'react';
import  "./summary.scss";

const SummarylongCols = (props) => {
    return (
        <React.Fragment>
            <div className="dk-reSummThreCols">
                <div className="dk-reSummThreHeader dk-reSHeaderCont78">
                    <div className="dk-filterIcon">
                        <i className="fa fa-search" aria-hidden="true"></i>
                    </div>
                    <div className="dk-reSHeaderTitle">{props.boxheaderTitle}</div>
                    <span>345</span>
                </div>
                <div className="dk-reSummTreContent">
                    <li>
                        <div>{props.text1}</div>
                        <span>156</span>
                    </li>
                    <li>
                        <div>{props.text2}</div>
                        <span>58</span>
                    </li>
                    <li>
                        <div>{props.text3}</div>
                        <span>86</span>
                    </li>
                    <li>
                        <div>{props.text4}</div>
                        <span>156</span>
                    </li>
                    <li>
                        <div>{props.text5}</div>
                        <span>58</span>
                    </li>
                    <li>
                        <div>{props.text6}</div>
                        <span>86</span>
                    </li>
                    <li>
                        <div>{props.text7}</div>
                        <span>156</span>
                    </li>
                    <li>
                        <div>{props.text8}</div>
                        <span>58</span>
                    </li>
                    <li>
                        <div>{props.text8}</div>
                        <span>86</span>
                    </li>
                    <li>
                        <div>{props.text9}</div>
                        <span>156</span>
                    </li>
                    <li>
                        <div>{props.text10}</div>
                        <span>58</span>
                    </li>
                    <li>
                        <div>{props.text11}</div>
                        <span>86</span>
                    </li>
                    <li>
                        <div>{props.text12}</div>
                        <span>156</span>
                    </li>
                    <li>
                        <div>{props.text13}</div>
                        <span>58</span>
                    </li>
                    <li>
                        <div>{props.text14}</div>
                        <span>86</span>
                    </li>
                    <li>
                        <div>{props.text15}</div>
                        <span>156</span>
                    </li>
                    <li>
                        <div>{props.text16}</div>
                        <span>156</span>
                    </li>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SummarylongCols;
