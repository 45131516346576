import React,{useEffect} from 'react';
import {Helmet} from "react-helmet";
const Meta = () =>{
    useEffect(() => {
        console.log('AA')
    },[])
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>My Title</title>
            <meta name="description" content="Helmet application" />
        </Helmet>
    )
}
export default Meta;