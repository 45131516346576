import React from "react";
import { Route, Link } from "react-router-dom";
import Submenu from "../../subMenu/advertisment/";
import { useSelector } from "react-redux";
import './style.scss';

const AdvertismentNavbar = () => {
    const { allBadge } = useSelector(state => state.tasks);
    const pathname = window.location.pathname;
    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-advertismentNavbar">
                <Route>
                    <ul>
                        <li><Link to="/advertisment/dashboard" className={`${pathname.match('/dashboard') ? 'active' : ''}`}>Dashboard</Link></li>
                        <li><Link to="/advertisment/live-ads" className={`${pathname.match('/live-ads') ? 'active' : ''}`}>Live Ads</Link></li>
                        <li><Link to="/advertisment/saved" className={`${pathname.match('/saved') ? 'active' : ''}`}>Saved</Link></li>
                        <li><Link to="/advertisment/create" className={`${pathname.match('/create') ? 'active' : ''}`}>Create</Link></li>
                        <li><Link to="/advertisment/plan" className={`${pathname.match('/plan') ? 'active' : ''}`}>Plan</Link></li>
                        <li><Link to="/advertisment/budget" className={`${pathname.match('/budget') ? 'active' : ''}`}>Budget</Link></li>
                        <li><Link to="/advertisment/credits" className={`${pathname.match('/credits') ? 'active' : ''}`}>Credits</Link></li>
                        <li><Link to="/advertisment/trackers" className={`${pathname.match('/trackers') ? 'active' : ''}`}>Trackers</Link></li>
                        <li><Link to="/advertisment/templates" className={`${pathname.match('/templates') ? 'active' : ''}`}>Templates</Link></li>
                        <li><Link to="/advertisment/resources" className={`${pathname.match('/resources') ? 'active' : ''}`}>Resources</Link></li>
                        <li><Link to="/advertisment/settings/logins" className={`${pathname.match('/settings') ? 'active' : ''}`}>Settings</Link></li>
                    </ul>
                </Route>
            </div>
            <Submenu path={pathname} />
        </React.Fragment>
    );
}

export default AdvertismentNavbar;
