import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import './style.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import HolidaysModal from './HolidaysModal';
import AddNewLeave from './AddNewLeave';

const Holidays = () => {
    return (
        <React.Fragment>
            <div className="dk-holidaysMain">
                <div className="dk-holidaysHead">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="dk-holidayLeft">
                            <Dropdown className="dk-dateDropdown">
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    2021
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">2021</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <div className="dk-holidayBtn">
                                <Dropdown className="dk-globalUserDropdown">
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <i class="fas fa-user"></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="d-flex">
                                            <Dropdown.Item href="#/action-1">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/1523633534-luiza.jpg" className="img-fluid" alt="" />
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-1">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad49d2ca540d.png" className="img-fluid" alt="" />
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-1">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5b4dce328e872.png" className="img-fluid" alt="" />
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-1">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5bd2c15416.png" className="img-fluid" alt="" />
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-1">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="img-fluid" alt="" />
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-1">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ca355dac8269.png" className="img-fluid" alt="" />
                                            </Dropdown.Item>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>

                                <HolidaysModal />
                            </div>
                        </div>
                        <h3 className="title">HOLIDAYS & DAYS OFF</h3>
                        <div className="dk-holidayRight">
                            <div className="dk-holidayRightBtn">
                                <button><i class="fas fa-info"></i></button>
                                <AddNewLeave />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="dk-holidayCalMain">
                    <div className="row">
                        <div className="col-md-2">
                            <div className="dk-holidayBox">
                                <div className="dk-dateHeading janBg">January</div>
                                <div className="dk-monthDateContainer">
                                    <ul className="d-flex dk-weekend">
                                        <li>M</li>
                                        <li>T</li>
                                        <li>W</li>
                                        <li>T</li>
                                        <li>F</li>
                                        <li>S</li>
                                        <li className="dk-sunday">S</li>
                                    </ul>
                                    <ul className="dk-holidaysDays dk-holidaysEndDays">
                                        <li id="1-Jan-2021">1</li>
                                        <li id="2-Jan-2021">2</li>
                                        <li id="3-Jan-2021">3</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="4-Jan-2021">4</li>
                                        <li id="5-Jan-2021">5</li>
                                        <li id="6-Jan-2021">6</li>
                                        <li id="7-Jan-2021">7</li>
                                        <li id="8-Jan-2021">8</li>
                                        <li id="9-Jan-2021">9</li>
                                        <li id="10-Jan-2021">10</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="11-Jan-2021">11</li>
                                        <li id="12-Jan-2021">12</li>
                                        <li id="13-Jan-2021">13</li>
                                        <li id="14-Jan-2021">14</li>
                                        <li id="15-Jan-2021">15</li>
                                        <li id="16-Jan-2021">16</li>
                                        <li id="17-Jan-2021">17</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="18-Jan-2021">18</li>
                                        <li id="19-Jan-2021">19</li>
                                        <li id="20-Jan-2021">20</li>
                                        <li id="21-Jan-2021">21</li>
                                        <li id="22-Jan-2021">22</li>
                                        <li id="23-Jan-2021">23</li>
                                        <li id="24-Jan-2021">24</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="25-Jan-2021">25</li>
                                        <li id="26-Jan-2021">26</li>
                                        <li id="27-Jan-2021">27</li>
                                        <li id="28-Jan-2021">28</li>
                                        <li id="29-Jan-2021">29</li>
                                        <li id="30-Jan-2021">30</li>
                                        <li id="31-Jan-2021">31</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="dk-holidayBox">
                                <div className="dk-dateHeading febBg">February</div>
                                <div className="dk-monthDateContainer">
                                    <ul className="d-flex dk-weekend">
                                        <li>M</li>
                                        <li>T</li>
                                        <li>W</li>
                                        <li>T</li>
                                        <li>F</li>
                                        <li>S</li>
                                        <li className="dk-sunday">S</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="1-Feb-2021">1</li>
                                        <li id="2-Feb-2021">2</li>
                                        <li id="3-Feb-2021">3</li>
                                        <li id="4-Feb-2021">4</li>
                                        <li id="5-Feb-2021">5</li>
                                        <li id="6-Feb-2021">6</li>
                                        <li id="7-Feb-2021">7</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="8-Feb-2021">8</li>
                                        <li id="9-Feb-2021">9</li>
                                        <li id="10-Feb-2021">10</li>
                                        <li id="11-Feb-2021">11</li>
                                        <li id="12-Feb-2021">12</li>
                                        <li id="13-Feb-2021">13</li>
                                        <li id="14-Feb-2021">14</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="15-Feb-2021">15</li>
                                        <li id="16-Feb-2021">16</li>
                                        <li id="17-Feb-2021">17</li>
                                        <li id="18-Feb-2021">18</li>
                                        <li id="19-Feb-2021">19</li>
                                        <li id="20-Feb-2021">20</li>
                                        <li id="21-Feb-2021">21</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="22-Feb-2021">22</li>
                                        <li id="23-Feb-2021">23</li>
                                        <li id="24-Feb-2021">24</li>
                                        <li id="25-Feb-2021">25</li>
                                        <li id="26-Feb-2021">26</li>
                                        <li id="27-Feb-2021">27</li>
                                        <li id="28-Feb-2021">28</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="dk-holidayBox">
                                <div className="dk-dateHeading marBg">March</div>
                                <div className="dk-monthDateContainer">
                                    <ul className="d-flex dk-weekend">
                                        <li>M</li>
                                        <li>T</li>
                                        <li>W</li>
                                        <li>T</li>
                                        <li>F</li>
                                        <li>S</li>
                                        <li className="dk-sunday">S</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="1-Mar-2021">1</li>
                                        <li id="2-Mar-2021">2</li>
                                        <li id="3-Mar-2021">3</li>
                                        <li id="4-Mar-2021">4</li>
                                        <li id="5-Mar-2021">5</li>
                                        <li id="6-Mar-2021">6</li>
                                        <li id="7-Mar-2021">7</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="8-Mar-2021">8</li>
                                        <li id="9-Mar-2021">9</li>
                                        <li id="10-Mar-2021">10</li>
                                        <li id="11-Mar-2021">11</li>
                                        <li id="12-Mar-2021">12</li>
                                        <li id="13-Mar-2021">13</li>
                                        <li id="14-Mar-2021">14</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="15-Mar-2021">15</li>
                                        <li id="16-Mar-2021">16</li>
                                        <li id="17-Mar-2021">17</li>
                                        <li id="18-Mar-2021">18</li>
                                        <li id="19-Mar-2021">19</li>
                                        <li id="20-Mar-2021">20</li>
                                        <li id="21-Mar-2021">21</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="22-Mar-2021">22</li>
                                        <li id="23-Mar-2021">23</li>
                                        <li id="24-Mar-2021">24</li>
                                        <li id="25-Mar-2021">25</li>
                                        <li id="26-Mar-2021">26</li>
                                        <li id="27-Mar-2021">27</li>
                                        <li id="28-Mar-2021">28</li>
                                    </ul>
                                    <ul className="dk-holidaysStartDays">
                                        <li id="22-Mar-2021">29</li>
                                        <li id="23-Mar-2021">30</li>
                                        <li id="24-Mar-2021">31</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="dk-holidayBox">
                                <div className="dk-dateHeading aprBg">April</div>
                                <div className="dk-monthDateContainer">
                                    <ul className="d-flex dk-weekend">
                                        <li>M</li>
                                        <li>T</li>
                                        <li>W</li>
                                        <li>T</li>
                                        <li>F</li>
                                        <li>S</li>
                                        <li className="dk-sunday">S</li>
                                    </ul>
                                    <ul className="dk-holidaysDays dk-holidaysEndDays">
                                        <li id="1-April-2021">1</li>
                                        <li id="2-April-2021">2</li>
                                        <li id="3-April-2021">3</li>
                                        <li id="4-April-2021">4</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="5-April-2021">5</li>
                                        <li id="6-April-2021">6</li>
                                        <li id="7-April-2021">7</li>
                                        <li id="8-April-2021">8</li>
                                        <li id="9-April-2021">9</li>
                                        <li id="10-April-2021">10</li>
                                        <li id="11-April-2021">11</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="12-April-2021">12</li>
                                        <li id="13-April-2021">13</li>
                                        <li id="14-April-2021">14</li>
                                        <li id="15-April-2021">15</li>
                                        <li id="16-April-2021">16</li>
                                        <li id="17-April-2021">17</li>
                                        <li id="18-April-2021">18</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="19-April-2021">19</li>
                                        <li id="20-April-2021">20</li>
                                        <li id="21-April-2021">21</li>
                                        <li id="22-April-2021">22</li>
                                        <li id="23-April-2021">23</li>
                                        <li id="24-April-2021">24</li>
                                        <li id="25-April-2021">25</li>
                                    </ul>
                                    <ul className="dk-holidaysStartDays">
                                        <li id="26-April-2021">26</li>
                                        <li id="27-April-2021">27</li>
                                        <li id="28-April-2021">28</li>
                                        <li id="29-April-2021">29</li>
                                        <li id="30-April-2021">30</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="dk-holidayBox">
                                <div className="dk-dateHeading mayBg">May</div>
                                <div className="dk-monthDateContainer">
                                    <ul className="d-flex dk-weekend">
                                        <li>M</li>
                                        <li>T</li>
                                        <li>W</li>
                                        <li>T</li>
                                        <li>F</li>
                                        <li>S</li>
                                        <li className="dk-sunday">S</li>
                                    </ul>
                                    <ul className="dk-holidaysDays dk-holidaysEndDays">
                                        <li id="1-May-2021">1</li>
                                        <li id="2-May-2021">2</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="3-May-2021">3</li>
                                        <li id="4-May-2021">4</li>
                                        <li id="5-May-2021">5</li>
                                        <li id="6-May-2021">6</li>
                                        <li id="7-May-2021">7</li>
                                        <li id="8-May-2021">8</li>
                                        <li id="9-May-2021">9</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="10-May-2021">10</li>
                                        <li id="11-May-2021">11</li>
                                        <li id="12-May-2021">12</li>
                                        <li id="13-May-2021">13</li>
                                        <li id="14-May-2021">14</li>
                                        <li id="15-May-2021">15</li>
                                        <li id="16-May-2021">16</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="17-May-2021">17</li>
                                        <li id="18-May-2021">18</li>
                                        <li id="19-May-2021">19</li>
                                        <li id="20-May-2021">20</li>
                                        <li id="21-May-2021">21</li>
                                        <li id="22-May-2021">22</li>
                                        <li id="23-May-2021">23</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="24-May-2021">24</li>
                                        <li id="25-May-2021">25</li>
                                        <li id="26-May-2021">26</li>
                                        <li id="27-May-2021">27</li>
                                        <li id="28-May-2021">28</li>
                                        <li id="29-May-2021">29</li>
                                        <li id="30-May-2021">30</li>
                                    </ul>
                                    <ul className="dk-holidaysStartDays">
                                        <li id="31-May-2021">31</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="dk-holidayBox">
                                <div className="dk-dateHeading junBg">June</div>
                                <div className="dk-monthDateContainer">
                                    <ul className="d-flex dk-weekend">
                                        <li>M</li>
                                        <li>T</li>
                                        <li>W</li>
                                        <li>T</li>
                                        <li>F</li>
                                        <li>S</li>
                                        <li className="dk-sunday">S</li>
                                    </ul>
                                    <ul className="dk-holidaysDays dk-holidaysEndDays">
                                        <li id="1-June-2021">1</li>
                                        <li id="2-June-2021" className="markHolidayActive"><span>2</span></li>
                                        <li id="3-June-2021">3</li>
                                        <li id="4-June-2021">4</li>
                                        <li id="5-June-2021">5</li>
                                        <li id="6-June-2021">6</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="7-June-2021">7</li>
                                        <li id="8-June-2021">8</li>
                                        <li id="9-June-2021">9</li>
                                        <li id="10-June-2021">10</li>
                                        <li id="11-June-2021" className="markHolidayActive"><span>11</span></li>
                                        <li id="12-June-2021" className="markHolidayActive"><span>12</span></li>
                                        <li id="13-June-2021">13</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="14-June-2021">14</li>
                                        <li id="15-June-2021" className="markHolidayActive"><span>15</span></li>
                                        <li id="16-June-2021">16</li>
                                        <li id="17-June-2021">17</li>
                                        <li id="18-June-2021" className="markHolidayActive"><span>18</span></li>
                                        <li id="19-June-2021">19</li>
                                        <li id="20-June-2021">20</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="21-June-2021">21</li>
                                        <li id="22-June-2021">22</li>
                                        <li id="23-June-2021">23</li>
                                        <li id="24-June-2021">24</li>
                                        <li id="25-June-2021">25</li>
                                        <li id="26-June-2021">26</li>
                                        <li id="27-June-2021">27</li>
                                    </ul>
                                    <ul className="dk-holidaysStartDays">
                                        <li id="28-June-2021">28</li>
                                        <li id="29-June-2021">29</li>
                                        <li id="30-June-2021">30</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="dk-holidayBox">
                                <div className="dk-dateHeading julBg">July</div>
                                <div className="dk-monthDateContainer">
                                    <ul className="d-flex dk-weekend">
                                        <li>M</li>
                                        <li>T</li>
                                        <li>W</li>
                                        <li>T</li>
                                        <li>F</li>
                                        <li>S</li>
                                        <li className="dk-sunday">S</li>
                                    </ul>
                                    <ul className="dk-holidaysDays dk-holidaysEndDays">
                                        <li id="1-July-2021" className="markHolidayActive"><span>1</span></li>
                                        <li id="2-July-2021">2</li>
                                        <li id="3-July-2021">3</li>
                                        <li id="4-July-2021">4</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="5-July-2021">5</li>
                                        <li id="6-July-2021">6</li>
                                        <li id="7-July-2021" className="markHolidayActive"><span>7</span></li>
                                        <li id="8-July-2021">8</li>
                                        <li id="9-July-2021" className="markHolidayActive"><span>9</span></li>
                                        <li id="10-July-2021">10</li>
                                        <li id="11-July-2021">11</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="12-July-2021">12</li>
                                        <li id="13-July-2021">13</li>
                                        <li id="14-July-2021">14</li>
                                        <li id="15-July-2021">15</li>
                                        <li id="16-July-2021">16</li>
                                        <li id="17-July-2021">17</li>
                                        <li id="18-July-2021">18</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="19-July-2021" className="markHolidayActive"><span>19</span></li>
                                        <li id="20-July-2021">20</li>
                                        <li id="21-July-2021">21</li>
                                        <li id="22-July-2021">22</li>
                                        <li id="23-July-2021">23</li>
                                        <li id="24-July-2021">24</li>
                                        <li id="25-July-2021">25</li>
                                    </ul>
                                    <ul className="dk-holidaysStartDays">
                                        <li id="26-July-2021">26</li>
                                        <li id="27-July-2021" className="markHolidayActive"><span>27</span></li>
                                        <li id="28-July-2021">28</li>
                                        <li id="29-July-2021">29</li>
                                        <li id="30-July-2021">30</li>
                                        <li id="31-July-2021">31</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="dk-holidayBox">
                                <div className="dk-dateHeading augBg">August</div>
                                <div className="dk-monthDateContainer">
                                    <ul className="d-flex dk-weekend">
                                        <li>M</li>
                                        <li>T</li>
                                        <li>W</li>
                                        <li>T</li>
                                        <li>F</li>
                                        <li>S</li>
                                        <li className="dk-sunday">S</li>
                                    </ul>
                                    <ul className="dk-holidaysDays dk-holidaysEndDays">
                                        <li id="1-Aug-2021">1</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="2-Aug-2021">2</li>
                                        <li id="3-Aug-2021">3</li>
                                        <li id="4-Aug-2021">4</li>
                                        <li id="5-Aug-2021">5</li>
                                        <li id="6-Aug-2021">6</li>
                                        <li id="7-Aug-2021">7</li>
                                        <li id="8-Aug-2021" className="markHolidayActive"><span>8</span></li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="9-Aug-2021" className="markHolidayActive"><span>9</span></li>
                                        <li id="10-Aug-2021">10</li>
                                        <li id="11-Aug-2021">11</li>
                                        <li id="12-Aug-2021">12</li>
                                        <li id="13-Aug-2021">13</li>
                                        <li id="14-Aug-2021">14</li>
                                        <li id="15-Aug-2021">15</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="16-Aug-2021">16</li>
                                        <li id="17-Aug-2021">17</li>
                                        <li id="18-Aug-2021">18</li>
                                        <li id="19-Aug-2021">19</li>
                                        <li id="20-Aug-2021">20</li>
                                        <li id="21-Aug-2021">21</li>
                                        <li id="22-Aug-2021">22</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="23-Aug-2021">23</li>
                                        <li id="24-Aug-2021">24</li>
                                        <li id="25-Aug-2021">25</li>
                                        <li id="26-Aug-2021">26</li>
                                        <li id="27-Aug-2021">27</li>
                                        <li id="28-Aug-2021">28</li>
                                        <li id="29-Aug-2021">29</li>
                                    </ul>
                                    <ul className="dk-holidaysStartDays">
                                        <li id="23-Aug-2021">30</li>
                                        <li id="24-Aug-2021">31</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="dk-holidayBox">
                                <div className="dk-dateHeading sepBg">September</div>
                                <div className="dk-monthDateContainer">
                                    <ul className="d-flex dk-weekend">
                                        <li>M</li>
                                        <li>T</li>
                                        <li>W</li>
                                        <li>T</li>
                                        <li>F</li>
                                        <li>S</li>
                                        <li className="dk-sunday">S</li>
                                    </ul>
                                    <ul className="dk-holidaysDays dk-holidaysEndDays">
                                        <li id="1-Sep-2021">1</li>
                                        <li id="2-Sep-2021">2</li>
                                        <li id="3-Sep-2021">3</li>
                                        <li id="4-Sep-2021">4</li>
                                        <li id="5-Sep-2021">5</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="6-Sep-2021">6</li>
                                        <li id="7-Sep-2021">7</li>
                                        <li id="8-Sep-2021">8</li>
                                        <li id="9-Sep-2021">9</li>
                                        <li id="10-Sep-2021">10</li>
                                        <li id="11-Sep-2021">11</li>
                                        <li id="12-Sep-2021">12</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="13-Sep-2021">13</li>
                                        <li id="14-Sep-2021">14</li>
                                        <li id="15-Sep-2021">15</li>
                                        <li id="16-Sep-2021">16</li>
                                        <li id="17-Sep-2021">17</li>
                                        <li id="18-Sep-2021">18</li>
                                        <li id="19-Sep-2021">19</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="20-Sep-2021">20</li>
                                        <li id="21-Sep-2021">21</li>
                                        <li id="22-Sep-2021">22</li>
                                        <li id="23-Sep-2021">23</li>
                                        <li id="24-Sep-2021">24</li>
                                        <li id="25-Sep-2021">25</li>
                                        <li id="26-Sep-2021">26</li>
                                    </ul>
                                    <ul className="dk-holidaysStartDays">
                                        <li id="23-Sep-2021">27</li>
                                        <li id="24-Sep-2021">28</li>
                                        <li id="25-Sep-2021">29</li>
                                        <li id="26-Sep-2021">30</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="dk-holidayBox">
                                <div className="dk-dateHeading octBg">October</div>
                                <div className="dk-monthDateContainer">
                                    <ul className="d-flex dk-weekend">
                                        <li>M</li>
                                        <li>T</li>
                                        <li>W</li>
                                        <li>T</li>
                                        <li>F</li>
                                        <li>S</li>
                                        <li className="dk-sunday">S</li>
                                    </ul>
                                    <ul className="dk-holidaysDays dk-holidaysEndDays">
                                        <li id="1-Oct-2021">1</li>
                                        <li id="2-Oct-2021">2</li>
                                        <li id="3-Oct-2021">3</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="4-Oct-2021">4</li>
                                        <li id="5-Oct-2021">5</li>
                                        <li id="6-Oct-2021">6</li>
                                        <li id="7-Oct-2021">7</li>
                                        <li id="8-Oct-2021">8</li>
                                        <li id="9-Oct-2021">8</li>
                                        <li id="10-Oct-2021">10</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="11-Oct-2021">11</li>
                                        <li id="12-Oct-2021">12</li>
                                        <li id="13-Oct-2021">13</li>
                                        <li id="14-Oct-2021">14</li>
                                        <li id="15-Oct-2021">15</li>
                                        <li id="16-Oct-2021">16</li>
                                        <li id="17-Oct-2021">17</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="18-Oct-2021">18</li>
                                        <li id="19-Oct-2021">19</li>
                                        <li id="20-Oct-2021">20</li>
                                        <li id="21-Oct-2021">21</li>
                                        <li id="22-Oct-2021">22</li>
                                        <li id="23-Oct-2021">23</li>
                                        <li id="24-Oct-2021">24</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="25-Oct-2021">25</li>
                                        <li id="26-Oct-2021">26</li>
                                        <li id="27-Oct-2021">27</li>
                                        <li id="27-Oct-2021">28</li>
                                        <li id="29-Oct-2021">29</li>
                                        <li id="30-Oct-2021">30</li>
                                        <li id="31-Oct-2021">31</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="dk-holidayBox">
                                <div className="dk-dateHeading novBg">November</div>
                                <div className="dk-monthDateContainer">
                                    <ul className="d-flex dk-weekend">
                                        <li>M</li>
                                        <li>T</li>
                                        <li>W</li>
                                        <li>T</li>
                                        <li>F</li>
                                        <li>S</li>
                                        <li className="dk-sunday">S</li>
                                    </ul>
                                    <ul className="dk-holidaysDays dk-holidaysEndDays">
                                        <li id="1-Nov-2021">1</li>
                                        <li id="2-Nov-2021">2</li>
                                        <li id="3-Nov-2021">3</li>
                                        <li id="4-Nov-2021">4</li>
                                        <li id="5-Nov-2021">5</li>
                                        <li id="6-Nov-2021">6</li>
                                        <li id="7-Nov-2021">7</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="8-Nov-2021">8</li>
                                        <li id="9-Nov-2021">9</li>
                                        <li id="10-Nov-2021">10</li>
                                        <li id="11-Nov-2021">11</li>
                                        <li id="12-Nov-2021">12</li>
                                        <li id="13-Nov-2021">13</li>
                                        <li id="14-Nov-2021">14</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="15-Nov-2021">15</li>
                                        <li id="16-Nov-2021">16</li>
                                        <li id="17-Nov-2021">17</li>
                                        <li id="18-Nov-2021">18</li>
                                        <li id="19-Nov-2021">19</li>
                                        <li id="20-Nov-2021">20</li>
                                        <li id="21-Nov-2021">21</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="22-Nov-2021">22</li>
                                        <li id="23-Nov-2021">23</li>
                                        <li id="24-Nov-2021">24</li>
                                        <li id="25-Nov-2021">25</li>
                                        <li id="26-Nov-2021">26</li>
                                        <li id="27-Nov-2021">27</li>
                                        <li id="28-Nov-2021">28</li>
                                    </ul>
                                    <ul className="dk-holidaysStartDays">
                                        <li id="29-Nov-2021">29</li>
                                        <li id="30-Nov-2021">30</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="dk-holidayBox">
                                <div className="dk-dateHeading novBg">December</div>
                                <div className="dk-monthDateContainer">
                                    <ul className="d-flex dk-weekend">
                                        <li>M</li>
                                        <li>T</li>
                                        <li>W</li>
                                        <li>T</li>
                                        <li>F</li>
                                        <li>S</li>
                                        <li className="dk-sunday">S</li>
                                    </ul>
                                    <ul className="dk-holidaysDays dk-holidaysEndDays">
                                        <li id="1-Dec-2021">1</li>
                                        <li id="2-Dec-2021">2</li>
                                        <li id="3-Dec-2021">3</li>
                                        <li id="4-Dec-2021">4</li>
                                        <li id="5-Dec-2021">5</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="6-Dec-2021">6</li>
                                        <li id="7-Dec-2021">7</li>
                                        <li id="8-Dec-2021">8</li>
                                        <li id="9-Dec-2021">9</li>
                                        <li id="10-Dec-2021">10</li>
                                        <li id="11-Dec-2021">11</li>
                                        <li id="12-Dec-2021">12</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="13-Dec-2021">13</li>
                                        <li id="14-Dec-2021">14</li>
                                        <li id="15-Dec-2021">15</li>
                                        <li id="16-Dec-2021">16</li>
                                        <li id="17-Dec-2021">17</li>
                                        <li id="18-Dec-2021">18</li>
                                        <li id="19-Dec-2021">19</li>
                                    </ul>
                                    <ul className="dk-holidaysDays">
                                        <li id="20-Dec-2021">20</li>
                                        <li id="21-Dec-2021">21</li>
                                        <li id="22-Dec-2021">22</li>
                                        <li id="23-Dec-2021">23</li>
                                        <li id="24-Dec-2021">24</li>
                                        <li id="25-Dec-2021">25</li>
                                        <li id="26-Dec-2021">26</li>
                                    </ul>
                                    <ul className="dk-holidaysStartDays">
                                        <li id="27-Dec-2021">27</li>
                                        <li id="28-Dec-2021">28</li>
                                        <li id="29-Dec-2021">29</li>
                                        <li id="30-Dec-2021">30</li>
                                        <li id="31-Dec-2021">31</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Holidays;
