import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from "react-redux";
const LoginRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticate } = useSelector(state => state.auth)
    return (
        <Route {...rest} render={props => {
            if (isAuthenticate)
                return <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
            // return <Component {...props} />
            return window.location.assign(process.env.REACT_APP_REDIRECT_LOGOUT_URL);
        }} />
    )
};
export default LoginRoute;