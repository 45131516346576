import React from 'react';

const Social = () => {
    return (
        <React.Fragment>
               <div className="row pr-3 mt-2">
                    <div className="col-md-8">
                        <div className="dkcli-socialMCon">
                            <div className="row pr-3">
                                <div className="form-group dk-CliDetailSocailView d-flex w-100">
                                    <span className="dk-cliSocailMAddonIcon">
                                        <i className="fa fa-globe" aria-hidden="true"></i>
                                    </span>
                                    <input type="text" className="form-control" placeholder="" />
                                    <span className="dk-cliSocailMAddonBtn">
                                    <button>Open</button>
                                    </span>
                                </div>
                                <div className="form-group dk-CliDetailSocailView d-flex w-100">
                                    <span className="dk-cliSocailMAddonIcon">
                                        <i className="fa fa-globe" aria-hidden="true"></i>
                                    </span>
                                    <input type="text" className="form-control" placeholder="" />
                                    <span className="dk-cliSocailMAddonBtn">
                                    <button>Open</button>
                                    </span>
                                </div>
                                <div className="form-group dk-CliDetailSocailView d-flex w-100">
                                    <span className="dk-cliSocailMAddonIcon">
                                       <i className="fab fa-facebook-square"></i>
                                    </span>
                                    <input type="text" className="form-control" placeholder="" />
                                    <span className="dk-cliSocailMAddonBtn">
                                    <button>Open</button>
                                    </span>
                                </div>
                                <div className="form-group dk-CliDetailSocailView d-flex w-100">
                                    <span className="dk-cliSocailMAddonIcon">
                                        <i className="fab fa-linkedin"></i>
                                    </span>
                                    <input type="text" className="form-control" placeholder="" />
                                    <span className="dk-cliSocailMAddonBtn">
                                    <button>Open</button>
                                    </span>
                                </div>
                                <div className="form-group dk-CliDetailSocailView d-flex w-100">
                                    <span className="dk-cliSocailMAddonIcon">
                                       <i className="fab fa-instagram-square"></i>
                                    </span>
                                    <input type="text" className="form-control" placeholder="" />
                                    <span className="dk-cliSocailMAddonBtn">
                                    <button>Open</button>
                                    </span>
                                </div>
                                <div className="form-group dk-CliDetailSocailView d-flex w-100">
                                    <span className="dk-cliSocailMAddonIcon">
                                       <i className="fab fa-twitter-square"></i>
                                    </span>
                                    <input type="text" className="form-control" placeholder="" />
                                    <span className="dk-cliSocailMAddonBtn">
                                    <button>Open</button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 pl-3">
                        <div className="dk-socailMrightCon mb-4">
                            <div className="dk-socailHeader">
                                <h2>Company Addresses</h2>
                                <a id="" href="#" className=" dk-topTbleIcon">
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                </a>
                            </div>
                            <div className="dk-socailConten">
                                <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            </div>
                        </div>
                    </div>
                </div>
        </React.Fragment>
    )
}

export default Social;
