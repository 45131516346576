import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import Authentication from "../../helpers/authenticate";
import { listTable, clearState } from '../../../slice/dkgTeam/teamSlice';
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from '../../../slice/utils/message/messageSlice'
import { BasicTable } from "./BasicTable";

const DkgTeam = () => {
    const dispatch = useDispatch();
    const { dataList } = useSelector(state => state.teamData)
    const { _id } = useSelector(state => state.auth.userInfo.companyId)
    const companyId = _id;

    useEffect(() => {
        dispatch(clearMessage())
        dispatch(clearState())
    }, [])

    useEffect(() => {
        async function fetchData() {
            try {
                await dispatch(listTable({ companyId }))
            } catch (err) {
                dispatch(showError({ msg: err }))
            }
        }
        fetchData()
    }, [dispatch])

    return (

        <React.Fragment>
            <Authentication />
            <div className="dk-dkgTeamMain">
                {
                    (dataList && dataList.length > -1) ? <BasicTable /> : null
                }
            </div>
        </React.Fragment>
    )
}
export default DkgTeam;