import React, { useState } from 'react';
import {Link} from "react-router-dom";
import "./incometax.scss"
import AddnewModal from "./modal/addnew/AddnewModal";
import ReorderModal from "./modal/reorder/ReorderModal";
// import ViewModal from "./modal/view/ViewModal";
import EditModal from "./modal/edit/EditModal";
import DeleteModal from "../../../ui/deletemodal/DeleteModal";

const IncomeTax = () => {
    const [addNewResorce, setaddNewResorce] = useState(false);
    const [isReorder, setisReorder] = useState(false);
    // const [isViewModal, setisViewModal] = useState(false);
    const [isEditModal, setisEditModal] = useState(false);
    const [isDeleteModal, setisDeleteModal] = useState(false);
    const showAddNewResource = () =>{
        setaddNewResorce(true)   
    }
    const hideAddNewResource = () =>{
        setaddNewResorce(false)
    }
    const showReorder = () =>{
        setisReorder(true)   
    }
    const hideReorder = () =>{
        setisReorder(false)
    }
//     const showviewModal = () =>{
//         setisViewModal(true)
//    }
//    const hideviewModal = () =>{
//        setisViewModal(false)
//    }
    const showeditModal = () =>{
         setisEditModal(true)
    }
    const hideeditModal = () =>{
        setisEditModal(false)
    }
    const showDeletemodal  = () =>{
        setisDeleteModal(true)
    }
    const hideDeletemodal = () =>{
        setisDeleteModal(false)
    }
    const category = [
        { key: 'category_1', value: 'category_1', text: 'Portal Logins' },
        { key: 'category_2', value: 'category_2', text: 'Account Logins' },
        { key: 'category_3', value: 'category_3', text: 'Hosting Ireland' },
        { key: 'category_4', value: 'category_4', text: 'Sitel' },
        { key: 'category_5', value: 'category_5', text: 'Wipro' },
        { key: 'category_6', value: 'category_5', text: 'Godaddy' },
        { key: 'category_7', value: 'category_5', text: 'SMS' }
    ]
    const sourcetype = [
        { key: 'sourcetype_1', value: 'sourcetype_1', text: 'Clients Portals' },
        { key: 'sourcetype_2', value: 'sourcetype_2', text: 'Communication' },
        { key: 'sourcetype_3', value: 'sourcetype_3', text: 'Job Board' },
        { key: 'sourcetype_4', value: 'sourcetype_4', text: 'Social Media' },
        { key: 'sourcetype_5', value: 'sourcetype_5', text: 'Domain Hosting' }
    ]
    return (
        <React.Fragment>
            {
                (addNewResorce) ? <AddnewModal onHide={hideAddNewResource} />:null
            }
            {
                (isReorder) ? <ReorderModal onHide={hideReorder} />:null
            }
            {/* {
                (isViewModal) ? <ViewModal  onHide={hideviewModal} />: null
            } */}
            {
                (isEditModal) ? <EditModal  onHide={hideeditModal} />:null
            }
            {
                (isDeleteModal) ? <DeleteModal onHide={hideDeletemodal}  />:null
            }
            <div className="container-fluid dk-resIncmetaxPageMain">
                <div className="row pl-3 pr-3">
                    <div className="col-md-4 col-sm-12">
                        <div className="dk-reSLeftheaderCont d-flex">
                            <span className="d-flex">
                                <input id="srchterm" type="text" className="form-control dk-topSerchInput" placeholder="Search..." value="" />
                                <div className="input-group-append dk-toplefSerchIcon">
                                    <button className="ml-0 mr-0" type="button"><i className="fa fa-search"></i></button>
                                </div>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="dk-reSCenterheaderCont">
                            <h2 className="dk-reSPageTitle">INCOME TAX %</h2>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 pr-0">
                        <div className="dk-reSRightheaderCont">
                            <div className="d-flex dk-resTopRightBtnCont">
                                <Link  className="dk-projRightbtn ml-2" onClick={showAddNewResource}>
                                    <i className="fa fa-plus"></i>
                                </Link>
                                <Link  className="dk-projRightbtn ml-2" onClick={showReorder}>
                                    <i className="fa fa-arrows-alt mr-2"></i> REORDER
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="dk-ResloginTableCon table-responsive mt-3">
                            <div className="table dk-ResloginTable">
                                <thead>
                                    <tr>
                                        <th>Country</th>
                                        <th>Currency</th>
                                        <th>Income Upto</th>
                                        <th>Basic Income Tax</th>
                                        <th>Others Tax</th>
                                        <th>Total Tax</th>
                                        <th>Net Income</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="dk-inComeTableIcn">
                                                <img class="person-imageFlex" src="https://d3eppau9to224z.cloudfront.net/resources/1624630900342.png" />
                                                <div class="iconwithTitle">AUSTRIA</div>
                                            </div>
                                        </td>
                                        <td>
                                            $
                                        </td>
                                        <td>1%</td>
                                        <td>1%</td>
                                        <td>1%</td>
                                        <td>1%</td>
                                        <td>
                                            <Link to="#" className="dk-calCulateBtn">
                                                Calculate
                                            </Link>
                                        </td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2">
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>   
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="dk-inComeTableIcn">
                                                <img class="person-imageFlex" src="https://d3eppau9to224z.cloudfront.net/resources/1624630900342.png" />
                                                <div class="iconwithTitle">AUSTRIA</div>
                                            </div>
                                        </td>
                                        <td>
                                            $
                                        </td>
                                        <td>1%</td>
                                        <td>1%</td>
                                        <td>1%</td>
                                        <td>1%</td>
                                        <td>
                                            <Link to="#" className="dk-calCulateBtn">
                                                Calculate
                                            </Link>
                                        </td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2">
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>   
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="dk-inComeTableIcn">
                                                <img class="person-imageFlex" src="https://d3eppau9to224z.cloudfront.net/resources/1624630900342.png" />
                                                <div class="iconwithTitle">AUSTRIA</div>
                                            </div>
                                        </td>
                                        <td>
                                            $
                                        </td>
                                        <td>1%</td>
                                        <td>1%</td>
                                        <td>1%</td>
                                        <td>1%</td>
                                        <td>
                                            <Link to="#" className="dk-calCulateBtn">
                                                Calculate
                                            </Link>
                                        </td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2">
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>   
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="dk-inComeTableIcn">
                                                <img class="person-imageFlex" src="https://d3eppau9to224z.cloudfront.net/resources/1624630900342.png" />
                                                <div class="iconwithTitle">AUSTRIA</div>
                                            </div>
                                        </td>
                                        <td>
                                            $
                                        </td>
                                        <td>1%</td>
                                        <td>1%</td>
                                        <td>1%</td>
                                        <td>1%</td>
                                        <td>
                                            <Link to="#" className="dk-calCulateBtn">
                                                Calculate
                                            </Link>
                                        </td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2">
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>   
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="dk-inComeTableIcn">
                                                <img class="person-imageFlex" src="https://d3eppau9to224z.cloudfront.net/resources/1624630900342.png" />
                                                <div class="iconwithTitle">AUSTRIA</div>
                                            </div>
                                        </td>
                                        <td>
                                            $
                                        </td>
                                        <td>1%</td>
                                        <td>1%</td>
                                        <td>1%</td>
                                        <td>1%</td>
                                        <td>
                                            <Link to="#" className="dk-calCulateBtn">
                                                Calculate
                                            </Link>
                                        </td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2">
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>   
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="dk-inComeTableIcn">
                                                <img class="person-imageFlex" src="https://d3eppau9to224z.cloudfront.net/resources/1624630900342.png" />
                                                <div class="iconwithTitle">AUSTRIA</div>
                                            </div>
                                        </td>
                                        <td>
                                            $
                                        </td>
                                        <td>1%</td>
                                        <td>1%</td>
                                        <td>1%</td>
                                        <td>1%</td>
                                        <td>
                                            <Link to="#" className="dk-calCulateBtn">
                                                Calculate
                                            </Link>
                                        </td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2">
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>   
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="dk-inComeTableIcn">
                                                <img class="person-imageFlex" src="https://d3eppau9to224z.cloudfront.net/resources/1624630900342.png" />
                                                <div class="iconwithTitle">AUSTRIA</div>
                                            </div>
                                        </td>
                                        <td>
                                            $
                                        </td>
                                        <td>1%</td>
                                        <td>1%</td>
                                        <td>1%</td>
                                        <td>1%</td>
                                        <td>
                                            <Link to="#" className="dk-calCulateBtn">
                                                Calculate
                                            </Link>
                                        </td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2">
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>   
                                        </td>
                                    </tr>
                                </tbody>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default IncomeTax;
