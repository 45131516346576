import React from 'react'
import "./style.scss";
import { useFileUpload } from "use-file-upload";

const FileUpload = () => {
    const defaultSrc ="https://www.pngkit.com/png/full/301-3012694_account-user-profile-avatar-comments-fa-user-circle.png";
    const [files, selectFiles] = useFileUpload();
    return (
        <React.Fragment>
            <div className="dk-FileUploadCon">
                <div className="dk-fileUldimgCon">
                {
                    (files?.source) ? <img src={files?.source} alt="preview" /> : <h3>browse Image</h3>
                }
                </div>
                <button
                onClick={() =>
                selectFiles({ accept: "image/*" }, ({ name, size, source, file }) => {
                    // console.log("Files Selected", { name, size, source, file });
                })
                }
                >
                  <i class="fas fa-upload mr-2"></i>  Upload 
                </button>
            </div>
            
        </React.Fragment>
    )
}

export default FileUpload;
