import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
/** Import All Loyouts */
import RecruitementLayout from "../layout/RecruitementLayout";
import PurchaseLayout from "../layout/PurchaseLayout";
import CommunicationLayout from "../layout/CommunicationLayout";
import SupportLayout from "../layout/SupportLayout";
import MarketingLayout from "../layout/MarketingLayout";
import LeadsManagementLayout from "../layout/LeadsManagement";
import AccountingLayout from "../layout/AccountingLayout";
import FinanceLayout from "../layout/FinanceLayout";
import HrLayout from "../layout/HRLayoute";
import TrainingLayout from "../layout/TrainingLayout";
import ResourcesLayout from "../layout/ResourcesLayout";
import ReportLayout from "../layout/ReportLayout";
import SalesLayout from "../layout/SalesLayout";
import AdvertismentLayout from "../layout/AdvertisementLayout";
import InformationCenterLayout from "../layout/InformationCenterLayout";
import TasksLayout from "../layout/TasksLayout";
import ProjectsLayout from "../layout/ProjectsLayout";
import MyFilesLayout from "../layout/MyFilesLayout";
/** End Import All Loyouts */
import { useDispatch, useSelector } from "react-redux";
import { userslist } from "../../slice/userList/userSlice"

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticate } = useSelector(state => state.auth)
    const { myModule } = useSelector(state => state.module)
    const { userInfo } = useSelector(state => state.auth)
    const companyId = userInfo.companyId._id;
    const dispatch = useDispatch();
    useEffect(() => {
        async function fetchUserList() {
            try {
                await dispatch(userslist({ companyId: companyId }))
            } catch (err) {
                console.log(err)
            }
        }
        fetchUserList()
    }, [])

    return (
        <Route {...rest} render={props => {
            if (isAuthenticate)
                switch (myModule) {
                    case 'Sales':
                        return <SalesLayout><Component {...props} /></SalesLayout>
                        break;

                    case 'Recruitment':
                        return <RecruitementLayout><Component {...props} /></RecruitementLayout>
                        break;

                    case "Purchase":
                        return <PurchaseLayout><Component {...props} /></PurchaseLayout>
                        break;

                    case "Announcements":
                        return <CommunicationLayout><Component {...props} /></CommunicationLayout>
                        break;

                    case "Support":
                        return <SupportLayout><Component {...props} /></SupportLayout>
                        break;

                    case "Marketing":
                        return <MarketingLayout><Component {...props} /></MarketingLayout>
                        break;

                    case "New Leads":
                        return <LeadsManagementLayout><Component {...props} /></LeadsManagementLayout>
                        break;

                    case 'Accounting':
                        return <AccountingLayout><Component {...props} /></AccountingLayout>
                        break;

                    case 'Finance':
                        return <FinanceLayout><Component {...props} /></FinanceLayout>
                        break;

                    case 'HR':
                        return <HrLayout><Component {...props} /></HrLayout>
                        break;

                    case 'Training':
                        return <TrainingLayout><Component {...props} /></TrainingLayout>
                        break;

                    case 'Resources':
                        return <ResourcesLayout><Component {...props} /></ResourcesLayout>
                        break;

                    case 'Report':
                        return <ReportLayout><Component {...props} /></ReportLayout>
                        break;

                    case 'Advertisement':
                        return <AdvertismentLayout><Component {...props} /></AdvertismentLayout>
                        break;

                    case 'Knowledge Base':
                        return <InformationCenterLayout><Component {...props} /></InformationCenterLayout>
                        break;

                    case 'Tasks':
                        return <TasksLayout><Component {...props} /></TasksLayout>
                        break;

                    case 'Projects':
                        return <ProjectsLayout><Component {...props} /></ProjectsLayout>
                        break;

                    case 'My Files':
                        return <MyFilesLayout><Component {...props} /></MyFilesLayout>
                        break;
                }
            return <Redirect
                to={{
                    pathname: "/"
                    , state: { from: props.location }
                }}
            />
        }} />
    )
};
export default PrivateRoute;