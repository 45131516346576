import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FaqCollapse from "./faqCollapse";
import { loadingStart, loadingEnd, clearLoading } from "../../../slice/utils/loader/linearSlice";
import { showError, clearMessage } from "../../../slice/utils/message/messageSlice";
import { listCategory, listAnswer, clearState } from "../../../slice/faqs/faqSlice";
import { useDispatch, useSelector } from "react-redux";
import Authentication from "../../helpers/authenticate";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function DkgFaq() {
  const classes = useStyles();
  const { categoryList, answerList, catId, isLoading, isSuccess, isError, errorMsg } = useSelector(state => state.faq);
  const { _id } = useSelector(state => state.auth.userInfo.companyId)
  const companyId = _id;

  const dispatch = useDispatch();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(clearLoading())
    dispatch(clearMessage())
  }, [])

  useEffect(() => {
    async function fetchCategoryInfo() {
      try {
        await dispatch(listCategory({ type: 'DKG Faq', companyId: companyId }))
      } catch (err) {
        dispatch(showError({ msg: err }))
      }
    }
    fetchCategoryInfo()
  }, [dispatch])

  useEffect(() => {
    if (catId != '0') {
      dispatch(listAnswer({ catId: catId }))
    }
  }, [catId])

  useEffect(() => {
    if (isLoading) {
      dispatch(loadingStart())
    }
    if (isSuccess) {
      dispatch(loadingEnd())
      dispatch(clearState())
    }
    if (isError) {
      dispatch(showError({ msg: errorMsg }))
      dispatch(loadingEnd())
      dispatch(clearState())
    }
  }, [isLoading, isError])

  const getAnswers = (catId) => {
    dispatch(listAnswer({ catId: catId }))
  }

  return (
    <React.Fragment>
      <Authentication />
      <div className="dk-dkgfaqMain">
        <div className="dk-faqCollapse-head">
          <h3 className="title">FAQ - USING MASTERBOX</h3>
          <div className="dk-faqCollapse-searchBox d-none">
            <input type="text" className="form-control" placeholder="Search..." id="" name="" />
            <span><i className="fas fa-search"></i></span>
          </div>
        </div>
        <div className={classes.root}>
          <div className="row">
            <div className="col-md-3">
              <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                  {
                    (categoryList && categoryList.length > 0) ? categoryList.map((item, index) =>
                      <Tab onClick={() => getAnswers(item._id)} data-catId={item._id} label={item.name} {...a11yProps(index)} />)
                      : null
                  }
                  {/* <Tab label="Introduction of Master Box" {...a11yProps(0)} />
                  <Tab label="Functions of Master Box" {...a11yProps(1)} /> */}
                </Tabs>
              </AppBar>
            </div>

            <div className="col-md-9 dk-tabsPanelFaq">
              <TabPanel>
                {
                  (answerList && categoryList.length > 0) ? <FaqCollapse /> : null
                }
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}