import React, { useEffect, useState } from 'react';
import { Route, Link } from "react-router-dom";
import './style.scss';
import { Dropdown } from 'semantic-ui-react';

const options = [
    {
        key: 'Half Day',
        text: 'Half Day',
        value: 'Half Day',
        label: '7',
    },
    {
        key: 'Full Day',
        text: 'Full Day',
        value: 'Full Day',
        label: '7',
    },
    {
        key: 'Long leave',
        text: 'Long leave',
        value: 'Long leave',
        label: '4',
    },
]

const TypesModal = () => {
    return (
        <React.Fragment>
            <Dropdown clearable options={options} selection placeholder='Type' />
        </React.Fragment>
    );
}

export default TypesModal;
