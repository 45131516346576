import React, { useState } from 'react';
import { Route, Link } from "react-router-dom";
import '../mlresource/style.scss';
import Modal from 'react-bootstrap/Modal';
import { Dropdown } from 'semantic-ui-react';
import '../../../../../node_modules/semantic-ui-css/semantic.min.css';
import MlDropdown from "./MlDropdown";

const statusOptions = [
    {
      key: 'Active',
      text: 'Active',
      value: 'Active',
    },
    {
      key: 'Inactive',
      text: 'Inactive',
      value: 'Inactive',
    },
]
const categoryOptions = [
    {
      key: 'Job Board',
      text: 'Job Board',
      value: 'Job Board',
    },
    {
      key: 'DKG Database',
      text: 'DKG Database',
      value: 'DKG Database',
    },
    {
        key: 'Linkedin',
        text: 'Linkedin',
        value: 'Linkedin',
    },
    {
        key: 'Social Media',
        text: 'Social Media',
        value: 'Social Media',
    },

]
const sourceTypeOptions = [
    {
      key: 'Ad Posting',
      text: 'Ad Posting',
      value: 'Ad Posting',
    },
    {
      key: 'CV Search',
      text: 'CV Search',
      value: 'CV Search',
    },
    {
        key: 'Ad Post + CV Search',
        text: 'Ad Post + CV Search',
        value: 'Ad Post + CV Search',
    },
]
const paidUnpaidOptions = [
    {
      key: 'Paid',
      text: 'Paid',
      value: 'Paid',
    },
    {
      key: 'Unpaid',
      text: 'Unpaid',
      value: 'Unpaid',
    },
    {
        key: 'Free Trial',
        text: 'Free Trial',
        value: 'Free Trial',
    },
]
const coverageOptions = [
    {
      key: 'Local Only',
      text: 'Local Only',
      value: 'Local Only',
    },
    {
      key: 'International',
      text: 'International',
      value: 'International',
    },
]

const EditModal = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return(
        <React.Fragment>
            <Link to="#" onClick={handleShow} className="dk-edit"><i class="far fa-edit"></i></Link>
            <Modal className="dk-mlAddNewModal dk-editResourceModal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Edit Resource</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-mlAddModalBody">
                    <form action="">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="">Manager</label>
                                    <div className="dk-userList">
                                        <MlDropdown />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Status</label>
                                    <label htmlFor="" class="dk-statusSelect">
                                        <Dropdown
                                            placeholder=''
                                            fluid
                                            selection
                                            options={statusOptions}
                                        />
                                    </label>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Name of Source</label>
                                    <label htmlFor="">
                                        <input type="text" name="" id="" className="form-control" />
                                    </label>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Category</label>
                                    <label htmlFor="">
                                        <Dropdown
                                            placeholder=''
                                            fluid
                                            selection
                                            options={categoryOptions}
                                        />
                                    </label>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Source Type</label>
                                    <label htmlFor="">
                                        <Dropdown
                                            placeholder=''
                                            fluid
                                            selection
                                            options={sourceTypeOptions}
                                        />
                                    </label>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Paid/Unpaid</label>
                                    <label htmlFor="">
                                        <Dropdown
                                            placeholder=''
                                            fluid
                                            selection
                                            options={paidUnpaidOptions}
                                        />
                                    </label>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Coverage</label>
                                    <label htmlFor="">
                                        <Dropdown
                                            placeholder=''
                                            fluid
                                            selection
                                            options={coverageOptions}
                                        />
                                    </label>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Location</label>
                                    <label htmlFor="">
                                        <input type="text" name="" id="" className="form-control" />
                                    </label>
                                </div>

                            </div>
                            <div className="col-md-12">
                                <div className="dk-resourcePopupbtnCont">
                                    <button>Update</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default EditModal;
