import React from "react";
import { Route, Link } from "react-router-dom";
import './style.scss';
import SalesImg from "../../assets/images/sales.png";
import InfoImg from "../../assets/images/info.png";
import ChatImg from "../../assets/images/announcements.png";
import TaskImg from "../../assets/images/task.png";
import ProjectsImg from "../../assets/images/projects.png";
import FilesImg from "../../assets/images/files.png";
import RecruitementImg from "../../assets/images/recruitement.png";
import ResourcingImg from "../../assets/images/resourcing.png";
import AdsImg from "../../assets/images/ads.png";
import ProjectionsImg from "../../assets/images/projections.png";
import AccountingImg from "../../assets/images/accounting.png";
import TrainingImg from "../../assets/images/training.png";
import MarketingImg from "../../assets/images/marketing.png";
import BusinessDevelopmentImg from "../../assets/images/business-development.png";
import HRImg from "../../assets/images/hr.png";
import SupportImg from "../../assets/images/support.png";
import CommunicationImg from "../../assets/images/communication.png";
import PurchaseImg from "../../assets/images/purchase.png";
import ReportImg from "../../assets/images/reports.png";
import { useDispatch, useSelector } from "react-redux";
import { setMyModule } from "../../slice/modules/moduleSlice";
import { useHistory } from "react-router-dom"
import Authentication from "../helpers/authenticate";
import ModuleHeader from "./ModuleHeader";
import Navbar from "../elements/header/navbar/mainMenu/modulesNavbar"
const AllModules = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const setModule = async (myModule, moduleColor) => {
        await dispatch(setMyModule({ module: myModule, color: moduleColor }))
        switch (myModule) {
            case 'Recruitment':
                history.push("/recruitment/dashboard");
                break;

            case 'Sales':
                history.push("/sales/dashboard");
                break;

            case "Purchase":
                history.push("/purchase/dashboard");
                break;

            case 'Announcements':
                history.push("/communication/dashboard");
                break;

            case 'Support':
                history.push("/support/dashboard");
                break;

            case 'Marketing':
                history.push("/marketing/dashboard");
                break;

            case 'New Leads':
                history.push("/leads-management/dashboard");
                break;

            case 'Accounting':
                history.push("/accounting/dashboard");
                break;

            case 'Finance':
                history.push("/finance/dashboard");
                break;

            case 'HR':
                history.push("/hr/dashboard");
                break;

            case 'Training':
                history.push("/training/dashboard");
                break;

            case 'Resources':
                history.push("/resources/dashboard");
                break;

            case 'Report':
                history.push("/report/dashboard");
                break;

            case 'Advertisement':
                history.push("/advertisment/dashboard");
                break;

            case 'Knowledge Base':
                history.push("/information-centre/dashboard");
                break;

            case 'Tasks':
                history.push("/tasks/dashboard");
                break;

            case 'Projects':
                history.push("/projects/dashboard");
                break;

            case 'My Files':
                history.push("/my-files/dashboard");
                break;
        }
    }

    return (
        <React.Fragment>
            <ModuleHeader />
            <Authentication />
            <Navbar />
            <div className="dk-portalMain">
                <div className="dk-portalContainer">
                    <div className="dk-topHeadTitle">
                        <h3 className="title">STANDARD BUSINESS APPLICATIONS</h3>
                        <h4 className="sbtitle">CUSTOMISED</h4>
                    </div>
                    <div className="dk-portalCols">
                        <div className="dk-portalBox dk_portalBox_3">
                            <Route>
                                <Link to="#" onClick={() => setModule('Training')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img src={TrainingImg} className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-PortalTitle">Training</div>
                                    </div>
                                </Link>
                            </Route>
                        </div>
                        <div className="dk-portalBox dk_portalBox_6">
                            <Route>
                                <Link to="#" onClick={() => setModule('Report', '##a43b63')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img src={ReportImg} className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-PortalTitle">Report</div>
                                    </div>
                                </Link>
                            </Route>
                        </div>
                        <div className="dk-portalBox dk_portalBox_10">
                            <Route>
                                <Link to="#" onClick={() => setModule('Support', '#555555')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img src={SupportImg} className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-PortalTitle">Support</div>
                                    </div>
                                </Link>
                            </Route>
                        </div>
                        <div className="dk-portalBox dk_portalBox_18">
                            <Route>
                                <Link to="#" onClick={() => setModule('Tasks', '#555555')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img src={TaskImg} className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-PortalTitle">Tasks</div>
                                    </div>
                                </Link>
                            </Route>
                        </div>
                        <div className="dk-portalBox dk_portalBox_2 mr-5">
                            <Route>
                                <Link to="#" onClick={() => setModule('Announcements')}>
                                    <div>
                                        <div className="dk-Portalicons PortaliconsBtn">
                                            <img src={ChatImg} className="img-fluid" alt="" />
                                            {/* <Route>
                                                <button><i class="fas fa-envelope"></i></button>
                                                <button><i class="far fa-calendar-alt"></i></button>
                                                <button><i class="fas fa-handshake"></i></button>
                                                <button><i class="fas fa-comments"></i></button>
                                            </Route> */}
                                        </div>
                                        <div className="dk-PortalTitle">Announcements</div>
                                    </div>
                                </Link>
                            </Route>
                        </div>
                        <div className="dk-portalBox dk_portalBox_1 ml-5">
                            <Route>
                                <Link to="#" onClick={() => setModule('Recruitment')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img src={RecruitementImg} className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-PortalTitle">Recruitment</div>
                                    </div>
                                </Link>
                            </Route>
                        </div>
                    </div>
                    <div className="dk-portalCols">
                        <div className="dk-portalBox dk_portalBox_9">
                            <Route>
                                <Link to="#" onClick={() => setModule('Projects', '#555555')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img src={ProjectsImg} className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-PortalTitle">Projects</div>
                                    </div>
                                </Link>
                            </Route>
                        </div>
                        <div className="dk-portalBox dk_portalBox_8">
                            <Route>
                                <Link to="#" onClick={() => setModule('New Leads')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img src={BusinessDevelopmentImg} className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-PortalTitle">New Leads</div>
                                    </div>
                                </Link>
                            </Route>
                        </div>
                        <div className="dk-portalBox dk_portalBox_7">
                            <Route>
                                <Link to="#" onClick={() => setModule('Marketing')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img src={MarketingImg} className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-PortalTitle">Marketing</div>
                                    </div>
                                </Link>
                            </Route>
                        </div>
                        <div className="dk-portalBox dk_portalBox_17">
                            <Route>
                                <Link to="#" onClick={() => setModule("Purchase")}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img src={PurchaseImg} className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-PortalTitle">Purchase</div>
                                    </div>
                                </Link>
                            </Route>
                        </div>
                        <div className="dk-portalBox dk_portalBox_13 mr-5">
                            <Route>
                                <Link to="#" onClick={() => setModule('Sales')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img src={SalesImg} className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-PortalTitle">Sales</div>
                                    </div>
                                </Link>
                            </Route>
                        </div>
                        <div className="dk-portalBox dk_portalBox_15 ml-5">
                            <Route>
                                <Link to="#" onClick={() => setModule('Advertisement')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img src={AdsImg} className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-PortalTitle">Advertisement</div>
                                    </div>
                                </Link>
                            </Route>
                        </div>
                    </div>
                    <div className="dk-portalCols">
                        <div className="dk-portalBox dk_portalBox_16">
                            <Route>
                                <Link to="#" onClick={() => setModule('My Files')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img src={FilesImg} className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-PortalTitle">My Files</div>
                                    </div>
                                </Link>
                            </Route>
                        </div>
                        <div className="dk-portalBox dk_portalBox_11">
                            <Route>
                                <Link to="#" onClick={() => setModule('Resources')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img src={ResourcingImg} className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-PortalTitle">Resources</div>
                                    </div>
                                </Link>
                            </Route>
                        </div>
                        <div className="dk-portalBox dk_portalBox_4">
                            <Route>
                                <Link to="#" onClick={() => setModule('HR')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img src={HRImg} className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-PortalTitle">HR</div>
                                    </div>
                                </Link>
                            </Route>
                        </div>
                        <div className="dk-portalBox dk_portalBox_12">
                            <Route>
                                <Link to="#" onClick={() => setModule('Finance')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img src={ProjectionsImg} className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-PortalTitle">Finance</div>
                                    </div>
                                </Link>
                            </Route>
                        </div>
                        <div className="dk-portalBox dk_portalBox_5 mr-5">
                            <Route>
                                <Link to="#" onClick={() => setModule('Accounting')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img src={AccountingImg} className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-PortalTitle">Accounting</div>
                                    </div>
                                </Link>
                            </Route>
                        </div>
                        <div className="dk-portalBox dk_portalBox_14 ml-5">
                            <Route>
                                <Link to="#" onClick={() => setModule('Knowledge Base')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img src={InfoImg} className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-PortalTitle">Knowledge Base</div>
                                    </div>
                                </Link>
                            </Route>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AllModules;
