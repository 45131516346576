import React, { useState } from 'react';
import { Route, Link } from "react-router-dom";
import './style.scss';
import Modal from 'react-bootstrap/Modal';
import { Dropdown } from 'semantic-ui-react';
import '../../../../node_modules/semantic-ui-css/semantic.min.css';

const taskType = [
    {
        key: 'My Task',
        text: 'My Task',
        value: 'My Task',
    },
    {
        key: 'Othe Task',
        text: 'Othe Task',
        value: 'Othe Task',
    },
    {
        key: 'Group Task',
        text: 'Group Task',
        value: 'Group Task',
    },
    {
        key: 'Payment Task',
        text: 'Payment Task',
        value: 'Payment Task',
    },
]
const taskCategory = [
    {
        key: 'General Task',
        text: 'General Task',
        value: 'General Task',
    },
    {
        key: 'Admin Task',
        text: 'Admin Task',
        value: 'Admin Task',
    },
    {
        key: 'Payments',
        text: 'Payments',
        value: 'Payments',
    },
    {
        key: 'Technical',
        text: 'Technical',
        value: 'Technical',
    },
    {
        key: 'Candidate Related',
        text: 'Candidate Related',
        value: 'Candidate Related',
    },
]

const AddNewTask = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <React.Fragment>
            <Link to="#" onClick={handleShow}>Add New Task</Link>
            <Modal className="dk-AddNewTaskModal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>CREATE NEW TASK</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="">Task Type</label>
                                    <label htmlFor="" className="dk-taskDropdown">
                                        <Dropdown
                                            placeholder=''
                                            fluid
                                            selection
                                            options={taskType}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="">Task Type</label>
                                    <label htmlFor="" className="dk-taskDropdown">
                                        <Dropdown
                                            placeholder=''
                                            fluid
                                            selection
                                            options={taskCategory}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="">Task Priority</label>
                                    <div className="dk-taskSwitch">
                                        <li class="tg-list-item">
                                            <input class="tgl tgl-skewed" id="cb3" type="checkbox" />
                                            <label class="tgl-btn" data-tg-off="Normal" data-tg-on="Urgent" for="cb3"></label>
                                        </li>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="">Task By</label>
                                    <label htmlFor="" className="dk-taskDropdown">
                                        <Dropdown
                                            placeholder=''
                                            fluid
                                            selection
                                            options={taskCategory}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="">Task For</label>
                                    <label htmlFor="" className="dk-taskDropdown">
                                        <Dropdown
                                            placeholder=''
                                            fluid
                                            selection
                                            options={taskCategory}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-2 pr-0">
                                <div className="form-group">
                                    <label htmlFor="">Start Date</label>
                                    <label htmlFor="" className="dk-TaskDate">
                                        <input type="date" className="form-control" name="" id="" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-2 pl-0">
                                <div className="form-group">
                                    <label htmlFor="">Start Time</label>
                                    <label htmlFor="" className="dk-TaskDate">
                                        <input type="time" className="form-control" name="" id="" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label htmlFor="">Task Title</label>
                                    <label htmlFor="">
                                        <input type="text" className="form-control" name="" id="" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="">Attachment</label>
                                    <label htmlFor="" className="dk-taskChoosefile">
                                        <div className="file-select-name">No file chosen...</div>
                                        <input type="file" name="" id="" />
                                        <span>Choose File</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="">
                                        <textarea name="" id="" className="form-control"></textarea>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group mb-0">
                                    <label htmlFor="" className="text-center">
                                        <button className="dk-taskCreateBtn">Create</button>
                                    </label>
                                </div>
                            </div>

                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default AddNewTask;
