import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { logoutUser } from "../../../../slice/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { IMG_CLOUD_BASE_URL } from "../../../../constants";

const UserNav = () => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.auth);
    const logout = async () => {
        dispatch(logoutUser({}))
        window.location.assign(process.env.REACT_APP_REDIRECT_LOGOUT_URL);
    }
    const pathname = window.location.pathname;
    return (
        <React.Fragment>
            <Dropdown className="dk-accountDropdown">
                <Dropdown.Toggle id="dropdown-basic">
                    <span>
                        <img src={IMG_CLOUD_BASE_URL + userInfo.userImg} className="img-fluid img-circle" alt="" />
                    </span>
                    <div className="title">
                        {userInfo.name.split(" ")[0]}
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item href="/dashboard"><i className="fas fa-home"></i> Dashboard</Dropdown.Item>
                    <Dropdown.Item href="/profile" className={`${pathname.match('/profile') ? 'active' : ''}`}><i class="fas fa-user-alt"></i> Profile</Dropdown.Item>
                    <Dropdown.Item href="/admin/dashboard" className={`${pathname.match('/admin') ? 'active' : ''}`}><i class="fas fa-user-cog"></i> Admin</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={logout}><i className="fas fa-power-off"></i> Logout</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    )
}
export default UserNav;