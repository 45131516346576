import React from 'react';
import  "./deletemodal.scss";
import {Modal,Button} from 'react-bootstrap';


const DeleteModal = ({onHide}) => {
    return (
        <React.Fragment>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dk-resDelPopupDialog"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body className="dk-deleteModaBody">
                    <p>Are you sure wants to Delete these records ?</p>
                </Modal.Body>
                <Modal.Footer className="dk-deleteModalFooter">
                    <Button className="dk-deleteNoBtn">No</Button>
                    <Button className="dk-deleteyesBtn" >Yes</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default DeleteModal;
