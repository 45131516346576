import React, { useEffect } from "react";
import Header from "../elements/header";
import ProgressBar from "../utils/loader/linear";
import { useSelector } from "react-redux";
import ResourcesmenuNavbar from "../elements/header/navbar/mainMenu/resources/ResourcesmenuNavbar";
const Layout = ({ children }) => {

    return (
        <React.Fragment>
            <Header />
                <ResourcesmenuNavbar />
            <ProgressBar />
            {children}
        </React.Fragment>
    )
}
export default Layout;