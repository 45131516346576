import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { rulesUpdate, rulesDelete, clearState } from '../../../../slice/rules/rulesSlice';
import DeleteModal from "../../../ui/delete";
import { showSuccess, showError, clearMessage } from "../../../../slice/utils/message/messageSlice";

const List = ({ number, itemValue, id, type, dataId }) => {
    const dispatch = useDispatch()
    const { isDelete, isError, errorMessage } = useSelector(state => state.rules)
    const [rowId, setRowId] = useState('0')
    const [value, setValue] = useState(itemValue)
    const [showDelete, setShowDelete] = useState(false)

    const updateRule = async (e) => {
        setValue(e.target.value)
        dispatch(rulesUpdate({ id: e.target.dataset.id, type: e.target.dataset.type, listId: e.target.dataset.dataid, list: e.target.value }))
    }

    const deleteList = async (id, type, dataId) => {
        setRowId(dataId)
        setShowDelete(true)
    }

    const handleClose = () => {
        setShowDelete(false)
        setRowId('0')
    }

    const deleteItem = async () => {
        dispatch(rulesDelete({ id: id, type: type, listId: dataId }))
    }

    useEffect(() => {
        if (isDelete) {
            dispatch(showSuccess({ msg: 'Delete successfully' }))
            dispatch(clearState())
            dispatch(clearMessage())
            setShowDelete(false)
            setRowId('0')
        }
        if (isError) {
            dispatch(showError({ msg: errorMessage }))
            dispatch(clearState())
            dispatch(clearMessage())
        }
    }, [isDelete, isError])
    return (
        <React.Fragment>
            <div className="d-flex mb-1">
                <div className="sn">{number}</div>
                <div className="textBox">
                    <input type="text" name="list" value={value} data-id={id} data-type={type} data-dataid={dataId} onChange={updateRule} />
                </div>
                {/* <div className="dragdelete">
                    <i class="fas fa-arrows-alt"></i>
                </div> */}
                <div className="dragdelete" onClick={() => deleteList(id, type, dataId)}>
                    <i class="far fa-trash-alt"></i>
                </div>
            </div>
            {
                (showDelete) ? <DeleteModal id={rowId} handleClose={handleClose} deleteItem={deleteItem} /> : null
            }
        </React.Fragment>
    )
}
export default List;