import React from 'react'

const CostBenefit = () => {
    return (
        <React.Fragment>
            <div className="container-fluid w-100 text-center" style={{marginTop: "155px"}}>
                <h5>Cost/Benefit</h5>
            </div>
        </React.Fragment>
    )
}

export default CostBenefit;
