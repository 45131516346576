import React from "react";
import { Route, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import './style.scss';
import AddNewTask from "../../../../../pages/tasks/AddNewTask";

const TasksNavbar = () => {
    const { allBadge } = useSelector(state => state.tasks);
    const pathname = window.location.pathname;
    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-navTasks">
                <Route>
                    <ul>
                        <li><Link to="/tasks/dashboard" className={`${pathname.match('/tasks/dashboard') ? 'active' : ''}`}>Dashboard</Link></li>
                        <li><Link to="#">Notifications</Link></li>
                        <li><Link to="/tasks/all-task" className={`${pathname.match('/tasks/all-task') ? 'active' : ''}`}>All Tasks</Link></li>
                        <li><Link to="/tasks/my-task" className={`${pathname.match('/tasks/my-task') ? 'active' : ''}`}>My Tasks</Link></li>
                        <li><Link to="/tasks/others-task" className={`${pathname.match('/tasks/others-task') ? 'active' : ''}`}>Others Tasks</Link></li>
                        <li><Link to="/tasks/group-task" className={`${pathname.match('/tasks/group-task') ? 'active' : ''}`}>Group Tasks</Link></li>
                        <li><AddNewTask /></li>
                        <li><Link to="#">Archived</Link></li>
                        <li><Link to="#">FAQ</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default TasksNavbar;
