import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import Authentication from "../../helpers/authenticate";

const Dashboard = () => {
    return (
        <React.Fragment>
            <Authentication />
        </React.Fragment>
    )
}
export default Dashboard;
