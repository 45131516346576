import React from "react"
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from "react-redux";
import {IMG_CLOUD_BASE_URL} from '../../../constants'
const UserList = ({onClick}) =>{
    const {usersList} = useSelector(state=>state.users)    
    return (
        <React.Fragment>
            { usersList.map((list,index)=>(
                <Dropdown.Item href="#">
                    <img src={IMG_CLOUD_BASE_URL+list.userImg} className="img-fluid img-circle" alt={list.userImg} title={list.firstName} data-id={list._id} onClick={onClick} />
                </Dropdown.Item> 
            ))}                      
        </React.Fragment>
    )
}
export default UserList;