import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const mailshotlist = createAsyncThunk(
    'mailshots/mailshotlist',
    async ({ companyId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/mailshots/' + companyId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);
export const mailshotAdd = createAsyncThunk(
    'mailshots/add',
    async ({ userId, joinDate, joinTime, totalCandidate, mailshotVia, projectTitle, category, company, project, language, roleType, companyId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/mailshots',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        userId, joinDate, joinTime, totalCandidate, mailshotVia, projectTitle, category, company, project, language, roleType, companyId
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const deleteMailShotData = createAsyncThunk(
    'mailshots/delete',
    async ({ rowId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/mailshots/' + rowId,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const updateMailShot = createAsyncThunk(
    'mailshots/update',
    async ({ userId, joinDate, joinTime, totalCandidate, mailshotVia, projectTitle, category, company, project, language, roleType, mailshotId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/mailshots/' + mailshotId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        userId, joinDate, joinTime, totalCandidate, mailshotVia, projectTitle, category, company, project, language, roleType
                    }),
                }
            );
            let data = await response.json();

            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const fethById = createAsyncThunk(
    'mailshots/singledata',
    async ({ mailshotId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/mailshots/' + mailshotId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateMailshots = createAsyncThunk(
    'mailshots/update',
    async ({ userId, joinDate, joinTime, totalCandidate, mailshotVia, projectTitle, company, category, project, language, roleType, mailshotId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/mailshots/' + mailshotId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        userId, joinDate, joinTime, totalCandidate, mailshotVia, projectTitle, company, category, project, language, roleType
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const mailshotsSlice = createSlice({
    name: 'mailshots',
    initialState: {
        mailList: {},
        singleMailList: {},
        isLoading: false,
        isInsert: false,
        isError: false,
        isUpdate: false,
        isDelete: false,
        errorMessage: '',
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isError = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isDelete = false;
            state.errorMessage = '';
            return state;
        }
    },
    extraReducers: {
        [mailshotlist.pending]: (state) => {
            state.isLoading = true;
        },
        [mailshotlist.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.mailList = payload.data;
        },
        [mailshotlist.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [mailshotAdd.pending]: (state) => {
        },
        [mailshotAdd.fulfilled]: (state, { payload }) => {
            state.isInsert = true;
            state.mailList.unshift(payload.data)

        },
        [mailshotAdd.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [deleteMailShotData.pending]: (state) => {
        },
        [deleteMailShotData.fulfilled]: (state, action) => {
            state.isDelete = true;
            state.mailList = state.mailList.filter((item) => item._id !== action.meta.arg.rowId)
            state.msg = 'Delete Successfully';
        },
        [deleteMailShotData.rejected]: (state, { payload }) => {
            state.isDelete = false;
            state.isError = true;
            state.msg = payload.message.msgBody
        },
        [updateMailShot.pending]: (state) => {
            //state.isLoading = true;
        },
        [updateMailShot.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            let mainArray = state.mailList;
            let finalResult = mainArray.map((r) => {
                if (r._id === payload.mailList._id) {
                    return (payload.mailList)
                } else {
                    return r
                }
            })
            state.mailList = finalResult
            state.msg = payload.message.msgBody
        },
        [updateMailShot.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = false;
            state.isError = true;
            state.msg = payload.msgBody
        },
        [fethById.pending]: (state) => {
            state.isLoading = true;
        },
        [fethById.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.singleMailList = payload.data;
        },
        [fethById.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
        }
        ,
        [updateMailshots.pending]: (state) => {
            state.isLoading = true;
        },
        [updateMailshots.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            let mainArray = state.mailList;
            let finalResult = mainArray.map((r) => {
                if (r._id === payload.data._id) {
                    return (payload.data)
                } else {
                    return r
                }
            })
            state.mailList = finalResult
            state.msg = payload.message.msgBody
        },
        [updateMailshots.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.msgBody
        }
    }
});

export const { clearState } = mailshotsSlice.actions;
export default mailshotsSlice.reducer;