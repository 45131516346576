import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
export const listColors = createAsyncThunk(
    'job_profile/colors',
    async ({ }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/colors/',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const listTable = createAsyncThunk(
    'job_profile/list',
    async ({ userId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/jobprofile/' + userId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const addHeadings = createAsyncThunk(
    'job_profile/add_heading',
    async ({ userId, name, bgColor, txtColor, boxId, parentId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/jobprofile/',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        userId,
                        name,
                        bgColor,
                        txtColor,
                        boxId,
                        parentId
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const updateHeadings = createAsyncThunk(
    'job_profile/update_heading',
    async ({ id, userId, name, bgColor, txtColor, boxId, parentId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/jobprofile/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        userId,
                        name,
                        bgColor,
                        txtColor,
                        boxId,
                        parentId
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

const jobProfileSlice = createSlice({
    name: 'jobProfile',
    initialState: {
        colors: [],
        dataList: [],
        isSuccess: false,
        isInsert: false,
        isUpdate: false,
        isDelete: false,
        isLoading: false,
        isError: false,
        isColorLoading: false,
        isColorSuccess: false,
        msg: ''
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isDelete = false;
            state.isError = false;
            state.isColorLoading = false;
            state.msg = '';
        }
    },
    extraReducers: {
        [listColors.pending]: (state) => {
            state.isColorLoading = true;
        },
        [listColors.fulfilled]: (state, { payload }) => {
            state.isColorLoading = false;
            state.isColorSuccess = true;
            state.colors = payload.colors;
        },
        [listColors.rejected]: (state, { payload }) => {
            state.isColorLoading = false;
        },
        [listTable.pending]: (state) => {
            state.isLoading = true;
        },
        [listTable.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.dataList = payload.catgory;
        },
        [listTable.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        [addHeadings.pending]: (state) => {
            state.isLoading = true;
        },
        [addHeadings.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = true;
            state.dataList.push(payload.data)
        },
        [addHeadings.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
        },
        [updateHeadings.pending]: (state) => {
            state.isLoading = true;
        },
        [updateHeadings.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            let mainArray = state.dataList;
            let finalResult = mainArray.map((r) => {
                if (r._id === payload.data._id) {
                    return (payload.data)
                } else {
                    return r
                }
            })
            state.dataList = finalResult
        },
        [updateHeadings.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
        },
    }
})

export const { clearState } = jobProfileSlice.actions
export default jobProfileSlice.reducer;