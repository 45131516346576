import React from 'react'

const Salaries = () => {
    return (
        <React.Fragment>
          <div className="container-fluid w-100 text-center" style={{marginTop: "155px"}}>
              <h5>Salaries</h5>
          </div>
        </React.Fragment>
    )
}

export default Salaries;
