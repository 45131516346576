import React, { Suspense, useState, useMemo, useEffect } from "react";
import TableLoader from "../../../utils/tableLoader";
import NoData from "../../../utils/tableNoData";
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import { Route, Link } from "react-router-dom";
import TablePagination from '../../../ui/pagination';
import { makeStyles } from '@material-ui/core/styles';
import '../style.scss';
import { GlobalFilter } from "../filters/GlobalFilter";
import MessageModal from "../MessageModal";
import Authentication from "../../../helpers/authenticate";
import { useDispatch, useSelector } from "react-redux";
import { mailshotlist, clearState, deleteMailShotData, fethById } from "../../../../slice/mailshots/mailshotsSlice";
import { IMG_CLOUD_BASE_URL } from "../../../../constants";
import UserFilterToggle from "../../../ui/tableUserFilter";
import DeleteModal from "../../../ui/delete";
import DateFormat from "../../../utils/dateFormats/DateFormat";
import TimeFormate from "../../../utils/timeFormats";
import { showSuccess, showError, clearMessage } from "../../../../slice/utils/message/messageSlice";
import AddNewMailshots from "../AddNewMailshots";
import { loadingStart, loadingEnd, clearLoading } from "../../../../slice/utils/loader/linearSlice";
import EditMailShots from "./EditMailShots";
import $ from "jquery";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(0),
        },
    },
}));

function Table({ loading, columns, data }) {
    const classes = useStyles();
    const [userFilterId, setUserFilterId] = useState('0')
    const [userFilterImg, setUserFilterImg] = useState('0')
    const handlePageChange = (event, value) => {
        gotoPage(Number(value) - 1);
    };

    const clickFilterUser = (e) => {
        setUserFilterId(e.target.id)
        setUserFilterImg(e.target.src)
        setFilter('userId', e.target.id)
    }

    const deleteFilter = (name) => {
        setFilter(name, '')
        if (name === 'userId') {
            setUserFilterId('0')
            setUserFilterImg('0')
        }
    }

    const tableInstance = useTable({
        columns: columns,
        data: data,
        initialState: {
            pageIndex: 0, pageSize: 100, hiddenColumns: ["userId"]
        },
    }, useGlobalFilter, useFilters, useSortBy, usePagination)

    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, state, setGlobalFilter, setFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize } } = tableInstance
    const { globalFilter } = state

    return (
        <React.Fragment>
            <div className="dk-mailshotsMain">
                <div className="dk-mailshotHead">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="dk-mailshotsRight">
                            <div className="dk-mailshotsSearch">
                                <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                            </div>
                            <UserFilterToggle userFilterId={userFilterId} userFilterImg={userFilterImg} list={tableInstance.flatRows} selectUser={clickFilterUser} deleteFilter={deleteFilter} />
                            <button className="filterTable"><i class="fas fa-search"></i></button>
                        </div>
                        <h3 className="title">MAILSHOTS HISTORY</h3>
                        <div className="dk-mailshotsLeft">
                            <AddNewMailshots />
                        </div>
                    </div>
                </div>
                <div className="dk-mailshotsTable">
                    <div className="table-responsive">
                        <table className="table table-bordered" {...getTableProps()}>
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            // Add the sorting props to control sorting. For this example
                                            // we can add them into the header props
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            {(!loading && tableInstance.flatRows.length <= 0) ? <NoData colspan="12" txt="No History list found..." /> : null}
                            {
                                (loading) ? <TableLoader colspan="12" /> :
                                    <tbody {...getTableBodyProps()}>
                                        {page.map(
                                            (row, i) => {
                                                prepareRow(row);
                                                return (
                                                    <tr {...row.getRowProps()}>
                                                        {row.cells.map(cell => {
                                                            return (
                                                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                            )
                                                        })}
                                                    </tr>
                                                )
                                            }
                                        )}
                                    </tbody>
                            }
                        </table>
                        <div className="dk-smsTable-pagination">
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="dk-showData">Showing {' '}{pageIndex + 1} to {pageOptions.length} (of {tableInstance.rows.length} entries)</span>
                                <div className={classes.root}>
                                    <TablePagination count={pageCount} onChange={handlePageChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  */}
        </React.Fragment>
    )
}

const Mailshots = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [data, setData] = useState([]);
    const [showDelete, setShowDelete] = useState(false)
    const [show, setShow] = useState(false);
    const { mailList, isLoading, isError, isDelete, msg, isInsert } = useSelector(state => state.mailshots)
    const [rowId, setRowId] = useState('0')
    const [isShowEdit, setIsShowEdit] = useState(false)
    const { _id } = useSelector(state => state.auth.userInfo.companyId)
    const companyId = _id;

    useEffect(() => {
        async function fetchData() {
            try {
                dispatch(mailshotlist({ companyId: companyId }))
            } catch (err) {
                console.log(err)
            }
        }
        fetchData()
    }, [dispatch])

    const editPopupShow = async (mailshotId) => {
        await dispatch(fethById({ mailshotId }))
        setRowId(mailshotId)
        setIsShowEdit(true)
    }

    useEffect(() => {
        if (!isLoading && mailList && mailList.length > -1) {
            setData(mailList)
        }
    }, [isLoading])

    useEffect(() => {
        if (isDelete) {
            setData(mailList)
        }
    }, [isDelete])

    // const handleToggle = () => {
    //     setIsActive(!isActive);
    // };

    const checkboxToggle = (e) => {
        console.log(e.target)
    }

    useEffect(() => {
        if (isError) {
            dispatch(clearState())
            dispatch(clearLoading())
            dispatch(showError({ msg: msg }))
        }
        if (isInsert) {
            setShow(false);
            dispatch(showSuccess({ msg: "Details added successfully" }))
            dispatch(clearState())
            dispatch(clearLoading())
            setData(mailList)
        }
    }, [isError, isInsert])
    const columns = useMemo(() => [
        {
            Header: 'SNo.',
            title: 'SNo.',
            Cell: (row, { cell }) => (
                <React.Fragment>
                    <div className="dk-smsTableCheckbox">
                        <input type="checkbox" className="chkCandidate" name="chkCandidate[]" value={row.row.original._id} onChange={(e) => toggleCheckbox(e, row.row.original._id)} />
                        {Number(row.row.id) + 1}
                    </div>
                </React.Fragment >
            )
        },
        {
            Header: 'Date',
            title: 'Date',
            Cell: (row, { cell }) => (
                <React.Fragment>
                    <DateFormat date={row.row.original.joinDate} />
                    <TimeFormate time={row.row.original.joinTime} />
                </React.Fragment>
            )
        },
        {
            id: 'userId',
            Header: 'Recruiter Id',
            accessor: 'userId._id',
            show: false
        },
        {
            Header: 'Recruiter',
            accessor: 'userId.firstName',
            Cell: ({ cell }) => (
                <img class="person-imageFlex" src={IMG_CLOUD_BASE_URL + cell.row.original.userId.userImg} title={cell.row.original.userId.firstName} />
            )
        },
        {
            id: 'totalCandidate',
            Header: 'Candidates',
            accessor: 'totalCandidate',
            show: false
        },

        {
            Header: 'Sent Via',
            accessor: 'mailshotVia'
        },
        {
            Header: 'Title',
            accessor: 'projectTitle'
        },
        {
            Header: 'Category',
            accessor: 'category',
        },
        {
            Header: 'Company',
            accessor: 'company',
        },
        {
            Header: 'Language',
            accessor: 'language',
        },
        {
            Header: 'Project',
            accessor: 'project',
        },
        {
            Header: 'Role Type',
            accessor: 'roleType',
        },
        {
            Header: 'Action',
            accessor: '_id',
            Cell: ({ cell }) => (
                <div className="dk-action">
                    <button className="dk-upload"><i class="fas fa-upload"></i></button>
                    <button className="dk-edit" onClick={() => editPopupShow(cell.row.original._id)}><i class="far fa-edit"></i></button>
                    <button className="dk-delete" onClick={() => deleteData(cell.row.original._id)}><i class="far fa-trash-alt"></i></button>
                    <button className="dk-file"><i class="fas fa-file-alt"></i></button>
                </div>
            )
        }
    ], [])

    const deleteData = async (id) => {
        setRowId(id)
        setShowDelete(true)
    }
    const handlePageChange = (event, value) => {
        // gotoPage(Number(value) - 1);
    };
    const handleClose = () => {
        setShowDelete(false)
        setIsShowEdit(false)
    }
    // const deleteItem = async () => {
    //     dispatch(deleteMailShotData({ rowId }))
    // }
    const deleteItem = async () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount == 0) {
            dispatch(deleteMailShotData({ rowId }))
        } else {
            var canIds = [];
            $.each($(".chkCandidate:checked"), function () {
                canIds.push($(this).val());
            });
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            fetch('/api/mailshots/delete', {
                method: 'POST',
                body: JSON.stringify({ 'ids': canIds }),
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token,
                }
            }).then(response => {
                if (response.status === 200) {
                    dispatch(showSuccess({ msg: 'Delete successfully' }))
                    setShowDelete(false)
                    setIsActive(false)
                    dispatch(mailshotlist({ companyId: companyId }))
                    dispatch(loadingEnd())
                }
            })
        }
    }

    useEffect(() => {
        if (isDelete) {
            dispatch(clearState())
            dispatch(clearMessage())
            dispatch(showSuccess({ msg: msg }))
            setShowDelete(false)
            setRowId('0')
        }
        if (isError) {
            dispatch(showError({ msg: msg }))
            dispatch(clearState())
            dispatch(clearMessage())
        }
    }, [isDelete, isError])
    /** Extra Div */
    const [isActive, setIsActive] = useState(false);
    const [countCheck, setCountCheck] = useState(0)
    const [checkBtnTxt, setCheckBtnTxt] = useState('Check All')

    const handleToggle = () => {
        //setIsActive(!isActive);
        $('.chkCandidate').prop('checked', false);
        setIsActive(false);
    };

    const toggleCheckAll = () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            setCheckBtnTxt('Uncheck All')
            setCountCheck(totalCheckboxes)
            $('.chkCandidate').prop('checked', true);
            // setCheckedBoxes(checkedBoxes.filter((v, i, a) => a.indexOf(v) === i))
        }
        if (checkedBoxCount == totalCheckboxes) {
            setCheckBtnTxt('Check All')
            setCountCheck(0)
            $('.chkCandidate').prop('checked', false);
            setIsActive(false)
        }
    }
    /** Delete Selected Checkbox  */
    const deleteSelected = () => {
        setShowDelete(true)
    }
    /* End Delete Selected Checkbox*/

    /** Single Checkbox Select */
    const toggleCheckbox = (e, id) => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount > 0) {
            setCountCheck(checkedBoxCount)
            setIsActive(true)
        } else {
            setCountCheck(0)
            setIsActive(false)
        }
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            setCheckBtnTxt('Check All')
        }
        if (checkedBoxCount == totalCheckboxes) {
            setCheckBtnTxt('Uncheck All')
        }
    }
    /** End Single Checkbox Select */
    /** End Extra Div */

    return (
        <React.Fragment>
            {
                (isShowEdit) ? <EditMailShots id={rowId} handleClose={handleClose} /> : null
            }
            {
                (showDelete) ? <DeleteModal handleClose={handleClose} deleteItem={deleteItem} /> : null
            }
            <Authentication />
            <div className="dk-mailshotsTable">
                <div className="row">
                    <div className="col-md-12">
                        <div className="dk-smsInboxTable">
                            <Table
                                data={data}
                                columns={columns}
                                loading={isLoading}
                            />
                        </div>
                    </div>

                </div>
            </div>
            <div className={`dk-smsMessage-selectMsg ${isActive ? "activePopup" : ""}`}>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="dk-selectNo">{countCheck}</div>
                    <div className="dk-rowSelected">
                        <span>Row Selected</span>
                    </div>
                    <div className="dk-checkAll" onClick={toggleCheckAll}>
                        <span><i class="fas fa-check"></i></span>
                        {checkBtnTxt}
                    </div>
                    <div className="dk-checkAll" onClick={deleteSelected}>
                        <span><i class="far fa-trash-alt"></i></span>
                        Delete
                    </div>
                    <div className="dk-checkAll d-flex align-items-center justify-content-center" onClick={handleToggle}>
                        <span><i class="fas fa-times"></i></span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Mailshots;
