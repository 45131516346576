import React from 'react';
import Header from "../allModules/ModuleHeader";

const BlankLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header />
            {children}
        </React.Fragment>
    )
}
export default BlankLayout;