import React from "react";
import { Route, Link } from "react-router-dom";
import '../../mainMenu/recruitment/style.scss';
import DashboardSubmenu from "./menu/DashboardSubmenu";
import SettingsSubmenu from "./menu/SettingsSubmenu";

const Submenu = ({ path }) => {

    const renderSubmenu = () => {
        switch (path.split("/")[2]) {
            case 'dashboard':
                return <DashboardSubmenu />
                break;

            case 'clients':
                return <DashboardSubmenu />
                break;

            case 'suppliers':
                return <DashboardSubmenu />
                break;

            case 'invoices':
                return <DashboardSubmenu />
                break;

            case 'income':
                return <DashboardSubmenu />
                break;

            case 'expense':
                return <DashboardSubmenu />
                break;

            case 'p-and-l':
                return <DashboardSubmenu />
                break;

            case 'cashflow':
                return <DashboardSubmenu />
                break;

            case 'overview':
                return <DashboardSubmenu />
                break;

            case 'returns':
                return <DashboardSubmenu />
                break;

            case 'settings':
                return <SettingsSubmenu />
                break;
        }
    }

    return (
        <React.Fragment>
            {renderSubmenu()}
        </React.Fragment>
    )
}
export default Submenu;