import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import ProfileImg from "../../../assets/images/profile.png";
import './style.scss';

const Profile = () => {
    const [showEdit, setShowEdit] = useState(false)

    const profileEdit = () => {
        setShowEdit(true)
    }

    const profileSave = () => {
        setShowEdit(false)
    }

    return (
        <React.Fragment>
            <div className="dk-profileMain">
                <h3 className="title">My Profile</h3>
                <div className="dk-profileColsMain">
                    <div className="dk-profileCols">
                        <div className="dk-profileHead">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="dk-profileDetails">
                                    {
                                        (!showEdit) ?
                                            <div className="dk-profilePhoto">
                                                <img src={ProfileImg} className="img-fluid" alt="" />
                                            </div>
                                            :
                                            <div className="dk-profilePhoto">
                                                <img src={ProfileImg} className="img-fluid" alt="" />
                                                <span className="choosePhoto">
                                                    <input type="file" value="" />
                                                    <span className="choosPhotoImg">
                                                        <i class="fas fa-camera"></i>
                                                    </span>
                                                </span>
                                            </div>
                                    }
                                    <div className="dk-profileText">
                                        <div className="title">Deepak Kumar</div>
                                        <div className="emailText">deepak@dkglobalrecruitment.com</div>
                                    </div>
                                </div>
                                {
                                    (!showEdit) ?
                                        <div className="dk-profileEditbtn">
                                            <Link to="#" onClick={profileEdit}>EDIT</Link>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>

                        {
                            (!showEdit) ?
                                <div className="dk-profileForm dk-viewForm" >
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Full Name</label>
                                                <label htmlFor="">Deepak Kumar</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">User Type</label>
                                                <label htmlFor="">Account Admin</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Job Title</label>
                                                <label htmlFor="">Software Developer</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Phone No</label>
                                                <label htmlFor="">(+353) 876942325</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Email Address</label>
                                                <label htmlFor="">deepak@dkglobalrecruitment.com</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Gender</label>
                                                <label htmlFor="">Male</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">City/Town</label>
                                                <label htmlFor="">Deepak</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Country</label>
                                                <label htmlFor="">Select State</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Nationality</label>
                                                <label htmlFor="">English</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">ID/Passport No</label>
                                                <label htmlFor="">1234567890</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group dk-calenderIcon">
                                                <label htmlFor="">Joined on</label>
                                                <label htmlFor="">02-Jan-2021</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group dk-calenderIcon">
                                                <label htmlFor="">Duration</label>
                                                <label htmlFor="">06 Months</label>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                :
                                <div className="dk-profileForm dk-editForm" >
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Full Name</label>
                                                <label htmlFor="">
                                                    <input type="text" name="" id="" className="form-control" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">User Type</label>
                                                <label htmlFor="">
                                                    <select className="form-control">
                                                        <option>Select</option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Job Title</label>
                                                <label htmlFor="">
                                                    <input type="text" name="" id="" className="form-control" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Phone No</label>
                                                <label htmlFor="">
                                                    <input type="text" name="" id="" className="form-control" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Email Address</label>
                                                <label htmlFor="">
                                                    <input type="text" name="" id="" className="form-control" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Gender</label>
                                                <label htmlFor="">
                                                    <select name="" id="" className="form-control">
                                                        <option value="">Select</option>
                                                        <option value="">Male</option>
                                                        <option value="">Female</option>
                                                        <option value="">Other</option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">City/Town</label>
                                                <label htmlFor="">
                                                    <input type="text" name="" id="" className="form-control" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Country</label>
                                                <label htmlFor="">
                                                    <input type="text" name="" id="" className="form-control" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">Nationality</label>
                                                <label htmlFor="">
                                                    <input type="text" name="" id="" className="form-control" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="">ID/Passport No</label>
                                                <label htmlFor="">
                                                    <input type="text" name="" id="" className="form-control" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group dk-calenderIcon">
                                                <label htmlFor="">Joined on</label>
                                                <label htmlFor="">
                                                    <input type="date" name="" id="" className="form-control" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group dk-calenderIcon">
                                                <label htmlFor="">Duration</label>
                                                <label htmlFor="">
                                                    <input type="text" name="" id="" className="form-control" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-4">
                                            <div className="form-group text-center mb-0">
                                                <label htmlFor="" className="dk-submitBtn">
                                                    <button className="dk-cancelBtn" onClick={profileSave}>Cancel</button>
                                                    <button onClick={profileSave}>Save</button>
                                                </label>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                        }
                    </div>

                    {/* <div className="dk-profileCols">
                        <div className="dk-profileHead dk-profileemail">
                            <h4 className="title">My Email Address</h4>
                            <h6 className="sbtitle">View and message all of the email addresses associated with your account.</h6>
                        </div>

                        <div className="dk-profileForm dk-viewForm mt-2">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <div className="dk-emailIcon"><i class="far fa-envelope"></i></div>
                                                <div className="dk-profileEmailCols">
                                                    <div className="dk-profileEmailText">
                                                        deepak@dkglobalrecruitment.com
                                                        <div className="small">25 days ago</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dk-profileEmailIcon">
                                                <div className="d-flex">
                                                    <span><i class="fas fa-crown"></i></span>
                                                    <span><i class="far fa-circle"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="dk-profileCols">
                        <div className="dk-profileHead dk-profileemail">
                            <h4 className="title">My Mobile Numbers</h4>
                            <h6 className="sbtitle">View and message all of the mobile number associated with your account.</h6>
                        </div>

                        <div className="dk-profileForm dk-viewForm mt-2">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <div className="dk-emailIcon dk-phoneIcon"><i class="fas fa-phone-alt"></i></div>
                                                <div className="dk-profileEmailCols">
                                                    <div className="dk-profileEmailText">
                                                        (+353) 876942325
                                                        <div className="small">25 days ago</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dk-profileEmailIcon">
                                                <div className="d-flex">
                                                    <span><i class="fas fa-crown"></i></span>
                                                    <span><i class="far fa-circle"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}


                </div>

            </div>
        </React.Fragment>
    );
}

export default Profile;
