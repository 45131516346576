import React, { useState } from 'react';
import {Link} from "react-router-dom";
import "./login.scss"
import {DropdownButton,Dropdown} from 'react-bootstrap';
import AddnewModal from "./modal/addnew/AddnewModal";
import ReorderModal from "./modal/reorder/ReorderModal";
import ViewModal from "./modal/view/ViewModal";
import EditModal from "./modal/edit/EditModal";
import DeleteModal from "../../../ui/deletemodal/DeleteModal";
import SelectItem from "../../../ui/selectpicker/SelectItem";

const Logins = () => {
    const [addNewResorce, setaddNewResorce] = useState(false);
    const [isReorder, setisReorder] = useState(false);
    const [isViewModal, setisViewModal] = useState(false);
    const [isEditModal, setisEditModal] = useState(false);
    const [isDeleteModal, setisDeleteModal] = useState(false);
    const showAddNewResource = () =>{
        setaddNewResorce(true)   
    }
    const hideAddNewResource = () =>{
        setaddNewResorce(false)
    }
    const showReorder = () =>{
        setisReorder(true)   
    }
    const hideReorder = () =>{
        setisReorder(false)
    }
    const showviewModal = () =>{
        setisViewModal(true)
   }
   const hideviewModal = () =>{
       setisViewModal(false)
   }
    const showeditModal = () =>{
         setisEditModal(true)
    }
    const hideeditModal = () =>{
        setisEditModal(false)
    }
    const showDeletemodal  = () =>{
        setisDeleteModal(true)
    }
    const hideDeletemodal = () =>{
        setisDeleteModal(false)
    }
    const category = [
        { key: 'category_1', value: 'category_1', text: 'Portal Logins' },
        { key: 'category_2', value: 'category_2', text: 'Account Logins' },
        { key: 'category_3', value: 'category_3', text: 'Hosting Ireland' },
        { key: 'category_4', value: 'category_4', text: 'Sitel' },
        { key: 'category_5', value: 'category_5', text: 'Wipro' },
        { key: 'category_6', value: 'category_5', text: 'Godaddy' },
        { key: 'category_7', value: 'category_5', text: 'SMS' }
    ]
    const sourcetype = [
        { key: 'sourcetype_1', value: 'sourcetype_1', text: 'Clients Portals' },
        { key: 'sourcetype_2', value: 'sourcetype_2', text: 'Communication' },
        { key: 'sourcetype_3', value: 'sourcetype_3', text: 'Job Board' },
        { key: 'sourcetype_4', value: 'sourcetype_4', text: 'Social Media' },
        { key: 'sourcetype_5', value: 'sourcetype_5', text: 'Domain Hosting' }
    ]
    return (
        <React.Fragment>
            {
                (addNewResorce) ? <AddnewModal onHide={hideAddNewResource} />:null
            }
            {
                (isReorder) ? <ReorderModal onHide={hideReorder} />:null
            }
            {
                (isViewModal) ? <ViewModal  onHide={hideviewModal} />: null
            }
            {
                (isEditModal) ? <EditModal  onHide={hideeditModal} />:null
            }
            {
                (isDeleteModal) ? <DeleteModal onHide={hideDeletemodal}  />:null
            }
            <div className="container-fluid dk-resLoginsPageMain">
                <div className="row pl-3 pr-3">
                    <div className="col-md-4 col-sm-12">
                        <div className="dk-reSLeftheaderCont d-flex">
                            <span className="d-flex">
                                <input id="srchterm" type="text" className="form-control dk-topSerchInput" placeholder="Search..." value="" />
                                <div className="input-group-append dk-toplefSerchIcon">
                                    <button className="ml-0 mr-0" type="button"><i className="fa fa-search"></i></button>
                                </div>
                            </span>
                            <Link  className="addRdrbtn ml-2" onClick={showAddNewResource}>
                                <i className="fa fa-plus"></i>
                            </Link>
                            <Link  className="addRdrbtn ml-2" onClick={showReorder}>
                                <i className="fa fa-arrows-alt"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="dk-reSCenterheaderCont">
                            <h2 className="dk-reSPageTitle">RESOURCE LOGINS</h2>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 pr-0">
                        <div className="dk-reSRightheaderCont">
                            <div className="d-flex dk-resTopRightBtnCont">
                                <div className="dk-reSLoginFilter mr-2">
                                   <SelectItem options={category} placeholder="Category"/>
                                </div>
                                <div className="dk-reSLoginFilter">
                                   <SelectItem options={sourcetype} placeholder="Source Type"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="dk-ResloginTableCon table-responsive mt-3">
                            <div className="table dk-ResloginTable">
                                <thead>
                                    <tr>
                                        <th>Sn.</th>
                                        <th>Status</th>
                                        <th>Name of Source</th>
                                        <th>Category</th>
                                        <th>Source Type</th>
                                        <th>User Name</th>
                                        <th>Password</th>
                                        <th>Website Link</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>
                                            <div className="dk-reSDropDwon">
                                                <DropdownButton id="dropdown-basic-button" title="Active">
                                                    <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </td>
                                        <td>Mphasis</td>
                                        <td>Portal Logins</td>
                                        <td>Clients Portals</td>
                                        <td>deepak@dkglobalrecruitment.com</td>
                                        <td>DKglobal@2020</td>
                                        <td>
                                            <div className="webLinkBtn">
                                                <a target="_blank" className="dk-resGoToUrlbtn" href="https://www.dkmasterbox.com/">Go to URL</a>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2" onClick={showviewModal}>
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>   
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>
                                            <div className="dk-reSDropDwon">
                                                <DropdownButton id="dropdown-basic-button" title="Active">
                                                    <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </td>
                                        <td>Mphasis</td>
                                        <td>Portal Logins</td>
                                        <td>Clients Portals</td>
                                        <td>deepak@dkglobalrecruitment.com</td>
                                        <td>DKglobal@2020</td>
                                        <td>
                                            <div className="webLinkBtn">
                                                <a target="_blank" className="dk-resGoToUrlbtn" href="https://www.dkmasterbox.com/">Go to URL</a>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2" onClick={showviewModal}>
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>
                                            <div className="dk-reSDropDwon">
                                                <DropdownButton id="dropdown-basic-button" title="Active">
                                                    <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </td>
                                        <td>Mphasis</td>
                                        <td>Portal Logins</td>
                                        <td>Clients Portals</td>
                                        <td>deepak@dkglobalrecruitment.com</td>
                                        <td>DKglobal@2020</td>
                                        <td>
                                            <div className="webLinkBtn">
                                                <a target="_blank" className="dk-resGoToUrlbtn" href="https://www.dkmasterbox.com/">Go to URL</a>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2" onClick={showviewModal}>
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>
                                            <div className="dk-reSDropDwon">
                                                <DropdownButton id="dropdown-basic-button" title="Active">
                                                    <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </td>
                                        <td>Mphasis</td>
                                        <td>Portal Logins</td>
                                        <td>Clients Portals</td>
                                        <td>deepak@dkglobalrecruitment.com</td>
                                        <td>DKglobal@2020</td>
                                        <td>
                                            <div className="webLinkBtn">
                                                <a target="_blank" className="dk-resGoToUrlbtn" href="https://www.dkmasterbox.com/">Go to URL</a>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2" onClick={showviewModal}>
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>
                                            <div className="dk-reSDropDwon">
                                                <DropdownButton id="dropdown-basic-button" title="Active">
                                                    <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </td>
                                        <td>Mphasis</td>
                                        <td>Portal Logins</td>
                                        <td>Clients Portals</td>
                                        <td>deepak@dkglobalrecruitment.com</td>
                                        <td>DKglobal@2020</td>
                                        <td>
                                            <div className="webLinkBtn">
                                                <a target="_blank" className="dk-resGoToUrlbtn" href="https://www.dkmasterbox.com/">Go to URL</a>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2" onClick={showviewModal}>
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td>
                                            <div className="dk-reSDropDwon">
                                                <DropdownButton id="dropdown-basic-button" title="Active">
                                                    <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </td>
                                        <td>Mphasis</td>
                                        <td>Portal Logins</td>
                                        <td>Clients Portals</td>
                                        <td>deepak@dkglobalrecruitment.com</td>
                                        <td>DKglobal@2020</td>
                                        <td>
                                            <div className="webLinkBtn">
                                                <a target="_blank" className="dk-resGoToUrlbtn" href="https://www.dkmasterbox.com/">Go to URL</a>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2" onClick={showviewModal}>
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td>
                                            <div className="dk-reSDropDwon">
                                                <DropdownButton id="dropdown-basic-button" title="Active">
                                                    <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </td>
                                        <td>Mphasis</td>
                                        <td>Portal Logins</td>
                                        <td>Clients Portals</td>
                                        <td>deepak@dkglobalrecruitment.com</td>
                                        <td>DKglobal@2020</td>
                                        <td>
                                            <div className="webLinkBtn">
                                                <a target="_blank" className="dk-resGoToUrlbtn" href="https://www.dkmasterbox.com/">Go to URL</a>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2" onClick={showviewModal}>
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td>
                                            <div className="dk-reSDropDwon">
                                                <DropdownButton id="dropdown-basic-button" title="Active">
                                                    <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </td>
                                        <td>Mphasis</td>
                                        <td>Portal Logins</td>
                                        <td>Clients Portals</td>
                                        <td>deepak@dkglobalrecruitment.com</td>
                                        <td>DKglobal@2020</td>
                                        <td>
                                            <div className="webLinkBtn">
                                                <a target="_blank" className="dk-resGoToUrlbtn" href="https://www.dkmasterbox.com/">Go to URL</a>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2" onClick={showviewModal}>
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>   
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td>
                                            <div className="dk-reSDropDwon">
                                                <DropdownButton id="dropdown-basic-button" title="Active">
                                                    <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </td>
                                        <td>Mphasis</td>
                                        <td>Portal Logins</td>
                                        <td>Clients Portals</td>
                                        <td>deepak@dkglobalrecruitment.com</td>
                                        <td>DKglobal@2020</td>
                                        <td>
                                            <div className="webLinkBtn">
                                                <a target="_blank" className="dk-resGoToUrlbtn" href="https://www.dkmasterbox.com/">Go to URL</a>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2" onClick={showviewModal}>
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td>
                                            <div className="dk-reSDropDwon">
                                                <DropdownButton id="dropdown-basic-button" title="Active">
                                                    <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </td>
                                        <td>Mphasis</td>
                                        <td>Portal Logins</td>
                                        <td>Clients Portals</td>
                                        <td>deepak@dkglobalrecruitment.com</td>
                                        <td>DKglobal@2020</td>
                                        <td>
                                            <div className="webLinkBtn">
                                                <a target="_blank" className="dk-resGoToUrlbtn" href="https://www.dkmasterbox.com/">Go to URL</a>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2" onClick={showviewModal}>
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Logins;
