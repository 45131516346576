import React, { useState } from 'react';
import {Link} from "react-router-dom";
import {DropdownButton,Dropdown} from 'react-bootstrap';
import Manager from "../../../../assets/images/maria.png";
import AddnewModal from "./modal/addnew/AddnewModal";
import ReorderModal from "./modal/reorder/ReorderModal";
import ViewModal from "./modal/view/ViewModal";
import EditModal from "./modal/edit/EditModal";
import DeleteModal from "../../../ui/deletemodal/DeleteModal";
const MlResource = () => {
    const [addNewResorce, setaddNewResorce] = useState(false);
    const [isReorder, setisReorder] = useState(false);
    const [isViewModal, setisViewModal] = useState(false);
    const [isEditModal, setisEditModal] = useState(false);
    const [isDeleteModal, setisDeleteModal] = useState(false);
    const showAddNewResource = () =>{
        setaddNewResorce(true)   
    }
    const hideAddNewResource = () =>{
        setaddNewResorce(false)
    }
    const showReorder = () =>{
        setisReorder(true)   
    }
    const hideReorder = () =>{
        setisReorder(false)
    }
    const showviewModal = () =>{
        setisViewModal(true)
   }
   const hideviewModal = () =>{
       setisViewModal(false)
   }
    const showeditModal = () =>{
         setisEditModal(true)
    }
    const hideeditModal = () =>{
        setisEditModal(false)
    }
    const showDeletemodal  = () =>{
        setisDeleteModal(true)
    }
    const hideDeletemodal = () =>{
        setisDeleteModal(false)
    }
    return (
        <React.Fragment>
        {
            (addNewResorce) ? <AddnewModal onHide={hideAddNewResource} />:null
        }
        {
            (isReorder) ? <ReorderModal onHide={hideReorder} />:null
        }
        {
            (isViewModal) ? <ViewModal  onHide={hideviewModal} />: null
        }
        {
            (isEditModal) ? <EditModal  onHide={hideeditModal} />:null
        }
        {
            (isDeleteModal) ? <DeleteModal onHide={hideDeletemodal}  />:null
        }
            <div className="container-fluid dk-resMLPageCon">
                <div className="row">
                    <div className="col-md-4 col-sm-12">
                        <div className="dk-reSLeftheaderCont d-flex">
                            <input id="srchterm" type="text" className="form-control" name="search" placeholder="Search by Text" />
                            <Link className="dk-reSPopUpIcon dk-reScricleIcon ml-2" to="#">
                                <i className="fa fa-user" aria-hidden="true"></i>
                            </Link>
                            <Link className="dk-reScricleIcon ml-2" to="#">
                                <i className="fa fa-file " aria-hidden="true"></i>
                            </Link>
                            <Link className="dk-reScricleIcon ml-2" to="#">
                                <i className="fa fa-filter" aria-hidden="true"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="dk-reSCenterheaderCont">
                            <h2 className="dk-reSPageTitle">MULTILINGUAL SOURCES</h2>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="dk-reSRightheaderCont">
                            <div className="d-flex dk-resTopRightBtnCont">
                                <button type="button" className="btn-default btn Ripple-parent dk-reSRightHeaderBtn mr-0" onClick={showAddNewResource}>
                                    <i data-test="fa" className="fa fa-plus mr-2"></i>ADD NEW
                                </button>
                                <button type="button" className="btn-default btn Ripple-parent dk-reSRightHeaderBtn" onClick={showReorder}>
                                    <i data-test="fa" className="fa fa-arrows-alt mr-2"></i>REORDER
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-m-12 col-sm-12">
                        <div className="dk-MLreSourceTableCon table-responsive mt-2">
                            <div className="table dk-mlreSTable table-bordered">
                                <thead>
                                    <tr>
                                        <th>Sno.</th>
                                        <th>Manager</th>
                                        <th>Status</th>
                                        <th>Name of Source</th>
                                        <th>Category</th>
                                        <th>Source Type</th>
                                        <th>Paid/Unpaid</th>
                                        <th>Coverage</th>
                                        <th>Location</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            1
                                        </td>
                                        <td>
                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="person-imageFlex" alt="Manager" />
                                        </td>
                                        <td>
                                            <div className="dk-reSDropDwon">
                                                <DropdownButton id="dropdown-basic-button" title="Active">
                                                    <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </td>
                                        <td className="text-left">Pracuj.pl</td>
                                        <td>Job Board</td>
                                        <td>Ad Posting </td>
                                        <td>Paid</td>
                                        <td>Local Only</td>
                                        <td>Poland</td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2" onClick={showviewModal}>
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            2
                                        </td>
                                        <td>
                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="person-imageFlex" alt="Manager" />
                                        </td>
                                        <td>
                                            <div className="dk-reSDropDwon">
                                                <DropdownButton id="dropdown-basic-button" title="Active">
                                                    <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </td>
                                        <td className="text-left">Pracuj.pl</td>
                                        <td>Job Board</td>
                                        <td>Ad Posting </td>
                                        <td>Paid</td>
                                        <td>Local Only</td>
                                        <td>Poland</td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2" onClick={showviewModal}>
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            3
                                        </td>
                                        <td>
                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="person-imageFlex" alt="Manager" />
                                        </td>
                                        <td>
                                            <div className="dk-reSDropDwon">
                                                <DropdownButton id="dropdown-basic-button" title="Active">
                                                    <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </td>
                                        <td className="text-left">Pracuj.pl</td>
                                        <td>Job Board</td>
                                        <td>Ad Posting </td>
                                        <td>Paid</td>
                                        <td>Local Only</td>
                                        <td>Poland</td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2" onClick={showviewModal}>
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            4
                                        </td>
                                        <td>
                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="person-imageFlex" alt="Manager" />
                                        </td>
                                        <td>
                                            <div className="dk-reSDropDwon">
                                                <DropdownButton id="dropdown-basic-button" title="Active">
                                                    <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </td>
                                        <td className="text-left">Pracuj.pl</td>
                                        <td>Job Board</td>
                                        <td>Ad Posting </td>
                                        <td>Paid</td>
                                        <td>Local Only</td>
                                        <td>Poland</td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2" onClick={showviewModal}>
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            5
                                        </td>
                                        <td>
                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="person-imageFlex" alt="Manager" />
                                        </td>
                                        <td>
                                            <div className="dk-reSDropDwon">
                                                <DropdownButton id="dropdown-basic-button" title="Active">
                                                    <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </td>
                                        <td className="text-left">Pracuj.pl</td>
                                        <td>Job Board</td>
                                        <td>Ad Posting </td>
                                        <td>Paid</td>
                                        <td>Local Only</td>
                                        <td>Poland</td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2" onClick={showviewModal}>
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            6
                                        </td>
                                        <td>
                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="person-imageFlex" alt="Manager" />
                                        </td>
                                        <td>
                                            <div className="dk-reSDropDwon">
                                                <DropdownButton id="dropdown-basic-button" title="Active">
                                                    <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </td>
                                        <td className="text-left">Pracuj.pl</td>
                                        <td>Job Board</td>
                                        <td>Ad Posting </td>
                                        <td>Paid</td>
                                        <td>Local Only</td>
                                        <td>Poland</td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2" onClick={showviewModal}>
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            7
                                        </td>
                                        <td>
                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="person-imageFlex" alt="Manager" />
                                        </td>
                                        <td>
                                            <div className="dk-reSDropDwon">
                                                <DropdownButton id="dropdown-basic-button" title="Active">
                                                    <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </td>
                                        <td className="text-left">Pracuj.pl</td>
                                        <td>Job Board</td>
                                        <td>Ad Posting </td>
                                        <td>Paid</td>
                                        <td>Local Only</td>
                                        <td>Poland</td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2" onClick={showviewModal}>
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>   
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            8
                                        </td>
                                        <td>
                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="person-imageFlex" alt="Manager" />
                                        </td>
                                        <td>
                                            <div className="dk-reSDropDwon">
                                                <DropdownButton id="dropdown-basic-button" title="Active">
                                                    <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </td>
                                        <td className="text-left">Pracuj.pl</td>
                                        <td>Job Board</td>
                                        <td>Ad Posting </td>
                                        <td>Paid</td>
                                        <td>Local Only</td>
                                        <td>Poland</td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2" onClick={showviewModal}>
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            9
                                        </td>
                                        <td>
                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="person-imageFlex" alt="Manager" />
                                        </td>
                                        <td>
                                            <div className="dk-reSDropDwon">
                                                <DropdownButton id="dropdown-basic-button" title="Active">
                                                    <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                    <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </td>
                                        <td className="text-left">Pracuj.pl</td>
                                        <td>Job Board</td>
                                        <td>Ad Posting </td>
                                        <td>Paid</td>
                                        <td>Local Only</td>
                                        <td>Poland</td>
                                        <td>
                                            <div className="dk-resAction">
                                                <a className="dk-view-btn mr-2" onClick={showviewModal}>
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-edit-btn mr-2" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </a>
                                                <a className="dk-delete-btn" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MlResource;
