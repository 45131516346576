import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import { Dropdown } from 'semantic-ui-react';
import './summary.scss';

const CaseStatusOption = [
    {
        key: 'DKG Process',
        text: 'DKG Process',
        value: 'DKG Process',
        label: '5120',
        style: {
            background: '#2d9b99',
            color: '#fff',
        }
    },
    {
        key: 'Client Process',
        text: 'Client Process',
        value: 'Client Process',
        label: '54',
        style: {
            background: '#286967',
            color: '#fff',
        }
    },
    {
        key: 'Job Offer',
        text: 'Job Offer',
        value: 'Job Offer',
        label: '16',
        style: {
            background: '#629bd3',
            color: '#fff',
        }
    },
    {
        key: 'Refused Offer',
        text: 'Refused Offer',
        value: 'Refused Offer',
        label: '288',
        style: {
            background: '#6b0000',
            color: '#fff',
        }
    },
    {
        key: 'Placement',
        text: 'Placement',
        value: 'Placement',
        label: '487',
        style: {
            background: '#4267b2',
            color: '#fff',
        }
    },
    {
        key: 'Credit',
        text: 'Credit',
        value: 'Credit',
        label: '90',
        style: {
            background: '#df744a',
            color: '#fff',
        }
    },
    {
        key: 'Closed',
        text: 'Closed',
        value: 'Closed',
        label: '27859',
        style: {
            background: '#e2445c',
            color: '#fff',
        }
    },
]

const StatusDropdown = () => {
    return (
        <React.Fragment>
            <Dropdown
                placeholder='Case Status'
                clearable
                fluid
                selection
                options={CaseStatusOption}
            />
        </React.Fragment>
    );
}

export default StatusDropdown;