import React from 'react';
import {Link} from "react-router-dom";
import SelectItem from '../../../../ui/selectpicker/SelectItem'

const Hiringfee = () => {
    const department = [
        { key: 'department_1', value: 'department_1', text: 'Department 1' },
        { key: 'department_2', value: 'department_2', text: 'Department 2' },
        { key: 'department_3', value: 'department_3', text: 'Department 3' },
        { key: 'department_4', value: 'department_4', text: 'Department 4' },
        { key: 'department_5', value: 'department_5', text: 'Department 5' }
    ]
    return (
        <React.Fragment>
            <div className="row pr-3">
                <div className="col-md-4">
                    <div className="dkclietopLeft d-flex">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Search by Text"/>
                            <div className="input-group-append">
                            <button className="btn btn-secondary legitRipple" type="button">
                                <i className="fa fa-search"></i>
                            </button>
                            </div>
                        </div>
                        <Link id="" to="#" className=" dk-topTbleIcon ml-2">
                            <i className="fas fa-sync-alt"></i>
                        </Link>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="dk-cliDetTabPaneBlkCon">
                        <div className="dk-cliDetTitle">
                            <h2 className="mb-0 mt-2">PER HIRE FEE</h2>
                        </div>
                    </div>    
                </div>
                <div className="col-md-4 dk-CliContactRight">
                    <div className="dkclietopRight">
                        <Link href="#" className=" dk-topTbleIcon mr-2">
                           <i className="fas fa-arrows-alt"></i>
                        </Link>
                    </div>
                    <Link to="#" className="dkHringAddBtn">
                       <i className="fa fa-plus" aria-hidden="true"></i> Add New
                    </Link>
                </div>
            </div>
            <div className="row pr-3 mt-1">
                <div className="col-md-12 col-sm-12">
                    <div className="dk-cliDetailTableCon dk-perHireFreeTableCon table-responsive">
                        <table className="table table-bordered dk-cliDetialTable">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Updated</th>
                                    <th>Category</th>
                                    <th>Project</th>
                                    <th>Role Type</th>
                                    <th>Language</th>
                                    <th>Fee Per Hire</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>01</td>
                                    <td>
                                        01 Jan 2021
                                    </td>
                                    <td>Category 1</td>
                                    <td>Project 1</td>
                                    <td>Role Type 1</td>
                                    <td>Language 1</td>
                                    <td>Fee Per Hire 1</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <a className="dk-edit-btn mr-2" href="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </a>
                                            <a className="dk-delete-btn" href="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>02</td>
                                    <td>
                                        01 Jan 2021
                                    </td>
                                    <td>Category 2</td>
                                    <td>Project 2</td>
                                    <td>Role Type 2</td>
                                    <td>Language 2</td>
                                    <td>Fee Per Hire 2</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <a className="dk-edit-btn mr-2" href="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </a>
                                            <a className="dk-delete-btn" href="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>03</td>
                                    <td>
                                        01 Jan 2021
                                    </td>
                                    <td>Category 3</td>
                                    <td>Project 3</td>
                                    <td>Role Type 3</td>
                                    <td>Language 3</td>
                                    <td>Fee Per Hire 3</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <a className="dk-edit-btn mr-2" href="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </a>
                                            <a className="dk-delete-btn" href="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>04</td>
                                    <td>
                                        01 Jan 2021
                                    </td>
                                    <td>Category 4</td>
                                    <td>Project 4</td>
                                    <td>Role Type 4</td>
                                    <td>Language 4</td>
                                    <td>Fee Per Hire 4</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <a className="dk-edit-btn mr-2" href="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </a>
                                            <a className="dk-delete-btn" href="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr> 
                                <tr>
                                    <td>05</td>
                                    <td>
                                        01 Jan 2021
                                    </td>
                                    <td>Category 5</td>
                                    <td>Project 5</td>
                                    <td>Role Type 5</td>
                                    <td>Language 5</td>
                                    <td>Fee Per Hire 5</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <a className="dk-edit-btn mr-2" href="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </a>
                                            <a className="dk-delete-btn" href="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>06</td>
                                    <td>
                                        01 Jan 2021
                                    </td>
                                    <td>Category 1</td>
                                    <td>Project 1</td>
                                    <td>Role Type 1</td>
                                    <td>Language 1</td>
                                    <td>Fee Per Hire 1</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <a className="dk-edit-btn mr-2" href="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </a>
                                            <a className="dk-delete-btn" href="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>07</td>
                                    <td>
                                        01 Jan 2021
                                    </td>
                                    <td>Category 2</td>
                                    <td>Project 2</td>
                                    <td>Role Type 2</td>
                                    <td>Language 2</td>
                                    <td>Fee Per Hire 2</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <a className="dk-edit-btn mr-2" href="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </a>
                                            <a className="dk-delete-btn" href="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>08</td>
                                    <td>
                                        01 Jan 2021
                                    </td>
                                    <td>Category 3</td>
                                    <td>Project 3</td>
                                    <td>Role Type 3</td>
                                    <td>Language 3</td>
                                    <td>Fee Per Hire 3</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <a className="dk-edit-btn mr-2" href="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </a>
                                            <a className="dk-delete-btn" href="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>09</td>
                                    <td>
                                        01 Jan 2021
                                    </td>
                                    <td>Category 4</td>
                                    <td>Project 4</td>
                                    <td>Role Type 4</td>
                                    <td>Language 4</td>
                                    <td>Fee Per Hire 4</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <a className="dk-edit-btn mr-2" href="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </a>
                                            <a className="dk-delete-btn" href="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr> 
                                <tr>
                                    <td>10</td>
                                    <td>
                                        01 Jan 2021
                                    </td>
                                    <td>Category 5</td>
                                    <td>Project 5</td>
                                    <td>Role Type 5</td>
                                    <td>Language 5</td>
                                    <td>Fee Per Hire 5</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <a className="dk-edit-btn mr-2" href="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </a>
                                            <a className="dk-delete-btn" href="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>                                                    
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Hiringfee;
