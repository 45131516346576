import React, { useState } from 'react';
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

const MessageModal = ({inboxId}) => {    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <React.Fragment>
            <span className="dk-smsDetailRead" onClick={handleShow}>
                <img src="https://www.dkbluebox.com/assets/images/open-pop.png" className="img-fluid" alt="" />
            </span>
            <Modal className="dk-smsMessageModal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> 
                        SMS Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-mlAddModalBody">
                    <p>{inboxId}</p>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default MessageModal;
