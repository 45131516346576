import React, { useState, useMemo, useEffect } from "react";
import { Route, Link } from "react-router-dom";
import AddNewBkValuesModal from './AddNewBkValuesModal';
import EditBkValuesModal from './EditBkValuesModal';
import './bkvalues.scss';
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";
import { loadingStart } from "../../../../../slice/utils/loader/linearSlice";
import { mainCategoryBox1, subCategoryBox2, subCategoryBox3, subCategoryBox4, valuesList, addValue, clearState, clearBox2, clearBox3, clearBox4 } from "../../../../../slice/admin/backendValues/backendValuesSlice";
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import ValueList from "./ValueList";

const useStyles = makeStyles({
    root: {
        width: 300,
    },
});

const BkValues = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { box1List, box2List, box3List, box4List, isLoadingBox1, isLoadingBox2, isLoadingBox3, isLoadingBox4, backendValuesList } = useSelector(state => state.backendValues)

    const [selectCategory, setSelectCategory] = useState('0')

    const [mainCategoryId, setMainCategoryId] = useState('0')
    const [subCategoryBox2Id, setSubCategoryBox2Id] = useState('0')
    const [subCategoryBox3Id, setSubCategoryBox3Id] = useState('0')
    const [subCategoryBox4Id, setSubCategoryBox4Id] = useState('0')

    useEffect(() => {
        async function fetchData() {
            try {
                await dispatch(mainCategoryBox1({}))
            } catch (err) {
                console.log(err)
            }
        }
        fetchData()
    }, [])

    useEffect(() => {
        if (mainCategoryId != '0') {
            async function fetchData() {
                try {
                    await dispatch(subCategoryBox2({ mainCategory: mainCategoryId }))
                    await dispatch(valuesList({ categoryId: mainCategoryId }))
                } catch (err) {
                    console.log(err)
                }
            }
            fetchData()
        }
    }, [mainCategoryId])

    useEffect(() => {
        if (subCategoryBox2Id != '0') {
            async function fetchData() {
                try {
                    await dispatch(subCategoryBox3({ parentId: mainCategoryId, parentId2: subCategoryBox2Id }))
                    await dispatch(valuesList({ categoryId: subCategoryBox2Id }))
                } catch (err) {
                    console.log(err)
                }
            }
            fetchData()
        }
    }, [subCategoryBox2Id])

    useEffect(() => {
        if (subCategoryBox3Id != '0') {
            async function fetchData() {
                try {
                    await dispatch(subCategoryBox4({ parentId: mainCategoryId, parentId2: subCategoryBox2Id, parentId3: subCategoryBox3Id }))
                    await dispatch(valuesList({ categoryId: subCategoryBox3Id }))
                } catch (err) {
                    console.log(err)
                }
            }
            fetchData()
        }
    }, [subCategoryBox3Id])

    const clickMainCategory = (id) => {
        setSelectCategory(id)
        setMainCategoryId(id)
        setSubCategoryBox2Id('0')
        setSubCategoryBox3Id('0')
        setSubCategoryBox4Id('0')
        dispatch(clearBox2())
        dispatch(clearBox3())
        dispatch(clearBox4())
    }

    const clickSubCategoryBox2 = (id) => {
        setSelectCategory(id)
        setSubCategoryBox2Id(id)
        setSubCategoryBox3Id('0')
        setSubCategoryBox4Id('0')
        dispatch(clearBox3())
        dispatch(clearBox4())
    }

    const clickSubCategoryBox3 = (id) => {
        setSelectCategory(id)
        setSubCategoryBox3Id(id)
        setSubCategoryBox4Id('0')
        dispatch(clearBox4())
    }

    const clickSubCategoryBox4 = (id) => {
        setSelectCategory(id)
        setSubCategoryBox4Id(id)
    }

    const addNewValues = () => {
        dispatch(addValue({ name: '', categoryId: selectCategory, bgColor: '#fff', textColor: '#333' }))
    }

    return (
        <React.Fragment>
            <div className="dk-toolsBkValues">
                <div className="dk-toolsValues-flex">
                    <div className="dk-toolsValues-cols">
                        <div className="dk-valueTitle">
                            <div className="d-flex align-items-center justify-content-between">
                                <EditBkValuesModal boxNo="1" parentId1="0" parentId2="0" parentId3="0" parentId4="0" headingText="Main Category" />
                                <h6 className="subTitle">Main Category</h6>
                                <AddNewBkValuesModal boxNo="1" parentId1="0" parentId2="0" parentId3="0" parentId4="0" headingText="Add Main Category" />
                            </div>
                        </div>
                        <div className="dk-toolsValueBox">
                            <ul>
                                {
                                    (isLoadingBox1) ? <li>
                                        <div class="clickBody">
                                            <Skeleton animation="wave" />
                                        </div>
                                    </li> :
                                        box1List.map((item, indx) => (
                                            <li><div class={(mainCategoryId == item._id) ? 'clickBody activeTabs' : 'clickBody'} onClick={() => clickMainCategory(item._id)}>{item.name}</div></li>
                                        ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="dk-toolsValues-cols">
                        <div className="dk-valueTitle">
                            <div className="d-flex align-items-center justify-content-between">
                                {
                                    (mainCategoryId != '0') ? <EditBkValuesModal boxNo="2" parentId1={mainCategoryId} parentId2="0" parentId3="0" parentId4="0" headingText="1st Sub Category" /> : <Link to="#" onClick={() => dispatch(showError({ msg: 'Select Main Category' }))}><i class="far fa-edit"></i></Link>
                                }
                                <h6 className="subTitle">1st Sub Category</h6>
                                {
                                    (mainCategoryId != '0') ? <AddNewBkValuesModal boxNo="2" parentId1={mainCategoryId} parentId2="0" parentId3="0" parentId4="0" headingText="Add 1st Sub Category" /> : <Link to="#" onClick={() => dispatch(showError({ msg: 'Select Main Category' }))}><i class="fas fa-plus"></i></Link>
                                }
                            </div>
                        </div>
                        <div className="dk-toolsValueBox">
                            <ul>
                                {
                                    (isLoadingBox2) ? <li>
                                        <div class="clickBody">
                                            <Skeleton animation="wave" />
                                        </div>
                                    </li> :
                                        box2List.map((item, indx) => (
                                            <li><div class={(subCategoryBox2Id == item._id) ? 'clickBody activeTabs' : 'clickBody'} onClick={() => clickSubCategoryBox2(item._id)}>{item.name}</div></li>
                                        ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="dk-toolsValues-cols">
                        <div className="dk-valueTitle">
                            <div className="d-flex align-items-center justify-content-between">
                                {
                                    (mainCategoryId != '0' && subCategoryBox2Id != '0') ? <EditBkValuesModal boxNo="3" parentId1={mainCategoryId} parentId2={subCategoryBox2Id} parentId3="0" parentId4="0" headingText="2nd Sub Category" /> : <Link to="#" onClick={() => dispatch(showError({ msg: 'Select 1st Sub Category' }))}><i class="far fa-edit"></i></Link>
                                }
                                <h6 className="subTitle">2nd Sub Category</h6>
                                {
                                    (mainCategoryId != '0' && subCategoryBox2Id != '0') ? <AddNewBkValuesModal boxNo="3" parentId1={mainCategoryId} parentId2={subCategoryBox2Id} parentId3="0" parentId4="0" headingText="Add 2nd Sub Category" /> : <Link to="#" onClick={() => dispatch(showError({ msg: 'Select 1st Sub Category' }))}><i class="fas fa-plus"></i></Link>
                                }
                            </div>
                        </div>
                        <div className="dk-toolsValueBox">
                            <ul>
                                {
                                    (isLoadingBox3) ? <li>
                                        <div class="clickBody">
                                            <Skeleton animation="wave" />
                                        </div>
                                    </li> :
                                        box3List.map((item, indx) => (
                                            <li><div class={(subCategoryBox3Id == item._id) ? 'clickBody activeTabs' : 'clickBody'} onClick={() => clickSubCategoryBox3(item._id)}>{item.name}</div></li>
                                        ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="dk-toolsValues-cols">
                        <div className="dk-valueTitle">
                            <div className="d-flex align-items-center justify-content-between">
                                {
                                    (mainCategoryId != '0' && subCategoryBox2Id != '0' && subCategoryBox3Id != '0') ? <EditBkValuesModal boxNo="3" parentId1={mainCategoryId} parentId2={subCategoryBox2Id} parentId3={subCategoryBox3Id} parentId4="0" headingText="3rd Sub Category" /> : <Link to="#" onClick={() => dispatch(showError({ msg: 'Select 2nd Sub Category' }))}><i class="far fa-edit"></i></Link>
                                }
                                <h6 className="subTitle">3rd Sub Category</h6>
                                {
                                    (mainCategoryId != '0' && subCategoryBox2Id != '0' && subCategoryBox3Id != '0') ? <AddNewBkValuesModal boxNo="4" parentId1={mainCategoryId} parentId2={subCategoryBox2Id} parentId3={subCategoryBox3Id} parentId4="0" headingText="Add 3rd Sub Category" /> : <Link to="#" onClick={() => dispatch(showError({ msg: 'Select 2nd Sub Category' }))}><i class="fas fa-plus"></i></Link>
                                }
                            </div>
                        </div>
                        <div className="dk-toolsValueBox">
                            <ul>
                                {
                                    (isLoadingBox4) ? <li>
                                        <div class="clickBody">
                                            <Skeleton animation="wave" />
                                        </div>
                                    </li> :
                                        box4List.map((item, indx) => (
                                            <li><div class={(subCategoryBox4Id == item._id) ? 'clickBody activeTabs' : 'clickBody'} onClick={() => clickSubCategoryBox4(item._id)}>{item.name}</div></li>
                                        ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="dk-toolsValues-cols dk-toolsLastValue ml-3">
                        <div className="dk-valueTitle">
                            <div className="d-flex align-items-center justify-content-between">
                                <Link to="#"></Link>
                                <h6 className="subTitle">Values</h6>
                                {
                                    (selectCategory != '0') ? <Link to="#" onClick={addNewValues}><i class="fas fa-plus"></i></Link> : <Link to="#" onClick={() => dispatch(showError({ msg: 'Select Category' }))}><i class="fas fa-plus"></i></Link>
                                }
                            </div>
                        </div>
                        <div className="dk-toolsValueField">
                            {
                                (backendValuesList.length > 0) ? <ValueList dataList={backendValuesList} /> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default BkValues;
