import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
/** Import All Loyouts */
import AdminLayout from "../layout/AdminLayout";
/** End Import All Loyouts */
import { useDispatch, useSelector } from "react-redux";
import { userslist } from "../../slice/userList/userSlice"

const AdminRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticate } = useSelector(state => state.auth)
    const { userInfo } = useSelector(state => state.auth)
    const companyId = userInfo.companyId._id;
    const dispatch = useDispatch();
    useEffect(() => {
        async function fetchUserList() {
            try {
                await dispatch(userslist({ companyId: companyId }))
            } catch (err) {
                console.log(err)
            }
        }
        fetchUserList()
    }, [])

    return (
        <Route {...rest} render={props => {
            if (isAuthenticate)
                return <AdminLayout><Component {...props} /></AdminLayout>
            return <Redirect
                to={{
                    pathname: "/"
                    , state: { from: props.location }
                }}
            />
        }} />
    )
};
export default AdminRoute;