import React,{useState} from 'react';
import  "./style.scss";
import {Link} from "react-router-dom";
import AddnewModal from "./modal/addnew/AddnewModal";
import ReorderModal from "./modal/reorder/ReorderModal";
import ViewModal from "./modal/view/ViewModal";
import EditModal from "./modal/edit/EditModal";
import DeleteModal from "../../../ui/deletemodal/DeleteModal";
import SelectItem from "../../../ui/selectpicker/SelectItem";
const Policies = () => {
    const [addNewResorce, setaddNewResorce] = useState(false);
    const [isReorder, setisReorder] = useState(false);
    const [isViewModal, setisViewModal] = useState(false);
    const [isEditModal, setisEditModal] = useState(false);
    const [isDeleteModal, setisDeleteModal] = useState(false);
    const showAddNewResource = () =>{
        setaddNewResorce(true)   
    }
    const hideAddNewResource = () =>{
        setaddNewResorce(false)
    }
    const showReorder = () =>{
        setisReorder(true)   
    }
    const hideReorder = () =>{
        setisReorder(false)
    }
    const showviewModal = () =>{
        setisViewModal(true)
   }
   const hideviewModal = () =>{
       setisViewModal(false)
   }
    const showeditModal = () =>{
         setisEditModal(true)
    }
    const hideeditModal = () =>{
        setisEditModal(false)
    }
    const showDeletemodal  = () =>{
        setisDeleteModal(true)
    }
    const hideDeletemodal = () =>{
        setisDeleteModal(false)
    }
    return (
        <React.Fragment>
            {
                (addNewResorce) ? <AddnewModal onHide={hideAddNewResource} />:null
            }
            {
                (isReorder) ? <ReorderModal onHide={hideReorder} />:null
            }
            {
                (isViewModal) ? <ViewModal  onHide={hideviewModal} />: null
            }
            {
                (isEditModal) ? <EditModal  onHide={hideeditModal} />:null
            }
            {
                (isDeleteModal) ? <DeleteModal onHide={hideDeletemodal}  />:null
            }
            <div className="container-fluid dk-cliListViewPageCont">
                <div className="row">
                    <div className="col-12 col-md-12 text-center">
                        <div className="row">
                            <div className="col-md-4 col-sm-12 dk-dkClileftGap">
                                <div class="dk-cliLeftheaderCont d-flex pl-3">
                                    <input id="srchterm" type="text" class="form-control searchTxtBox" name="search" placeholder="Search by Text" />
                                    <button class="btn dk-mbclientsrchInput legitRipple" type="button">
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="dk-clntsPageTitle">
                                    <h2>Clients policies</h2>
                                </div> 
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="dk-ClieAddNewBtn pr-3" onClick={showAddNewResource}>
                                    <Link to="#"><i class="fa fa-plus mr-2" aria-hidden="true"></i>Add New</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <div className="listViewTableCon table-responsive">
                            <div className="table dk-clListViewTable">
                                <thead>
                                    <tr>
                                        <th>
                                            {/* <div class="dk-cliLeftheaderCont d-flex">
                                                <input id="srchterm" type="text" class="form-control searchTxtBox" name="search" placeholder="Search by Text" />
                                                <button class="btn dk-mbclientsrchInput legitRipple" type="button">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </div> */}
                                            SNo.
                                        </th>
                                        <th>Status</th>
                                        <th>Name of Client</th>
                                        <th>Category</th>
                                        <th>Contract</th>
                                        <th>Guarantee</th>
                                        <th>Duplication</th>
                                        <th>Sendouts</th>
                                        <th>Invoice</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            01
                                        </td>
                                        <td>
                                            <Link class=" dk-cllistStatusbtnt dk-clientsActBtn" href="">Active</Link>
                                        </td>
                                        <td>
                                            Lydia Stavropoulou
                                        </td>
                                        <td>
                                            ML
                                        </td>
                                        <td>
                                            Contract 1
                                        </td>
                                        <td>
                                           Guarantee 1
                                        </td>
                                        <td>
                                            Duplication 1
                                        </td>
                                        <td>Sendouts 1</td>
                                        <td>Invoice 1</td>
                                        <td>
                                            <div class="dk-CliDetailAction">
                                                {/* <Link class="dk-view-btn mr-2" href="#">
                                                    <i class="fa fa-eye " aria-hidden="true"></i>
                                                </Link> */}
                                                <Link class="dk-edit-btn mr-2" href="#" onClick={showeditModal}>
                                                    <i class="fa fa-edit " aria-hidden="true"></i>
                                                </Link>
                                                <Link class="dk-delete-btn" href="#" onClick={showDeletemodal}>
                                                    <i class="fa fa-trash " aria-hidden="true"></i>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            02
                                        </td>
                                        <td>
                                            <Link class=" dk-cllistStatusbtnt dk-clientsActBtn" href="">Active</Link>
                                        </td>
                                        <td>
                                            Lydia Stavropoulou
                                        </td>
                                        <td>
                                            ML
                                        </td>
                                        <td>
                                            Contract 2
                                        </td>
                                        <td>
                                           Guarantee 2
                                        </td>
                                        <td>
                                            Duplication 2
                                        </td>
                                        <td>Sendouts 2</td>
                                        <td>Invoice 2</td>
                                        <td>
                                            <div class="dk-CliDetailAction">
                                                {/* <Link class="dk-view-btn mr-2" href="#">
                                                    <i class="fa fa-eye " aria-hidden="true"></i>
                                                </Link> */}
                                                <Link class="dk-edit-btn mr-2" href="#" onClick={showeditModal}>
                                                    <i class="fa fa-edit " aria-hidden="true"></i>
                                                </Link>
                                                <Link class="dk-delete-btn" href="#" onClick={showDeletemodal}>
                                                    <i class="fa fa-trash " aria-hidden="true"></i>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                             03
                                        </td>
                                        <td>
                                            <Link class=" dk-cllistStatusbtnt dk-clientsActBtn" href="">Active</Link>
                                        </td>
                                        <td>
                                            Lydia Stavropoulou
                                        </td>
                                        <td>
                                            ML
                                        </td>
                                        <td>
                                            Contract 3
                                        </td>
                                        <td>
                                           Guarantee 3
                                        </td>
                                        <td>
                                            Duplication 3
                                        </td>
                                        <td>Sendouts 3</td>
                                        <td>Invoice 3</td>
                                        <td>
                                            <div class="dk-CliDetailAction">
                                                {/* <Link class="dk-view-btn mr-2" href="#">
                                                    <i class="fa fa-eye " aria-hidden="true"></i>
                                                </Link> */}
                                                <Link class="dk-edit-btn mr-2" href="#" onClick={showeditModal}>
                                                    <i class="fa fa-edit " aria-hidden="true"></i>
                                                </Link>
                                                <Link class="dk-delete-btn" href="#" onClick={showDeletemodal}>
                                                    <i class="fa fa-trash " aria-hidden="true"></i>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            04
                                        </td>
                                        <td>
                                            <Link class=" dk-cllistStatusbtnt dk-clientsActBtn" href="">Active</Link>
                                        </td>
                                        <td>
                                            Lydia Stavropoulou
                                        </td>
                                        <td>
                                            ML
                                        </td>
                                        <td>
                                            Contract 4
                                        </td>
                                        <td>
                                           Guarantee 4
                                        </td>
                                        <td>
                                            Duplication 4
                                        </td>
                                        <td>Sendouts 4</td>
                                        <td>Invoice 4</td>
                                        <td>
                                            <div class="dk-CliDetailAction">
                                                {/* <Link class="dk-view-btn mr-2" href="#">
                                                    <i class="fa fa-eye " aria-hidden="true"></i>
                                                </Link> */}
                                                <Link class="dk-edit-btn mr-2" href="#" onClick={showeditModal}>
                                                    <i class="fa fa-edit " aria-hidden="true"></i>
                                                </Link>
                                                <Link class="dk-delete-btn" href="#" onClick={showDeletemodal}>
                                                    <i class="fa fa-trash " aria-hidden="true"></i>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            05
                                        </td>
                                        <td>
                                            <Link class=" dk-cllistStatusbtnt dk-clientsActBtn" href="">Active</Link>
                                        </td>
                                        <td>
                                            Lydia Stavropoulou
                                        </td>
                                        <td>
                                            ML
                                        </td>
                                        <td>
                                            Contract 5
                                        </td>
                                        <td>
                                           Guarantee 5
                                        </td>
                                        <td>
                                            Duplication 5
                                        </td>
                                        <td>Sendouts 5</td>
                                        <td>Invoice 5</td>
                                        <td>
                                            <div class="dk-CliDetailAction">
                                                {/* <Link class="dk-view-btn mr-2" href="#">
                                                    <i class="fa fa-eye " aria-hidden="true"></i>
                                                </Link> */}
                                                <Link class="dk-edit-btn mr-2" href="#" onClick={showeditModal}>
                                                    <i class="fa fa-edit " aria-hidden="true"></i>
                                                </Link>
                                                <Link class="dk-delete-btn" href="#" onClick={showDeletemodal}>
                                                    <i class="fa fa-trash " aria-hidden="true"></i>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Policies;

