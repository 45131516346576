import React,{useState} from 'react';
const ShortDetails = ({ details,maxLength }) => {    
    return (
        <React.Fragment>
            {
            (details.length) > maxLength ? (details.substring(0,maxLength) + '. . .') : details.substring(0,maxLength)
            }
        </React.Fragment>
    )
}
export default ShortDetails;



