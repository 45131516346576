import React,{useState} from 'react';
import {Link} from "react-router-dom";
import {Modal} from 'react-bootstrap';
import "./addnewres.scss"
import ManagerImg from "../../../../../../assets/images/deepak.png";
import SelectItem from "../../../../../ui/selectpicker/SelectItem";


const AddnewModal = ({onHide}) => {
    const department = [
        { key: 'department_1', value: 'department_1', text: 'Department 1' },
        { key: 'department_2', value: 'department_2', text: 'Department 2' },
        { key: 'department_3', value: 'department_3', text: 'Department 3' },
        { key: 'department_4', value: 'department_4', text: 'Department 4' },
        { key: 'department_5', value: 'department_5', text: 'Department 5' }
    ]
    return (
        <React.Fragment>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dk-addNewResModalDialog modal-40w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton className="dk-resourcePopupHedaer">
                    <Modal.Title id="example-custom-modal-styling-title">
                        Add New Resource
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                    <label class="dk-mlmangerLabel">Manager</label>
                                    <Link class="dk-reSPopUpIcon" to="#">
                                        <img src={ManagerImg} className="selectedUserImgPop" alt="ManagerImg" />
                                    </Link>
                                </div>
                                <div className="form-group">
                                    <label class="dk-mlmangerLabel">Status</label>
                                    <div className="dk-addNewSlectDropDwn">
                                      <SelectItem options={department} placeholder=""/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label class="dk-mlmangerLabel">Source Type</label>
                                    <div className="dk-addNewSlectDropDwn">
                                        <SelectItem options={department} placeholder=""/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label class="dk-mlmangerLabel">Name of Source</label>
                                    <input type="text" class="form-control broderContrl" name="name" />
                                </div>
                                <div className="form-group">
                                    <label class="dk-mlmangerLabel">Category</label>
                                    <div className="dk-addNewSlectDropDwn">
                                        <SelectItem options={department} placeholder=""/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label class="dk-mlmangerLabel">Paid/Unpaid</label>
                                    <div className="dk-addNewSlectDropDwn">
                                        <SelectItem options={department} placeholder=""/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label class="dk-mlmangerLabel">Location</label>
                                    <input type="text" class="form-control broderContrl" name="name" />
                                </div>
                                <div className="form-group">
                                    <label class="dk-mlmangerLabel">Coverage</label>
                                    <div className="dk-addNewSlectDropDwn">
                                        <SelectItem options={department} placeholder=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12">
                                <div className="dk-addresbtnCont">
                                    <button>Add </button>
                                </div>
                            </div> 
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default AddnewModal;
