import React from 'react';

const JobProfiles = () => {
    return (
        <React.Fragment>
          <div className="container-fluid w-100 text-center" style={{marginTop: "155px"}}>
              <h5>Job Profiles</h5>
          </div>
        </React.Fragment>
    )
}

export default JobProfiles;
