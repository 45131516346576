import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import './bkvalues.scss';
import Submit from "../../../../ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import { addCategory, clearState } from "../../../../../slice/admin/backendValues/backendValuesSlice";
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";

const AddNewBkValuesModal = ({ boxNo, parentId1, parentId2, parentId3, parentId4, headingText }) => {
    const dispatch = useDispatch();

    const { isInsert, isError, msg } = useSelector(state => state.backendValues)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [name, setName] = useState('');

    const handleButtonClick = async () => {
        if (!name) {
            dispatch(showError({ msg: 'Please enter category name.' }))
        } else {
            setSuccess(false);
            setLoading(true);
            await dispatch(addCategory({ boxNo, parentId1, parentId2, parentId3, parentId4, name }))
        }
    }

    useEffect(() => {
        setName('')
    }, [show])

    useEffect(() => {
        if (isInsert) {
            dispatch(showSuccess({ msg: 'Category added successfully.' }))
            dispatch(clearState())
            setSuccess(true);
            setLoading(false);
            setShow(false)
        }
        if (isError) {
            dispatch(showError({ msg }))
            dispatch(clearState())
            setSuccess(false);
            setLoading(false);
        }
    }, [isInsert, isError])

    return (
        <>
            <Link to="#" onClick={handleShow}><i class="fas fa-plus"></i></Link>
            <Modal className="dk-toolsBkVlueseditModal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{headingText}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-toolsValuesModalBody">
                    <div className="dk-toolsModalbody">
                        <form action="">
                            <div className="form-group">
                                <label htmlFor="">Name of category</label>
                                <label htmlFor="">
                                    <input type="text" placeholder="Enter name..." name="name" className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
                                </label>
                            </div>
                            <div className="form-footer mb-0">
                                <button type="button" className="closeBtn" onClick={() => setShow(false)}>Close</button>
                                <Submit txt="Submit" loading={loading} success={success} onClick={handleButtonClick} position="justify-content-center" />
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddNewBkValuesModal;
