import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { stat } from 'fs';

export const loginUser = createAsyncThunk(
    'auth/login',
    async ({ role, email, password }, thunkAPI) => {
        try {
            const response = await fetch('/api/user/login',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        role,
                        email,
                        password,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                localStorage.setItem(
                    "authentication",
                    JSON.stringify({
                        token: data.token,
                        refreshToken: data.refreshToken
                    })
                );
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const logoutUser = createAsyncThunk(
    'auth/logout',
    async ({ }, thunkAPI) => {
        try {
            const response = await fetch('/api/user/logout',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                localStorage.removeItem("authentication");
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        userInfo: {},
        isAuthenticate: false,
        isSuccess: false,
        isError: false,
        isLoading: false,
        errorMessage: '',
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isLoading = false;
            state.errorMessage = '';
            return state;
        },
        doLogin: (state, { payload }) => {
            state.isAuthenticate = true;
            state.userInfo = payload;
        }
    },
    extraReducers: {
        [loginUser.pending]: (state) => {
            state.isLoading = true;
        },
        [loginUser.fulfilled]: (state, { payload }) => {
            state.isAuthenticate = payload.isAuthenticated
            state.userInfo = payload.user;
            state.isSuccess = true;
            state.isLoading = false;
        },
        [loginUser.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
            state.isLoading = false;
        },
        [logoutUser.pending]: (state) => {
            state.isLoading = true;
        },
        [logoutUser.fulfilled]: (state, { payload }) => {
            state.isAuthenticate = payload.isAuthenticated
            state.userInfo = payload.user;
            state.isSuccess = true;
            state.isLoading = false;
        }
    }
});
export const { clearState, doLogin } = authSlice.actions;
export default authSlice.reducer;