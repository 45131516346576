import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const Samplemenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[2];
    const segment2 = pathname.split("/")[3];
    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/report/sample" className={(segment1 == 'report' && segment2 == 'report') ? 'active' : ''}>Sample</Link></li>
                        <li><Link to="/report/sample" className={`${(segment2 == 'report') ? 'active' : ''}`}>Sample2</Link></li>
                        <li><Link to="/report/sample" className={`${(segment2 == 'report') ? 'active' : ''}`}>Sample 3</Link></li>
                        <li><Link to="/report/sample" className={`${(segment2 == 'report') ? 'active' : ''}`}>Sample 3</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default Samplemenu;
