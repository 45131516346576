import React from 'react'

const IncomeTax = () => {
    return (
        <React.Fragment>
            <div className="container-fluid w-100 text-center" style={{marginTop: "155px"}}>
                <h5>Income Tax</h5>
            </div>
        </React.Fragment>
    )
}

export default IncomeTax;
