import React, { useEffect } from "react";
import Header from "../elements/header";
import PurchaseNavbar from "../elements/header/navbar/mainMenu/purchase/PurchaseNavbar";

const Layout = ({ children }) => {

    return (
        <React.Fragment>
            <Header />
            {/* <RecruitmentNavbar /> */}
            <PurchaseNavbar />
            {children}
        </React.Fragment>
    )
}
export default Layout;