import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import './duplicate.scss';

const Duplicate = () => {
    return (
        <React.Fragment>
            <div className="dk-duplicateMain">
                <h3 className="title">Duplicate Check</h3>
                <div className="dk-hadForm">
                    <div className="d-flex align-items-center justify-content-center">
                        <form action="" className="col-12 d-flex">
                            <input type="text" name="" id="" placeholder="Name" className="form-control" />
                            <input type="text" name="" id="" placeholder="Email ID" className="form-control" />
                            <button><i class="fas fa-search"></i> Search</button>
                            <Link to="#" className="reload">Reset</Link>
                        </form>
                    </div>
                </div>
                <div className="dk-duplicateTable">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Sn.</th>
                                    <th>Profile Created</th>
                                    <th>Recruiter</th>
                                    <th>Profile ID</th>
                                    <th>Candidate Name</th>
                                    <th>Candidate Email</th>
                                    <th>Profile Table</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>03-Jul-2021</td>
                                    <td>
                                        <div className="dk-recImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5a701dc2182ac.png" alt="" />
                                        </div>
                                    </td>
                                    <td>35697</td>
                                    <td>Mukul Jauhari</td>
                                    <td>mukuljauhari@gmail.com</td>
                                    <td><b>Pipeline Archive</b></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Duplicate;
