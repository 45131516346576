import React, { useState } from 'react';
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import DnDList from 'react-dnd-list'
import { useSelector } from "react-redux";
import {IMG_CLOUD_BASE_URL} from '../../../../../src/constants';
const Item = props => {
    const dnd = props.dnd  
    return (
        <tr style={{ ...dnd.item.styles, ...dnd.handler.styles }}
        className={dnd.item.classes}
        ref={dnd.item.ref}
        {...dnd.handler.listeners}>
        <td className="dk-dragBtn text-center" >
            <span><i className="fas fa-arrows-alt"></i></span>
        </td>
        <td className="text-center">{props.item.level}</td>
        <td className="dk-memberImg text-center">
            <img src={IMG_CLOUD_BASE_URL+props.item.userId.userImg} className="img-fluid img-circle" alt={props.item.userId.userImg} title={props.item.name}/>
        </td>
        <td>{props.item.name}</td>
        <td>{props.item.jobTitle}</td> 
      </tr>
    )
  }  
const ReorderModal = () => {
    let { dataList } = useSelector(state=>state.teamData)
    const [list, setList] = useState(dataList)
   // const [list, setList] = useState(['1','2','3','4'])
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleChange = ()=>{
        console.log(list)
    }
    
    return(
        <React.Fragment>
            <Link to="#" onClick={handleShow}><i className="fas fa-arrows-alt"></i></Link>
            <Modal className="dk-levelModal dk-addNewModal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Reorder</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-AddnewModal dk-reorderMOdal">
                    <div className="dk-reorderTable">
                        <div className="table-responisve">
                            <table className="table table-bordered">
                                <tbody>
                                     {/* <tr>
                                       <td className="dk-dragBtn text-center">
                                            <span><i className="fas fa-arrows-alt"></i></span>
                                        </td>
                                        <td className="text-center">Level 1</td>
                                        <td className="dk-memberImg text-center">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid img-circle" alt="" />
                                        </td>
                                        <td>Deepak Kumar</td>
                                        <td>Managing Director</td> 
                                     </tr> */}
                                        <DnDList
                                            items={list}
                                            itemComponent={Item}
                                            setList={setList}
                                            onChange={handleChange}
                                        />
                                   
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default ReorderModal;