import React from "react";
import { Route, Link } from "react-router-dom";
import Submenu from "../../subMenu/hr/";
import { useSelector } from "react-redux";
import './style.scss';

const HrNavbar = () => {
    const { allBadge } = useSelector(state => state.tasks);
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[2];
    const segment2 = pathname.split("/")[3];
    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-navHR">
                <Route>
                    <ul>
                        <li><Link to="/hr/dashboard" className={`${pathname.match('/hr/dashboard') ? 'active' : ''}`}>Dashboard</Link></li>
                        <li><Link to="#">Sample</Link></li>
                        <li><Link to="#">Sample 2</Link></li>
                        <li><Link to="#">Sample 3</Link></li>
                        <li><Link to="#">Sample 4</Link></li>
                        <li><Link to="/hr/leaves" className={`${pathname.match('/hr/leaves') ? 'active' : ''}`}>Leaves</Link></li>
                        <li><Link to="#">Sample 6</Link></li>
                        <li><Link to="#">Sample 7</Link></li>
                        <li><Link to="#">Sample 8</Link></li>
                        <li><Link to="#">Sample 9</Link></li>
                        <li><Link to="#">Sample 10</Link></li>
                    </ul>
                </Route>
            </div>
            <Submenu path={pathname} />
        </React.Fragment>
    );
}

export default HrNavbar;
