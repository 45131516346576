import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/css/style.css';
import App from './App';
import { store } from './app/store';
import '../node_modules/semantic-ui-css/semantic.min.css';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import Success from "./components/utils/messages/success";
import Error from "./components/utils/messages/error";
import ProgressBar from "./components/utils/loader/linear";
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
let persistor = persistStore(store);
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffffff',
      contrastText: '#333',
    },
  },
});
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ProgressBar />
          <Success />
          <Error />
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
