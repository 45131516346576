import React from "react";
import { Route, Link } from "react-router-dom";
import OnboardingTabs from "./OnboardingTabs";

const Onboarding = () => {
    return(
        <React.Fragment>
            <div className="dk-onboardingMain">
                <h3 className="text-center">Onboarding Information</h3>
                <div className="dk-onboardingTabs">
                    <OnboardingTabs />
                </div>
            </div>
        </React.Fragment>
    );
}

export default Onboarding;