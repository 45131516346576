import React, { useState } from "react";
import { Link, Route } from "react-router-dom";
import { Dropdown, Input } from 'semantic-ui-react';
import DatePicker from "react-datepicker";
import "../../../../../../node_modules/react-datepicker/dist/react-datepicker.css";
import './searchcv.scss';


const CaseWorkerOption = [
    {
        key: 'Mariana Cont',
        text: 'Mariana Cont',
        value: 'Mariana Cont',
        label: '8542',
        image: { avatar: true, src: 'https://www.admin.dkmasterbox.com/assets/uploads/user_images/5ad5c2940e458.png' },
    },
    {
        key: 'Maria Ciocas',
        text: 'Maria Ciocas',
        value: 'Maria Ciocas',
        label: '4512',
        image: { avatar: true, src: 'https://www.admin.dkmasterbox.com/assets/uploads/user_images/5ad49d2ca540d.png' },
    },
    {
        key: 'Elena lanole',
        text: 'Elena lanole',
        value: 'Elena lanole',
        label: '4349',
        image: { avatar: true, src: 'https://www.admin.dkmasterbox.com/assets/uploads/user_images/5b4dce328e872.png' },
    },
    {
        key: 'Adelina Coada',
        text: 'Adelina Coada',
        value: 'Adelina Coada',
        label: '3569',
        image: { avatar: true, src: 'https://www.admin.dkmasterbox.com/assets/uploads/user_images/5ca355dac8269.png' },
    },
    {
        key: 'Filip Gichev',
        text: 'Filip Gichev',
        value: 'Filip Gichev',
        label: '3014',
        image: { avatar: true, src: 'https://www.admin.dkmasterbox.com/assets/uploads/user_images/5ad5e54fdac9c.png' },
    },
    {
        key: 'Mariana Cont',
        text: 'Mariana Cont',
        value: 'Mariana Cont',
        label: '8542',
        image: { avatar: true, src: 'https://www.admin.dkmasterbox.com/assets/uploads/user_images/5ad5c2940e458.png' },
    },
    {
        key: 'Maria Ciocas',
        text: 'Maria Ciocas',
        value: 'Maria Ciocas',
        label: '4512',
        image: { avatar: true, src: 'https://www.admin.dkmasterbox.com/assets/uploads/user_images/5ad49d2ca540d.png' },
    },
    {
        key: 'Elena lanole',
        text: 'Elena lanole',
        value: 'Elena lanole',
        label: '4349',
        image: { avatar: true, src: 'https://www.admin.dkmasterbox.com/assets/uploads/user_images/5b4dce328e872.png' },
    },
    {
        key: 'Adelina Coada',
        text: 'Adelina Coada',
        value: 'Adelina Coada',
        label: '3569',
        image: { avatar: true, src: 'https://www.admin.dkmasterbox.com/assets/uploads/user_images/5ca355dac8269.png' },
    },
    {
        key: 'Filip Gichev',
        text: 'Filip Gichev',
        value: 'Filip Gichev',
        label: '3014',
        image: { avatar: true, src: 'https://www.admin.dkmasterbox.com/assets/uploads/user_images/5ad5e54fdac9c.png' },
    },
    {
        key: 'Mariana Cont',
        text: 'Mariana Cont',
        value: 'Mariana Cont',
        label: '8542',
        image: { avatar: true, src: 'https://www.admin.dkmasterbox.com/assets/uploads/user_images/5ad5c2940e458.png' },
    },
    {
        key: 'Maria Ciocas',
        text: 'Maria Ciocas',
        value: 'Maria Ciocas',
        label: '4512',
        image: { avatar: true, src: 'https://www.admin.dkmasterbox.com/assets/uploads/user_images/5ad49d2ca540d.png' },
    },
    {
        key: 'Elena lanole',
        text: 'Elena lanole',
        value: 'Elena lanole',
        label: '4349',
        image: { avatar: true, src: 'https://www.admin.dkmasterbox.com/assets/uploads/user_images/5b4dce328e872.png' },
    },
    {
        key: 'Adelina Coada',
        text: 'Adelina Coada',
        value: 'Adelina Coada',
        label: '3569',
        image: { avatar: true, src: 'https://www.admin.dkmasterbox.com/assets/uploads/user_images/5ca355dac8269.png' },
    },
    {
        key: 'Filip Gichev',
        text: 'Filip Gichev',
        value: 'Filip Gichev',
        label: '3014',
        image: { avatar: true, src: 'https://www.admin.dkmasterbox.com/assets/uploads/user_images/5ad5e54fdac9c.png' },
    },

]

const CaseStatusOption = [
    {
        key: 'DKG Process',
        text: 'DKG Process',
        value: 'DKG Process',
        label: '5107',
        style: {
            background: '#009a9a',
            color: '#fff',
        }
    },
    {
        key: 'Client Process',
        text: 'Client Process',
        value: 'Client Process',
        label: '58',
        style: {
            background: '#1b6867',
            color: '#fff',
        }
    },
    {
        key: 'Job Offer',
        text: 'Job Offer',
        value: 'Job Offer',
        label: '17',
        style: {
            background: '#599ad5',
            color: '#fff',
        }
    },
    {
        key: 'Refused Offer',
        text: 'Refused Offer',
        value: 'Refused Offer',
        label: '280',
        style: {
            background: '#6b0000',
            color: '#fff',
        }
    },
    {
        key: 'Placement',
        text: 'Placement',
        value: 'Placement',
        label: '487',
        style: {
            background: '#4267b2',
            color: '#fff',
        }
    },
    {
        key: 'Credit',
        text: 'Credit',
        value: 'Credit',
        label: '90',
        style: {
            background: '#df744a',
            color: '#fff',
        }
    },
    {
        key: 'Closed',
        text: 'Closed',
        value: 'Closed',
        label: '90',
        style: {
            background: '#e2445c',
            color: '#fff',
        }
    },
]

const CaseProgressOption = [
    {
        key: 'Job spec Sent',
        text: 'Job spec Sent',
        value: 'Job spec Sent',
        label: '5107',
    },
    {
        key: 'Qualifying',
        text: 'Qualifying',
        value: 'Qualifying',
        label: '10401',
    },
    {
        key: '',
        text: '',
        value: '',
        label: '10401',
    },
    {
        key: 'Duplicate Check',
        text: 'Duplicate Check',
        value: 'Duplicate Check',
        label: '2458',
    },
    {
        key: 'HR Interview',
        text: 'HR Interview',
        value: 'HR Interview',
        label: '640',
    },
]

const CaseStageOption = [
    {
        key: 'Not Reachable',
        text: 'Not Reachable',
        value: 'Not Reachable',
        label: '7610',
    },
    {
        key: 'Not Interested in Job',
        text: 'Not Interested in Job',
        value: 'Not Interested in Job',
        label: '5707',
    },
    {
        key: '',
        text: '',
        value: '',
        label: '4708',
    },
    {
        key: 'Project is Filled',
        text: 'Project is Filled',
        value: 'Project is Filled',
        label: '2458',
    },
    {
        key: 'Candidate is Duplicate',
        text: 'Candidate is Duplicate',
        value: 'Candidate is Duplicate',
        label: '640',
    },
]

const DatesProfileOption = [
    {
        key: 'Create on',
        text: 'Create on',
        value: 'Create on',
    },
    {
        key: 'Job Spec Sent',
        text: 'Job Spec Sent',
        value: 'Job Spec Sent',
    },
    {
        key: 'CV Sendout',
        text: 'CV Sendout',
        value: 'CV Sendout',
    },
    {
        key: 'Interview Prep Sent',
        text: 'Interview Prep Sent',
        value: 'Interview Prep Sent',
    },
    {
        key: 'Placement',
        text: 'Placement',
        value: 'Placement',
    },
    {
        key: 'Joining',
        text: 'Joining',
        value: 'Joining',
    },
]

const ProfileFlagsOption = [
    {
        key: 'Blank Flag',
        text: 'Blank Flag',
        value: 'Blank Flag',
        label: '31975',
        icon: 'flag outline',
    },
    {
        key: 'Green Flag',
        text: 'Green Flag',
        value: 'Green Flag',
        label: '1457',
        icon: 'flag',
    },
    {
        key: 'Red Flag',
        text: 'Red Flag',
        value: 'Red Flag',
        label: '1457',
        icon: 'flag',
    },
]

const ProfileStatusOption = [
    {
        key: 'Clear Case',
        text: 'Clear Case',
        value: 'Clear Case',
        label: '33919',
        style: {
            background: '#009a9a',
            color: '#fff',
        }
    },
    {
        key: 'Blocked Case',
        text: 'Blocked Case',
        value: 'Blocked Case',
        label: '0',
        style: {
            background: '#c12c27',
            color: '#fff',
        }
    },
]

const GenderOption = [
    {
        key: 'Not Confirmed',
        text: 'Not Confirmed',
        value: 'Not Confirmed',
        label: '30151',
    },
    {
        key: 'Male',
        text: 'Male',
        value: 'Male',
        label: '2078',
    },
    {
        key: 'Female',
        text: 'Female',
        value: 'Female',
        label: '1735',
    },
]

const AgeOption = [
    {
        key: 'Option 1',
        text: 'Option 1',
        value: 'Option 1',
        label: '59',
    },
    {
        key: 'Option 2',
        text: 'Option 2',
        value: 'Option 2',
        label: '65',
    },
    {
        key: 'Option 3',
        text: 'Option 3',
        value: 'Option 3',
        label: '59',
    },
    {
        key: 'Option 4',
        text: 'Option 4',
        value: 'Option 4',
        label: '35',
    },
    {
        key: 'Option 5',
        text: 'Option 5',
        value: 'Option 5',
        label: '45',
    },
]

const ProfileSkillsOption = [
    {
        key: '',
        text: '',
        value: '',
        label: '33928',
    },
    {
        key: 'Unity 3D',
        text: 'Unity 3D',
        value: 'Unity 3D',
        label: '7',
    },
    {
        key: 'UI/UX',
        text: 'UI/UX',
        value: 'UI/UX',
        label: '6',
    },
    {
        key: 'Java',
        text: 'Java',
        value: 'Java',
        label: '6',
    },
    {
        key: 'Jira',
        text: 'Jira',
        value: 'Jira',
        label: '2',
    },
]

const ItSkillsExpOption = [
    {
        key: '',
        text: '',
        value: '',
        label: '33948',
    },
    {
        key: 'Senior',
        text: 'Senior',
        value: 'Senior',
        label: '12',
    },
    {
        key: 'Scrum',
        text: 'Scrum',
        value: 'Scrum',
        label: '1',
    },
    {
        key: 'Jira',
        text: 'Jira',
        value: 'Jira',
        label: '1',
    },
    {
        key: 'Junior',
        text: 'Junior',
        value: 'Junior',
        label: '1',
    },
]

const NativeLanguageOption = [
    {
        key: 'Italian',
        text: 'Italian',
        value: 'Italian',
        label: '7844',
    },
    {
        key: 'Romanian',
        text: 'Romanian',
        value: 'Romanian',
        label: '2730',
    },
    {
        key: 'Greek',
        text: 'Greek',
        value: 'Greek',
        label: '2458',
    },
    {
        key: 'English',
        text: 'English',
        value: 'English',
        label: '2454',
    },
    {
        key: 'French',
        text: 'French',
        value: 'French',
        label: '2322',
    },
]

const FluentLanguageOption = [
    {
        key: 'Italian',
        text: 'Italian',
        value: 'Italian',
        label: '7844',
    },
    {
        key: 'Romanian',
        text: 'Romanian',
        value: 'Romanian',
        label: '2730',
    },
    {
        key: 'Greek',
        text: 'Greek',
        value: 'Greek',
        label: '2458',
    },
    {
        key: 'English',
        text: 'English',
        value: 'English',
        label: '2454',
    },
    {
        key: 'French',
        text: 'French',
        value: 'French',
        label: '2322',
    },
]

const IntermediateLanguageOption = [
    {
        key: 'Italian',
        text: 'Italian',
        value: 'Italian',
        label: '7844',
    },
    {
        key: 'Romanian',
        text: 'Romanian',
        value: 'Romanian',
        label: '2730',
    },
    {
        key: 'Greek',
        text: 'Greek',
        value: 'Greek',
        label: '2458',
    },
    {
        key: 'English',
        text: 'English',
        value: 'English',
        label: '2454',
    },
    {
        key: 'French',
        text: 'French',
        value: 'French',
        label: '2322',
    },
]

const NationalityOption = [
    {
        key: 'Italian',
        text: 'Italian',
        value: 'Italian',
        label: '7844',
    },
    {
        key: 'Romanian',
        text: 'Romanian',
        value: 'Romanian',
        label: '2730',
    },
    {
        key: 'Greek',
        text: 'Greek',
        value: 'Greek',
        label: '2458',
    },
    {
        key: 'Not Confirmed',
        text: 'Not Confirmed',
        value: 'Not Confirmed',
        label: '2458',
    },
    {
        key: 'English',
        text: 'English',
        value: 'English',
        label: '2454',
    },
    {
        key: 'French',
        text: 'French',
        value: 'French',
        label: '2322',
    },
]

const WorkPermitOption = [
    {
        key: 'EU National',
        text: 'EU National',
        value: 'EU National',
        label: '25140',
    },
    {
        key: '',
        text: '',
        value: '',
        label: '5596',
    },
    {
        key: 'Not Confirmed',
        text: 'Not Confirmed',
        value: 'Not Confirmed',
        label: '2458',
    },
    {
        key: 'Non - EU',
        text: 'Non - EU',
        value: 'Non - EU',
        label: '2458',
    },
    {
        key: 'Work Permit',
        text: 'Work Permit',
        value: 'Work Permit',
        label: '2454',
    },
    {
        key: 'Residence - Student',
        text: 'Residence - Student',
        value: 'Residence - Student',
        label: '9',
    },
]

const CurrentCountryOption = [
    {
        key: 'Italy',
        text: 'Italy',
        value: 'Italy',
        label: '4850',
    },
    {
        key: 'Greece',
        text: 'Greece',
        value: 'Greece',
        label: '3303',
    },
    {
        key: 'România',
        text: 'România',
        value: 'România',
        label: '3095',
    },
    {
        key: 'United Kingdom',
        text: 'United Kingdom',
        value: 'United Kingdom',
        label: '2669',
    },
    {
        key: 'Portugal',
        text: 'Portugal',
        value: 'Portugal',
        label: '2454',
    },
    {
        key: 'Spain',
        text: 'Spain',
        value: 'Spain',
        label: '1266',
    },
]

const SourceProfileOption = [
    {
        key: 'Indeed',
        text: 'Indeed',
        value: 'Indeed',
        label: '11085',
    },
    {
        key: 'ELJ',
        text: 'ELJ',
        value: 'ELJ',
        label: '9304',
    },
    {
        key: 'MLV',
        text: 'MLV',
        value: 'MLV',
        label: '2582',
    },
    {
        key: 'TL',
        text: 'TL',
        value: 'TL',
        label: '2352',
    },
    {
        key: 'Ejobs',
        text: 'Ejobs',
        value: 'Ejobs',
        label: '1209',
    },
    {
        key: 'DK Website',
        text: 'DK Website',
        value: 'DK Website',
        label: '1095',
    },
]

const CompanyOption = [
    {
        key: '',
        text: '',
        value: '',
        label: '5246',
    },
    {
        key: 'Carousel',
        text: 'Carousel',
        value: 'Carousel',
        label: '33',
    },
    {
        key: 'Carrolls Dublin',
        text: 'Carrolls Dublin',
        value: 'Carrolls Dublin',
        label: '8',
    },
    {
        key: 'Genpact Bucharest',
        text: 'Genpact Bucharest',
        value: 'Genpact Bucharest',
        label: '297',
    },
    {
        key: 'Genpact Cluj',
        text: 'Genpact Cluj',
        value: 'Genpact Cluj',
        label: '33',
    },
    {
        key: 'Genpact Dublin',
        text: 'Genpact Dublin',
        value: 'Genpact Dublin',
        label: '37',
    },
]

const JobCountryOption = [
    {
        key: '',
        text: '',
        value: '',
        label: '5246',
    },
    {
        key: 'Bulgaria',
        text: 'Bulgaria',
        value: 'Bulgaria',
        label: '33',
    },
    {
        key: 'Czech Republic',
        text: 'Czech Republic',
        value: 'Czech Republic',
        label: '86',
    },
    {
        key: 'France',
        text: 'France',
        value: 'France',
        label: '6',
    },
    {
        key: 'Germany',
        text: 'Germany',
        value: 'Germany',
        label: '33',
    },
    {
        key: 'Greece',
        text: 'Greece',
        value: 'Greece',
        label: '37',
    },
]


const JobProjectOption = [
    {
        key: 'Accor Hotel',
        text: 'Accor Hotel',
        value: 'Accor Hotel',
        label: '5246',
    },
    {
        key: 'Adidas',
        text: 'Adidas',
        value: 'Adidas',
        label: '33',
    },
    {
        key: 'Airbnb',
        text: 'Airbnb',
        value: 'Airbnb',
        label: '86',
    },
    {
        key: 'AirCaraibe',
        text: 'AirCaraibe',
        value: 'AirCaraibe',
        label: '6',
    },
    {
        key: 'Antivirus',
        text: 'Antivirus',
        value: 'Antivirus',
        label: '33',
    },
    {
        key: 'Artefact',
        text: 'Artefact',
        value: 'Artefact',
        label: '37',
    },
]

const JobLanguageOption = [
    {
        key: 'African',
        text: 'African',
        value: 'African',
        label: '2',
    },
    {
        key: 'Arabic',
        text: 'Arabic',
        value: 'Arabic',
        label: '33',
    },
    {
        key: 'Arabic + French',
        text: 'Arabic + French',
        value: 'Arabic + French',
        label: '86',
    },
    {
        key: 'Bosnian',
        text: 'Bosnian',
        value: 'Bosnian',
        label: '6',
    },
    {
        key: 'Bulgarian',
        text: 'Bulgarian',
        value: 'Bulgarian',
        label: '33',
    },
    {
        key: 'Czech/Slovak',
        text: 'Czech/Slovak',
        value: 'Czech/Slovak',
        label: '37',
    },
]

const JobRoleTypeOption = [
    {
        key: 'Account Manager',
        text: 'Account Manager',
        value: 'Account Manager',
        label: '2',
    },
    {
        key: 'Account Payable',
        text: 'Account Payable',
        value: 'Account Payable',
        label: '33',
    },
    {
        key: 'Account Receivable',
        text: 'Account Receivable',
        value: 'Account Receivable',
        label: '86',
    },
    {
        key: 'Accounts Payable',
        text: 'Accounts Payable',
        value: 'Accounts Payable',
        label: '6',
    },
    {
        key: 'Administrator',
        text: 'Administrator',
        value: 'Administrator',
        label: '33',
    },
    {
        key: 'AP Accountant',
        text: 'AP Accountant',
        value: 'AP Accountant',
        label: '37',
    },
]

const JobTitleOption = [
    {
        key: 'Option 1',
        text: 'Option 1',
        value: 'Option 1',
        label: '59',
    },
    {
        key: 'Option 2',
        text: 'Option 2',
        value: 'Option 2',
        label: '33',
    },
    {
        key: 'Option 3',
        text: 'Option 3',
        value: 'Option 3',
        label: '86',
    },
    {
        key: 'Option 4',
        text: 'Option 4',
        value: 'Option 4',
        label: '66',
    },
    {
        key: 'Option 5',
        text: 'Option 5',
        value: 'Option 5',
        label: '33',
    },
]

const SenorityOption = [
    {
        key: 'Option 1',
        text: 'Option 1',
        value: 'Option 1',
        label: '59',
    },
    {
        key: 'Option 2',
        text: 'Option 2',
        value: 'Option 2',
        label: '33',
    },
    {
        key: 'Option 3',
        text: 'Option 3',
        value: 'Option 3',
        label: '86',
    },
    {
        key: 'Option 4',
        text: 'Option 4',
        value: 'Option 4',
        label: '66',
    },
    {
        key: 'Option 5',
        text: 'Option 5',
        value: 'Option 5',
        label: '33',
    },
]

const EmployerOption = [
    {
        key: 'Option 1',
        text: 'Option 1',
        value: 'Option 1',
        label: '59',
    },
    {
        key: 'Option 2',
        text: 'Option 2',
        value: 'Option 2',
        label: '33',
    },
    {
        key: 'Option 3',
        text: 'Option 3',
        value: 'Option 3',
        label: '86',
    },
    {
        key: 'Option 4',
        text: 'Option 4',
        value: 'Option 4',
        label: '66',
    },
    {
        key: 'Option 5',
        text: 'Option 5',
        value: 'Option 5',
        label: '33',
    },
]

const ExperienceOption = [
    {
        key: 'Option 1',
        text: 'Option 1',
        value: 'Option 1',
        label: '59',
    },
    {
        key: 'Option 2',
        text: 'Option 2',
        value: 'Option 2',
        label: '33',
    },
    {
        key: 'Option 3',
        text: 'Option 3',
        value: 'Option 3',
        label: '86',
    },
    {
        key: 'Option 4',
        text: 'Option 4',
        value: 'Option 4',
        label: '66',
    },
    {
        key: 'Option 5',
        text: 'Option 5',
        value: 'Option 5',
        label: '33',
    },
]

const DurationOption = [
    {
        key: 'Option 1',
        text: 'Option 1',
        value: 'Option 1',
        label: '59',
    },
    {
        key: 'Option 2',
        text: 'Option 2',
        value: 'Option 2',
        label: '33',
    },
    {
        key: 'Option 3',
        text: 'Option 3',
        value: 'Option 3',
        label: '86',
    },
    {
        key: 'Option 4',
        text: 'Option 4',
        value: 'Option 4',
        label: '66',
    },
    {
        key: 'Option 5',
        text: 'Option 5',
        value: 'Option 5',
        label: '33',
    },
]

const IndustriesOption = [
    {
        key: 'Option 1',
        text: 'Option 1',
        value: 'Option 1',
        label: '59',
    },
    {
        key: 'Option 2',
        text: 'Option 2',
        value: 'Option 2',
        label: '33',
    },
    {
        key: 'Option 3',
        text: 'Option 3',
        value: 'Option 3',
        label: '86',
    },
    {
        key: 'Option 4',
        text: 'Option 4',
        value: 'Option 4',
        label: '66',
    },
    {
        key: 'Option 5',
        text: 'Option 5',
        value: 'Option 5',
        label: '33',
    },
]

const SectorsOption = [
    {
        key: 'Option 1',
        text: 'Option 1',
        value: 'Option 1',
        label: '59',
    },
    {
        key: 'Option 2',
        text: 'Option 2',
        value: 'Option 2',
        label: '33',
    },
    {
        key: 'Option 3',
        text: 'Option 3',
        value: 'Option 3',
        label: '86',
    },
    {
        key: 'Option 4',
        text: 'Option 4',
        value: 'Option 4',
        label: '66',
    },
    {
        key: 'Option 5',
        text: 'Option 5',
        value: 'Option 5',
        label: '33',
    },
]

const SearchCv = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    return (
        <React.Fragment>
            <div className="dk-searchCvMain">
                <div className="dk-filterMain">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="dk-filterMainLeft">
                            <span>Total Candidates : </span>
                            <span className="noOfCandidates">30148</span>
                        </div>
                        <div className="dk-searchTags">
                            <li className="searchTagCategory">ML
                                <Link to="#" className="dk-close"><i class="fas fa-times"></i></Link>
                            </li>
                        </div>
                        <div className="dk-filterMainRight">
                            <button><i class="fas fa-search"></i> Search Records</button>
                        </div>
                    </div>
                </div>

                <div className="dk-searchContainer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="dk-workerCols">
                                    <i class="fas fa-user-circle"></i>
                                    <Dropdown
                                        placeholder='Case Worker'
                                        clearable
                                        fluid
                                        selection
                                        options={CaseWorkerOption}
                                    />
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="dk-searchCvtext">
                                    <input type="text" placeholder="Search Keywords" name="" id="" className="form-control" />
                                    <button><i class="fas fa-sync-alt"></i></button>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3 pr-4 mt-4">
                                <div className="dk-categorySearch">
                                    <div className="sbtitle">Category <i class="fas fa-search"></i></div>
                                    <ul>
                                        <li>
                                            <p><input type="radio" name="categorySelection" id="" /> ML</p>
                                            <span>33336</span>
                                        </li>
                                        <li>
                                            <p><input type="radio" name="categorySelection" id="" /> IT</p>
                                            <span>135</span>
                                        </li>
                                        <li>
                                            <p><input type="radio" name="categorySelection" id="" /> IT</p>
                                            <span>448</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 pr-4 mt-4">
                                <div className="dk-categorySearch dk-searchDropdownddl">
                                    <div className="sbtitle">Case Filters <i class="fas fa-search"></i></div>
                                    <Dropdown
                                        placeholder='Case Status'
                                        clearable
                                        fluid
                                        selection
                                        options={CaseStatusOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {CaseStatusOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown
                                        placeholder='Case Progress'
                                        clearable
                                        fluid
                                        selection
                                        options={CaseProgressOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {CaseProgressOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown
                                        placeholder='Case Stage'
                                        clearable
                                        fluid
                                        selection
                                        options={CaseStageOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {CaseStageOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>

                            <div className="col-md-3 pr-4 mt-4">
                                <div className="dk-categorySearch dk-searchDropdownddl dk-caseDatesCols">
                                    <div className="sbtitle">Case Dates <i class="fas fa-search"></i></div>
                                    <Dropdown
                                        placeholder='Profile Dates'
                                        clearable
                                        fluid
                                        selection
                                        options={DatesProfileOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {DatesProfileOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <label htmlFor="" className="dk-searchDate">
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            placeholderText="From"
                                            dateFormat="d MMM yyyy"
                                        />
                                    </label>
                                    <label htmlFor="" className="dk-searchDate">
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            placeholderText="To"
                                            dateFormat="d MMM yyyy"
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-3 pr-4 mt-4">
                                <div className="dk-categorySearch dk-searchDropdownddl">
                                    <div className="sbtitle">Profile Filters <i class="fas fa-search"></i></div>
                                    <Dropdown
                                        placeholder='Profile Flags'
                                        clearable
                                        fluid
                                        selection
                                        options={ProfileFlagsOption}
                                    >
                                        <Dropdown.Menu>
                                            <Dropdown.Menu scrolling>
                                                {ProfileFlagsOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown
                                        placeholder='Profile Status'
                                        clearable
                                        fluid
                                        selection
                                        options={ProfileStatusOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {ProfileStatusOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            
                            <div className="col-md-3 pr-4 mt-4">
                                <div className="dk-categorySearch dk-searchDropdownddl">
                                    <div className="sbtitle">Personal <i class="fas fa-search"></i></div>
                                    <div className="dk-flexCols-main">
                                        <div className="dk-flexCols">
                                            <span>ID</span>
                                            <input type="text" placeholder="Search ID" name="" id="" className="form-con" />
                                        </div>
                                        <div className="dk-flexCols">
                                            <span>Name</span>
                                            <input type="text" placeholder="Search Name" name="" id="" className="form-con" />
                                        </div>
                                        <div className="dk-flexCols">
                                            <span>Email</span>
                                            <input type="text" placeholder="Search Email" name="" id="" className="form-con" />
                                        </div>
                                    </div>
                                    <Dropdown
                                        placeholder='Gender'
                                        fluid
                                        selection
                                        options={GenderOption}
                                    >
                                        <Dropdown.Menu>
                                            <Dropdown.Menu scrolling>
                                                {GenderOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Age'
                                        fluid
                                        selection
                                        options={AgeOption}
                                    >
                                        <Dropdown.Menu>
                                            <Dropdown.Menu scrolling>
                                                {AgeOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>

                            <div className="col-md-3 pr-4 mt-4">
                                <div className="dk-categorySearch dk-searchDropdownddl">
                                    <div className="sbtitle">Profile Skills <i class="fas fa-search"></i></div>
                                    <Dropdown
                                        placeholder='IT Skills'
                                        fluid
                                        selection
                                        options={ProfileSkillsOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {ProfileSkillsOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='IT Skills Exp'
                                        fluid
                                        selection
                                        options={ItSkillsExpOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {ItSkillsExpOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Native Language'
                                        fluid
                                        selection
                                        options={NativeLanguageOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {NativeLanguageOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Fluent Language'
                                        fluid
                                        selection
                                        options={FluentLanguageOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {FluentLanguageOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Intermediate Language'
                                        fluid
                                        selection
                                        options={IntermediateLanguageOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {IntermediateLanguageOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </div>
                            </div>

                            <div className="col-md-3 pr-4 mt-4">
                                <div className="dk-categorySearch dk-searchDropdownddl">
                                    <div className="sbtitle">Screening <i class="fas fa-search"></i></div>
                                    <Dropdown
                                        placeholder='Nationality'
                                        fluid
                                        selection
                                        options={NationalityOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {NationalityOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    
                                    <Dropdown
                                        placeholder='Work Permit'
                                        fluid
                                        selection
                                        options={WorkPermitOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {WorkPermitOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div className="dk-flexCols-main">
                                        <div className="dk-flexCols">
                                            <span>City</span>
                                            <input type="text" placeholder="Search Current City" name="" id="" className="form-control" />
                                        </div>
                                    </div>

                                    <Dropdown
                                        placeholder='Current Country'
                                        fluid
                                        selection
                                        options={CurrentCountryOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {CurrentCountryOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Source of Profile'
                                        fluid
                                        selection
                                        options={SourceProfileOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {SourceProfileOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>

                            <div className="col-md-3 pr-4 mt-4">
                                <div className="dk-categorySearch dk-searchDropdownddl">
                                    <div className="sbtitle">Job Order <i class="fas fa-search"></i></div>
                                    <Dropdown
                                        placeholder='Company'
                                        fluid
                                        selection
                                        options={CompanyOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {CompanyOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    
                                    <Dropdown
                                        placeholder='Job Country'
                                        fluid
                                        selection
                                        options={JobCountryOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {JobCountryOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Job Project'
                                        fluid
                                        selection
                                        options={JobProjectOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {JobProjectOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Job Language'
                                        fluid
                                        selection
                                        options={JobLanguageOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {JobLanguageOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Job Role Type'
                                        fluid
                                        selection
                                        options={JobRoleTypeOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {JobRoleTypeOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>

                            <div className="col-md-3 pr-4 mt-4">
                                <div className="dk-categorySearch dk-searchDropdownddl">
                                    <div className="sbtitle">Employement <i class="fas fa-search"></i></div>
                                    <Dropdown
                                        placeholder='Job Title'
                                        fluid
                                        selection
                                        options={JobTitleOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {JobTitleOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    
                                    <Dropdown
                                        placeholder='Senority'
                                        fluid
                                        selection
                                        options={SenorityOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {SenorityOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Employer'
                                        fluid
                                        selection
                                        options={EmployerOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {EmployerOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Experience'
                                        fluid
                                        selection
                                        options={ExperienceOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {ExperienceOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Duration'
                                        fluid
                                        selection
                                        options={DurationOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {DurationOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Industries'
                                        fluid
                                        selection
                                        options={IndustriesOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {IndustriesOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Sectors'
                                        fluid
                                        selection
                                        options={SectorsOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {SectorsOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </div>
                            </div>

                            <div className="col-md-3 pr-4 mt-4">
                                <div className="dk-categorySearch dk-searchDropdownddl">
                                    <div className="sbtitle">Qualification <i class="fas fa-search"></i></div>
                                    <Dropdown
                                        placeholder='Job Title'
                                        fluid
                                        selection
                                        options={JobTitleOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {JobTitleOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    
                                    <Dropdown
                                        placeholder='Senority'
                                        fluid
                                        selection
                                        options={SenorityOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {SenorityOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Employer'
                                        fluid
                                        selection
                                        options={EmployerOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {EmployerOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Experience'
                                        fluid
                                        selection
                                        options={ExperienceOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {ExperienceOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Duration'
                                        fluid
                                        selection
                                        options={DurationOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {DurationOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Industries'
                                        fluid
                                        selection
                                        options={IndustriesOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {IndustriesOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Sectors'
                                        fluid
                                        selection
                                        options={SectorsOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {SectorsOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </div>
                            </div>

                            <div className="col-md-3 pr-4 mt-4">
                                <div className="dk-categorySearch dk-searchDropdownddl">
                                    <div className="sbtitle">Salary & Benefits <i class="fas fa-search"></i></div>
                                    <Dropdown
                                        placeholder='Job Title'
                                        fluid
                                        selection
                                        options={JobTitleOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {JobTitleOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    
                                    <Dropdown
                                        placeholder='Senority'
                                        fluid
                                        selection
                                        options={SenorityOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {SenorityOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Employer'
                                        fluid
                                        selection
                                        options={EmployerOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {EmployerOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Experience'
                                        fluid
                                        selection
                                        options={ExperienceOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {ExperienceOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Duration'
                                        fluid
                                        selection
                                        options={DurationOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {DurationOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Industries'
                                        fluid
                                        selection
                                        options={IndustriesOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {IndustriesOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Sectors'
                                        fluid
                                        selection
                                        options={SectorsOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {SectorsOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </div>
                            </div>

                            <div className="col-md-3 pr-4 mt-4">
                                <div className="dk-categorySearch dk-searchDropdownddl">
                                    <div className="sbtitle">Desires <i class="fas fa-search"></i></div>
                                    <Dropdown
                                        placeholder='Job Title'
                                        fluid
                                        selection
                                        options={JobTitleOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {JobTitleOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    
                                    <Dropdown
                                        placeholder='Senority'
                                        fluid
                                        selection
                                        options={SenorityOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {SenorityOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Employer'
                                        fluid
                                        selection
                                        options={EmployerOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {EmployerOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Experience'
                                        fluid
                                        selection
                                        options={ExperienceOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {ExperienceOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Duration'
                                        fluid
                                        selection
                                        options={DurationOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {DurationOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Industries'
                                        fluid
                                        selection
                                        options={IndustriesOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {IndustriesOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        placeholder='Sectors'
                                        fluid
                                        selection
                                        options={SectorsOption}
                                    >
                                        <Dropdown.Menu>
                                            <Input icon='search' placeholder='Search' iconPosition='left' className='search' />
                                            <Dropdown.Menu scrolling>
                                                {SectorsOption.map((option) => (
                                                    <Dropdown.Item key={option.value} {...option} />
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </div>
                            </div>


                        </div>

                    </div>
                </div>

            </div>
        </React.Fragment>
    );
}

export default SearchCv;
