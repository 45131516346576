import React, { useEffect } from "react";
import Header from "../elements/header";
import FinanceNavbar from "../elements/header/navbar/mainMenu/finance/FinanceNavbar";
import ProgressBar from "../utils/loader/linear";
import { useSelector } from "react-redux";
const Layout = ({ children }) => {

    return (
        <React.Fragment>
            <Header />
            <FinanceNavbar />
            <ProgressBar />
            {children}
        </React.Fragment>
    )
}
export default Layout;