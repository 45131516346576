import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const mainCategoryBox1 = createAsyncThunk(
    'backend_values/main_category',
    async ({ }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/backend-value-category/box1',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const subCategoryBox2 = createAsyncThunk(
    'backend_values/sub_category_box2',
    async ({ mainCategory }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/backend-value-category/box2/' + mainCategory,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const subCategoryBox3 = createAsyncThunk(
    'backend_values/sub_category_box3',
    async ({ parentId, parentId2 }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/backend-value-category/box3/' + parentId + '/' + parentId2,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const subCategoryBox4 = createAsyncThunk(
    'backend_values/sub_category_box4',
    async ({ parentId, parentId2, parentId3 }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/backend-value-category/box4/' + parentId + '/' + parentId2 + '/' + parentId3,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const addCategory = createAsyncThunk(
    'backend_values/add_category',
    async ({ boxNo, parentId1, parentId2, parentId3, parentId4, name }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/backend-value-category/',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        boxNo, parentId1, parentId2, parentId3, parentId4, name
                    })
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const valuesList = createAsyncThunk(
    'backend_values/values',
    async ({ categoryId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/backend-category-values/' + categoryId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const deleteCategory = createAsyncThunk(
    'backend_values/delete_category',
    async ({ boxNo, id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/backend-value-category/' + id,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

export const updateCategoryValue = createAsyncThunk(
    'backend_values/update_category',
    async ({ id, boxNo, name }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/backend-value-category/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        name
                    })
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

export const reorderCategory = createAsyncThunk(
    'backend_values/reorder_category',
    async ({ ids }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/backend-value-category/reorder',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        ids
                    })
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

export const reorderValues = createAsyncThunk(
    'backend_values/reorder_values',
    async ({ ids }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/backend-category-values/reorder',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        ids
                    })
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

export const deleteBkValue = createAsyncThunk(
    'backend_values/delete_values',
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/backend-category-values/' + id,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

export const addValue = createAsyncThunk(
    'backend_values/add_value',
    async ({ name, categoryId, bgColor, textColor }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/backend-category-values/',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        name, categoryId, bgColor, textColor
                    })
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateValue = createAsyncThunk(
    'backend_values/update_value',
    async ({ id, name }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/backend-category-values/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        name
                    })
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

export const updateColor = createAsyncThunk(
    'backend_values/update_value_color',
    async ({ id, bgColor, textColor }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            const response = await fetch('/api/backend-category-values/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                    body: JSON.stringify({
                        bgColor, textColor
                    })
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

const backendValuesSlice = createSlice({
    name: 'backendValues',
    initialState: {
        box1List: [],
        box2List: [],
        box3List: [],
        box4List: [],
        backendValuesList: [],
        isloading: false,
        isReoder: false,
        isLoadingBox1: false,
        isLoadingBox2: false,
        isLoadingBox3: false,
        isLoadingBox4: false,
        isLoadingValues: false,
        isInsert: false,
        isUpdate: false,
        isUpdateColor: false,
        isDelete: false,
        isSuccess: false,
        isError: false,
        msg: ''
    },
    reducers: {
        clearState: (state) => {
            state.isloading = false;
            state.isLoadingBox1 = false;
            state.isLoadingBox2 = false;
            state.isLoadingBox3 = false;
            state.isLoadingBox4 = false;
            state.isLoadingValues = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isUpdateColor = false;
            state.isReoder = false;
            state.isDelete = false;
            state.isSuccess = false;
            state.isError = false;
            state.msg = '';
        },
        clearBox2: (state) => {
            state.box2List = []
        },
        clearBox3: (state) => {
            state.box3List = []
        },
        clearBox4: (state) => {
            state.box4List = []
        }
    },
    extraReducers: {
        [mainCategoryBox1.pending]: (state) => {
            state.isLoadingBox1 = true;
        },
        [mainCategoryBox1.fulfilled]: (state, { payload }) => {
            state.isLoadingBox1 = false;
            state.box1List = payload.data;
        },
        [mainCategoryBox1.rejected]: (state, { payload }) => {
            state.isLoadingBox1 = false;
            state.isError = payload.message.msgError;
            state.msg = payload.message.msgBody;
        },
        [subCategoryBox2.pending]: (state) => {
            state.isLoadingBox2 = true;
        },
        [subCategoryBox2.fulfilled]: (state, { payload }) => {
            state.isLoadingBox2 = false;
            state.box2List = payload.data;
        },
        [subCategoryBox2.rejected]: (state, { payload }) => {
            state.isLoadingBox1 = false;
            state.isError = payload.message.msgError;
            state.msg = payload.message.msgBody;
        },
        [subCategoryBox3.pending]: (state) => {
            state.isLoadingBox3 = true;
        },
        [subCategoryBox3.fulfilled]: (state, { payload }) => {
            state.isLoadingBox3 = false;
            state.box3List = payload.data;
        },
        [subCategoryBox3.rejected]: (state, { payload }) => {
            state.isLoadingBox1 = false;
            state.isError = payload.message.msgError;
            state.msg = payload.message.msgBody;
        },
        [subCategoryBox4.pending]: (state) => {
            state.isLoadingBox4 = true;
        },
        [subCategoryBox4.fulfilled]: (state, { payload }) => {
            state.isLoadingBox4 = false;
            state.box4List = payload.data;
        },
        [subCategoryBox4.rejected]: (state, { payload }) => {
            state.isLoadingBox1 = false;
            state.isError = payload.message.msgError;
            state.msg = payload.message.msgBody;
        },
        [addCategory.pending]: (state) => {
            state.isInsert = false;
        },
        [addCategory.fulfilled]: (state, { payload }) => {
            state.isInsert = true;
            switch (payload.data.boxNo) {
                case '1':
                    state.box1List.push(payload.data)
                    break;

                case '2':
                    state.box2List.push(payload.data)
                    break;

                case '3':
                    state.box3List.push(payload.data)
                    break;

                case '4':
                    state.box4List.push(payload.data)
                    break;
            }
            state.msg = payload.message.msgBody;
        },
        [addCategory.rejected]: (state, { payload }) => {
            state.isInsert = false;
            state.isError = payload.message.msgError;
            state.msg = payload.message.msgBody;
        },
        [valuesList.pending]: (state) => {
            state.isloading = true;
        },
        [valuesList.fulfilled]: (state, { payload }) => {
            state.isloading = false;
            state.backendValuesList = payload.data;
        },
        [valuesList.rejected]: (state, { payload }) => {
            state.isloading = false;
            state.isError = payload.message.msgError;
            state.msg = payload.message.msgBody;
        },
        [deleteCategory.pending]: (state) => {
            state.isloading = true;
        },
        [deleteCategory.fulfilled]: (state, action) => {
            state.isloading = false;
            switch (action.meta.arg.boxNo) {
                case '1':
                    state.box1List = state.box1List.filter((item) => item._id !== action.meta.arg.id)
                    break;

                case '2':
                    state.box2List = state.box2List.filter((item) => item._id !== action.meta.arg.id)
                    break;

                case '3':
                    state.box3List = state.box3List.filter((item) => item._id !== action.meta.arg.id)
                    break;

                case '4':
                    state.box4List = state.box4List.filter((item) => item._id !== action.meta.arg.id)
                    break;
            }
            state.isDelete = true;
            state.msg = 'Delete successfully.';
        },
        [deleteCategory.rejected]: (state, { payload }) => {
            state.isloading = false;
            state.isError = true;
            state.msg = 'Something went wrong! Try again.';
        },
        [updateCategoryValue.pending]: (state) => {
            state.isloading = true;
        },
        [updateCategoryValue.fulfilled]: (state, { payload }) => {
            state.isloading = false;
            switch (payload.data.boxNo) {
                case '1':
                    var mainArray = state.box1List;
                    var finalResult = mainArray.map((r) => {
                        if (r._id === payload.data._id) {
                            return (payload.data)
                        } else {
                            return r
                        }
                    })
                    state.box1List = finalResult
                    break;

                case '2':
                    var mainArray = state.box2List;
                    var finalResult = mainArray.map((r) => {
                        if (r._id === payload.data._id) {
                            return (payload.data)
                        } else {
                            return r
                        }
                    })
                    state.box2List = finalResult
                    break;

                case '3':
                    var mainArray = state.box3List;
                    var finalResult = mainArray.map((r) => {
                        if (r._id === payload.data._id) {
                            return (payload.data)
                        } else {
                            return r
                        }
                    })
                    state.box3List = finalResult
                    break;

                case '4':
                    var mainArray = state.box4List;
                    var finalResult = mainArray.map((r) => {
                        if (r._id === payload.data._id) {
                            return (payload.data)
                        } else {
                            return r
                        }
                    })
                    state.box4List = finalResult
                    break;
            }
            state.isUpdate = true;
            state.msg = 'Update successfully.';
        },
        [updateCategoryValue.rejected]: (state) => {
            state.isloading = false;
            state.isError = true;
            state.msg = 'Something went wrong! Try again.';
        },
        [reorderCategory.pending]: (state) => {
            state.isloading = true;
        },
        [reorderCategory.fulfilled]: (state, { payload }) => {
            state.isloading = false;
            state.isReoder = true;
            state.msg = "Reorder successfully.";
        },
        [reorderCategory.rejected]: (state, { payload }) => {
            state.isloading = false;
            state.isError = payload.message.msgError;
            state.msg = payload.message.msgBody;
        },
        [reorderValues.pending]: (state) => {
        },
        [reorderValues.fulfilled]: (state, { payload }) => {
        },
        [reorderValues.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.msg = payload.message.msgBody;
        },
        [deleteBkValue.pending]: (state) => {
            state.isloading = true;
        },
        [deleteBkValue.fulfilled]: (state, action) => {
            state.isloading = false;
            state.backendValuesList = state.backendValuesList.filter((item) => item._id !== action.meta.arg.id)
            state.isDelete = true;
            state.msg = 'Delete successfully.';
        },
        [deleteBkValue.rejected]: (state, { payload }) => {
            state.isloading = false;
            state.isError = true;
            state.msg = 'Something went wrong! Try again.';
        },
        [addValue.pending]: (state) => {
        },
        [addValue.fulfilled]: (state, { payload }) => {
            state.backendValuesList.push(payload.data)
        },
        [addValue.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.msg = payload.message.msgBody;
        },
        [updateValue.pending]: (state) => {
        },
        [updateValue.fulfilled]: (state, { payload }) => {
            var mainArray = state.backendValuesList;
            var finalResult = mainArray.map((r) => {
                if (r._id === payload.data._id) {
                    return (payload.data)
                } else {
                    return r
                }
            })
            state.backendValuesList = finalResult
        },
        [updateValue.rejected]: (state) => {
            state.isError = true;
            state.msg = 'Something went wrong! Try again.';
        },
        [updateColor.pending]: (state) => {
            state.isUpdateColor = false;
        },
        [updateColor.fulfilled]: (state, { payload }) => {
            var mainArray = state.backendValuesList;
            var finalResult = mainArray.map((r) => {
                if (r._id === payload.data._id) {
                    return (payload.data)
                } else {
                    return r
                }
            })
            state.backendValuesList = finalResult;
            state.isUpdateColor = true;
        },
        [updateColor.rejected]: (state) => {
            state.isUpdateColor = false;
            state.isError = true;
            state.msg = 'Something went wrong! Try again.';
        },
    }
})

export const { clearState, clearBox2, clearBox3, clearBox4 } = backendValuesSlice.actions
export default backendValuesSlice.reducer;