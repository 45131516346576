import React from 'react';
import "./selectitem.scss";
import { Select } from 'semantic-ui-react'

const SelectItem = (props) => {
    return (
        <React.Fragment>
            <Select placeholder={props.placeholder} options={props.options} className={`dk-overdues-task-slect`} />
        </React.Fragment>
    )
}

export default SelectItem;
