import React from 'react'

const Interviews = () => {
    return (
        <React.Fragment>
           <div className="row pr-3">
                <div className="col-md-4 col-sm-12">
                    <div className="dkCliInterLeftCon">
                        <div className="dkInterviewH">
                            <h2>Select Path</h2>
                        </div>
                        <div className="row mt-1">
                            <div className="form-group dk-CliInterViewSelect d-flex w-100">
                                <select className="form-control" id="exampleFormControlSelect1">
                                    <option>Select Category</option>
                                    <option>Category 1</option>
                                    <option>Category 2</option>
                                    <option>Category 3</option>
                                    <option>Category 4</option>
                                </select>
                            </div>
                            <div className="form-group dk-CliInterViewSelect d-flex w-100">
                                <select className="form-control" id="exampleFormControlSelect1">
                                    <option>Select Location</option>
                                    <option>Location 1</option>
                                    <option>Location 2</option>
                                    <option>Location 3</option>
                                    <option>Location 4</option>
                                </select>
                            </div>
                            <div className="form-group dk-CliInterViewSelect d-flex w-100">
                                <select className="form-control" id="exampleFormControlSelect1">
                                    <option>Select Project</option>
                                    <option>Project 1</option>
                                    <option>Project 2</option>
                                    <option>Project 3</option>
                                    <option>Project 4</option>
                                </select>
                            </div>
                            <div className="form-group dk-CliInterViewSelect d-flex w-100">
                                <select className="form-control" id="exampleFormControlSelect1">
                                    <option>Select Role Type</option>
                                    <option>Role Type 1</option>
                                    <option>Role Type 2</option>
                                    <option>Role Type 3</option>
                                    <option>Role Type 4</option>
                                </select>
                            </div>
                            <div className="form-group dk-CliInterViewSelect d-flex w-100">
                                <select className="form-control" id="exampleFormControlSelect1">
                                    <option>Select Language</option>
                                    <option>Language 1</option>
                                    <option>Language 2</option>
                                    <option>Language 3</option>
                                    <option>Language 4</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-8 col-sm-12 pl-3">
                    <div className="dk-InterviewRightCon">
                        <div className="dk-RightTophder d-flex">
                            <h2 className="topTitle">Interview Questions</h2>
                            <a href="#"><i className="fa fa-plus" aria-hidden="true"></i> Add New</a>
                        </div>
                        <div className="dk-InterviewRightContent mt-1">
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Interviews;
