import React, { useEffect, useState } from 'react';
import { Route, Link } from "react-router-dom";
import './style.scss';
import { Dropdown } from 'semantic-ui-react';

const options = [
    {
        key: 'Person',
        text: 'Person',
        value: 'Person',
        label: '8',
    },
    {
        key: 'Medical',
        text: 'Medical',
        value: 'Medical',
        label: '7',
    },
    {
        key: 'Summer',
        text: 'Summer',
        value: 'Summer',
        label: '3',
    },
]

const ReasonModal = () => {
    return (
        <React.Fragment>
            <Dropdown clearable options={options} selection placeholder='Reason' />
        </React.Fragment>
    );
}

export default ReasonModal;
