import React from 'react';
import {Link} from "react-router-dom";
import SelectItem from '../../../../ui/selectpicker/SelectItem';
import {DropdownButton,Dropdown} from 'react-bootstrap';

const Invoices = () => {
    const invoice_status = [
        { key: 'invoice_status_1', value: 'invoice_status_1', text: 'Invoice Status 1' },
        { key: 'invoice_status_2', value: 'invoice_status_2', text: 'Invoice Status 2' },
        { key: 'invoice_status_3', value: 'invoice_status_3', text: 'Invoice Status 3' },
        { key: 'invoice_status_4', value: 'invoice_status_4', text: 'Invoice Status 4' },
        { key: 'invoice_status_5', value: 'invoice_status_5', text: 'Invoice status 5' }
    ]
    return (
        <React.Fragment>
            <div className="row pr-3">
                <div className="col-md-4">
                    <div className="dkclietopLeft d-flex">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Search by Text"/>
                            <div className="input-group-append">
                            <button className="btn btn-secondary legitRipple" type="button">
                                <i className="fa fa-search"></i>
                            </button>
                            </div>
                        </div>
                        <Link id="" to="#" className=" dk-topTbleIcon ml-2">
                            <i class="fas fa-sync-alt"></i>
                        </Link>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="dk-cliDetTabPaneBlkCon">
                        <div className="dk-cliDetTitle">
                            <h2 className="mb-0 mt-2">Invoices</h2>
                        </div>
                    </div>    
                </div>
                <div className="col-md-4 dk-CliContactRight">
                    <div className="dkclietopRight">
                        <Link href="#" className=" dk-topTbleIcon mr-2">
                           <i className="fas fa-plus"></i>
                        </Link>
                    </div>
                    <div className="dk-CliDetSlectFilter pr-0">
                        <SelectItem options={invoice_status} placeholder="Invoice Status"/>
                    </div>
                </div>
            </div>
            <div className="row pr-3 mt-1">
                <div className="col-md-12 col-sm-12">
                    <div className="dk-cliDetailTableCon">
                        <table className="table table-bordered dk-cliDetialTable mb-1">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Inv Status</th>
                                    <th>Create</th>
                                    <th>INV No</th>
                                    <th>Name of Candidate</th>
                                    <th>Project</th>
                                    <th>Language</th>
                                    <th>Joining</th>
                                    <th>Pay Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>01</td>
                                    <td>
                                        <div className="dk-cliDorpDwon">
                                            <DropdownButton id="dropdown-basic-button" title="Active">
                                                <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </td>
                                    <td>01 Jan 2021</td>
                                    <td>35076</td>
                                    <td>Name of Candidate 1</td>
                                    <td>Project 1</td>
                                    <td>Language 2</td>
                                    <td>17 July 2021</td>
                                    <td>Pay Status 1</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <a className="dk-edit-btn mr-2" href="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </a>
                                            <a className="dk-delete-btn" href="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>02</td>
                                    <td>
                                        <div className="dk-cliDorpDwon">
                                            <DropdownButton id="dropdown-basic-button" title="Active">
                                                <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </td>
                                    <td>01 Jan 2021</td>
                                    <td>35076</td>
                                    <td>Name of Candidate 1</td>
                                    <td>Project 2</td>
                                    <td>Language 3</td>
                                    <td>17 July 2021</td>
                                    <td>Pay Status 1</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <a className="dk-edit-btn mr-2" href="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </a>
                                            <a className="dk-delete-btn" href="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>03</td>
                                    <td>
                                        <div className="dk-cliDorpDwon">
                                            <DropdownButton id="dropdown-basic-button" title="Active">
                                                <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </td>
                                    <td>01 Jan 2021</td>
                                    <td>35076</td>
                                    <td>Name of Candidate 1</td>
                                    <td>Project 3</td>
                                    <td>Language 4</td>
                                    <td>17 July 2021</td>
                                    <td>Pay Status 1</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <a className="dk-edit-btn mr-2" href="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </a>
                                            <a className="dk-delete-btn" href="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>04</td>
                                    <td>
                                        <div className="dk-cliDorpDwon">
                                            <DropdownButton id="dropdown-basic-button" title="Active">
                                                <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </td>
                                    <td>01 Jan 2021</td>
                                    <td>35076</td>
                                    <td>Name of Candidate 1</td>
                                    <td>Project 4</td>
                                    <td>Language 4</td>
                                    <td>17 July 2021</td>
                                    <td>Pay Status 1</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <a className="dk-edit-btn mr-2" href="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </a>
                                            <a className="dk-delete-btn" href="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>05</td>
                                    <td>
                                        <div className="dk-cliDorpDwon">
                                            <DropdownButton id="dropdown-basic-button" title="Active">
                                                <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </td>
                                    <td>01 Jan 2021</td>
                                    <td>35076</td>
                                    <td>Name of Candidate 1</td>
                                    <td>Project 4</td>
                                    <td>Language 5</td>
                                    <td>17 July 2021</td>
                                    <td>Pay Status 1</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <a className="dk-edit-btn mr-2" href="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </a>
                                            <a className="dk-delete-btn" href="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row pr-3 mt-0">
                <div className=" col-md-12 col-sm-12  ml-auto">
                    <div className="dk-cliDetViwpagination">
                        <div className="dktotalNumberCounter">
                            <h5>Total row count 5</h5>
                        </div>
                        <div className="dk-cliInvociePag">
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item">
                                    <a className="page-link legitRipple" href="#" aria-label="Previous">
                                        <span aria-hidden="true">«</span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                    </li>
                                    <li className="page-item"><a className="page-link legitRipple" href="#">1</a></li>
                                    <li className="page-item"><a className="page-link legitRipple" href="#">2</a></li>
                                    <li className="page-item"><a className="page-link legitRipple" href="#">3</a></li>
                                    <li className="page-item">
                                    <a className="page-link legitRipple" href="#" aria-label="Next">
                                        <span aria-hidden="true">»</span>
                                        <span className="sr-only">Next</span>
                                    </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Invoices;
