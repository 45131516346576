import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import styles from './Login.module.css';
import Logo from './login_logo.png'
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../slice/utils/message/messageSlice";
import { loginUser, clearState } from "../../../slice/auth/authSlice";
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
        marginTop: 40,
        marginBottom: 40,
    },
    margin: {
        margin: theme.spacing(1),
    },
    activeLoginBtn: {
        backgroundColor: '#10333c'
    },
    textField: {
        margin: theme.spacing(5),
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(20),
        width: '25ch'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -22,
        marginLeft: -12,
    },
}));

const Login = () => {
    const classes = useStyles();
    const [activeUser, setActiveUser] = useState('activeLoginBtn');
    const [activeAdmin, setActiveAdmin] = useState('');

    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);

    const [role, setRole] = useState('User');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const history = useHistory();
    const { isSuccess, isError, isLoading, errorMessage } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    const clickSetRole = (roleType) => {
        setRole(roleType)
        if (roleType == 'User') {
            setActiveUser('activeLoginBtn')
            setActiveAdmin('')
        } else {
            setActiveUser('')
            setActiveAdmin('activeLoginBtn')
        }
    }

    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {
            case "email":
                setEmail(value)
                break;

            case "password":
                setPassword(value)
                break;

            default:
                break;
        }
    }

    const handleButtonClick = async () => {
        if (!email || !password) {
            dispatch(showError({ msg: "Please fill all required details" }))
        } else {
            try {
                dispatch(clearMessage())
                await dispatch(loginUser({ role, email, password }))
                if (isLoading) {
                    setSuccess(false);
                    setLoading(true);
                }
            } catch (err) {
                setSuccess(false);
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        document.title = "Login | DK Masterbox"
        dispatch(clearMessage());
        dispatch(clearState());
    }, [])

    useEffect(() => {
        if (isError) {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: errorMessage }))
            dispatch(clearState());
        }
        if (isSuccess) {
            setSuccess(true);
            setLoading(false);
            dispatch(showSuccess({ msg: "Login successfully" }))
            history.push("/dashboard");
        }
    }, [isError, isSuccess]);

    return (
        <React.Fragment>
            <div className={styles.containerLogin + " " + styles.bg}>
                <div className={styles.wrapLogin}>
                    <div className="w-100 dk-loginBtnTabs">
                        <div className={styles.formLogo}>
                            <img src={Logo} width="100%" alt="logo" />
                        </div>
                        <div className={classes.root}>
                            <ButtonGroup size="large" color="primary" fullWidth aria-label="outlined button group">
                                <Button
                                    onClick={() => clickSetRole('User')}
                                    className={styles.loginButtonsTab + " " + activeUser}>
                                    User
                                </Button>
                                <Button onClick={() => clickSetRole('Admin')} className={styles.loginButtonsTab + " " + activeAdmin}> Admin</Button>
                            </ButtonGroup>
                        </div>
                        <div className="col-12">
                            <TextField
                                name="email"
                                className={classes.margin + classes.textField}
                                id="input-with-icon-textfield"
                                label=""
                                placeholder="Username"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonIcon style={{ color: 'white' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={handleChange}
                                value={email}
                            />
                        </div>
                        <div className="col-12 mt-4">
                            <TextField
                                type="password"
                                name="password"
                                className={classes.margin + classes.textField}
                                id="input-with-icon-textfield"
                                label=""
                                placeholder="Password"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon style={{ color: 'white' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={handleChange}
                                value={password}
                            />
                        </div>
                        <div className={classes.wrapper + " col-12 mt-4 d-flex justify-content-center"}>
                            <Button variant="contained" className={styles.loginButton + " mt-4 mb-5 " + buttonClassname} disabled={loading} onClick={handleButtonClick}><Typography>Login</Typography></Button>
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                        <div className="col-12 mt-5 d-flex justify-content-center">
                            <Link to="\forget-password" className={styles.forgetPassword}><Typography>Forget Password</Typography></Link>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}
export default Login;