import React from "react";
import { Route, Link } from "react-router-dom";
import '../../mainMenu/recruitment/style.scss';

const Submenu = ({ path }) => {
    const renderSubmenu = () => {
        switch (path.split("/")[2]) {
            // case 'dashboard':
            //     return <DashboardSubmenu />
            //     break;

            // case 'tasks':
            //     return <TasksSubmenu />
            //     break;

            // case 'companies':
            //     return <CompanySubmenu />
            //     break;

            // case 'projections':
            //     return <ProjectionSubmenu />
            //     break;

            // case 'resources':
            //     return <ResourceSubmenu />
            //     break;

            // case 'database':
            //     return <DatabaseSubmenu />
            //     break;
        }
    }

    return (
        <React.Fragment>
            {renderSubmenu()}
        </React.Fragment>
    )
}
export default Submenu;