import React, { Suspense, useState, useMemo, useEffect } from "react";
import TableLoader from "../../../utils/tableLoader";
import NoData from "../../../utils/tableNoData";
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import { Route, Link } from "react-router-dom";
import TablePagination from '../../../ui/pagination';
import { makeStyles } from '@material-ui/core/styles';
import '../style.scss';
import SmsLeftPanel from "../SmsLeftPanel";
import { GlobalFilter } from "../filters/GlobalFilter";
import MessageModal from "../MessageModal";
import Authentication from "../../../helpers/authenticate";
import { useDispatch, useSelector } from "react-redux";
import { smsInbox, clearState, deleteSentSms } from "../../../../slice/sms/smsSlice";
import { IMG_CLOUD_BASE_URL } from "../../../../constants";
import UserFilterToggle from "../../../ui/tableUserFilter";
import DateFormat from '../../../utils/dateFormats';
import ShortDetails from '../../../utils/shortDetails';
import DeleteModal from "../../../ui/delete";
import { showSuccess, showError, clearMessage } from "../../../../slice/utils/message/messageSlice";
import $ from "jquery";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(0),
        },
    },
}));

function Table({ loading, columns, data }) {
    const classes = useStyles();
    const [userFilterId, setUserFilterId] = useState('0')
    const [userFilterImg, setUserFilterImg] = useState('0')
    const handlePageChange = (event, value) => {
        gotoPage(Number(value) - 1);
    };

    const clickFilterUser = (e) => {
        setUserFilterId(e.target.id)
        setUserFilterImg(e.target.src)
        setFilter('userId', e.target.id)
    }

    const deleteFilter = (name) => {
        setFilter(name, '')
        if (name === 'userId') {
            setUserFilterId('0')
            setUserFilterImg('0')
        }
    }

    const tableInstance = useTable({
        columns: columns,
        data: data,
        initialState: {
            pageIndex: 0, pageSize: 100, hiddenColumns: ["userId"]
        },
    }, useGlobalFilter, useFilters, useSortBy, usePagination)

    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, state, setGlobalFilter, setFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize } } = tableInstance
    const { globalFilter } = state

    return (
        <React.Fragment>
            <div className="dk-smsTableHead">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="dk-smsTableHead-right">
                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                        <button className="dk-circleBtn"><i class="fas fa-sync-alt"></i></button>
                    </div>
                </div>
            </div>
            <div className="table-reposive">
                <table className="table table-bordered" {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    // Add the sorting props to control sorting. For this example
                                    // we can add them into the header props
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    {(!loading && tableInstance.flatRows.length <= 0) ? <NoData colspan="7" txt="No SMS list found..." /> : null}
                    {
                        (loading) ? <TableLoader colspan="7" /> :
                            <tbody {...getTableBodyProps()}>
                                {page.map(
                                    (row, i) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map(cell => {
                                                    return (
                                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    }
                                )}
                            </tbody>
                    }
                </table>
                <div className="dk-smsTable-pagination">
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="dk-showData">Showing {' '}{pageIndex + 1} to {pageOptions.length} (of {tableInstance.rows.length} entries)</span>
                        <div className={classes.root}>
                            <TablePagination count={pageCount} onChange={handlePageChange} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const SmsInbox = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isActive, setActive] = useState("false");
    const [data, setData] = useState([]);
    const [showDelete, setShowDelete] = useState(false)
    const { smsInboxList, isLoading, isError, isDelete, msg } = useSelector(state => state.sms)
    const [rowId, setRowId] = useState('0')
    const { _id } = useSelector(state => state.auth.userInfo.companyId)
    const companyId = _id;

    useEffect(() => {
        async function fetchData() {
            try {
                dispatch(smsInbox({ companyId }))
            } catch (err) {
                console.log(err)
            }
        }
        fetchData()
    }, [dispatch])

    useEffect(() => {
        if (!isLoading && smsInboxList && smsInboxList.length > -1) {
            setData(smsInboxList)
        }
    }, [isLoading])

    useEffect(() => {
        if (isDelete) {
            setData(smsInboxList)
        }
    }, [isDelete])

    const checkboxToggle = (e) => {
        console.log(e.target)
    }

    const columns = useMemo(() => [
        {
            Header: 'SNo.',
            title: 'SNo.',
            Cell: (row, { cell }) => (
                <React.Fragment>
                    <div className="dk-smsTableCheckbox">
                        <input type="checkbox" className="chkCandidate" name="chkCandidate[]" value={row.row.original._id} onChange={(e) => toggleCheckbox(e, row.row.original._id)} />
                        {Number(row.row.id) + 1}
                    </div>
                </React.Fragment >
            )
        },
        {
            Header: 'From',
            accessor: 'number'
        },
        {
            id: 'userId',
            Header: 'Sent By',
            accessor: 'userId._id',
            show: false
        },
        {
            Header: 'Message',
            accessor: 'smsBody',
            Cell: ({ cell }) => (
                <div className="d-flex align-items-center justify-content-between">
                    <ShortDetails details={cell.row.values.smsBody} maxLength={50} />
                    <MessageModal inboxId={cell.row.original.smsBody} />
                </div>
            )
        },
        {
            Header: 'ReceivedAt',
            accessor: 'createdAt',
            Cell: (row) => (<DateFormat date={row.row.original.createdAt} />)
        },

    ], [])

    const deleteData = async (id) => {
        setRowId(id)
        setShowDelete(true)
    }
    const handlePageChange = (event, value) => {
        // gotoPage(Number(value) - 1);
    };
    const handleClose = () => {
        setShowDelete(false)
    }
    const deleteItem = async () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount == 0) {
            dispatch(deleteSentSms({ rowId }))
        } else {
            var canIds = [];
            $.each($(".chkCandidate:checked"), function () {
                canIds.push($(this).val());
            });
            const { token } = JSON.parse(localStorage.getItem("authentication"));
            fetch('/api/sms/delete', {
                method: 'POST',
                body: JSON.stringify({ 'ids': canIds }),
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token,
                }
            }).then(response => {
                if (response.status === 200) {
                    dispatch(showSuccess({ msg: 'Delete successfully' }))
                    setShowDelete(false)
                    setActive(false)
                    dispatch(smsInbox({}))
                }
            })
        }
    }

    useEffect(() => {
        if (isDelete) {
            dispatch(clearState())
            dispatch(clearMessage())
            dispatch(showSuccess({ msg: msg }))
            setShowDelete(false)
            setRowId('0')
        }
        if (isError) {
            dispatch(showError({ msg: msg }))
            dispatch(clearState())
            dispatch(clearMessage())
        }
    }, [isDelete, isError])
    /** Extra Div */

    const [countCheck, setCountCheck] = useState(0)
    const [checkBtnTxt, setCheckBtnTxt] = useState('Check All')

    const handleToggle = () => {
        //setActive(!isActive);
        $('.chkCandidate').prop('checked', false);
        setActive(false);
    };

    const toggleCheckAll = () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            setCheckBtnTxt('Uncheck All')
            setCountCheck(totalCheckboxes)
            $('.chkCandidate').prop('checked', true);
            // setCheckedBoxes(checkedBoxes.filter((v, i, a) => a.indexOf(v) === i))
        }
        if (checkedBoxCount == totalCheckboxes) {
            setCheckBtnTxt('Check All')
            setCountCheck(0)
            $('.chkCandidate').prop('checked', false);
            setActive(false)
        }
    }
    /** Delete Selected Checkbox  */
    const deleteSelected = () => {
        setShowDelete(true)
    }
    /* End Delete Selected Checkbox*/

    /** Single Checkbox Select */
    const toggleCheckbox = (e, id) => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount > 0) {
            setCountCheck(checkedBoxCount)
            setActive(true)
        } else {
            setCountCheck(0)
            setActive(false)
        }
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            setCheckBtnTxt('Check All')
        }
        if (checkedBoxCount == totalCheckboxes) {
            setCheckBtnTxt('Uncheck All')
        }
    }
    /** End Single Checkbox Select */

    /** End Extra Div */

    return (
        <React.Fragment>
            {
                (showDelete) ? <DeleteModal handleClose={handleClose} deleteItem={deleteItem} /> : null
            }
            <Authentication />
            <div className="dk-smsMain">
                <h3 className="title">SMS Inbox</h3>
                <div className="row">
                    <SmsLeftPanel />
                    <div className="col-md-10">
                        <div className="dk-smsInboxTable">
                            <Table
                                data={data}
                                columns={columns}
                                loading={isLoading}
                            />
                        </div>
                    </div>

                </div>
            </div>
            <div className={`dk-smsMessage-selectMsg ${isActive == true ? "activePopup" : ""}`}>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="dk-selectNo">{countCheck}</div>
                    <div className="dk-rowSelected">
                        <span>Row Selected  </span>
                    </div>
                    <div className="dk-checkAll" onClick={toggleCheckAll}>
                        <span><i class="fas fa-check"></i></span>
                        {checkBtnTxt}
                    </div>
                    <div className="dk-checkAll" onClick={deleteSelected}>
                        <span><i class="far fa-trash-alt"></i></span>
                        Delete
                    </div>
                    <div className="dk-checkAll d-flex align-items-center justify-content-center" onClick={handleToggle}>
                        <span><i class="fas fa-times"></i></span>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}

export default SmsInbox;
