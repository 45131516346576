import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Submit from "../../../../ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import { templeteAdd, clearState } from "../../../../../slice/sms/templateSlice";
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";

const AddEditTemplatesModal = () => {
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { userInfo } = useSelector(state => state.auth)
    const { isInsert, isError, errorMessage } = useSelector(state => state.smsTemplate)
    const { _id } = useSelector(state => state.auth.userInfo.companyId)
    const companyId = _id;

    const userId = userInfo.id;
    const [name, setName] = useState('');
    const [body, setBody] = useState('');

    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {
            case "name":
                setName(value)
                break;

            case "body":
                setBody(value)
                break;

            default:
                break;
        }
    }

    const handleButtonClick = async () => {
        if (!name || !body) {
            dispatch(showError({ msg: 'Please select all fields.' }))
        } else {
            if (!loading) {
                setSuccess(false);
                setLoading(true);
                await dispatch(templeteAdd({ userId: userId, name: name, body: body, companyId: companyId }))
            }
        }
    };

    useEffect(() => {
        dispatch(clearMessage())
    }, [])

    useEffect(() => {
        if (isInsert) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: 'Template saved successfully.' }))
            setName('')
            setBody('')
            setSuccess(true);
            setLoading(false);
            setShow(false);
        }
        if (isError) {
            dispatch(showError({ msg: errorMessage }))
            dispatch(clearState())
            setSuccess(true);
            setLoading(false);
        }
    }, [isInsert, isError])

    return (
        <React.Fragment>
            <button className="dk-circleBtn" onClick={handleShow}><i class="fas fa-plus"></i></button>
            <Modal className="dk-smsMessageModal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Template</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-mlAddModalBody dk-smstemplatesModalBody">
                    <form action="">
                        <div className="form-group">
                            <label htmlFor="">Template Name:</label>
                            <label htmlFor="">
                                <input type="text" name="name" className="form-control" value={name} onChange={handleChange} />
                            </label>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Template Body:</label>
                            <label htmlFor="">
                                <textarea name="body" className="form-control" value={body} onChange={handleChange}></textarea>
                            </label>
                        </div>
                        <div className="form-group text-center mb-0">
                            <label htmlFor="">
                                <Submit txt="Submit" loading={loading} success={success} onClick={handleButtonClick} position="justify-content-center" />
                            </label>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default AddEditTemplatesModal;
