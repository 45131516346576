import React from "react";
import { Route, Link } from "react-router-dom";
import Submenu from "../../subMenu/admin/";
import { useSelector } from "react-redux";
import './style.scss';

const AdminNavbar = () => {
    const { allBadge } = useSelector(state => state.tasks);
    const pathname = window.location.pathname;
    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-adminNavbar">
                <Route>
                    <ul>
                        <li><Link to="/admin/dashboard" className={`${pathname.match('/admin/dashboard') ? 'active' : ''}`}>Dashboard</Link></li>
                        <li><Link to="#"><span className="dk-badge">0</span>Mailbox</Link></li>
                        <li><Link to="#"><span className="dk-badge">0</span>Alerts</Link></li>
                        <li><Link to="#"><span className="dk-badge">0</span>Support</Link></li>
                        <li><Link to="#">Apps</Link></li>
                        <li><Link to="#">Subscription</Link></li>
                        <li><Link to="#">Billings</Link></li>
                        <li><Link to="#">Profile</Link></li>
                        <li><Link to="/admin/tools/backend-values" className={`${pathname.match('/admin/tools') ? 'active' : ''}`}>Tools</Link></li>
                        <li><Link to="#">Users</Link></li>
                        <li><Link to="#">Settings</Link></li>
                    </ul>
                </Route>
            </div>
            <Submenu path={pathname} />
        </React.Fragment>
    );
}

export default AdminNavbar;
