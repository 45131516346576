import React, { useState } from 'react';
import { Route, Link } from "react-router-dom";
import '../mlresource/style.scss';
import Modal from 'react-bootstrap/Modal';

const DeleteModal = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <React.Fragment>
            <Link to="#" onClick={handleShow} className="dk-delete"><i class="far fa-trash-alt"></i></Link>
            <Modal className="dk-mlAddNewModal dk-deleteModal" show={show} onHide={handleClose}>
                <Modal.Body className="dk-mlAddModalBody">
                    <p>Are you sure wants to Delete these records ?</p>
                    <div className="d-flex justify-content-end">
                        <button>No</button>
                        <button className="dk-yesBtn">Yes</button>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default DeleteModal;
