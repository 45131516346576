import React from 'react'
const ExtraDiv =({isActive,countCheck,toggleCheckAll,checkBtnTxt,deleteSelected,handleToggle})=>{ 
    return(
        <React.Fragment>
            <div className={`dk-smsMessage-selectMsg ${isActive ? "activePopup" : ""}`}>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="dk-selectNo">{countCheck}</div>
                    <div className="dk-rowSelected">
                        <span>Row Selected</span>
                    </div>  
                    <div className="dk-checkAll" onClick={toggleCheckAll}>
                        <span><i class="fas fa-check"></i></span>
                        {checkBtnTxt}
                    </div>
                    <div className="dk-checkAll" onClick={deleteSelected}>
                        <span><i class="far fa-trash-alt"></i></span>
                        Delete
                    </div>
                    <div className="dk-checkAll d-flex align-items-center justify-content-center" onClick={handleToggle}>
                        <span><i class="fas fa-times"></i></span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ExtraDiv;