import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import './style.scss';

const InformationCentreDashboard = () => {
    return (
        <React.Fragment>

        </React.Fragment>
    )
}
export default InformationCentreDashboard;
