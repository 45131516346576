import React,{useState} from 'react';
import {Modal} from 'react-bootstrap';
import "./addnewres.scss"
import FlagImg from "../../../../../../assets/images/takeimage.png";


const AddnewModal = ({onHide}) => {

    return (
        <React.Fragment>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dk-addNewResModalDialog modal-40w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton className="dk-resourcePopupHedaer">
                    <Modal.Title id="example-custom-modal-styling-title">
                        Add New
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <div className="form-group dk-imgRowDiv">
                                    <div className="dk-flagUploadFilter">
                                        <div className="dk-imgincFlagCon">
                                            <input type="file" />    
                                            <img className="previewImglass" src={FlagImg} alt="preview" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Name of Country</label>
                                    <input type="text" className="form-control broderContrl" name="countryName" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Currency</label>
                                    <input type="text" className="form-control broderContrl" name="currency" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Income Upto</label>
                                    <input type="text" className="form-control broderContrl" name="income" />
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                    <label>Basic Income Tax</label>
                                    <input type="text" className="form-control broderContrl" name="basicIncome" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Others Tax</label>
                                    <input type="text" className="form-control broderContrl" name="securityTax" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Total Tax</label>
                                    <input type="text" className="form-control broderContrl" name="healthTax" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Net Income</label>
                                    <input type="text" className="form-control broderContrl" name="payableTax" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="dk-resourcePopupbtnCont">
                                    <button className="">ADD</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default AddnewModal;
