import React from 'react';
import  "./style.scss";
import Reacuiter from "../../../../assets/images/deepak.png";
import {DropdownButton,Dropdown,Tab,Nav} from 'react-bootstrap';
import Contacts from './contacts/Contacts';
import WorkFlow from './workflow/WorkFlow';
import Agreements from './agreements/Agreements';
import Invoices from './invoices/Invoices';
import Hiringfee from './hiringfee/Hiringfee';
import Social from './social/Social';
import Interviews from './interviews/Interviews';
import Preferences from './preferences/Preferences';

const DetailView = () => {
    return (
        <React.Fragment>
            <div className="container-fluid dk-cliDetVPageCon">
                <div className="dk-clientDetViewTopSec">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="dk-cliDetViewPagN">
                                <ul>
                                    <li className="dk-detListHover">
                                        <a href="#">
                                            <i className="fa fa-fast-backward" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li className="dk-detListHover">
                                        <a href="#">
                                            <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li aria-current="page">
                                        <a href="#" className="dk-NoCounttext">1 of 100 records</a>
                                    </li>
                                    <li className="dk-detListHover">
                                        <a href="#">
                                            <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li className="dk-detListHover">
                                        <a href="#">
                                            <i className="fa fa-fast-forward" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-5 col-md-5 col-sm-12 pr-4">
                            <div className="dk-cliDetilFormCon">
                                <div className="row">
                                    <div className="col-md-7 col-sm-12">
                                        <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label>Company Name</label>
                                            <input type="text" className="form-control" placeholder="Deepak Kumar" />
                                            <span className="dk-clViewAddonIcon">
                                                <i className="fa fa-building"  aria-hidden="true"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-5 col-sm-12 pl-0">
                                        <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label className="dk-smalllabel">Client ID</label>
                                            <input type="text" className="form-control" placeholder="4567" />
                                            <span className="dk-clViewAddonIcon">
                                                <i className="fa fa-user" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label>City/Town</label>
                                            <input type="text" className="form-control" placeholder="Dublin" />
                                            <span className="dk-clViewAddonIcon">
                                                <i className="fa fa-map-marker" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-0">
                                        <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label className="dk-smalllabel">Country</label>
                                            <input type="text" className="form-control" placeholder="Ireland"/>
                                            <span className="dk-clViewAddonIcon">
                                                <i className="fa fa-map-marker" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label>Industry</label>
                                            <select className="form-control" id="exampleFormControlSelect1">
                                                <option>Software</option>
                                                <option>Transport</option>
                                                <option>Computer</option>
                                                <option>Education</option>
                                                <option>Pharmaceutical</option>
                                            </select>
                                            <span className="dk-clViewAddonIcon">
                                                <i className="fa fa-building" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label>Business</label>
                                            <select className="form-control" id="exampleFormControlSelect1">
                                                <option>Business 1</option>
                                                <option>Business 2</option>
                                                <option>Business 3</option>
                                                <option>Business 4</option>
                                                <option>Business 5</option>
                                            </select>
                                            <span className="dk-clViewAddonIcon">
                                                <i className="fa fa-building" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group dk-Candinate-fromGroup d-flex mb-0">
                                            <label>Address</label>
                                            <input type="text" className="form-control" placeholder="Bloom House, Railway St, Mountjoy, Dublin, Ireland" />
                                            <span className="dk-clViewAddonIcon">
                                                <i className="fa fa-map-marker" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-2 col-sm-12">
                            <div className="dk-cliDetViewCntreCon"> 
                                <div className="dk-cliRecImgPopMainCon">
                                   <img src={Reacuiter} at="ReacuiterImg" />
                                </div>
                                <div className="dk-cliDorpDwon">
                                    <DropdownButton id="dropdown-basic-button" title="Active">
                                        <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                        <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                    </DropdownButton>
                                </div>
                                <div className="dk-ClientimgUpload d-flex mb-0">
                                    <div className="dk-recuploadImg">
                                        <input type="file" name="" id="" />
                                        <span>
                                            <img src="https://www.dkmasterbox.com/assets/images/logo.png" className="img-fluid" alt="" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-md-5 col-sm-12 pl-4">
                            <div className="dk-cliDetilFormCon">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group dk-Candinate-fromGroup d-flex">
                                                    <label className="dk-smalllabel">Phone 1</label>
                                                    <input type="text" className="form-control" placeholder="+351 911 775 132" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group dk-Candinate-fromGroup d-flex">
                                                    <label className="dk-smalllabel">Phone 2</label>
                                                    <input type="text" className="form-control" placeholder="+351 911 775 132" />
                                                    <span className="dk-clViewAddonIcon">
                                                        <i className="fa fa-phone" aria-hidden="true"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label className="dk-smalllabel">Email @</label>
                                            <input type="text" className="form-control" placeholder="deepak123@gmail.com" />
                                            <span className="dk-clViewAddonIcon">
                                                <i className="fa fa-envelope" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                        <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label className="dk-smalllabel">Website 1</label>
                                            <input type="text" className="form-control" placeholder="https://www.linkedin.com/" />
                                            <span className="dk-clViewAddonIcon">
                                                <i className="fa fa-globe" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                        <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label className="dk-smalllabel">Website 2</label>
                                            <input type="text" className="form-control" placeholder="https://www.linkedin.com/" />
                                            <span className="dk-clViewAddonIcon">
                                                <i className="fa fa-globe" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                        <div className="form-group dk-Candinate-fromGroup d-flex mb-0">
                                            <label className="dk-smalllabel">CV Upload</label>
                                            <input type="text" className="form-control" placeholder="https://www.linkedin.com/" />
                                            <span className="dk-clViewAddonIcon">
                                                <i className="fa fa-globe" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4 pl-2 pr-2">
                        <div className="col-md-12 col-sm-12 dk-DetViewBottomSecCon">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="details">
                                <div className="row">
                                    <div className="col-md-2 col-sm-12 dk-ClieDetViewSideMenu pl-0" id="dk-cliDetailsV">
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="details">Details</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="contacts">Contacts</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="socialm">Social M</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="workflow">Workflow</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="interviews">Interviews</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="preferences">Preferences</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="agreements">Agreements</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="invoices">Invoices</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="hiringfee">Hiring Fee</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    </div>
                                    <div className="col-md-10 col-sm-12 dk-ClieDetViewMainCont">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="details">
                                            <div className="dk-ClentViewTabPane">
                                                <div className="row pr-3">
                                                    <div className="col-md-4 col-sm-12">
                                                            <div className="dk-cliDetTabPaneBlkCon">
                                                                <div className="dk-cliDetTitle">
                                                                    <h2>DEMAND</h2>
                                                                </div>
                                                                <div className="dk-cliDeteContent">
                                                                    <p>
                                                                    
                                                                    </p>
                                                                </div>
                                                            </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-12">
                                                            <div className="dk-cliDetTabPaneBlkCon">
                                                                <div className="dk-cliDetTitle">
                                                                    <h2>PERFORMANCE</h2>
                                                                </div>
                                                                <div className="dk-cliDeteContent">
                                                                    <p>
                                                                    
                                                                    </p>
                                                                </div>
                                                            </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-12">
                                                            <div className="dk-cliDetTabPaneBlkCon">
                                                                <div className="dk-cliDetTitle">
                                                                    <h2>SALE</h2>
                                                                </div>
                                                                <div className="dk-cliDeteContent">
                                                                    <p>
                                                                    
                                                                    </p>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="contacts">
                                            <div className="dk-ClentViewTabPane">
                                                <Contacts />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="socialm">
                                           <div className="dk-ClentViewTabPane">
                                               <Social />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="workflow">
                                            <div className="dk-ClentViewTabPane">
                                                <WorkFlow />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="interviews">
                                            <div className="dk-ClentViewTabPane">
                                                <Interviews />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="preferences">
                                            <div className="dk-ClentViewTabPane">
                                               <Preferences />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="agreements">
                                            <div className="dk-ClentViewTabPane">
                                                <Agreements />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="invoices">
                                            <div className="dk-ClentViewTabPane">
                                               <Invoices />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="hiringfee">
                                            <div className="dk-ClentViewTabPane">
                                                <Hiringfee />
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                    </div>
                                </div>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default DetailView;
